import LoginTypes from './LoginTypes';

// Button management
export function loadLoginSetting (data) {
  return {
    type: LoginTypes.GET_CUSTOM_LOGIN_REQUEST,
    payload: data
  };
}
export function updateLoginSetting (data) {
  return {
    type: LoginTypes.UPDATE_CUSTOM_LOGIN_REQUEST,
    payload: data
  };
}
export function initLoginSetting (data) {
  return {
    type: LoginTypes.INIT_CUSTOM_LOGIN_REQUEST,
    payload: data
  };
}
