export default {
  GET_HEADER_SETTING: {
    url: '/header/detail',
    method: 'GET'
  },
  UPDATE_HEADER: {
    url: '/header/update',
    method: 'PUT'
  },
  GET_BODY_SETTING: {
    url: '/body/detail',
    method: 'GET'
  },
  UPDATE_BODY: {
    url: '/body/update',
    method: 'PUT'
  },
  GET_FOOTER_SETTING: {
    url: '/footer/detail',
    method: 'GET'
  },
  UPDATE_FOOTER: {
    url: '/footer/update',
    method: 'PUT'
  },
  GET_LINK_DETAIL: {
    url: '/footer/detail/',
    method: 'GET'
  },
  CREATE_LINK: {
    url: '/footer/add',
    method: 'POST'
  },
  UPDATE_LINK: {
    url: '/footer/update/',
    method: 'PUT'
  },
  DELETE_LINK: {
    url: '/footer/delete/',
    method: 'DELETE'
  }
};
