const path = '/user-group';

export default {
  GET_USER_GROUPS_ALL: {
    url: `${path}/list`,
    method: 'GET'
  },
  GET_USER_GROUPS: {
    url: `${path}/page`,
    method: 'GET'
  },
  GET_USER_DETAIL: {
    url: `${path}/detail/`,
    method: 'GET'
  },
  DELETE_USER_GROUP: {
    url: `${path}/delete/`,
    method: 'DELETE'
  },
  UPDATE_USER_GROUP: {
    url: `${path}/update/`,
    method: 'PUT'
  },
  ADD_USER_GROUP: {
    url: `${path}/add`,
    method: 'POST'
  }
};
