const _prefix = '@message/';

export default {
  ADD_CONTENT_DETAIL_PARAM_REQUEST: _prefix + 'ADD_CONTENT_DETAIL_PARAM_REQUEST',
  UPDATE_CONTENT_DETAIL_PARAM_REQUEST: _prefix + 'UPDATE_CONTENT_DETAIL_PARAM_REQUEST',
  DELETE_CONTENT_DETAIL_PARAM_REQUEST: _prefix + 'DELETE_CONTENT_DETAIL_PARAM_REQUEST',
  GET_CONTENT_DETAIL_PARAM_REQUEST: _prefix + 'GET_CONTENT_DETAIL_PARAM_REQUEST',
  GET_CONTENT_DETAIL_PARAM_SUCCESS: _prefix + 'GET_CONTENT_DETAIL_PARAM_SUCCESS',

  GET_LIST_CONTENT_DETAIL_PARAM_REQUEST: _prefix + 'GET_LIST_CONTENT_DETAIL_PARAM_REQUEST',
  GET_LIST_CONTENT_DETAIL_PARAM_SUCCESS: _prefix + 'GET_LIST_CONTENT_DETAIL_PARAM_SUCCESS',

  UPDATE_ORDINAL_CONTENT_DETAIL_PARAM_REQUEST: _prefix + 'UPDATE_ORDINAL_CONTENT_DETAIL_PARAM_REQUEST',

  GET_PAGE_TITLE_REQUEST: _prefix + 'GET_PAGE_TITLE_REQUEST',
  GET_PAGE_TITLE_SUCCESS: _prefix + 'GET_PAGE_TITLE_SUCCESS',
  GET_PAGE_TITLE_ERROR: _prefix + 'GET_PAGE_TITLE_ERROR',

  UPDATE_PAGE_TITLE_REQUEST: _prefix + 'UPDATE_PAGE_TITLE_REQUEST',
  UPDATE_PAGE_TITLE_SUCCESS: _prefix + 'UPDATE_PAGE_TITLE_SUCCESS',

  GET_SEARCH_BOX_REQUEST: _prefix + 'GET_SEARCH_BOX_REQUEST',
  GET_SEARCH_BOX_SUCCESS: _prefix + 'GET_SEARCH_BOX_SUCCESS',

  GET_SEARCH_BOX_ALL: _prefix + 'GET_SEARCH_BOX_ALL',
  GET_SEARCH_BOX_ALL_SUCCESS: _prefix + 'GET_SEARCH_BOX_ALL_SUCCESS',

  GET_SEARCH_BOX_DETAIL_REQUEST: _prefix + 'GET_SEARCH_BOX_DETAIL_REQUEST',
  GET_SEARCH_BOX_DETAIL_SUCCESS: _prefix + 'GET_SEARCH_BOX_DETAIL_SUCCESS',

  ADD_SEARCH_BOX_REQUEST: _prefix + 'ADD_SEARCH_BOX_REQUEST',
  UPDATE_SEARCH_BOX_REQUEST: _prefix + 'UPDATE_SEARCH_BOX_REQUEST',
  DELETE_SEARCH_BOX_REQUEST: _prefix + 'DELETE_SEARCH_BOX_REQUEST',

  GET_TAB_REQUEST: _prefix + 'GET_TAB_REQUEST',
  GET_TAB_SUCCESS: _prefix + 'GET_TAB_SUCCESS',

  GET_TAB_DETAIL_REQUEST: _prefix + 'GET_TAB_DETAIL_REQUEST',
  GET_TAB_DETAIL_SUCCESS: _prefix + 'GET_TAB_DETAIL_SUCCESS',

  ADD_TAB_REQUEST: _prefix + 'ADD_TAB_REQUEST',
  UPDATE_TAB_REQUEST: _prefix + 'UPDATE_TAB_REQUEST',
  DELETE_TAB_REQUEST: _prefix + 'DELETE_TAB_REQUEST',
  UPDATE_ORDINAL_TAB_REQUEST: _prefix + 'UPDATE_ORDINAL_TAB_REQUEST',

  //
  GET_SEARCH_RESULT_REQUEST: _prefix + 'GET_SEARCH_RESULT_REQUEST',
  GET_SEARCH_RESULT_SUCCESS: _prefix + 'GET_SEARCH_RESULT_SUCCESS',
  UPDATE__SEARCH_RESULT_SETTING_REQUEST: _prefix + 'UPDATE__SEARCH_RESULT_SETTING_QUERIES',
  UPDATE_ORDINAL_SEARCH_RESULT_SETTING_REQUEST: _prefix + 'UPDATE_ORDINAL_SEARCH_RESULT_SETTING_REQUEST',

  UPDATE_SEARCH_RESULT_QUERIES: _prefix + 'UPDATE_SEARCH_RESULT_QUERIES',
  ADD_CONTENT_PARAM_REQUEST: _prefix + 'ADD_CONTENT_PARAM_REQUEST',
  UPDATE_CONTENT_PARAM_REQUEST: _prefix + 'UPDATE_CONTENT_PARAM_REQUEST',
  GET_CONTENT_PARAM_DETAIL_REQUEST: _prefix + 'GET_CONTENT_PARAM_DETAIL_REQUEST',
  GET_CONTENT_PARAM_DETAIL_SUCCESS: _prefix + 'GET_CONTENT_PARAM_DETAIL_SUCCESS',
  DELETE_CONTENT_PARAM_REQUEST: _prefix + 'DELETE_CONTENT_PARAM_REQUEST',
  GET_SEARCH_RESULT_DETAIL_REQUEST: _prefix + 'GET_SEARCH_RESULT_DETAIL_REQUEST',
  GET_SEARCH_RESULT_DETAIL_SUCCESS: _prefix + 'GET_SEARCH_RESULT_DETAIL_SUCCESS'
};
