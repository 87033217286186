import ContentGroupTypes from './ContentGroupTypes';
import { CONTENT_GROUP_TYPE, PAGINATION } from '../../configs/AppConfig';

const initialState = {
  isContentGroupDetailLoad: true,
  contentGroupData: [],
  contentGroupAll: [],
  pagination: {
    ...PAGINATION,
    totalPages: 0,
    totalRecords: 0
  },

  contentGroupDetail: {
    name: '',
    createdDate: '',
    numRow: '',
    itemSize: ''
  },
  favoritesRecentlyRead: []
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  switch (type) {
    case ContentGroupTypes.GET_CONTENT_GROUP_DATA_SUCCESS:
      newState.contentGroupData = [...payload];
      // newState.pagination.totalRecords = payload.totalElements;
      // newState.pagination.totalPages = payload.totalPages;
      // newState.pagination.size = payload.size;
      // newState.pagination.page = payload.number + 1;
      // newState.pagination = { ...newState.pagination };
      return newState;
    case ContentGroupTypes.GET_CONTENT_GROUP_DETAIL_SUCCESS:
      for (const key in payload) {
        if (!payload[key]) {
          payload[key] = '';
        }
      }
      newState.contentGroupDetail = { ...payload };
      newState.isContentGroupDetailLoad = false;
      return newState;
    case ContentGroupTypes.GET_CONTENT_GROUP_DETAIL_REQUEST:
      newState.isContentGroupDetailLoad = true;
      return newState;
    case ContentGroupTypes.GET_CONTENT_GROUP_ALL_SUCCESS:
      newState.contentGroupAll = [...payload.sort(function (a, b) {
        if (a.default === false && b.default === true) {
          return 1;
        }
        if (a.default === true && b.default === false) {
          return -1;
        }
        if (a.default === true && b.default === true) {
          if (a.type === CONTENT_GROUP_TYPE.NORMAL) {
            return -1;
          }
          if (b.type === CONTENT_GROUP_TYPE.NORMAL) {
            return 1;
          }
        }
        return 0;
      })];
      return newState;
    case ContentGroupTypes.GET_FAVORITES_RECENTLY_SUCCESS:
      newState.favoritesRecentlyRead = [...payload];
      return newState;
    default:
      newState = state;
      break;
  }

  // return new state
  return newState;
}
