export default {
  GET_SUBSCRIPTION_PLAN: {
    url: '/subscription-plan',
    method: 'GET'
  },
  GET_SUBSCRIPTION_PLAN_DETAIL: {
    url: '/subscription-plan/public/',
    method: 'GET'
  },
  UPDATE_SUBSCRIPTION_PLAN_DETAIL: {
    url: '/subscription-plan/',
    method: 'PUT'
  },
  DELETE_SUBSCRIPTION_PLAN: {
    url: '/subscription-plan/',
    method: 'DELETE'
  },
  ADD_SUBSCRIPTION_PLAN: {
    url: '/subscription-plan/add',
    method: 'POST'
  },
  GET_PAGE_SUBSCRIPTION_PLAN: {
    url: '/subscription-plan/page',
    method: 'GET'
  },
  CANCEL_SUBSCRIPTION_PLAN_USER_REGISTERED: {
    url: '/subscription-plan/user/cancel/registered/',
    method: 'DELETE'
  },
  GET_PAGE_USER_SUBSCRIPTION_PLAN: {
    url: '/subscription-plan/public/user/page',
    method: 'GET'
  },
  GET_DETAIL_USER_SUBSCRIPTION_PLAN_REGISTERED: {
    url: '/subscription-plan/user/registered/',
    method: 'GET'
  },
  GET_PAGE_USER_SUBSCRIPTION_PLAN_REGISTERED: {
    url: '/subscription-plan/user/registered/page',
    method: 'GET'
  },
  GET_DISCOUNT_ORDINAL_DETAIL: {
    url: '/discount-ordinal/',
    method: 'GET'
  },
  GET_DISCOUNT_ORDINAL_PAGE: {
    url: '/discount-ordinal/page',
    method: 'GET'
  },
  ADD_DISCOUNT_ORDINAL: {
    url: '/discount-ordinal/add',
    method: 'POST'
  },
  UPDATE_DISCOUNT_ORDINAL: {
    url: '/discount-ordinal/',
    method: 'PUT'
  },
  DELETE_DISCOUNT_ORDINAL: {
    url: '/discount-ordinal/',
    method: 'DELETE'
  }
};
