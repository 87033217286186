import RouteTypes from './RouteTypes';
import { filterMenuRoutes } from '../../configs/routes';
import UnauthenticatedRoute from '../../components/layout/UnauthenticatedLayout';

const initialState = {
  layoutId: -1, // useful to compare authentication layout when change route
  layoutRoute: UnauthenticatedRoute, // authentication layout [div, AuthenticatedRoute, UnauthenticatedRoute, ...] reading from routes.js config file
  menuRoutes: filterMenuRoutes(),
  history: undefined, // route history
  routeTitle: undefined, // current route title key
  routePath: undefined, // current route path
  cachedRoutePath: undefined, // cached route path to redirect after logged in
  cachedSearch: undefined // cached route query params to redirect after logged in
};

export default function routeReducer (state = initialState, action) {
  const {
    type,
    payload: {
      layoutId,
      layoutRoute,
      history,
      routeTitle,
      routePath,
      pathname,
      search
    } = {}
  } = action;
  switch (type) {
    case RouteTypes.CHANGE_LAYOUT_ROUTE:
      return Object.assign({}, state, { layoutId, layoutRoute });

    case RouteTypes.SET_HISTORY:
      return Object.assign({}, state, { history });

    case RouteTypes.UPDATE_ROUTE:
      return Object.assign({}, state, { routeTitle, routePath });

    case RouteTypes.CACHE_ROUTE:
      return Object.assign({}, state, {
        cachedRoutePath: pathname,
        cachedSearch: search
      });

    default:
      return state;
  }
}
