import { all, put, takeEvery } from 'redux-saga/effects';
import LoginTypes from './LoginTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/custom-login';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';

// Input management

function * getLoginSetting () {
  yield takeEvery(LoginTypes.GET_CUSTOM_LOGIN_REQUEST, _getLoginSetting);
}

function * _getLoginSetting ({ payload }) {
  const api = {
    ...API.GET_LOGIN_SETTING
  };
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      yield put({
        type: LoginTypes.GET_CUSTOM_LOGIN_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * updateLogin () {
  yield takeEvery(LoginTypes.UPDATE_CUSTOM_LOGIN_REQUEST, _updateLogin);
}

function * _updateLogin ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.UPDATE_LOGIN_SETTING,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: LoginTypes.GET_CUSTOM_LOGIN_REQUEST,
        payload: {}
      });
    }
  });
}

function * initLoginSetting () {
  yield takeEvery(LoginTypes.INIT_CUSTOM_LOGIN_REQUEST, _initLoginSetting);
}

function * _initLoginSetting () {
  yield createRequest({
    api: {
      ...API.INIT_LOGIN_SETTING
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    }
  });
}

export default function * customLoginSaga () {
  yield all([
    getLoginSetting(),
    updateLogin(),
    initLoginSetting()
  ]);
}
