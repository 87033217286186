const spacing = 4;
const AppStyle = {
  colors: {
    default: {
      light: '#CCCCCC',
      dark: '#C3C3C3'
    },
    primary: {
      light: '#2196F3',
      dark: '#087DDA'
    },
    success: {
      light: '#4CAF50',
      dark: '#339637'
    },
    info: {
      light: '#00BCD4',
      dark: '#00A3BB'
    },
    warning: {
      light: '#FF9800',
      dark: '#E67F00'
    },
    danger: {
      light: '#E53935',
      dark: '#CC201C'
    },
    text: {
      light: '#448AFF',
      dark: '#212121'
    },
    disabled: {
      light: 'rgba(0, 0, 0, 0.12)',
      dark: 'rgba(0, 0, 0, 0.26)'
    }
  },
  app: {
    spacing: spacing
  },
  button: {
    delay: 100,
    modalDelay: 80,
    circularSize: 28,
    circularThickness: 4
  },
  page: {
    spacingXs: 8,
    spacingSm: 12,
    spacing: 16,
    background: '#FAFAFA'
  },
  sidebar: {
    widthSize: {
      mobile: '80%',
      small: 64,
      medium: 240,
      large: 320
    },
    backdrop: {
      background: 'rgba(0, 0, 0, 0.54)'
    },
    background: '#FFF',

    getSize: size => {
      let _size = 0;
      switch (typeof size) {
        case 'string':
          _size = AppStyle.sidebar.widthSize[size];
          break;
        case 'number':
          _size = size;
          break;
        default:
          _size = 0;
      }
      return _size;
    }
  },
  menuBar: {
    height: 56,
    color: '#FFF',
    background: {
      light: '#03A9F4',
      dark: '#0288D1'
    }
  },
  iconMenu: {
    width: 310,
    profile: {
      width: 216
    }
  },
  footer: {
    height: 36,
    color: '#FFF',
    background: {
      light: '#03A9F4',
      dark: '#0288D1'
    }
  },
  modal: {
    borderRadius: 0,
    title: {
      fontSize: '1.125rem'
    },
    textContent: {
      fontSize: '0.875rem'
    }
  },
  transitionTime: {
    sidebar: {
      width: 200,
      left: 200
    },
    routeMenu: {
      navIcon: 300
    },
    scrollbar: {
      autoHideTimeout: 2000,
      autoHideDuration: 500
    }
  }
};

export default AppStyle;
