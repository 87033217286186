import { isSessionValidating } from '../session/sessionSelector';
import { FlashScreenPage } from '../../configs/routes';

export const getMenuRoutes = state => state.route.menuRoutes;
export const getRouteTitle = state => state.route.routeTitle;
export const getRoutePath = state => state.route.routePath;
export const getCachedRoutePath = state => ({
  pathname: state.route.cachedRoutePath,
  search: state.route.cachedSearch
});
export const getHistory = state => state.route.history;
export const getLayoutRoute = state =>
  isSessionValidating(state) ? FlashScreenPage : state.route.layoutRoute;
