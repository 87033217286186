import TabTypes from './TabTypes';

// Button management
export function addButton (data) {
  return {
    type: TabTypes.ADD_BUTTON_REQUEST,
    payload: data
  };
}
export function updateButton (data) {
  return {
    type: TabTypes.UPDATE_BUTTON_REQUEST,
    payload: data
  };
}
export function deleteButton (data) {
  return {
    type: TabTypes.DELETE_BUTTON_REQUEST,
    payload: data
  };
}
export function loadButtonDetail (data) {
  return {
    type: TabTypes.GET_BUTTON_REQUEST,
    payload: data
  };
}
export function loadTabSetting (data) {
  return {
    type: TabTypes.GET_TAB_REQUEST,
    payload: data
  };
}
export function updateTab (data) {
  return {
    type: TabTypes.UPDATE_TAB_REQUEST,
    payload: data
  };
}
