import { all, put, takeEvery, select } from 'redux-saga/effects';
import SortTypes from './SortTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/sort';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';
import { getCompanyDomain } from 'state/company/companySelector';

// Sort management

function * getListSort () {
  yield takeEvery(SortTypes.GET_LIST_SORT_REQUEST, _getListSort);
}

function * _getListSort ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  const domain = yield select(getCompanyDomain);
  yield createRequest({
    api: {
      ...API.GET_LIST_SORT,
      params: { ...request, domain }
    },
    onSuccess: function * (response) {
      yield put({
        type: SortTypes.GET_LIST_SORT_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * getAllSort () {
  yield takeEvery(SortTypes.GET_ALL_SORT_REQUEST, _getAllSort);
}

function * _getAllSort ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  const domain = yield select(getCompanyDomain);

  yield createRequest({
    api: {
      ...API.GET_ALL_SORT,
      params: { ...request, domain }
    },
    onSuccess: function * (response) {
      yield put({
        type: SortTypes.GET_ALL_SORT_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * getSortDetail () {
  yield takeEvery(SortTypes.GET_SORT_REQUEST, _getSortDetail);
}

function * _getSortDetail ({ payload }) {
  const api = {
    ...API.GET_SORT_DETAIL,
    url: API.GET_SORT_DETAIL.url + payload
  };
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      yield put({
        type: SortTypes.GET_SORT_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * addSort () {
  yield takeEvery(SortTypes.ADD_SORT_REQUEST, _addSort);
}

function * _addSort ({ payload }) {
  const { type } = payload;
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.ADD_SORT,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: SortTypes.GET_ALL_SORT_REQUEST,
        payload: { type }
      });
    }
  });
}

function * updateSort () {
  yield takeEvery(SortTypes.UPDATE_SORT_REQUEST, _updateSort);
}

function * _updateSort ({ payload }) {
  const { type, ...rest } = payload;
  const request = snakecaseKeys({ ...rest });
  yield createRequest({
    api: {
      ...API.UPDATE_SORT,
      url: API.UPDATE_SORT.url + payload.id,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: SortTypes.GET_ALL_SORT_REQUEST,
        payload: { type }
      });
    }
  });
}

function * deleteSort () {
  yield takeEvery(SortTypes.DELETE_SORT_REQUEST, _deleteSort);
}

function * _deleteSort ({ payload }) {
  const { id, type } = payload;
  const api = { ...API.DELETE_SORT };
  api.url += id;
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.delete.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: SortTypes.GET_ALL_SORT_REQUEST,
        payload: { type }
      });
    }
  });
}

function * updateOrdinalSort () {
  yield takeEvery(SortTypes.UPDATE_ORDINAL_SORT_REQUEST, _updateOrdinalSort);
}

function * _updateOrdinalSort ({ payload }) {
  const { type } = payload;
  const request = snakecaseKeys(payload);
  yield createRequest({
    api: {
      ...API.UPDATE_SORT_ORDINAL,
      data: request
    },
    onSuccess: function * (response) {
      yield put({
        type: SortTypes.GET_ALL_SORT_REQUEST,
        payload: { type }
      });
    }
  });
}

function * resetSort () {
  yield takeEvery(SortTypes.RESET_SORT_REQUEST, _resetSort);
}

function * _resetSort () {
  yield createRequest({
    api: {
      ...API.RESET_SORT
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    }
  });
}

export default function * sortSaga () {
  yield all([
    getListSort(),
    getAllSort(),
    getSortDetail(),
    addSort(),
    updateSort(),
    deleteSort(),
    resetSort(),
    updateOrdinalSort()
  ]);
}
