import SessionTypes from './SessionTypes';

export function login (payload) {
  return {
    type: SessionTypes.LOG_IN_REQUEST,
    payload: payload
  };
}

export function logout () {
  return {
    type: SessionTypes.LOG_OUT
  };
}

export function cacheRoutePath () {
  return {
    type: SessionTypes.CACHE_PREV_LOGIN_ROUTE
  };
}

export function clearAuthInfo () {
  return {
    type: SessionTypes.CLEAR_AUTH
  };
}

export function registerAccountUser (payload) {
  return {
    type: SessionTypes.REGISTER_ACCOUNT_USER_REQUEST,
    payload: payload
  };
}

export function resetRegister () {
  return {
    type: SessionTypes.RESET_USER_REGISTERED
  };
}
