const _prefix = '@content-detail/';

export default {
  GET_SETTING_REQUEST: _prefix + 'GET_SETTING_REQUEST',
  GET_SETTING_SUCCESS: _prefix + 'GET_SETTING_SUCCESS',

  UPDATE_SETTING_REQUEST: _prefix + 'UPDATE_SETTING_REQUEST',

  GET_BUTTON_REQUEST: _prefix + 'GET_BUTTON_REQUEST',
  GET_BUTTON_SUCCESS: _prefix + 'GET_BUTTON_SUCCESS',

  UPDATE_BUTTON_REQUEST: _prefix + 'UPDATE_BUTTON_REQUEST',
  CREATE_BUTTON_REQUEST: _prefix + 'CREATE_BUTTON_REQUEST',
  DELETE_BUTTON_REQUEST: _prefix + 'DELETE_BUTTON_REQUEST'
};
