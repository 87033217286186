export default {
  GET_LOGIN_SETTING: {
    url: '/custom-login/detail',
    method: 'GET'
  },
  UPDATE_LOGIN_SETTING: {
    url: '/custom-login/update',
    method: 'PUT'
  },
  INIT_LOGIN_SETTING: {
    url: '/custom-login/public',
    method: 'POST'
  }
};
