import MessageTypes from './MessageTypes';

// Content detail params management
export function loadListContentDetailParam (data) {
  return {
    type: MessageTypes.GET_LIST_CONTENT_DETAIL_PARAM_REQUEST,
    payload: data
  };
}
export function addContentDetail (data) {
  return {
    type: MessageTypes.ADD_CONTENT_DETAIL_PARAM_REQUEST,
    payload: data
  };
}
export function getContentDetailParam (data) {
  return {
    type: MessageTypes.GET_CONTENT_DETAIL_PARAM_REQUEST,
    payload: data
  };
}
export function updateContentDetailParam (data) {
  return {
    type: MessageTypes.UPDATE_CONTENT_DETAIL_PARAM_REQUEST,
    payload: data
  };
}
export function updateOrdinalContentDetailParam (data) {
  return {
    type: MessageTypes.UPDATE_ORDINAL_CONTENT_DETAIL_PARAM_REQUEST,
    payload: data
  };
}
export function deleteContentDetailParam (data) {
  return {
    type: MessageTypes.DELETE_CONTENT_DETAIL_PARAM_REQUEST,
    payload: data
  };
}

// Page Title Management
export function loadPageTitle (data) {
  return {
    type: MessageTypes.GET_PAGE_TITLE_REQUEST,
    payload: data
  };
}
export function updatePageTitle (data) {
  return {
    type: MessageTypes.UPDATE_PAGE_TITLE_REQUEST,
    payload: data
  };
}

// Search Box Management
export function loadSearchBox (data) {
  return {
    type: MessageTypes.GET_SEARCH_BOX_REQUEST,
    payload: data
  };
}
export function loadALLSearchBox (data) {
  return {
    type: MessageTypes.GET_SEARCH_BOX_ALL,
    payload: data
  };
}
export function loadSearchBoxDetail (data) {
  return {
    type: MessageTypes.GET_SEARCH_BOX_DETAIL_REQUEST,
    payload: data
  };
}
export function addSearchBox (data) {
  return {
    type: MessageTypes.ADD_SEARCH_BOX_REQUEST,
    payload: data
  };
}
export function updateSearchBox (data) {
  return {
    type: MessageTypes.UPDATE_SEARCH_BOX_REQUEST,
    payload: data
  };
}
export function deleteSearchBox (data) {
  return {
    type: MessageTypes.DELETE_SEARCH_BOX_REQUEST,
    payload: data
  };
}

// Layout Tab
export function loadTab (data) {
  return {
    type: MessageTypes.GET_TAB_REQUEST,
    payload: data
  };
}
export function loadTabDetail (data) {
  return {
    type: MessageTypes.GET_TAB_DETAIL_REQUEST,
    payload: data
  };
}
export function addTab (data) {
  return {
    type: MessageTypes.ADD_TAB_REQUEST,
    payload: data
  };
}
export function updateTab (data) {
  return {
    type: MessageTypes.UPDATE_TAB_REQUEST,
    payload: data
  };
}
export function updateOrdinalTab (data) {
  return {
    type: MessageTypes.UPDATE_ORDINAL_TAB_REQUEST,
    payload: data
  };
}
export function deleteTab (data) {
  return {
    type: MessageTypes.DELETE_TAB_REQUEST,
    payload: data
  };
}
export function loadSearchResults (data) {
  return {
    type: MessageTypes.GET_SEARCH_RESULT_REQUEST,
    payload: data
  };
}
export function updateDetailSearchResult (data) {
  return {
    type: MessageTypes.UPDATE__SEARCH_RESULT_SETTING_REQUEST,
    payload: data
  };
}
export function updateOrdinalDetailSearchResult (data) {
  return {
    type: MessageTypes.UPDATE_ORDINAL_SEARCH_RESULT_SETTING_REQUEST,
    payload: data
  };
}

export function loadContentParamDetail (data) {
  return {
    type: MessageTypes.GET_CONTENT_PARAM_DETAIL_REQUEST,
    payload: data
  };
}

export function addContentParam (data) {
  return {
    type: MessageTypes.ADD_CONTENT_PARAM_REQUEST,
    payload: data
  };
}

export function deleteContentParam (data) {
  return {
    type: MessageTypes.DELETE_CONTENT_PARAM_REQUEST,
    payload: data
  };
}

export function updateContentParamDetail (data) {
  return {
    type: MessageTypes.UPDATE_CONTENT_PARAM_REQUEST,
    payload: data
  };
}

export function loadSearchResultDetail (data) {
  return {
    type: MessageTypes.GET_SEARCH_RESULT_DETAIL_REQUEST,
    payload: data
  };
}
