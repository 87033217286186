import HashtagTypes from './HashtagTypes';

/**
 * load Hashtag
 */
export function loadHashtagList (data) {
  return {
    type: HashtagTypes.GET_LIST_HASHTAG_REQUEST,
    payload: data
  };
}

/**
 * load Search box
 */
export function loadSearchBoxHashtag (data) {
  return {
    type: HashtagTypes.GET_SEARCH_BOX_HASHTAG_REQUEST,
    payload: data
  };
}

/**
 * Update Hashtag
 * @data {
    id: 'string'
    ordinal_number: number,
    value: "string"
  }
 */
export function updateHashtagDetail (data) {
  return {
    type: HashtagTypes.UPDATE_HASHTAG_REQUEST,
    payload: data
  };
}

/**
 * Update Hashtag
 * @data { search_fields: [Array]}
 */
export function updateSearchBoxHashtag (data) {
  return {
    type: HashtagTypes.UPDATE_SEARCH_BOX_HASHTAG_REQUEST,
    payload: data
  };
}

/**
 * Update Hashtag
 * @data { is_multiple_select: Boolean }
 */
export function updateMultipleSelectHashtag (data) {
  return {
    type: HashtagTypes.UPDATE_MULTIPLE_SELECT_HASH_TAG_REQUEST,
    payload: data
  };
}

/**
 * Add Hashtag
 * @data { value: 'string' }
 */
export function addHashtag (data) {
  return {
    type: HashtagTypes.ADD_HASHTAG_REQUEST,
    payload: data
  };
}

/**
 * Delete Hashtag
 * @data { value: 'string' }
 */
export function deleteHashtag (data) {
  return {
    type: HashtagTypes.DELETE_HASHTAG_REQUEST,
    payload: data
  };
}

export function getPublicHashtag (data) {
  return {
    type: HashtagTypes.GET_LIST_HASHTAG_USER_REQUEST,
    payload: data
  };
}

export function getHashtagSetting () {
  return {
    type: HashtagTypes.GET_HASHTAG_SETTING_REQUEST
  };
}

export function updateHashtagSetting (data) {
  return {
    type: HashtagTypes.UPDATE_HASHTAG_SETTING_REQUEST,
    payload: data
  };
}
