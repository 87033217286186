export const getSubscriptionPlans = state => state.subscription.subscriptionPlans;
export const getSubscriptionAll = state => state.subscription.subscriptionAll;
export const getSubscriptionDetail = state => state.subscription.subscriptionDetail;
export const getPageSubscriptionPlan = state => state.subscription.subscriptionPage;
export const getPagination = state => state.subscription.pagination;
export const getIsOpenPopup = state => state.subscription.isOpenPopUp;
export const getIsSaving = state => state.subscription.isSaving;
export const getIsLoading = state => state.subscription.isLoading;
export const getIsLoadDetail = state => state.subscription.isLoadDetail;
export const getIsListLoad = state => state.subscription.isListLoad;
export const getSubscriptionList = state => state.subscription.subscriptionList;
export const getSubscriptionOrdinalList = state => state.subscription.subscriptionOrdinalList;
export const getSubscriptionOrdinalDetail = state => state.subscription.subscriptionOrdinalDetail;
export const getIsSubscriptionDetailLoad = state => state.subscription.isSubscriptionDetailLoad;
export const getQueries = state => state.subscription.queries;
export const getHomeSubscriptionList = state => state.subscription.homeSubscriptionList;
export const getPaginationDiscount = state => state.subscription.paginationDiscount;
export const getIsLoadHomeSubscriptionList = state => state.subscription.isLoadHomeSubscriptionList;
