import { all, takeEvery, put, select } from 'redux-saga/effects';
import CompanyTypes from './CompanyTypes';
import NotificationTypes from '../notification/NotificationTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/company';
import Util from '../../utils/Util';
import snakecaseKeys from 'snakecase-keys';
import { changeRoute } from '../route/RouteActions';
import { getCompanyDomain } from './companySelector';
import SearchTypes from 'state/search/SearchTypes';

function * loadCompanies () {
  return yield takeEvery(CompanyTypes.GET_COMPANIES_REQUEST, _loadCompanies);
}

function * _loadCompanies ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_COMPANY_LIST,
      params: { ...payload }
    },
    onSuccess: function * (data) {
      yield put({
        type: CompanyTypes.UPDATE_COMPANIES_QUERIES,
        payload: payload
      });
      yield put({
        type: CompanyTypes.GET_COMPANIES_SUCCESS,
        payload: Util.toCamelCaseKey(data.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: CompanyTypes.GET_COMPANIES_ERROR,
        payload: status
      });
    }
  });
}

function * loadCompanyDetail () {
  return yield takeEvery(CompanyTypes.GET_COMPANY_DETAIL_REQUEST, _loadCompanyDetail);
}

function * _loadCompanyDetail ({ payload }) {
  const api = { ...API.GET_COMPANY_DETAIL };
  api.url += payload;
  yield createRequest({
    api,
    onSuccess: function * (response) {
      const data = Util.toCamelCaseKey(response.data);
      yield put({
        type: CompanyTypes.GET_COMPANY_DETAIL_SUCCESS,
        payload: { ...data }
      });
    }
  });
}

function * createCompany () {
  return yield takeEvery(CompanyTypes.CREATE_COMPANIES_REQUEST, _createCompany);
}

function * _createCompany ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.CREATE_COMPANY,
      data: request
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put(changeRoute('/admin/companies'));
    },
    onError: function * ({ status }) {
      yield put({
        type: CompanyTypes.CREATE_COMPANIES_ERROR,
        payload: status
      });
    }
  });
}

function * updateCompanyDetail () {
  return yield takeEvery(CompanyTypes.UPDATE_COMPANY_DETAIL_REQUEST, _updateCompanyDetail);
}

function * _updateCompanyDetail ({ payload }) {
  const api = { ...API.UPDATE_COMPANY };
  api.url += payload.id;
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...api,
      data: request
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      if (payload.apiKey) {
        yield put({
          type: CompanyTypes.GET_COMPANY_DETAIL_REQUEST,
          payload: payload.id
        });
      }
    },
    onError: function * ({ status }) {
      yield put({
        type: CompanyTypes.GET_COMPANY_DETAIL_ERROR,
        payload: status
      });
      if (status === 876 || status === 877) {
        yield put({
          type: CompanyTypes.GET_SETTING_REQUEST,
          payload: window.location.hostname
        });
      }
    }
  });
}

function * uploadCompanyLogo () {
  return yield takeEvery(CompanyTypes.UPLOAD_COMPANY_LOGO_REQUEST, _uploadCompanyLogo);
}

function * _uploadCompanyLogo ({ payload }) {
  const formData = new FormData();
  formData.append('file', payload.file);
  yield createRequest({
    api: {
      ...API.UPLOAD_LOGO,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: { id: payload.id },
      data: formData
    },
    onSuccess: function * ({ status }) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.upload.${status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: CompanyTypes.GET_COMPANY_DETAIL_REQUEST,
        payload: payload.id
      });
    }
  });
}

function * uploadCompanyFavicon () {
  return yield takeEvery(CompanyTypes.UPLOAD_COMPANY_FAVICON_REQUEST, _uploadCompanyFavicon);
}

function * _uploadCompanyFavicon ({ payload }) {
  const formData = new FormData();
  formData.append('file', payload.file);
  yield createRequest({
    api: {
      ...API.UPLOAD_FAVICON,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: { id: payload.id },
      data: formData
    },
    onSuccess: function * ({ status }) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.upload.${status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: CompanyTypes.GET_COMPANY_DETAIL_REQUEST,
        payload: payload.id
      });
    }
  });
}

function * deleteCompany () {
  yield takeEvery(CompanyTypes.DELETE_COMPANY_REQUEST, _deleteCompany);
}

function * _deleteCompany ({ payload }) {
  const api = { ...API.DELETE_COMPANY };
  api.url += payload.id;
  yield createRequest({
    api: api,
    onSuccess: function * ({ status }) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.delete.${status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: CompanyTypes.GET_COMPANIES_REQUEST,
        payload: payload.queries
      });
    }
  });
}

function * loadEndUserCompanyDetail () {
  yield takeEvery(CompanyTypes.GET_END_USER_COMPANY_DETAIL_REQUEST, _loadEndUserCompanyDetail);
}

function * _loadEndUserCompanyDetail ({ payload }) {
  const domain = yield select(getCompanyDomain);
  yield createRequest({
    api: {
      ...API.GET_END_USER_COMPANY_DETAIL,
      params: {
        domain: domain
      }
    },
    onSuccess: function * ({ data }) {
      yield put({
        type: CompanyTypes.GET_END_USER_COMPANY_DETAIL_SUCCESS,
        payload: Util.toCamelCaseKey(data)
      });
    },
    onError: function * ({ status }) {
      if (status === 201 && !window.location.origin.includes('admin')) {
        yield put(changeRoute('/'));
      }
    }
  });
}

function * getSetting () {
  yield takeEvery(CompanyTypes.GET_SETTING_REQUEST, _getSetting);
}

function * _getSetting ({ payload }) {
  const request = snakecaseKeys({ domain: payload });
  yield put({
    type: SearchTypes.GET_SETTING_REQUEST,
    payload: {}
  });
  yield createRequest({
    api: {
      ...API.GET_SETTING,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: CompanyTypes.GET_SETTING_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
      yield put({
        type: SearchTypes.GET_SETTING_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * () {
      yield put({
        type: CompanyTypes.GET_SETTING_ERROR,
        payload: {}
      });
    }
  });
}

function * verifyPaymentVeritrans () {
  yield takeEvery(CompanyTypes.VERIFY_PAYMENT_VERITRANS_REQUEST, _verifyPaymentVeritrans);
}

function * _verifyPaymentVeritrans ({ payload }) {
  const request = snakecaseKeys({ ...payload.data });
  yield createRequest({
    api: {
      ...API.VERIFY_PAYMENT_VERITRANS,
      params: request
    },
    onSuccess: function * ({ status }) {
      yield put({
        type: CompanyTypes.VERIFY_PAYMENT_VERITRANS_SUCCESS
      });
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.verify.veritrans.${status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: CompanyTypes.VERIFY_PAYMENT_VERITRANS_ERROR
      });
    }
  });
}

function * updatePaymentVeritrans () {
  yield takeEvery(CompanyTypes.UPDATE_PAYMENT_VERITRANS_REQUEST, _updatePaymentVeritrans);
}

function * _updatePaymentVeritrans ({ payload }) {
  const request = snakecaseKeys({ ...payload.data });
  yield createRequest({
    api: {
      ...API.UPDATE_PAYMENT_VERITRANS,
      data: request
    },
    onSuccess: function * ({ status }) {
      yield put({
        type: CompanyTypes.UPDATE_PAYMENT_VERITRANS_SUCCESS
      });
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: CompanyTypes.UPDATE_PAYMENT_VERITRANS_ERROR
      });
    }
  });
}

export default function * companySaga () {
  yield all([
    loadCompanies(),
    loadCompanyDetail(),
    createCompany(),
    updateCompanyDetail(),
    uploadCompanyLogo(),
    uploadCompanyFavicon(),
    deleteCompany(),
    loadEndUserCompanyDetail(),
    getSetting(),
    updatePaymentVeritrans(),
    verifyPaymentVeritrans()
  ]);
}
