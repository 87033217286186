const _prefix = '@contentGroup/';

export default {
  GET_CONTENT_GROUP_DATA_REQUEST: _prefix + 'GET_CONTENT_GROUP_DATA_REQUEST',
  GET_CONTENT_GROUP_DATA_SUCCESS: _prefix + 'GET_CONTENT_GROUP_DATA_SUCCESS',
  GET_CONTENT_GROUP_DATA_ERROR: _prefix + 'GET_CONTENT_GROUP_DATA_ERROR',

  ADD_CONTENT_GROUP_REQUEST: _prefix + 'ADD_CONTENT_GROUP_REQUEST',
  UPDATE_CONTENT_GROUP_REQUEST: _prefix + 'UPDATE_CONTENT_GROUP_REQUEST',
  DELETE_CONTENT_GROUP_REQUEST: _prefix + 'DELETE_CONTENT_GROUP_REQUEST',

  GET_CONTENT_GROUP_DETAIL_REQUEST: _prefix + 'GET_CONTENT_GROUP_DETAIL_REQUEST',
  GET_CONTENT_GROUP_DETAIL_SUCCESS: _prefix + 'GET_CONTENT_GROUP_DETAIL_SUCCESS',
  GET_CONTENT_GROUP_DETAIL_ERROR: _prefix + 'GET_CONTENT_GROUP_DETAIL_ERROR',

  GET_CONTENT_GROUP_ALL_REQUEST: _prefix + 'GET_CONTENT_GROUP_ALL_REQUEST',
  GET_CONTENT_GROUP_ALL_SUCCESS: _prefix + 'GET_CONTENT_GROUP_ALL_SUCCESS',
  GET_CONTENT_GROUP_ALL_ERROR: _prefix + 'GET_CONTENT_GROUP_ALL_ERROR',

  RESET_CONTENT_GROUP_REQUEST: _prefix + 'RESET_CONTENT_GROUP_REQUEST',
  GET_FAVORITES_RECENTLY_REQUEST: _prefix + 'GET_FAVORITES_RECENTLY_REQUEST',
  GET_FAVORITES_RECENTLY_SUCCESS: _prefix + 'GET_FAVORITES_RECENTLY_SUCCESS',

  UPDATE_REF_CODE_REQUEST: _prefix + 'UPDATE_REF_CODE_REQUEST',
  UPDATE_REF_CODE_SUCCESS: _prefix + 'UPDATE_REF_CODE_SUCCESS',
  UPDATE_REF_CODE_ERROR: _prefix + 'UPDATE_REF_CODE_ERROR'
};
