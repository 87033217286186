import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ActionContext } from '../layout/MainContainer';
import {
  getViewerToken,
  getPrintSettingViewer,
  getIsErrorViewerOnline,
  getContentTypeViewer,
  getWatermark
} from 'state/content/contentSelector';
import {
  getBeViewerHost
} from 'state/search/searchSelector';
import { getAuthUser } from 'state/session/sessionSelector';
import { useParams } from 'react-router-dom';
import TranslateMessage from 'components/common/TranslateMessage/TranslateMessage';
import { PRINT_SETTING, UPLOAD_FILE_TYPES } from 'configs/AppConfig';
import { useMedia } from 'react-use';

const Style = {
  fullSize: {
    width: '100%',
    height: 'calc(var(--vh, 1vh) * 100)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      fontWeight: 700,
      background: 'rgb(229, 57, 53)',
      color: '#fff',
      padding: '1rem'
    }
  },
  fullSizeEpubMobile: {
    width: '100VW',
    height: '100svh',
    overFlowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    border: 'none',
    '& body': {
      overflow: 'hidden',
      margin: '0px'
    }
  }
};

export const SearchViewBook = props => {
  const {
    classes,
    viewerToken,
    beViewerHost,
    isError,
    enablePrint,
    authInfo,
    contentType,
    watermark = {},
    contentActions: { getViewerToken }
  } = props;

  const { id, contentGroupId, viewToken } = useParams();
  const below768 = useMedia('(max-width: 768px)');

  useEffect(() => {
    if (viewToken) {
      getViewerToken({
        tokenId: viewToken
      });
    } else {
      getViewerToken({
        contentId: id,
        contentGroupId: contentGroupId
      });
    }
  }, [id, viewToken]);

  const { watermarkContent: wmContent, color: wmColor, fontSize: wmFontSize, position: wmPosition, id: wmId } = watermark || {};

  const encodeWatermarkContent = encodeURIComponent(wmContent ? wmContent.replaceAll('&nbsp;', '') : '');
  const viewerSrc = `https://${beViewerHost}/viewer/${contentType}/index.html?token=${viewerToken}`;
  const viewerParams = `${enablePrint !== PRINT_SETTING.DO_NOT_ALLOW ? `&pr=${enablePrint}` : ''}${authInfo ? '&ui=' + authInfo.beUserId : ''}`;
  const watermarkURL = `&wt=${encodeWatermarkContent}${wmFontSize && ('&ws=' + wmFontSize)}${wmColor && ('&wc=' + encodeURIComponent(wmColor))}${wmPosition && ('&wp=' + wmPosition)}`;
  const viewerURL = `${viewerSrc}${viewerParams}${!!wmId && contentType === UPLOAD_FILE_TYPES.PDF ? watermarkURL : ''}`;

  useEffect(() => {
    if (viewerToken && viewerToken !== '') {
      const viewer = document.getElementById('urss-viewer');
      if (viewer) {
        viewer.focus();
        viewer.onload = (e) => {
          viewer.focus();
          viewer.contentWindow.focus();
        };
      }
    }
  }, [viewerToken]);

  useEffect(() => {
    const handleWindowResize = (e) => {
      setTimeout(() => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }, 300);
    };

    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <React.Fragment>
      {
        viewerToken && viewerToken !== '' && beViewerHost && beViewerHost !== ''
          ? <iframe id={'urss-viewer'} className={below768 && contentType === 'epub' ? classes.fullSizeEpubMobile : classes.fullSize}
            src={viewerURL} title="URSS View Book Online" allow="fullscreen">
          </iframe>
          : null
      }
      {
        isError && <TranslateMessage id="label.notAllowViewBook" />
      }
    </React.Fragment>
  );
};

SearchViewBook.defaultProps = {
};

SearchViewBook.propTypes = {
  classes: PropTypes.object.isRequired,
  viewerToken: PropTypes.string,
  beViewerHost: PropTypes.string,
  searchActions: PropTypes.object.isRequired,
  contentActions: PropTypes.object.isRequired,
  enablePrint: PropTypes.any,
  isError: PropTypes.bool,
  authInfo: PropTypes.object,
  watermark: PropTypes.any,
  contentType: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    viewerToken: getViewerToken(state),
    watermark: getWatermark(state),
    beViewerHost: getBeViewerHost(state),
    enablePrint: getPrintSettingViewer(state),
    isError: getIsErrorViewerOnline(state),
    authInfo: getAuthUser(state),
    contentType: getContentTypeViewer(state)
  };
};

// export default withStyles(Style)(props => (<SearchViewBook {...props} />));

export default connect(mapStateToProps)(
  withStyles(Style)(
    props => (
      <ActionContext.Consumer>
        {({ contentActions, searchActions }) => (
          <SearchViewBook
            {...props}
            contentActions={contentActions}
            searchActions={searchActions}
          />
        )}
      </ActionContext.Consumer>
    )
  )
);
