import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import {
  Navbar,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav
} from 'reactstrap';
import TranslateMessage from '../common/TranslateMessage/TranslateMessage';
import { AccountCircle, Person, PersonAdd, ExitToApp, LocalLibrary, Menu, VpnKey, Subscriptions } from '@material-ui/icons';
import { getMenuRoutes } from 'state/route/routeSelector';
import { getAuthUser, getIsRegistered, getIsRegistering, isAuthenticated, isGoogleLogin } from 'state/session/sessionSelector';
import { getPageTitle, getPageLogo, getPageFavicon, getBannerSetting } from 'state/search/searchSelector';
import { ActionContext } from '../layout/MainContainer';
import LoginModal from 'components/modal/LoginModal';
import LoginEndUserForm from 'views/end-user/form/LoginEndUserForm';
import md5 from 'md5';
import { APP_URL, AUTHEN_TYPES, BANNER_TYPE, IMAGE_PATH, OPTION_LOGIN, USER_CONFIG } from 'configs/AppConfig';
import Util from 'utils/Util';
import { Box, makeStyles } from '@material-ui/core';
// import notFoundImage from '../../assets/img/not-found.png';
import { useMedia } from 'react-use';
import { getAccountType, getCompanyDomain, getGoogleId, getShowLinkRegisterUser, getShowSubscriptionStatus, getViewSettings } from 'state/company/companySelector';

const HomeHeader = (props) => {
  const {
    sessionActions: { logout, login },
    authUser,
    pageTitle,
    pageLogo,
    pageFavicon,
    isAuthen,
    toggleSidebar,
    showLoginFormModal,
    setShowLoginFormModal,
    bannerSetting,
    showSidebar,
    showSubscription,
    isShowRegisterUser
  } = props;

  const style = useSelector((state) => state.search.headerSetting) || {};
  const below768 = useMedia('(max-width: 768px)');

  const viewSettings = useSelector(getViewSettings) || {};
  const isOpenType = viewSettings.accountType === OPTION_LOGIN.OPEN_TYPE;

  const useStyle = makeStyles(() => (
    {
      header: {
        display: 'flex',
        backgroundColor: `${style.backgroundColor}!important`,
        color: style.color || '#d0d0d0',
        padding: below768 ? '6px 10px' : '30px 1.3rem',
        height: below768 ? '80px' : 'unset',
        width: '100%',
        '& .dropdown-toggle:after': {
          display: 'none'
        },
        '& .nav-link': {
          color: `${style.color || '#d0d0d0'}!important`,
          whiteSpace: 'unset',
          wordBreak: 'break-word',
          wordWrap: 'break-word'
        }
      },
      headerItem: {
        display: 'flex',
        flexFlow: 'column nowrap',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        padding: '0 0.5rem',
        color: style.color || '#d0d0d0',
        cursor: 'pointer'
      },
      logo: {
        // width: below768 ? 'auto' : '100%',
        width: 'auto',
        height: '100%',
        minHeight: below768 ? 'unset' : '60px',
        maxWidth: below768 ? '70px' : 'unset',
        maxHeight: '70px',
        objectFit: 'contain'
      },
      logoBox: {
        display: 'flex',
        alignItems: 'center'
      },
      pageTitle: {
        margin: '0 10px!important',
        padding: 0,
        color: `${style.color || '#fff'}!important`,
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        maxWidth: below768 ? 'calc(100vw - 170px)' : 'calc(100vw - 470px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '1.25rem',
        '&:hover': {
          textDecoration: 'none'
        }
      },
      wrapControl: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
      },
      bannerBox: {
        height: below768 ? bannerSetting.heightBanner * 0.6 : bannerSetting.heightBanner,
        backgroundColor: bannerSetting.backgroundColor,
        color: bannerSetting.textColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: below768 ? bannerSetting.fontSize * 0.6 : bannerSetting.fontSize,
        cursor: 'pointer',
        '& img': {
          objectFit: 'contain',
          height: '100%',
          width: '100%'
        }
      }
    }
  ));

  const classes = useStyle();

  const history = useHistory();
  const urlUpload = process.env.REACT_APP_UPLOAD_PATH + '/';

  useEffect(() => {
    // Set Favicon
    if (pageFavicon) {
      const favicon = document.getElementById('favicon');
      favicon.href = `${urlUpload}${IMAGE_PATH.FAVICON}${pageFavicon}`;
      const faviconApple = document.getElementById('appleTouchIcon');
      faviconApple.href = `${urlUpload}${IMAGE_PATH.FAVICON}${pageFavicon}`;
    }
  }, [pageFavicon]);

  const handleLoginFormSubmit = async (data, authenType) => {
    setShowLoginFormModal(false);
    let request;
    if (authenType === AUTHEN_TYPES.EMAIL) {
      request = {
        password: md5(data.password),
        rememberMe: data.rememberMe,
        email: data.email,
        loginType: AUTHEN_TYPES.EMAIL,
        loginRole: USER_CONFIG.USER.roleLevel
      };
    } else if (authenType === AUTHEN_TYPES.GOOGLE_LOGIN) {
      request = data;
    } else {
      const userTrace = await Util.getUserTrace();
      const ip = userTrace.ip;
      request = {
        ip: ip,
        loginType: AUTHEN_TYPES.IP
      };
    }
    login(request);
  };

  const handleLogout = () => {
    logout();
  };
  const openLinkBanner = () => {
    if (bannerSetting.link && bannerSetting.link !== '') {
      window.open(bannerSetting.link);
    }
  };

  const renderBanner = () => {
    if (bannerSetting && bannerSetting.isShow) {
      if (bannerSetting.typeBanner === BANNER_TYPE.MESSAGE && bannerSetting.message && bannerSetting.message !== '') {
        return <Box className={classes.bannerBox} onClick={() => openLinkBanner()}>
          {bannerSetting.message}
        </Box>;
      } else if (bannerSetting.typeBanner === BANNER_TYPE.IMAGE && bannerSetting.image && bannerSetting.image !== '') {
        return <Box className={classes.bannerBox} onClick={() => openLinkBanner()}>
          <img src={`${APP_URL.UPLOAD_PATH}/${IMAGE_PATH.BANNER}${bannerSetting.image}`} alt="banner" />
        </Box>;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <div>
      <Navbar dark expand="md" className="p-0">
        <Box className={classes.header} style={{ width: '100%' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" flex="1 auto">
            <Box className={classes.logoBox}>
              <Link
                to={'/'}>
                {pageLogo && pageLogo !== '' && pageLogo !== 'null'
                  ? (
                    <img className={`${classes.logo}`} width="40px" src={`${urlUpload}${IMAGE_PATH.LOGO}${pageLogo}`} />
                  )
                  : (
                    // <img className={`${classes.logo}`} width="40px" src={notFoundImage} />
                    <></>
                  )
                }
              </Link>
              {
                pageTitle && <Link to={'/'} className={classes.pageTitle}>{pageTitle}</Link>
              }
            </Box>
          </Box>
          <Box className={classes.wrapControl}>
            {
              !below768 && <div className={classes.headerItem} onClick={() => history.push('/home-search')}>
                <LocalLibrary />
                <TranslateMessage id="menu.books" />
              </div>
            }
            {
              !below768 && !isOpenType
                ? <React.Fragment>
                  {
                    authUser && isAuthen
                      ? (
                        <Collapse isOpen={true} navbar style={{
                          flex: 'unset',
                          alignItems: 'flex-start',
                          display: 'flex',
                          padding: '0 0.5rem',
                          minWidth: 100,
                          justifyContent: 'center',
                          flexGrow: 0
                        }}>
                          <Nav navbar style={{ flexDirection: 'row' }}>
                            <UncontrolledDropdown nav inNavbar>
                              <DropdownToggle nav caret className={classes.headerItem}>
                                <AccountCircle className="ml-2" />
                                <span>{authUser.name ? `${authUser.name}` : `${authUser.fullName}`}</span>
                              </DropdownToggle>
                              <DropdownMenu right style={{ position: 'absolute' }}>
                                {
                                  authUser.roleLevel !== 4 ? (
                                    <>
                                      <DropdownItem className="cs-dropdown-item" onClick={() => {
                                        history.push('/profile');
                                      }}>
                                        <Person className="mr-2" />
                                        <TranslateMessage id="label.profile" />
                                      </DropdownItem>
                                    </>
                                  ) : null
                                }
                                {
                                  showSubscription && <DropdownItem className="cs-dropdown-item" onClick={() => {
                                    history.push('/subscription');
                                  }}>
                                    <Subscriptions className="mr-2" />
                                    <TranslateMessage id="label.subscription" />
                                  </DropdownItem>
                                }
                                <DropdownItem className="cs-dropdown-item" onClick={handleLogout}>
                                  <ExitToApp className="mr-2" />
                                  {
                                    !below768 && <TranslateMessage id="label.logout" />
                                  }
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </Nav>
                        </Collapse>
                      )
                      : (
                        <>
                          {!isOpenType && isShowRegisterUser && <div
                            className={classes.headerItem}
                            onClick={() => history.push('/register')}
                          >
                            <PersonAdd className="mr-2" />
                            {
                              !below768 && <TranslateMessage id="menu.registerUser" />
                            }
                          </div>}
                          <div
                            className={classes.headerItem}
                            onClick={() => setShowLoginFormModal(true)}
                          >
                            <VpnKey />
                            {
                              !below768 && <TranslateMessage id="menu.login" />
                            }
                          </div>
                        </>

                      )
                  }
                </React.Fragment>
                : null
            }
            {
              showSidebar || below768
                ? <div className={classes.headerItem} onClick={() => toggleSidebar()}>
                  <Menu />
                  {
                    !below768 && <TranslateMessage id="menu.menu" />
                  }
                </div>
                : null
            }
          </Box>
        </Box>
      </Navbar>
      {
        renderBanner()
      }
      <LoginModal
        isOpen={showLoginFormModal}
        toggle={() => setShowLoginFormModal(false)}
        size="md"
        backdrop={'static'}
      >
        <LoginEndUserForm
          onCancel={() => setShowLoginFormModal(false)}
          onSubmit={handleLoginFormSubmit}
        />
      </LoginModal>
    </div>
  );
};

HomeHeader.defaultProps = {};

HomeHeader.propTypes = {
  location: PropTypes.object,
  sessionActions: PropTypes.object.isRequired,
  menuRoutes: PropTypes.array.isRequired,
  authUser: PropTypes.object,
  isAuthen: PropTypes.bool,
  pageTitle: PropTypes.any,
  pageLogo: PropTypes.any,
  pageFavicon: PropTypes.any,
  toggleSidebar: PropTypes.func,
  isOpenSidebar: PropTypes.bool,
  loadPublicContentGroups: PropTypes.func,
  showLoginFormModal: PropTypes.bool,
  setShowLoginFormModal: PropTypes.func,
  bannerSetting: PropTypes.any,
  isGoogleLogin: PropTypes.bool,
  googleId: PropTypes.any,
  showSidebar: PropTypes.bool,
  accountType: PropTypes.any,
  domain: PropTypes.any,
  isRegistering: PropTypes.bool,
  isRegistered: PropTypes.bool,
  showSubscription: PropTypes.bool,
  isShowRegisterUser: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    menuRoutes: getMenuRoutes(state),
    authUser: getAuthUser(state),
    pageTitle: getPageTitle(state),
    pageLogo: getPageLogo(state),
    pageFavicon: getPageFavicon(state),
    isAuthen: isAuthenticated(state),
    accountType: getAccountType(state),
    bannerSetting: getBannerSetting(state),
    isGoogleLogin: isGoogleLogin(state),
    googleId: getGoogleId(state),
    domain: getCompanyDomain(state),
    isRegistering: getIsRegistering(state),
    isRegistered: getIsRegistered(state),
    showSubscription: getShowSubscriptionStatus(state),
    isShowRegisterUser: getShowLinkRegisterUser(state)
  };
};

export default connect(mapStateToProps)(
  props => (
    <ActionContext.Consumer>
      {({ sessionActions }) => (
        <HomeHeader
          {...props}
          sessionActions={sessionActions}
        />
      )}
    </ActionContext.Consumer>
  )
);
