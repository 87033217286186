
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { getContentDetailParams, getContentInfo, getIsContentInfoLoad } from 'state/search/searchSelector';
import PropTypes from 'prop-types';
import TranslateMessage from 'components/common/TranslateMessage/TranslateMessage';
import { useIntl } from 'react-intl';
import { AUTHEN_TYPES, BUTTON_ACTION, CONTENT_GROUP_TYPE, LAYOUT_SETTING, OPTION_LOGIN, PATTERNS, POSITION_CONFIG, SEARCH_FIELD, USER_CONFIG } from 'configs/AppConfig';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Col, Row } from 'reactstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Util from 'utils/Util';
import { TextField } from '@material-ui/core';
import { getBeUserId, getToken, getTokenApiKey, isAuthenticated } from 'state/session/sessionSelector';
import { ActionContext } from 'components/layout/MainContainer';
import { useParams, useHistory } from 'react-router-dom';
import ModalConfirm from 'components/common/Modal/ModalConfirm';
import LoginModal from 'components/modal/LoginModal';
import LoginEndUserForm from './form/LoginEndUserForm';
import { getCompanyDomain, getViewSettings } from 'state/company/companySelector';
import md5 from 'md5';
// import StorageHelper from 'utils/StorageHelper';
import { getShowFavorite } from 'state/home/homeSelector';
import SubscriptionModal from './SubscriptionModal';
// import { authorizeCharge } from 'state/payment/PaymentActions';

const DetailContentPage = props => {
  const {
    contentInfo = {},
    isLoading,
    contentSetting,
    isLoggedIn,
    domain,
    searchActions: {
      loadDetail
    },
    sessionActions: {
      login
    },
    contentActions: {
      addFavoriteContent,
      removeFavoriteContent
    },
    subscriptionActions: {
      getPageUserSubscriptionPlans
    },
    // notificationActions: {
    //   showNotification
    // },
    // paymentActions: {
    //   resetHtml,
    //   resetPaymentStatus,
    //   authorizeCharge
    // },
    // couponActions: {
    //   loadCouponUser,
    //   setCouponUserNull
    // },
    // homeActions: {
    //   loadPublicContentGroups
    // },
    contentId,
    contentGroupId
    // token,
    // tokenApiKey,
    // beUserId
  } = props;
  const { id: contentDetailId } = contentInfo || {};

  const { contentToken } = useParams();
  const history = useHistory();

  const [sortFields, setSortFields] = useState([]);
  const [longFields, setLongFields] = useState([]);

  const [isShowSubscription, setIsShowSubscription] = useState(false);
  const [showConfirmViewer, setShowConfirmViewer] = useState(false);
  const [showLoginFormModal, setShowLoginFormModal] = useState(false);
  const [isShowViewBook, setIsShowViewBook] = useState(false);
  // const [showRegister, setShowRegister] = useState(false);
  // const [isOpenClose, setIsOpenClose] = useState(true);
  // const [showInfo, setShowInfo] = useState(false);
  // const [nameSubscription, setNameSubscription] = useState();

  const [url, setUrl] = useState('');

  const { formatMessage: f } = useIntl();

  const isBEOStaging = !process.env.REACT_APP_IS_BEO_STAGING
    ? process.env.REACT_APP_IS_STAGING_SERVER : (process.env.REACT_APP_IS_BEO_STAGING === 'true');

  const style = useSelector((state) => state.search.contentDetailSetting) || {};
  const viewSettings = useSelector(getViewSettings) || {};
  const isOpenType = viewSettings.accountType === OPTION_LOGIN.OPEN_TYPE;
  const isNolayout = viewSettings.viewPage === LAYOUT_SETTING.NO_LAYOUT.id;
  const showSubscriptionStatus = viewSettings.showSubscriptionStatus;
  const showFavorite = useSelector(getShowFavorite);
  const isPaymentSuccess = useSelector((state) => state.payment.isPaymentSuccess);
  const isPaymentError = useSelector((state) => state.payment.isPaymentError);

  const styleButtonViewBook = style.buttons && style.buttons.length > 0 ? style.buttons.filter(item => item.actionType === BUTTON_ACTION.VIEW_BOOK.id)[0] : {};
  const useStyle = makeStyles(() => (
    {
      itemMax: {
        maxWidth: 300,
        minWidth: 300,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block'
      },
      coverCard: {
        display: 'flex',
        overflowX: 'auto',
        paddingBottom: '10px',
        color: 'black'
      },
      itemCard: {
        width: '300px',
        minWidth: 300,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        border: '1px solid rgba(0, 0, 0, 0.125)',
        borderRadius: '0.25rem'
      },
      noImage: {
        marginBottom: '0.5rem',
        marginRight: '0.5rem',
        position: 'relative',
        overflow: 'hidden',
        border: '1px solid #d0d0d0',
        '& img': {
          maxHeight: 300,
          maxWidth: 300,
          objectFit: 'contain',
          minWidth: 180
        }
      },
      favoriteIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: '#F08828',
        margin: '0.8rem 1rem',
        fontSize: '1.5rem',
        background: '#fff',
        borderRadius: '50%',
        height: 28,
        width: 28,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.2)'
        }
      },
      registered: {
        position: 'absolute',
        top: 0,
        margin: '0.8rem 1rem',
        fontSize: '1.5rem',
        height: 28,
        width: 28,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
      },
      bold: {
        fontWeight: 700
      },
      buttonView: {
        color: '#ffffff',
        fontWeight: 700,
        marginTop: '1rem'
      },
      iconButton: {
        marginRight: '0.5rem'
      },
      skeletonItemImage: {
        width: '100%',
        height: '100%',
        marginBottom: '0.5rem'
      },
      spin: {
        display: 'flex',
        justifyContent: 'center'
      },
      skeletonItemButton: {
        width: '115px!important',
        height: '45px'
      },
      modalStyle: {
        padding: 20,
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        color: style.color || '',
        fontSize: style.fontSize || '',
        border: style.borderColor ? `1px solid ${style.borderColor}` : '',
        backgroundColor: `${style.backgroundColor || 'transparent'}!important`,
        '& div': {
          wordBreak: 'break-word',
          wordWrap: 'break-word'
        },
        minHeight: '100%',
        width: '100%'
      },
      smallFieldBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'start'
      },
      btnViewBook: {
        // fontSize: below768 ? '12px' : '16px',
        border: '2px solid',
        boxShadow: 'none!important',
        backgroundColor: styleButtonViewBook.backgroundColor || '#e26042',
        color: styleButtonViewBook.color || '#fff',
        borderColor: styleButtonViewBook.borderColor || 'transparent',
        height: styleButtonViewBook.height || 'auto',
        paddingTop: styleButtonViewBook.height ? 0 : 6,
        paddingBottom: styleButtonViewBook.height ? 0 : 6,
        '&:hover, &:focus, &:active': {
          backgroundColor: `${styleButtonViewBook.hoverBackgroundColor || styleButtonViewBook.backgroundColor || '#e26042a8'}!important`,
          color: `${styleButtonViewBook.hoverColor || styleButtonViewBook.color || '#fff'}!important`,
          borderColor: `${styleButtonViewBook.hoverBorderColor || styleButtonViewBook.borderColor || 'transparent'}!important`
        }
      },
      contentOverlay: {
        width: '100%',
        zIndex: 1,
        position: 'absolute',
        backgroundColor: '#fff',
        color: '#000',
        border: '1px solid #f7d32f',
        transform: 'rotate(-45deg)',
        left: '-30%',
        top: '1.5rem',
        fontSize: '12px',
        padding: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 600
      },
      noData: {
        textAlign: 'center',
        padding: '1rem',
        flex: '1 0 100%'
      }
    }
  ));

  const classes = useStyle();

  const allowViewBook = isLoggedIn ||
    isOpenType ||
    isNolayout;

  const urlViewer = url + '/view-online/' + (contentDetailId || contentId) + `${contentGroupId ? '/' + contentGroupId : ''}`;

  useEffect(() => {
    if (isPaymentSuccess || isPaymentError) {
      handleLoadDetail({ contentId, contentGroupId });
    }
  }, [isPaymentSuccess, isPaymentError]);

  useEffect(() => {
    const protocol = window.location.protocol;
    const hash = window.location.hash !== '' ? '#' : '';
    const port = window.location.port;
    if (port) {
      setUrl(protocol + '//' + domain + ':' + port + hash);
    } else {
      setUrl(protocol + '//' + domain + hash);
    }
  }, []);
  useEffect(() => {
    if (contentGroupId && (contentGroupId !== 'home-search' && contentGroupId !== 'search' &&
    contentGroupId !== 'user' && contentGroupId !== 'profile' &&
    contentGroupId !== 'subscription' && contentGroupId !== 'view-online' && contentGroupId !== 'content')) {
      const validate = /[a-z]/g;
      const format = validate.exec(contentGroupId.toString());
      if (format) {
        history.push('/');
      }
    }
  }, [contentGroupId]);

  useEffect(() => {
    if (contentId) {
      const regex = PATTERNS.ELEMENT_ID;
      const validId = regex.test(contentId.toString());
      if (!validId) {
        if (isNolayout) {
          history.push('/404');
        } else {
          history.push('/');
        }
      }
    }
    if (allowViewBook && contentId && !contentGroupId) {
      handleLoadDetail({ contentId });
    }
  }, [contentId, allowViewBook]);

  useEffect(() => {
    if (isLoggedIn) {
      if (isShowViewBook && contentDetailId && contentGroupId) {
        const windowReference = window.open(urlViewer);
        if (!windowReference) {
          setShowConfirmViewer(true);
        }
        setIsShowViewBook(false);
      }
    } else {
      setIsShowViewBook(false);
    }
  }, [isLoggedIn]);

  // useEffect(() => {
  //   const cacheStorage = StorageHelper.getSessionObject(STORAGE_KEYS.cacheStorage);
  //   if (isLoggedIn && cacheStorage && cacheStorage.isShowViewBook) {
  //     if (contentId) {
  //       const windowReference = window.open(urlViewer);
  //       if (!windowReference) {
  //         setShowConfirmViewer(true);
  //       }
  //       setIsShowViewBook(false);
  //     }
  //     StorageHelper.removeSessionItem(STORAGE_KEYS.cacheStorage);
  //   }
  // }, [isLoggedIn]);

  useEffect(() => {
    if (contentId && contentGroupId) {
      handleLoadDetail({ contentId, contentGroupId });
    }
  }, [contentId, contentGroupId, allowViewBook]);
  useEffect(() => {
    if (contentToken) {
      handleLoadDetail({ contentToken });
    }
  }, [contentToken]);
  useEffect(() => {
    if (contentInfo) {
      let arraySortField = [];
      let arrayLongField = [];
      if (contentSetting && contentSetting.length > 0) {
        for (const item of contentSetting) {
          const displayField = renderContentInfo(item, contentInfo, false);
          if (item.hasBox) {
            arrayLongField = [...arrayLongField, displayField];
          } else {
            arraySortField = [...arraySortField, displayField];
          }
        }
        setSortFields(arraySortField);
        setLongFields(arrayLongField);
      } else {
        setSortFields(arraySortField);
        setLongFields(arrayLongField);
      }
    }
  }, [contentInfo]);

  const onSelectFavorite = (contentId, isFavorite) => {
    const params = {
      contentId,
      contentGroup: {
        contentGroupId: contentGroupId
      }
    };
    if (isFavorite) {
      removeFavoriteContent(params);
    } else {
      addFavoriteContent(params);
    }
  };

  const renderOverlayText = (contentGroupType) => {
    switch (contentGroupType) {
      case CONTENT_GROUP_TYPE.NEW:
        return f({ id: 'label.newOverlay' });
      case CONTENT_GROUP_TYPE.POPULAR:
        return f({ id: 'label.popularOverlay' });
      case CONTENT_GROUP_TYPE.RECOMMEND:
        return f({ id: 'label.recommendOverlay' });
      default:
        return '';
    }
  };
  const handleLoadDetail = ({ contentId, contentGroupId, contentToken }) => {
    loadDetail({ contentId, contentGroupId, contentToken });
  };
  const handleSetInnerHTML = (text) => {
    return (<span dangerouslySetInnerHTML={{ __html: text }} />);
  };
  const returnTemplateContentInfo = (searchField, itemSetting, contentInfo, isFullWidth) => {
    return (
      <Col
        sm={isFullWidth ? 12 : itemSetting.size}
        key={`key-detail-${searchField.dbKey}-${(new Date()).getTime()}`}
        id={`key-detail-${searchField.dbKey}-${(new Date()).getTime()}`}
        className={`mB-1 ${itemSetting.hasBox && 'has-box'}`}
      >
        <span>
          {`${itemSetting.title}: `}
        </span>
        {
          itemSetting.hasBox
            ? <TextField variant={'outlined'} multiline type={'textarea'} value={contentInfo[searchField.dbKey]} />
            : handleSetInnerHTML(contentInfo[searchField.dbKey])
        }
      </Col>
    );
  };
  const renderContentInfo = (itemSetting, contentInfo, isFullWidth) => {
    switch (itemSetting.field) {
      case SEARCH_FIELD.TITLE.id:
        return returnTemplateContentInfo(SEARCH_FIELD.TITLE, itemSetting, contentInfo, isFullWidth);
      case SEARCH_FIELD.AUTHOR.id:
        return returnTemplateContentInfo(SEARCH_FIELD.AUTHOR, itemSetting, contentInfo, isFullWidth);
      case SEARCH_FIELD.KEYWORDS.id:
        return returnTemplateContentInfo(SEARCH_FIELD.KEYWORDS, itemSetting, contentInfo, isFullWidth);
      case SEARCH_FIELD.TOC.id:
        return returnTemplateContentInfo(SEARCH_FIELD.TOC, itemSetting, contentInfo, isFullWidth);
      case SEARCH_FIELD.DISTRIBUTOR.id:
        return returnTemplateContentInfo(SEARCH_FIELD.DISTRIBUTOR, itemSetting, contentInfo, isFullWidth);
      case SEARCH_FIELD.DESCRIPTION.id:
        return returnTemplateContentInfo(SEARCH_FIELD.DESCRIPTION, itemSetting, contentInfo, isFullWidth);
      case SEARCH_FIELD.PARAM_1.id:
        return returnTemplateContentInfo(SEARCH_FIELD.PARAM_1, itemSetting, contentInfo, isFullWidth);
      case SEARCH_FIELD.PARAM_2.id:
        return returnTemplateContentInfo(SEARCH_FIELD.PARAM_2, itemSetting, contentInfo, isFullWidth);
      case SEARCH_FIELD.PARAM_3.id:
        return returnTemplateContentInfo(SEARCH_FIELD.PARAM_3, itemSetting, contentInfo, isFullWidth);
      case SEARCH_FIELD.PARAM_4.id:
        return returnTemplateContentInfo(SEARCH_FIELD.PARAM_4, itemSetting, contentInfo, isFullWidth);
      case SEARCH_FIELD.PARAM_5.id:
        return returnTemplateContentInfo(SEARCH_FIELD.PARAM_5, itemSetting, contentInfo, isFullWidth);
      default:
        break;
    }
  };
  const showSubscriptionList = contentGroupId => {
    getPageUserSubscriptionPlans({ contentGroupId: contentGroupId, size: 100 });
    setIsShowSubscription(true);
  };
  const openViewOnline = (content) => {
    setIsShowViewBook(true);
    if (allowViewBook) {
      window.open(urlViewer, '_blank');
    } else {
      // setContentDetailId(content.id);
      setShowLoginFormModal(true);
    }
  };
  const cancelLoginModal = () => {
    setShowLoginFormModal(false);
    setIsShowViewBook(false);
  };
  const handleLoginFormSubmit = async (data, authenType) => {
    setShowLoginFormModal(false);
    let request;
    if (authenType === AUTHEN_TYPES.EMAIL) {
      request = {
        password: md5(data.password),
        rememberMe: data.rememberMe,
        email: data.email,
        loginType: AUTHEN_TYPES.EMAIL,
        loginRole: USER_CONFIG.USER.roleLevel,
        path: data.path
      };
    } else if (authenType === AUTHEN_TYPES.GOOGLE_LOGIN) {
      request = data;
    } else {
      const userTrace = await Util.getUserTrace();
      const ip = userTrace.ip;
      request = {
        ip: ip,
        loginType: AUTHEN_TYPES.IP
      };
    }
    login(request);
  };

  // const handleFormSubmit = (data) => {
  //   // Check if add mode
  //   if (showRegister) {
  //     setShowRegister(false);
  //     const request = {
  //       ...data,
  //       couponCode: data.couponCode,
  //       subscriptionPlanId: data.id,
  //       updateContentGroup: true,
  //       queries: {
  //         contentGroupId: contentGroupId,
  //         size: 100
  //       }
  //     };
  //     if (data.isHaveCard) {
  //       authorizeCharge(request);
  //     } else {
  //       const errorMessage = Util.handleVeritransPayment(request, authorizeCharge);
  //       if (errorMessage) {
  //         showNotification({
  //           message: errorMessage,
  //           level: 'error',
  //           autoDismiss: 3,
  //           position: 'tc'
  //         });
  //       }
  //     }
  //   }
  // };

  // const handleCompletePayment = () => {
  //   resetHtml();
  //   setShowRegister(false);
  //   getAuthInfo();
  //   loadPublicContentGroups();
  // };
  // const handleCancelSubscriptionDetail = () => {
  //   setShowRegister(false);
  //   setCouponUserNull();
  // };
  // const openRegister = (id, name) => {
  //   setNameSubscription(name);
  //   getSubscriptionDetail(id);
  //   setCouponUserNull();
  //   setShowRegister(true);
  // };

  return (
    <div className={`${classes.modalStyle}`}>
      {
        <React.Fragment>
          {
            isLoading || !window.BEObj
              ? <SkeletonTheme>
                <Row>
                  <Col sm={12}>
                    <Row>
                      <Col sm={4}>
                        <div><Skeleton className={classes.skeletonItemImage} /></div>
                      </Col>
                      <Col sm={8}>
                        <div>
                          <h5><Skeleton /></h5>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} className={'text-left'} >
                    <div><Skeleton className={classes.skeletonItemButton} /></div>
                  </Col>
                </Row>
              </SkeletonTheme>
              : <React.Fragment>
                {
                  contentInfo
                    ? <Row>
                      <Col sm={12}>
                        <div className={classes.smallFieldBox}>
                          <div className={classes.noImage}>
                            {
                              contentInfo.type && <div className={classes.contentOverlay}>
                                {renderOverlayText(contentInfo.type)}
                              </div>
                            }
                            <img src={contentInfo.beContentsId ? window.BEObj.getContentsURL({ cid: contentInfo.beContentsId, type: 2, staging: isBEOStaging, cdn: true }) : require('../../assets/img/no-image.png')} />
                            {
                              showSubscriptionStatus && contentInfo.isRegistered
                                ? <div className={`${classes.registered}`} title={f({ id: contentInfo.isSubscriptionPlan ? 'label.subscribed' : 'label.free' })} style={
                                  {
                                    right: (isLoggedIn && showFavorite) ? 30 : 0
                                  }
                                }>
                                  <i className={'fas fa-check-circle'} style={{ color: 'green' }} />
                                </div>
                                : null
                            }
                            {
                              isLoggedIn && showFavorite && (
                                <div
                                  className={`${classes.favoriteIcon}`}
                                  onClick={() => onSelectFavorite(contentInfo.id, contentInfo.isFavorite)}
                                >
                                  {
                                    contentInfo.isFavorite
                                      ? (<i className={'fas fa-star'}></i>)
                                      : (<i className="far fa-star"></i>)
                                  }
                                </div>
                              )
                            }
                          </div>
                          <div className={'row'} style={{ flex: '1 0 0px', minWidth: 280 }}>
                            {
                              sortFields
                            }
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} className={'mt-2'} >
                        <Row>
                          {
                            longFields
                          }
                        </Row>
                      </Col>
                      {
                        (contentInfo.view === true || contentInfo.view === false ? contentInfo.view : true)
                          ? <Col sm={12} className={Util.getTextAlign(style.buttonPosition || POSITION_CONFIG.RIGHT.id)}>
                            <Button
                              color={'warning'}
                              className={`${classes.buttonView} ${classes.btnViewBook}`}
                              onClick={allowViewBook
                                ? (!contentInfo.isSubscriptionPlan || contentInfo.isRegistered
                                  ? () => openViewOnline(contentInfo, contentGroupId)
                                  : () => showSubscriptionList(contentGroupId))
                                : () => openViewOnline(contentInfo, contentGroupId)}
                            >
                              {
                                styleButtonViewBook.label && styleButtonViewBook.label !== ''
                                  ? <span>{styleButtonViewBook.label}</span>
                                  : <React.Fragment>
                                    <span className={classes.iconButton}>
                                      <i className="fas fa-book-open"></i>
                                    </span>
                                    <TranslateMessage id={'label.displayBook'} />
                                  </React.Fragment>
                              }
                            </Button>
                          </Col>
                          : null
                      }
                      <SubscriptionModal
                        isOpen={isShowSubscription}
                        toggle={() => setIsShowSubscription(false)}
                        // openRegister={openRegister}
                      />
                      {/* <CommonModal
                        isOpen={showRegister}
                        toggle={isOpenClose ? () => setShowRegister(false) : null}
                        title={nameSubscription || ''}
                        size="lg"
                        backdrop={'static'}
                      >
                        <SubscriptionFormDetail
                          onCancel={handleCancelSubscriptionDetail}
                          onSubmit={handleFormSubmit}
                          detailMode={false}
                          completePayment={handleCompletePayment}
                          showNotification={showNotification}
                          resetPaymentStatus={resetPaymentStatus}
                          setIsOpenClose={setIsOpenClose}
                          getCouponUser={loadCouponUser}
                        />
                      </CommonModal> */}
                      <ModalConfirm
                        isOpen={showConfirmViewer}
                        toggle={() => setShowConfirmViewer(false)}
                        message={f({ id: 'label.confirmViewNewTab' })}
                        doConfirm={() => openViewOnline()}
                        doCancel={() => setShowConfirmViewer(false)}
                        backdrop={'static'}
                        level={'info'}
                      />
                      <LoginModal
                        isOpen={showLoginFormModal}
                        toggle={() => cancelLoginModal()}
                        size="md"
                        backdrop={'static'}
                      >
                        <LoginEndUserForm
                          onCancel={() => cancelLoginModal()}
                          onSubmit={handleLoginFormSubmit}
                          isShowViewBook={isShowViewBook}
                          contentDetailId={contentInfo.id}
                          selectedContentGroup={contentGroupId}
                        />
                      </LoginModal>
                    </Row>
                    : <div className={classes.noData}>
                      <TranslateMessage id="text.noData" />
                    </div>
                }
              </React.Fragment>
          }
        </React.Fragment>
      }
    </div>
  );
};

DetailContentPage.propTypes = {
  contentActions: PropTypes.any,
  searchActions: PropTypes.any,
  sessionActions: PropTypes.any,
  // notificationActions: PropTypes.any,
  // couponActions: PropTypes.any,
  subscriptionActions: PropTypes.any,
  // paymentActions: PropTypes.any,
  // homeActions: PropTypes.any,
  className: PropTypes.object,
  contentInfo: PropTypes.any,
  isLoading: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  contentSetting: PropTypes.array,
  domain: PropTypes.any,
  contentId: PropTypes.any,
  contentGroupId: PropTypes.any,
  isLoadContentDetail: PropTypes.bool,
  token: PropTypes.string,
  tokenApiKey: PropTypes.string,
  beUserId: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    isLoading: getIsContentInfoLoad(state),
    isLoggedIn: isAuthenticated(state),
    contentInfo: getContentInfo(state),
    domain: getCompanyDomain(state),
    contentSetting: getContentDetailParams(state),
    token: getToken(state),
    tokenApiKey: getTokenApiKey(state),
    beUserId: getBeUserId(state)
  };
};

export default connect(mapStateToProps)(props => (
  <ActionContext.Consumer>
    {({
      searchActions,
      sessionActions,
      contentActions,
      subscriptionActions
    }) => (
      <DetailContentPage
        {...props}
        searchActions={searchActions}
        sessionActions={sessionActions}
        contentActions={contentActions}
        subscriptionActions={subscriptionActions}
      />
    )}
  </ActionContext.Consumer>
));
