export default {
  GET_LAYOUT_SETTING: {
    url: '/layout-setting/detail',
    method: 'GET'
  },
  UPDATE_LAYOUT_SETTING: {
    url: '/layout-setting/update',
    method: 'PUT'
  },
  RESET_LAYOUT_SETTING: {
    url: '/layout-setting/reset-layout-setting',
    method: 'PUT'
  }
};
