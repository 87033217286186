const _prefix = '@company/';

export default {
  GET_COMPANIES_REQUEST: _prefix + 'GET_COMPANIES_REQUEST',
  GET_COMPANIES_SUCCESS: _prefix + 'GET_COMPANIES_SUCCESS',
  GET_COMPANIES_ERROR: _prefix + 'GET_COMPANIES_ERROR',

  GET_COMPANY_DETAIL_REQUEST: _prefix + 'GET_COMPANY_DETAIL_REQUEST',
  GET_COMPANY_DETAIL_SUCCESS: _prefix + 'GET_COMPANY_DETAIL_SUCCESS',
  GET_COMPANY_DETAIL_ERROR: _prefix + 'GET_COMPANY_DETAIL_ERROR',

  CREATE_COMPANIES_REQUEST: _prefix + 'CREATE_COMPANIES_REQUEST',
  CREATE_COMPANIES_SUCCESS: _prefix + 'CREATE_COMPANIES_SUCCESS',
  CREATE_COMPANIES_ERROR: _prefix + 'CREATE_COMPANIES_ERROR',

  UPDATE_COMPANY_DETAIL_REQUEST: _prefix + 'UPDATE_COMPANY_DETAIL_REQUEST',
  UPDATE_COMPANY_DETAIL_SUCCESS: _prefix + 'UPDATE_COMPANY_DETAIL_SUCCESS',
  UPDATE_COMPANY_DETAIL_ERROR: _prefix + 'UPDATE_COMPANY_DETAIL_ERROR',

  UPLOAD_COMPANY_LOGO_REQUEST: _prefix + 'UPLOAD_COMPANY_LOGO_REQUEST',
  UPLOAD_COMPANY_LOGO_SUCCESS: _prefix + 'UPLOAD_COMPANY_LOGO_SUCCESS',
  UPLOAD_COMPANY_LOGO_ERROR: _prefix + 'UPLOAD_COMPANY_LOGO_ERROR',

  UPLOAD_COMPANY_FAVICON_REQUEST: _prefix + 'UPLOAD_COMPANY_FAVICON_REQUEST',
  UPLOAD_COMPANY_FAVICON_SUCCESS: _prefix + 'UPLOAD_COMPANY_FAVICON_SUCCESS',
  UPLOAD_COMPANY_FAVICON_ERROR: _prefix + 'UPLOAD_COMPANY_FAVICON_ERROR',

  GET_SETTING_REQUEST: _prefix + 'GET_SETTING_REQUEST',
  GET_SETTING_SUCCESS: _prefix + 'GET_SETTING_SUCCESS',
  GET_SETTING_ERROR: _prefix + 'GET_SETTING_ERROR',

  UPDATE_COMPANIES_QUERIES: _prefix + 'UPDATE_COMPANIES_QUERIES',

  DELETE_COMPANY_REQUEST: _prefix + 'DELETE_COMPANY_REQUEST',

  UPDATE_SELECTED: _prefix + 'UPDATE_SELECTED',
  UPDATE_SELECTED_ALL: _prefix + 'UPDATE_SELECTED_ALL',

  SET_COMPANY_DOMAIN: _prefix + 'SET_COMPANY_DOMAIN',

  GET_END_USER_COMPANY_DETAIL_REQUEST: _prefix + 'GET_END_USER_COMPANY_DETAIL_REQUEST',
  GET_END_USER_COMPANY_DETAIL_SUCCESS: _prefix + 'GET_END_USER_COMPANY_DETAIL_SUCCESS',

  VERIFY_PAYMENT_VERITRANS_REQUEST: _prefix + 'VERIFY_PAYMENT_VERITRANS_REQUEST',
  VERIFY_PAYMENT_VERITRANS_SUCCESS: _prefix + 'VERIFY_PAYMENT_VERITRANS_SUCCESS',
  VERIFY_PAYMENT_VERITRANS_ERROR: _prefix + 'VERIFY_PAYMENT_VERITRANS_ERROR',

  UPDATE_PAYMENT_VERITRANS_REQUEST: _prefix + 'UPDATE_PAYMENT_VERITRANS_REQUEST',
  UPDATE_PAYMENT_VERITRANS_SUCCESS: _prefix + 'UPDATE_PAYMENT_VERITRANS_SUCCESS',
  UPDATE_PAYMENT_VERITRANS_ERROR: _prefix + 'UPDATE_PAYMENT_VERITRANS_ERROR'
};
