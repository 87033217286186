const _prefix = '@user/';

export default {
  GET_USERS_REQUEST: _prefix + 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: _prefix + 'GET_USERS_SUCCESS',

  GET_USER_DETAIL_REQUEST: _prefix + 'GET_USER_DETAIL_REQUEST',
  GET_USER_DETAIL_SUCCESS: _prefix + 'GET_USER_DETAIL_SUCCESS',

  UPDATE_USER_QUERIES: _prefix + 'UPDATE_USER_QUERIES',

  ADD_USER_REQUEST: _prefix + 'ADD_USER_REQUEST',
  DELETE_USER_REQUEST: _prefix + 'DELETE_USER_REQUEST',
  UPDATE_USER_REQUEST: _prefix + 'UPDATE_USER_REQUEST',

  EXPORT_USERS_REQUEST: _prefix + 'EXPORT_USERS_REQUEST',

  GET_USER_SUBCRIPTION_PLAN_PAGE_REQUEST: _prefix + 'GET_USER_SUBCRIPTION_PLAN_PAGE_REQUEST',
  GET_USER_SUBCRIPTION_PLAN_PAGE_SUCCESS: _prefix + 'GET_USER_SUBCRIPTION_PLAN_PAGE_SUCCESS',
  UPDATE_USER_SUBCRIPTION_PLAN_PAGE_QUERIES: _prefix + 'UPDATE_USER_SUBCRIPTION_PLAN_PAGE_QUERIES'

};
