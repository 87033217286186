export default {
  GET_DATA: {
    url: '/content/search',
    method: 'POST'
  },
  GET_PUBLIC_DATA: {
    url: '/content/public/search',
    method: 'POST'
  },
  GET_INFO: {
    url: '/content/public/detail/',
    method: 'GET'
  },
  GET_SETTING: {
    url: '/company/public/view-settings',
    method: 'GET'
  },
  SEARCH_FULL_TEXT: {
    url: '/content/public/full-text-search',
    method: 'POST'
  },
  SEARCH_FULL_TEXT_AUTHEN: {
    url: '/content/full-text-search',
    method: 'POST'
  },
  GET_SEARCH_TERM_PAGE: {
    url: '/search-term/page',
    method: 'GET'
  }
};
