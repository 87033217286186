import { all, put, takeEvery } from 'redux-saga/effects';
import BannerTypes from './BannerTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/banner';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';

function * getBannerDetail () {
  yield takeEvery(BannerTypes.GET_BANNER_REQUEST, _getBannerDetail);
}

function * _getBannerDetail () {
  const api = {
    ...API.GET_BANNER_DETAIL
  };
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      yield put({
        type: BannerTypes.GET_BANNER_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * updateBanner () {
  yield takeEvery(BannerTypes.UPDATE_BANNER_REQUEST, _updateBanner);
}

function * _updateBanner ({ payload }) {
  const { imageFile, ...rest } = payload;
  const data = new FormData();
  data.append('file', imageFile);
  data.append('content-data', new Blob([JSON.stringify(snakecaseKeys(rest))], {
    type: 'application/json'
  }));
  yield createRequest({
    api: {
      ...API.UPDATE_BANNER,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: BannerTypes.GET_BANNER_REQUEST,
        payload: {}
      });
    }
  });
}

function * resetBanner () {
  yield takeEvery(BannerTypes.RESET_BANNER_REQUEST, _resetBanner);
}

function * _resetBanner () {
  yield createRequest({
    api: {
      ...API.RESET_BANNER
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: BannerTypes.GET_BANNER_REQUEST,
        payload: {}
      });
    }
  });
}

export default function * bannerSaga () {
  yield all([
    getBannerDetail(),
    updateBanner(),
    resetBanner()
  ]);
}
