import { all, put, takeEvery } from 'redux-saga/effects';
import CouponTypes from './CouponTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/coupon';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';
import { PAGINATION } from 'configs/AppConfig';
import NotificationTypes from 'state/notification/NotificationTypes';

function * loadCouponPage () {
  yield takeEvery(CouponTypes.GET_COUPON_PAGE_REQUEST, _loadCouponPage);
}

function * _loadCouponPage ({ payload }) {
  const request = snakecaseKeys({ ...PAGINATION, ...payload });
  yield createRequest({
    api: {
      ...API.GET_COUPON_PAGE,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: CouponTypes.GET_COUPON_PAGE_SUCCESS,
        payload: Util.toCamelCaseKey({ ...payload, ...response.data })
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: CouponTypes.GET_COUPON_PAGE_ERROR,
        payload: status
      });
    }
  });
}

function * loadCouponList () {
  yield takeEvery(CouponTypes.GET_LIST_COUPON_REQUEST, _loadCouponList);
}

function * _loadCouponList ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GET_COUPON_LIST,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: CouponTypes.GET_LIST_COUPON_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: CouponTypes.GET_LIST_COUPON_ERROR,
        payload: status
      });
    }
  });
}

function * loadCouponDetail () {
  yield takeEvery(CouponTypes.GET_COUPON_DETAIL_REQUEST, _loadCouponDetail);
}

function * _loadCouponDetail ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_COUPON_DETAIL,
      url: API.GET_COUPON_DETAIL.url + payload
    },
    onSuccess: function * (response) {
      yield put({
        type: CouponTypes.GET_COUPON_DETAIL_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: CouponTypes.GET_COUPON_DETAIL_ERROR,
        payload: status
      });
    }
  });
}

function * updateCouponDetail () {
  yield takeEvery(CouponTypes.UPDATE_COUPON_REQUEST, _updateCouponDetail);
}

function * _updateCouponDetail ({ payload }) {
  const request = snakecaseKeys({ ...payload.data });
  yield createRequest({
    api: {
      ...API.UPDATE_COUPON_DETAIL,
      url: API.UPDATE_COUPON_DETAIL.url + payload.data.id,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: CouponTypes.GET_COUPON_PAGE_REQUEST,
        payload: payload.queries
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: CouponTypes.GET_COUPON_PAGE_REQUEST,
        payload: payload.queries
      });
    }
  });
}

function * addCoupon () {
  yield takeEvery(CouponTypes.ADD_COUPON_REQUEST, _addCoupon);
}

function * _addCoupon ({ payload }) {
  const request = snakecaseKeys({ ...payload.data });
  yield createRequest({
    api: {
      ...API.ADD_COUPON,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: CouponTypes.GET_COUPON_PAGE_REQUEST,
        payload: payload.queries
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: CouponTypes.GET_COUPON_PAGE_REQUEST,
        payload: payload.queries
      });
    }
  });
}

function * deleteCoupon () {
  yield takeEvery(CouponTypes.DELETE_COUPON_REQUEST, _deleteCoupon);
}

function * _deleteCoupon ({ payload }) {
  yield createRequest({
    api: {
      ...API.DELETE_COUPON,
      url: API.DELETE_COUPON.url + payload.data.id
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.delete.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: CouponTypes.GET_COUPON_PAGE_REQUEST,
        payload: payload.queries
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: CouponTypes.GET_COUPON_PAGE_REQUEST,
        payload: payload.queries
      });
    }
  });
}

function * loadCouponUser () {
  yield takeEvery(CouponTypes.GET_COUPON_USER_REQUEST, _loadCouponUser);
}

function * _loadCouponUser ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GET_MANAGE_COUPON_USER,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.coupon.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: CouponTypes.GET_COUPON_USER_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: CouponTypes.GET_COUPON_USER_ERROR
      });
    }
  });
}

export default function * couponSaga () {
  yield all([
    loadCouponPage(),
    loadCouponList(),
    loadCouponDetail(),
    updateCouponDetail(),
    addCoupon(),
    deleteCoupon(),
    loadCouponUser()
  ]);
}
