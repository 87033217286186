import { SCROLL_TO_TOP, SET_SCROLLBAR } from './scrollbarActionTypes';

export function setScrollbar (name, scrollbar) {
  return {
    type: SET_SCROLLBAR,
    payload: { name, scrollbar }
  };
}

export function scrollToTop (name) {
  return {
    type: SCROLL_TO_TOP,
    payload: { name }
  };
}
