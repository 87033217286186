import SearchResultTypes from './SearchResultTypes';

// Search Result management
export function addButton (data) {
  return {
    type: SearchResultTypes.CREATE_BUTTON_REQUEST,
    payload: data
  };
}
export function updateButton (data) {
  return {
    type: SearchResultTypes.UPDATE_BUTTON_REQUEST,
    payload: data
  };
}
export function deleteButton (data) {
  return {
    type: SearchResultTypes.DELETE_BUTTON_REQUEST,
    payload: data
  };
}
export function loadButtonDetail (data) {
  return {
    type: SearchResultTypes.GET_BUTTON_REQUEST,
    payload: data
  };
}
