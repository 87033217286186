import StorageHelper from '../../utils/StorageHelper';
import { STORAGE_KEYS } from '../../configs/AppConfig';

export const isAuthenticated = state => state.session.isAuthenticated;
export const isSessionValidating = state => state.session.isSessionValidating;
export const isGoogleLogin = state => state.session.isGoogleLogin || StorageHelper.getLocalObject(STORAGE_KEYS.googleLogin) ? StorageHelper.getLocalObject(STORAGE_KEYS.googleLogin).isGoogleLogin : state.session.isGoogleLogin;
export const getToken = state => state.session.authToken || StorageHelper.getCookie(STORAGE_KEYS.session);
export const getAuthUser = state => state.session.user;
export const isLoading = state => state.session.isLoading;
export const getIsRegistering = state => state.session.isRegistering;
export const getIsRegistered = state => state.session.isRegistered;
export const getTokenApiKey = state => state.session.user.tokenApiKey;
export const getBeUserId = state => state.session.user.beUserId;
