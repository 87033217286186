const _prefix = '@userGroup/';

export default {
  GET_LIST_USER_GROUPS_REQUEST: _prefix + 'GET_LIST_USER_GROUPS_REQUEST',
  GET_LIST_USER_GROUPS_SUCCESS: _prefix + 'GET_LIST_USER_GROUPS_SUCCESS',

  GET_USER_GROUPS_REQUEST: _prefix + 'GET_USER_GROUPS_REQUEST',
  GET_USER_GROUPS_SUCCESS: _prefix + 'GET_USER_GROUPS_SUCCESS',

  GET_USER_GROUP_DETAIL_REQUEST: _prefix + 'GET_USER_GROUP_DETAIL_REQUEST',
  GET_USER_GROUP_DETAIL_SUCCESS: _prefix + 'GET_USER_GROUP_DETAIL_SUCCESS',

  UPDATE_USER_GROUP_QUERIES: _prefix + 'UPDATE_USER_GROUP_QUERIES',

  ADD_USER_GROUP_REQUEST: _prefix + 'ADD_USER_GROUP_REQUEST',
  DELETE_USER_GROUP_REQUEST: _prefix + 'DELETE_USER_GROUP_REQUEST',
  UPDATE_USER_GROUP_REQUEST: _prefix + 'UPDATE_USER_GROUP_REQUEST'
};
