import ImportUserTypes from './ImportUserTypes';

export function loadImportData (data) {
  return {
    type: ImportUserTypes.GET_IMPORT_DATA_REQUEST,
    payload: data
  };
}

export function downloadTemplate (payload) {
  return {
    type: ImportUserTypes.DOWNLOAD_TEMPLATE,
    payload: payload
  };
}

export function uploadUserCSV (payload) {
  return {
    type: ImportUserTypes.UPLOAD_USER_CSV,
    payload: payload
  };
}

export function downloadFileLogs (id, fileName) {
  return {
    type: ImportUserTypes.DOWNLOAD_FILE_LOGS,
    payload: {
      id: id,
      fileName: fileName
    }
  };
}

export function importUserCSV (params) {
  return {
    type: ImportUserTypes.IMPORT_USER_CSV,
    payload: params
  };
}

export function checkImportProgress (data) {
  return {
    type: ImportUserTypes.CHECK_IMPORT_PROGRESS_REQUEST,
    payload: data
  };
}
