import UserGroupTypes from './UserGroupTypes';
import { PAGINATION } from '../../configs/AppConfig';

const initialState = {
  userGroups: [],
  total: 0,
  totalPages: 0,
  queries: {
    ...PAGINATION
  },
  isLoadingUserGroupDetail: true,
  userGroupDetail: {
    name: '',
    maxUser: '',
    session: '',
    staffRoleId: '',
    username: '',
    password: '',
    ipAddress: [],
    expiryPeriodNumber: '',
    contentGroupIds: [],
    default: false
  }
};

const userGroupReducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  switch (type) {
    case UserGroupTypes.GET_LIST_USER_GROUPS_SUCCESS:
      newState.userGroupsAll = [...payload.sort(function (a, b) {
        if (a.default === false && b.default === true) {
          return 1;
        }
        if (a.default === true && b.default === false) {
          return -1;
        }
        return 0;
      })];
      return newState;
    case UserGroupTypes.GET_USER_GROUPS_SUCCESS:
      newState.userGroups = [...payload.content.sort(function (a, b) {
        if (a.default === false && b.default === true) {
          return 1;
        }
        if (a.default === true && b.default === false) {
          return -1;
        }
        return 0;
      })];
      newState.totalPages = payload.totalPages;
      newState.total = payload.totalElements;
      return newState;
    case UserGroupTypes.UPDATE_USER_GROUP_QUERIES:
      newState.queries.page = payload.page;
      newState.queries.size = payload.size;
      newState.queries.search_key = payload.search_key;
      return newState;
    case UserGroupTypes.GET_USER_GROUP_DETAIL_REQUEST:
      newState.isLoadingUserGroupDetail = true;
      return newState;
    case UserGroupTypes.GET_USER_GROUP_DETAIL_SUCCESS:
      for (const key in payload) {
        if (!payload[key] && payload[key] !== 0) {
          payload[key] = '';
        }
      }
      newState.userGroupDetail = {
        ...newState.userGroupDetail,
        ...payload,
        password: payload.username ? 'Abc123456' : ''
      };
      newState.isLoadingUserGroupDetail = false;
      return newState;
    default:
      newState = state;
      break;
  }
  // return new state
  return newState;
};

export default userGroupReducer;
