import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Search from '@material-ui/icons/Search';

const InputSearch = props => {
  const { onSearch, searchText = '', id, style } = props;
  const { formatMessage: f } = useIntl();
  const [searchValue, setSearchValue] = useState(searchText || '');

  useEffect(() => {
    setSearchValue(searchText);
  }, [searchText]);

  const handleClick = () => {
    if (searchValue.length === 0) {
      onSearch('search', { searchText: '' });
      return;
    }
    onSearch('search', { searchText: searchValue });
  };
  const onBlurHandler = () => {
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const keyPress = event => {
    if (event.keyCode === 13) {
      handleClick();
    }
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel margin="dense">{f({ id: 'label.search' })}</InputLabel>
      <OutlinedInput
        id={id}
        value={searchValue}
        onChange={(event) => setSearchValue(event.target.value)}
        onKeyDown={(event) => keyPress(event)}
        onBlur={onBlurHandler}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClick}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              type="submit"
              size="small"
            >
              <Search />
            </IconButton>
          </InputAdornment>
        }
        labelWidth={60}
        margin="dense"
        style={style}
      />
    </FormControl >
  );
};

InputSearch.propTypes = {
  onSearch: PropTypes.func,
  searchText: PropTypes.string,
  onClear: PropTypes.func,
  id: PropTypes.string.isRequired,
  style: PropTypes.any
};

export default InputSearch;
