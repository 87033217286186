import { all, put, takeEvery } from 'redux-saga/effects';
import LayoutTypes from './LayoutTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/layout';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';

// Layout management

function * getLayoutSetting () {
  yield takeEvery(LayoutTypes.GET_LAYOUT_SETTING_REQUEST, _getLayoutSetting);
}

function * _getLayoutSetting ({ payload }) {
  const api = {
    ...API.GET_LAYOUT_SETTING
  };
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      yield put({
        type: LayoutTypes.GET_LAYOUT_SETTING_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * updateLayoutSetting () {
  yield takeEvery(LayoutTypes.UPDATE_LAYOUT_REQUEST, _updateLayoutSetting);
}

function * _updateLayoutSetting ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.UPDATE_LAYOUT_SETTING,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: LayoutTypes.GET_LAYOUT_SETTING_REQUEST,
        payload: {}
      });
    }
  });
}

function * resetLayoutSetting () {
  yield takeEvery(LayoutTypes.RESET_LAYOUT_REQUEST, _resetLayoutSetting);
}

function * _resetLayoutSetting ({ payload }) {
  yield createRequest({
    api: {
      ...API.RESET_LAYOUT_SETTING
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    }
  });
}

export default function * layoutSaga () {
  yield all([
    getLayoutSetting(),
    updateLayoutSetting(),
    resetLayoutSetting()
  ]);
}
