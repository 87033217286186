import NotificationTypes from './NotificationTypes';

export function setupNotification (provider) {
  return {
    type: NotificationTypes.SET_UP_NOTIFICATION,
    payload: { provider }
  };
}

export function showNotification (config) {
  return {
    type: NotificationTypes.SHOW_NOTIFICATION,
    payload: { config }
  };
}
