import SearchTypes from './SearchTypes';

export function getData (data) {
  return {
    type: SearchTypes.GET_DATA_REQUEST,
    payload: data
  };
}

export function getPublicData (data) {
  return {
    type: SearchTypes.GET_PUBLIC_DATA_REQUEST,
    payload: data
  };
}

export function loadDetail (data) {
  return {
    type: SearchTypes.GET_CONTENT_INFO_REQUEST,
    payload: data
  };
}

export function loadOptions (data) {
  return {
    type: SearchTypes.GET_OPTIONS_REQUEST,
    payload: data
  };
}

export function resetSearchPage (data) {
  return {
    type: SearchTypes.RESET_PAGE,
    payload: data
  };
}

export function searchFullText (data) {
  return {
    type: SearchTypes.SEARCH_FULL_TEXT_REQUEST,
    payload: data
  };
}

export function selectedSearchTab (data) {
  return {
    type: SearchTypes.SELECTED_TAB_ID,
    payload: data
  };
}

export function loadSearchTermPage (data) {
  return {
    type: SearchTypes.GET_SEARCH_TERM_PAGE_REQUEST,
    payload: data
  };
}
