export default {
  GET_BANNER_DETAIL: {
    url: '/banner',
    method: 'GET'
  },
  UPDATE_BANNER: {
    url: '/banner',
    method: 'PUT'
  },
  RESET_BANNER: {
    url: '/banner/reset-banner',
    method: 'PUT'
  }
};
