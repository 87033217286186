const _prefix = '@tab/';

export default {
  ADD_BUTTON_REQUEST: _prefix + 'ADD_BUTTON_REQUEST',
  UPDATE_BUTTON_REQUEST: _prefix + 'UPDATE_BUTTON_REQUEST',
  DELETE_BUTTON_REQUEST: _prefix + 'DELETE_BUTTON_REQUEST',

  GET_BUTTON_REQUEST: _prefix + 'GET_BUTTON_REQUEST',
  GET_BUTTON_SUCCESS: _prefix + 'GET_BUTTON_SUCCESS',

  GET_TAB_REQUEST: _prefix + 'GET_TAB_REQUEST',
  GET_TAB_SUCCESS: _prefix + 'GET_TAB_SUCCESS',

  UPDATE_TAB_REQUEST: _prefix + 'UPDATE_TAB_REQUEST'
};
