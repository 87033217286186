import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getMenuRoutes, getRoutePath } from 'state/route/routeSelector';
import { getAuthUser } from 'state/session/sessionSelector';
import { connect, useSelector } from 'react-redux';
import { ActionContext } from 'components/layout/MainContainer';
import TranslateMessage from 'components/common/TranslateMessage/TranslateMessage';
import { Link } from 'react-router-dom';
import {
  getShowSubscriptionStatus,
  getViewSettings
} from 'state/company/companySelector';

import './styles/AppDrawer.css';
import Icon from '@material-ui/core/Icon';
import { OPTION_LOGIN } from 'configs/AppConfig';

// Initial class name for app drawer
const className = {
  mobile: 'mobile',
  mini: 'mini',
  normal: 'normal'
};

const AppDrawer = (props) => {
  const {
    drawerValue
  } = props;

  const viewSettings = useSelector(getViewSettings) || {};
  const isOpenType = viewSettings.accountType === OPTION_LOGIN.OPEN_TYPE;
  const showSubscriptionStatus = useSelector(getShowSubscriptionStatus);

  const user = props.authUser;
  const menus = props.menuRoutes.filter(item => {
    if (item.accessRole.includes(user.roleLevel)) {
      if (isOpenType && item.ignoreLoginSetting && item.ignoreLoginSetting.includes(OPTION_LOGIN.OPEN_TYPE)) {
        return false;
      }
      if (showSubscriptionStatus) {
        return true;
      } else {
        return !item.showWithSubscription;
      }
    } else {
      return false;
    }
  });
  const [drawerClass, setDrawerClass] = useState(className.normal);

  /**
   * Listen for drawerValue change to update the class name
   */
  useEffect(() => {
    if (drawerValue === className.mini) {
      setDrawerClass(className.mini);
    }

    if (drawerValue === className.normal) {
      setDrawerClass(className.normal);
    }

    if (drawerValue === className.mobile) {
      setDrawerClass(className.mobile);
    }
  }, [drawerValue]);

  return (
    <nav
      className={`side-drawer ${drawerClass}`}
    >
      <div
        className={`list__container ${drawerClass}`}
      >
        <ul>
          {
            menus.map(menu => {
              const classes = props.routePath === menu.path ? ' bg-info text-white' : '';
              return (
                <Link to={menu.path} key={menu.path}>
                  <li
                    className={`cs-menu-item${classes}`}
                    style={{
                      flexDirection: drawerValue === className.normal ? 'row' : 'column',
                      fontSize: drawerValue !== className.normal ? '11px' : 'inherit',
                      padding: drawerValue !== className.normal ? '0.5rem 0' : ''
                    }}
                  >
                    <Icon className={drawerValue === 'normal' ? 'mr-2' : undefined}>{menu.iconClass}</Icon>
                    {
                      drawerValue === 'normal' && <TranslateMessage id={menu.title} />
                    }
                  </li>
                </Link>
              );
            })
          }
        </ul>
      </div>
    </nav>
  );
};

AppDrawer.defaultProps = {};

AppDrawer.propTypes = {
  location: PropTypes.object.isRequired,
  sessionActions: PropTypes.object.isRequired,
  menuRoutes: PropTypes.array.isRequired,
  routePath: PropTypes.string,
  authUser: PropTypes.object.isRequired,
  drawerValue: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
  return {
    menuRoutes: getMenuRoutes(state),
    routePath: getRoutePath(state),
    authUser: getAuthUser(state)
  };
};

export default connect(mapStateToProps)(
  props => (
    <ActionContext.Consumer>
      {({ sessionActions }) => (
        <AppDrawer
          {...props}
          sessionActions={sessionActions}
        />
      )}
    </ActionContext.Consumer>
  )
);
