import EmailSenderTypes from './EmailTemplateTypes';
import { SENT_EMAIL_TYPE } from 'configs/AppConfig';

const initialState = {
  emailTemplate: {
    bodyJa: '',
    bodyEn: '',
    subjectJa: '',
    subjectEn: '',
    sentEmailType: '',
    isDefault: false
  },
  emailRegister: {
    bodyJa: '',
    bodyEn: '',
    subjectJa: '',
    subjectEn: '',
    sentEmailType: '',
    isDefault: false
  },
  emailConfirmOrder: {
    termsJa: '',
    termsEn: '',
    senderJa: '',
    senderEn: '',
    inquiryFormJa: '',
    inquiryFormEn: '',
    privacyPolicyJa: '',
    privacyPolicyEn: '',
    sentEmailType: '',
    isDefault: false
  },
  emailRenewalFail: {
    termsJa: '',
    termsEn: '',
    senderJa: '',
    senderEn: '',
    inquiryFormJa: '',
    inquiryFormEn: '',
    privacyPolicyJa: '',
    privacyPolicyEn: '',
    sentEmailType: '',
    isDefault: false
  },
  emailNotifyRenewal: {
    termsJa: '',
    termsEn: '',
    senderJa: '',
    senderEn: '',
    inquiryFormJa: '',
    inquiryFormEn: '',
    privacyPolicyJa: '',
    privacyPolicyEn: '',
    sentEmailType: '',
    isDefault: false
  }
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  const newState = { ...state };
  switch (type) {
    case EmailSenderTypes.GET_EMAIL_TEMPLATE_SUCCESS: {
      const {
        bodyJa,
        bodyEn,
        subjectJa,
        subjectEn,
        termsJa,
        termsEn,
        senderJa,
        senderEn,
        inquiryFormJa,
        inquiryFormEn,
        privacyPolicyJa,
        privacyPolicyEn,
        type,
        isDefault
      } = payload;
      switch (type) {
        case SENT_EMAIL_TYPE.FORGOT_PASSWORD:
          newState.emailTemplate = {
            bodyJa: bodyJa || '',
            bodyEn: bodyEn || '',
            subjectJa: subjectJa || '',
            subjectEn: subjectEn || '',
            termsJa: termsJa || '',
            termsEn: termsEn || '',
            senderJa: senderJa || '',
            senderEn: senderEn || '',
            inquiryFormJa: inquiryFormJa || '',
            inquiryFormEn: inquiryFormEn || '',
            privacyPolicyJa: privacyPolicyJa || '',
            privacyPolicyEn: privacyPolicyEn || '',
            sentEmailType: type || '',
            isDefault
          };
          break;
        case SENT_EMAIL_TYPE.REGISTER_USER:
          newState.emailRegister = {
            bodyJa: bodyJa || '',
            bodyEn: bodyEn || '',
            subjectJa: subjectJa || '',
            subjectEn: subjectEn || '',
            termsJa: termsJa || '',
            termsEn: termsEn || '',
            senderJa: senderJa || '',
            senderEn: senderEn || '',
            inquiryFormJa: inquiryFormJa || '',
            inquiryFormEn: inquiryFormEn || '',
            privacyPolicyJa: privacyPolicyJa || '',
            privacyPolicyEn: privacyPolicyEn || '',
            sentEmailType: type || '',
            isDefault
          };
          break;
        case SENT_EMAIL_TYPE.CONFIRM_ORDER:
        case SENT_EMAIL_TYPE.RENEWAL_FAIL:
        case SENT_EMAIL_TYPE.NOTIFY_RENEWAL:
        case SENT_EMAIL_TYPE.PAYMENT_PROCESS:
          newState.emailTemplate = {
            bodyJa: bodyJa || '',
            bodyEn: bodyEn || '',
            subjectJa: subjectJa || '',
            subjectEn: subjectEn || '',
            termsJa: termsJa || '',
            termsEn: termsEn || '',
            senderJa: senderJa || '',
            senderEn: senderEn || '',
            inquiryFormJa: inquiryFormJa || '',
            inquiryFormEn: inquiryFormEn || '',
            privacyPolicyJa: privacyPolicyJa || '',
            privacyPolicyEn: privacyPolicyEn || '',
            sentEmailType: type || '',
            isDefault
          };
          break;
        default:
          break;
      }
      break;
    }
  }
  return newState;
}
