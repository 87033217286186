const _prefix = '@content/';

export default {
  GET_CONTENT_DATA_REQUEST: _prefix + 'GET_CONTENT_DATA_REQUEST',
  GET_CONTENT_DATA_SUCCESS: _prefix + 'GET_CONTENT_DATA_SUCCESS',
  GET_CONTENT_DATA_ERROR: _prefix + 'GET_CONTENT_DATA_ERROR',

  ADD_CONTENT_REQUEST: _prefix + 'ADD_CONTENT_REQUEST',
  UPDATE_CONTENT_REQUEST: _prefix + 'UPDATE_CONTENT_REQUEST',
  DELETE_CONTENT_REQUEST: _prefix + 'DELETE_CONTENT_REQUEST',

  GET_CONTENT_DETAIL_REQUEST: _prefix + 'GET_CONTENT_DETAIL_REQUEST',
  GET_CONTENT_DETAIL_SUCCESS: _prefix + 'GET_CONTENT_DETAIL_SUCCESS',
  GET_CONTENT_DETAIL_ERROR: _prefix + 'GET_CONTENT_DETAIL_ERROR',

  UPDATE_PAGINATION: _prefix + 'UPDATE_PAGINATION',

  EXPORT_DATA_REQUEST: _prefix + 'EXPORT_DATA_REQUEST',
  UPLOAD_CONTENT_REQUEST: _prefix + 'UPLOAD_CONTENT_REQUEST',
  SET_UPLOAD_PROGRESS: 'SET_UPLOAD_PROGRESS',
  SUCCESS_UPLOAD_CONTENT: _prefix + 'SUCCESS_UPLOAD_CONTENT',
  FAILURE_UPLOAD_CONTENT: _prefix + 'FAILURE_UPLOAD_CONTENT',

  GET_VIEWER_TOKEN: _prefix + 'GET_VIEWER_TOKEN',
  GET_VIEWER_TOKEN_SUCCESS: _prefix + 'GET_VIEWER_TOKEN_SUCCESS',
  GET_VIEWER_TOKEN_ERROR: _prefix + 'GET_VIEWER_TOKEN_ERROR',

  CLOSE_UPLOAD_PROGRESS: _prefix + 'CLOSE_UPLOAD_PROGRESS',
  EXPORT_CONTENTS_REQUEST: _prefix + 'EXPORT_CONTENTS_REQUEST',

  ADD_FAVORITE_CONTENT: _prefix + 'ADD_FAVORITE_CONTENT',
  REMOVE_FAVORITE_CONTENT: _prefix + 'REMOVE_FAVORITE_CONTENT',

  UPDATE_CONTENT_DETAIL_ID: _prefix + 'UPDATE_CONTENT_DETAIL_ID',
  UPDATE_CONTENT_TOKEN_ID: _prefix + 'UPDATE_CONTENT_TOKEN_ID'
};
