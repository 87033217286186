import React, { useLayoutEffect, useState } from 'react';

const ScrollTopButton = props => {
  const [display, setDisplay] = useState('none');

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useLayoutEffect(() => {
    const listener = () => {
      if (window.pageYOffset < 200) {
        setDisplay('none');
      } else {
        setDisplay('flex');
      }
    };
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);

  return (
    <div
      onClick={scrollToTop}
      className="to-top-btn bg-info"
      style={{
        display: display
      }}
    >
      <i className="fas fa-chevron-up"/>
    </div>
  );
};

export default ScrollTopButton;
