export default {
  GET_BUTTON: {
    url: '/search-result-setting/detail-button/',
    method: 'GET'
  },
  ADD_BUTTON: {
    url: '/search-result-setting/add-button',
    method: 'POST'
  },
  UPDATE_BUTTON: {
    url: '/search-result-setting/update-button/',
    method: 'PUT'
  },
  DELETE_BUTTON: {
    url: '/search-result-setting/delete-button/',
    method: 'DELETE'
  }
};
