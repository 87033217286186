import ImportTypes from './ImportTypes';

export function loadImportData (data) {
  return {
    type: ImportTypes.GET_IMPORT_DATA_REQUEST,
    payload: data
  };
}

export function downloadTemplate (payload) {
  return {
    type: ImportTypes.DOWNLOAD_TEMPLATE,
    payload: payload
  };
}

export function uploadContentCSV (payload) {
  return {
    type: ImportTypes.UPLOAD_CONTENT_CSV,
    payload: payload
  };
}

export function downloadFileLogs (id, fileName) {
  return {
    type: ImportTypes.DOWNLOAD_FILE_LOGS,
    payload: {
      id: id,
      fileName: fileName
    }
  };
}

export function importContentCSV (params) {
  return {
    type: ImportTypes.IMPORT_CONTENT_CSV,
    payload: params
  };
}

export function checkImportProgress (data) {
  return {
    type: ImportTypes.CHECK_IMPORT_PROGRESS_REQUEST,
    payload: data
  };
}
