import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import TranslateMessage from '../common/TranslateMessage/TranslateMessage';
import ChangePasswordForm from '../form/ChangePasswordForm';

const ChangePasswordModal = props => {
  const {
    isOpen,
    toggle,
    className,
    onSubmit,
    backdrop,
    strictPassword
  } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={className} backdrop={backdrop}>
      <ModalHeader toggle={toggle}>
        <TranslateMessage id="label.changePassword"/>
      </ModalHeader>
      <ModalBody>
        <ChangePasswordForm
          onSubmit={onSubmit}
          strictPassword={strictPassword}
        />
      </ModalBody>
    </Modal>
  );
};

ChangePasswordModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  className: PropTypes.object,
  onSubmit: PropTypes.func,
  backdrop: PropTypes.any,
  strictPassword: PropTypes.bool
};

export default ChangePasswordModal;
