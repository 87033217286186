import { all, put, takeEvery } from 'redux-saga/effects';
import UploadTypes from '../upload/UploadTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/upload';
import NotificationTypes from '../notification/NotificationTypes';
import Util from '../../utils/Util';
import snakecaseKeys from 'snakecase-keys';
import { PAGINATION, SECTION_TYPES, UPLOAD_FILE_TYPES } from '../../configs/AppConfig';
import { saveAs } from 'file-saver';

function * loadUploadedFiles () {
  yield takeEvery(UploadTypes.GET_UPLOADED_FILES_REQUEST, _loadUploadedFiles);
}

function * _loadUploadedFiles ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GET_DISCLOSURE_UPLOADED_FILES,
      params: request
    },
    onSuccess: function * (response) {
      yield put({
        type: payload.sectionType === SECTION_TYPES.DISCLOSURE.value ? UploadTypes.UPDATE_DISCLOSURE_FILES_QUERIES : UploadTypes.UPDATE_PATIENT_FILES_QUERIES,
        payload: { ...payload }
      });
      yield put({
        type: payload.sectionType === SECTION_TYPES.DISCLOSURE.value ? UploadTypes.GET_DISCLOSURE_GROUP_UPLOADED_FILES_SUCCESS : UploadTypes.GET_PATIENT_GROUP_UPLOADED_FILES_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: UploadTypes.GET_UPLOADED_FILES_ERROR,
        payload: status
      });
    }
  });
}

function * uploadFile () {
  yield takeEvery(UploadTypes.UPLOAD_FILE_REQUEST, _uploadFile);
}

function * _uploadFile ({ payload }) {
  const uploadFileType = payload.type || UPLOAD_FILE_TYPES.CSV;
  const formData = new FormData();
  formData.append('file', payload.file);
  formData.append('section_type', payload.sectionType);
  formData.append('year', payload.year);
  if (payload.sectionId) {
    formData.append('root_section_id', payload.sectionId);
  }
  const api = uploadFileType === UPLOAD_FILE_TYPES.CSV ? { ...API.UPLOAD_CSV_FILE } : { ...API.UPLOAD_PDF_FILE };
  yield createRequest({
    api: {
      ...api,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.upload.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: UploadTypes.GET_UPLOADED_FILES_REQUEST,
        payload: {
          ...PAGINATION,
          sectionType: payload.sectionType
        }
      });
    }
  });
}

function * importCSV () {
  yield takeEvery(UploadTypes.IMPORT_CSV_REQUEST, _importCSV);
}

function * _importCSV ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.IMPORT_CSV_FILE,
      params: request
    },
    onSuccess: function * () {
      yield put({
        type: UploadTypes.GET_UPLOADED_FILES_REQUEST,
        payload: {
          ...PAGINATION,
          sectionType: payload.sectionType
        }
      });
      yield put({
        type: UploadTypes.CHECK_IMPORT_PROGRESS_REQUEST
      });
    }
  });
}

function * checkImportProgress () {
  yield takeEvery(UploadTypes.CHECK_IMPORT_PROGRESS_REQUEST, _checkImportProgress);
}

function * _checkImportProgress () {
  yield createRequest({
    api: {
      ...API.CHECK_IMPORT_PROGRESS
    },
    onSuccess: function * (response) {
      yield put({
        type: UploadTypes.CHECK_IMPORT_PROGRESS_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * downloadCSVTemplate () {
  yield takeEvery(UploadTypes.DOWNLOAD_CSV_TEMPLATE, _downloadCSVTemplate);
}

function * _downloadCSVTemplate ({ payload }) {
  const params = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.DOWNLOAD_TEMPLATE,
      params: params,
      headers: {
        download: true
      }
    },
    onSuccess: function (response) {
      // Set '\ufeff' so that the file is actually converted to UTF-8
      const blob = new Blob(['\ufeff', response], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, payload.fileName);
    }
  });
}

export default function * uploadSaga () {
  yield all([
    loadUploadedFiles(),
    uploadFile(),
    importCSV(),
    checkImportProgress(),
    downloadCSVTemplate()
  ]);
}
