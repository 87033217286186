import {
  put,
  cancelled,
  take,
  fork,
  cancel,
  call,
  select,
  all,
  takeEvery
} from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';

import createRequest from '../../api/httpRequest';
import SessionTypes from './SessionTypes';
import StorageHelper from '../../utils/StorageHelper';
import { FORMS, STORAGE_KEYS, AUTHEN_TYPES, USER_CONFIG } from '../../configs/AppConfig';
import API_AUTHEN from '../../api/authentication';
import { cacheRoute, changeRoute } from '../route/RouteActions';
import { getCachedRoutePath, getHistory } from '../route/routeSelector';
import RouteTypes from '../route/RouteTypes';
import camelcaseKeys from 'camelcase-keys';
import Util from '../../utils/Util';
import { changeLocale } from '../locale/LocaleActions';
import CompanyTypes from '../company/CompanyTypes';
import { getCompanyDomain } from '../company/companySelector';
import { DEFAULT_LOCALE } from '../../configs/locale';
import SearchTypes from 'state/search/SearchTypes';
import moment from 'moment-timezone';
import * as APIStatus from '../../configs/ApiStatus';
import snakecaseKeys from 'snakecase-keys';
import NotificationTypes from 'state/notification/NotificationTypes';
import HomeTypes from 'state/home/HomeTypes';
import SubscriptionTypes from 'state/subscriptions/SubscriptionTypes';

let loginTask;

export default function * sessionSaga () {
  yield all([
    sessionLifeCycleSaga(),
    restoreSessionSaga(),
    getAuthInfo(),
    registerAccountUser()
  ]);
}

/**
 * Some rules:
 * - Token just stored in session storage [don't store in local storage]
 * - Credentials always stored in session storage [if remember login is true then store both session & local storage]
 */
function * sessionLifeCycleSaga () {
  while (true) {
    // init session
    const authToken = yield call(
      StorageHelper.getCookie,
      STORAGE_KEYS.session
    );
    const isAuthenGoogleLogin = yield call(
      StorageHelper.getLocalItem,
      STORAGE_KEYS.googleLogin
    );
    if (authToken) {
      // there is token in session => validate token state
      yield fork(validateSession);
    } else {
      if (isAuthenGoogleLogin) {
        yield call(
          StorageHelper.setLocalObject,
          STORAGE_KEYS.googleLogin,
          { isGoogleLogin: false }
        );
      }
      const userStore = yield call(StorageHelper.getLocalObject, STORAGE_KEYS.user) || {};
      if (userStore) {
        yield call(StorageHelper.removeLocalItem, STORAGE_KEYS.user);
      }
      // wait for login request...
      const { payload } = yield take(SessionTypes.LOG_IN_REQUEST);
      yield put({ type: SearchTypes.RESET_SETTING });
      // notify for redux-form request is handling
      yield put(startSubmit(FORMS.login));
      // fork return a Task object (a task with mission handle login request)
      loginTask = yield fork(authorize, payload);
    }

    // after logged in, wait some another request [log out, reauthorize when token expired...]
    const action = yield take([
      SessionTypes.LOG_OUT,
      SessionTypes.REAUTHORIZE, // call API return status 401
      SessionTypes.LOG_IN_ERROR,
      SessionTypes.RESTORE_SESSION_FAILURE
    ]);
    switch (action.type) {
      case SessionTypes.LOG_OUT:
        yield call(logout);
        break;

      case SessionTypes.REAUTHORIZE:
      case SessionTypes.RESTORE_SESSION_FAILURE:
        yield clearToken();
        break;

      default:
      // don't do anything
    }
  }
}

function * logout () {
  yield put({ type: SearchTypes.RESET_SETTING });

  // cancel login if request is handling
  if (loginTask) {
    yield cancel(loginTask);
  }

  yield createRequest({
    api: API_AUTHEN.LOG_OUT
  });

  yield put({ type: SessionTypes.LOG_OUT_SUCCESS });
  yield clearToken();
}

function * clearToken () {
  const userStore = yield call(StorageHelper.getLocalObject, STORAGE_KEYS.user) || {};
  const user = userStore || {};
  yield call(StorageHelper.removeCookie, STORAGE_KEYS.session, { path: '/admin' });
  yield call(StorageHelper.removeCookie, STORAGE_KEYS.session);
  yield call(StorageHelper.removeLocalItem, STORAGE_KEYS.user);
  yield call(
    StorageHelper.setLocalObject,
    STORAGE_KEYS.googleLogin,
    { isGoogleLogin: false }
  );
  if (user.roleLevel === USER_CONFIG.SYSTEM_ADMIN.roleLevel || user.roleLevel === USER_CONFIG.COMPANY_ADMIN.roleLevel) {
    yield put(changeRoute('/admin-login', ''));
  } else {
    yield put(changeRoute('/login', ''));
  }
  yield put(changeLocale(Util.getBrowserLanguage() || DEFAULT_LOCALE));
  yield put(cacheRoute(null, null));
}

function * authorize ({ email, password, rememberMe, ip, loginType, loginRole, authorizeCode, redirectUrl }) {
  const currentDomain = yield select(getCompanyDomain);
  let api = Object.assign({}, API_AUTHEN.LOG_IN, {
    data: {
      email: email,
      keep_login: rememberMe,
      password_hash: password,
      login_type: loginRole,
      domain: currentDomain
    }
  });
  let ignoreErrorMessage = false;
  if (loginType === AUTHEN_TYPES.IP) {
    api = Object.assign({}, API_AUTHEN.LOG_IN_BY_IP_ADDRESS, {
      data: {
        client_ip_address: ip,
        keep_login: rememberMe,
        company_domain: currentDomain
      }
    });
  }
  if (loginType === AUTHEN_TYPES.AUTO_IP) {
    api = Object.assign({}, API_AUTHEN.AUTO_LOG_IN_BY_IP_ADDRESS, {
      data: {
        client_ip_address: ip,
        keep_login: rememberMe,
        company_domain: currentDomain
      }
    });

    ignoreErrorMessage = true;
  }
  if (loginType === AUTHEN_TYPES.GOOGLE_LOGIN) {
    api = Object.assign({}, API_AUTHEN.LOGIN_WITH_GOOGLE, {
      data: {
        domain: currentDomain,
        keep_login: rememberMe,
        authorize_code: authorizeCode,
        redirect_url: redirectUrl
      }
    });
  }
  yield createRequest({
    api: api,
    onSuccess: function * ({ data, status }, response) {
      if (data && status === 200) {
        yield put({
          type: HomeTypes.EMPTY_PUBLIC_CONTENT_GROUP,
          payload: []
        });
        yield put({
          type: SubscriptionTypes.EMPTY_HOME_SUBSCRIPTION_PLAN_LIST
        });
        const res = Util.toCamelCaseKey(data);
        const { exceededSessionMessage, displayMessageExceededSession } = res;
        let user = {
          companyId: res.companyId,
          domain: res.domain,
          email: res.email,
          name: res.fullName,
          id: res.id,
          lang: res.lang ? res.lang : Util.getBrowserLanguage() || DEFAULT_LOCALE,
          roleLevel: res.roleLevel
        };
        if (loginType !== AUTHEN_TYPES.EMAIL && loginType !== AUTHEN_TYPES.GOOGLE_LOGIN) {
          user = {
            companyId: res.companyId,
            domain: res.domain,
            id: res.id,
            name: res.name,
            roleLevel: res.roleLevel,
            lang: res.lang ? res.lang : Util.getBrowserLanguage() || DEFAULT_LOCALE
          };
        }
        const currentDomain = yield select(getCompanyDomain);
        const domain = res.domain;
        const now = moment();
        const expireDate = moment.utc(res.expireDate).local();
        const diff = expireDate.diff(now) / 1000;
        console.log(diff);
        if (currentDomain === domain) {
          // store in local storage
          yield call(StorageHelper.setCookie, STORAGE_KEYS.session, res.accessToken, {
            maxAge: 60 * 60 * 24 * 365
          });

          yield put(changeLocale(user.lang));
          if (loginType === AUTHEN_TYPES.GOOGLE_LOGIN) {
            yield call(
              StorageHelper.setLocalObject,
              STORAGE_KEYS.googleLogin,
              { isGoogleLogin: true }
            );
            yield put({
              type: SessionTypes.LOG_IN_SUCCESS,
              payload: { accessToken: res.accessToken, user: user, isGoogleLogin: true }
            });
            const cacheStorage = StorageHelper.getSessionObject(STORAGE_KEYS.cacheStorage);
            if (!cacheStorage.cacheRoute.includes('/login')) {
              yield put(changeRoute(`${cacheStorage.cacheRoute}`, ''));
            } else {
              StorageHelper.removeSessionItem(STORAGE_KEYS.cacheStorage);
            }
          } else {
            yield call(
              StorageHelper.setLocalObject,
              STORAGE_KEYS.googleLogin,
              { isGoogleLogin: false }
            );
            yield put({
              type: SessionTypes.LOG_IN_SUCCESS,
              payload: { accessToken: res.accessToken, user: user, isGoogleLogin: false }
            });
          }

          yield put(stopSubmit(FORMS.login));
          yield fork(validateSession);
        }
        if (displayMessageExceededSession) {
          yield put({
            type: NotificationTypes.SHOW_NOTIFICATION,
            payload: {
              config: {
                message: exceededSessionMessage && exceededSessionMessage !== '' ? exceededSessionMessage : 'text.exceededMessageDefault',
                level: 'error',
                notShowIcon: true,
                autoDismiss: 5,
                position: 'tc'
              }
            }
          });
        }
      } else {
        console.error('Can not read "access_token" from response');
      }
    },
    onError: function * (error) {
      if (loginType === AUTHEN_TYPES.GOOGLE_LOGIN) {
        if (error.status === APIStatus.ERR_USER_NOT_FOUND.status || error.status === APIStatus.ERR_EMAIL_ALREADY_EXISTED.status ||
          error.status === APIStatus.ERR_USER_BLOCKED.status || error.status === APIStatus.ERR_BAD_REQUEST.status ||
          error.status === APIStatus.ERR_BOOKEND_API.status || error.status === APIStatus.ERR_COMPANY_NOT_FOUND.status ||
          error.status === APIStatus.ERR_USER_GROUP_NOT_FOUND.status || error.status === APIStatus.ERR_USER_GROUP_MAX_SESSION.status ||
          error.status === APIStatus.ERR_USER_ACCESS_EXPIRED.status || error.status === APIStatus.ERR_COMPANY_MAX_SESSION.status) {
          yield put(stopSubmit(FORMS.login, { _error: 'api.error.' + error.status }));
          yield put({ type: SessionTypes.CACHE_PREV_LOGIN_ROUTE });
          yield put({ type: SessionTypes.LOG_IN_ERROR });
        }
      } else {
        yield put(stopSubmit(FORMS.login, { _error: 'api.error.' + error.status }));
        yield put({ type: SessionTypes.LOG_IN_ERROR });
      }
    },
    onRequestError: function * (response) {
      yield put(stopSubmit(FORMS.login));
      yield put({ type: SessionTypes.LOG_IN_ERROR });
    },
    ignoreErrorMessage
  });

  if (yield cancelled()) {
    // ... put special cancellation handling code here
  }
}

function * getAuthInfo () {
  yield takeEvery(SessionTypes.GET_AUTH_INFO_REQUEST, validateSession);
}

function * validateSession () {
  yield createRequest({
    api: API_AUTHEN.GET_AUTH_INFO,
    onSuccess: function * ({ data }, response) {
      const user = camelcaseKeys(data);
      const domain = data.domain;
      // update token;
      // const authToken = response.config.headers[REQUEST.ACCESS_HEADER];
      // yield call(StorageHelper.setCookie, STORAGE_KEYS.session, authToken, {
      //   maxAge: 24 * 60 * 60
      // });
      yield call(
        StorageHelper.setLocalObject,
        STORAGE_KEYS.user,
        user
      );
      yield put(changeLocale(user.lang || Util.getBrowserLanguage() || DEFAULT_LOCALE));
      yield put({ type: SessionTypes.RESTORE_SESSION_SUCCESS, payload: user });
      yield put({ type: CompanyTypes.SET_COMPANY_DOMAIN, payload: domain });
    },
    onError: function * () {
      // cache route to restore session after login
      yield put({ type: SessionTypes.CACHE_PREV_LOGIN_ROUTE });
      yield put({ type: SessionTypes.RESTORE_SESSION_FAILURE });
    }
  });

  if (yield cancelled()) {
    // ... put special cancellation handling code here
  }
}

/**
 * There are 3 place dispatch `SessionTypes.CACHE_PREV_LOGIN_ROUTE` action
 * 1. errorSaga - handleApiError(): in case API return status code 401
 * 2. sessionSaga - validateSession(): in case validate session failure [token is invalid or expired]
 * 3. AuthenticatedRoute component - getDerivedStateFromProps(): in case access a router required authenticated but not yet authenticated
 */
// restore the last accessed route after login
function * restoreSessionSaga () {
  // only active after set history
  yield take(RouteTypes.SET_HISTORY);
  while (true) {
    const { pathname } = yield select(getCachedRoutePath);
    if (!pathname) {
      // update current route before redirect to login page
      yield take(SessionTypes.CACHE_PREV_LOGIN_ROUTE);

      // get current route
      const {
        location: { pathname, search }
      } = yield select(getHistory);
      if (pathname.includes('/login') && search) {
        yield put(changeRoute(pathname, null));
      } else {
        yield put(cacheRoute(pathname, search));
      }
    }

    const action = yield take([
      SessionTypes.LOG_IN_SUCCESS,
      SessionTypes.REAUTHORIZE, // call API return status 401
      SessionTypes.RESTORE_SESSION_FAILURE
    ]);
    switch (action.type) {
      case SessionTypes.LOG_IN_SUCCESS:
        // restore the last route
        // eslint-disable-next-line no-case-declarations
        const { pathname, search } = yield select(getCachedRoutePath);
        if (pathname) {
          yield put(changeRoute(pathname, search));
          yield put(cacheRoute(null, null));
        }
        break;

      case SessionTypes.REAUTHORIZE:
      case SessionTypes.RESTORE_SESSION_FAILURE:
        yield clearToken();
        break;

      default:
        break;
    }
  }
}

function * registerAccountUser () {
  yield takeEvery(SessionTypes.REGISTER_ACCOUNT_USER_REQUEST, _registerAccountUser);
}

function * _registerAccountUser ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API_AUTHEN.SIGN_UP,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.register.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: SessionTypes.REGISTER_ACCOUNT_USER_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * () {
      yield put({
        type: SessionTypes.REGISTER_ACCOUNT_USER_ERROR
      });
    }
  });
}
