import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import TranslateMessage from '../common/TranslateMessage/TranslateMessage';
import ChangeEmailForm from '../form/ChangeEmailForm';

const ChangeEmailModal = props => {
  const {
    isOpen,
    toggle,
    className,
    onSubmit,
    backdrop
  } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={className} backdrop={backdrop}>
      <ModalHeader toggle={toggle}>
        <TranslateMessage id="title.changeEmail"/>
      </ModalHeader>
      <ModalBody>
        <ChangeEmailForm
          onSubmit={onSubmit}
        />
      </ModalBody>
    </Modal>
  );
};

ChangeEmailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  className: PropTypes.object,
  onSubmit: PropTypes.func,
  backdrop: PropTypes.any
};

export default ChangeEmailModal;
