import { all, put, takeEvery } from 'redux-saga/effects';
import TabTypes from './TabTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/tab';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';
import { PAGINATION } from '../../configs/AppConfig';

// Tab management

function * getTabSetting () {
  yield takeEvery(TabTypes.GET_TAB_REQUEST, _getTabSetting);
}

function * _getTabSetting ({ payload }) {
  const api = {
    ...API.GET_TAB_SETTING
  };
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      yield put({
        type: TabTypes.GET_TAB_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * updateTabSetting () {
  yield takeEvery(TabTypes.UPDATE_TAB_REQUEST, _updateTabSetting);
}

function * _updateTabSetting ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.UPDATE_TAB,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: TabTypes.GET_TAB_REQUEST,
        payload: {}
      });
    }
  });
}

function * getButton () {
  yield takeEvery(TabTypes.GET_BUTTON_REQUEST, _getButton);
}

function * _getButton ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_BUTTON,
      url: API.GET_BUTTON.url + payload
    },
    onSuccess: function * (response) {
      yield put({
        type: TabTypes.GET_BUTTON_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * addButton () {
  yield takeEvery(TabTypes.ADD_BUTTON_REQUEST, _addButton);
}

function * _addButton ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.ADD_BUTTON,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: TabTypes.GET_TAB_REQUEST,
        payload: {}
      });
    }
  });
}

function * updateButton () {
  yield takeEvery(TabTypes.UPDATE_BUTTON_REQUEST, _updateButton);
}

function * _updateButton ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.UPDATE_BUTTON,
      url: API.UPDATE_BUTTON.url + payload.id,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: TabTypes.GET_TAB_REQUEST,
        payload: PAGINATION
      });
    }
  });
}

function * deleteButton () {
  yield takeEvery(TabTypes.DELETE_BUTTON_REQUEST, _deleteButton);
}

function * _deleteButton ({ payload }) {
  yield createRequest({
    api: {
      ...API.DELETE_BUTTON,
      url: API.DELETE_BUTTON.url + payload
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.delete.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: TabTypes.GET_TAB_REQUEST,
        payload: PAGINATION
      });
    }
  });
}

export default function * tabSaga () {
  yield all([
    getTabSetting(),
    updateTabSetting(),
    getButton(),
    addButton(),
    updateButton(),
    deleteButton()
  ]);
}
