export const getContentList = state => state.content.contentData;
export const getPagination = state => state.content.pagination;
export const getSelected = state => state.content.selected;
export const getContentDetail = state => state.content.contentDetail;
export const getIsContentDetailLoad = state => state.content.isContentDetailLoad;
export const getUploadContent = state => state.content.uploadContents;
export const getViewerToken = state => state.content.viewerToken;
export const getWatermark = state => state.content.watermark;
export const getPrintSettingViewer = state => state.content.enablePrint;
export const getContentTypeViewer = state => state.content.contentType;
export const getIsErrorViewerOnline = state => state.content.isErrorViewer;

export const checkHaveContentDetail = state => state.content.haveContentDetail;
