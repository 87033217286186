import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Spinner } from 'reactstrap';
import TranslateMessage from '../../common/TranslateMessage/TranslateMessage';
import PropTypes from 'prop-types';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useIntl } from 'react-intl';
import InputSearch from 'components/common/Input/InputSearch';
import { connect, useSelector } from 'react-redux';
import { ActionContext } from 'components/layout/MainContainer';
// import { getIsLoading, getPagination, getTransactionList, getTransactionTotalPages, getTransactionTotalRecords } from 'state/transaction/TransactionSelector';
import Util from 'utils/Util';
import moment from 'moment';
import { getToken, isAuthenticated } from 'state/session/sessionSelector';
import { getIsLoading, getPagination, getTransactionList, getTransactionTotalPages, getTransactionTotalRecords } from 'state/payment/paymentSelector';

const Style = {
  spin: {
    display: 'flex',
    justifyContent: 'center'
  },
  itemMax: {
    maxWidth: 300,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block'
  },
  coverCard: {
    display: 'flex',
    overflowX: 'auto',
    paddingBottom: '10px'
  },
  itemCard: {
    width: '300px',
    minWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.125)',
    borderRadius: '0.25rem'
  }

};

const UserTablePaymentHistory = (props) => {
  const {
    list,
    queries,
    totalRecords,
    totalPages,
    isLoading,
    paymentActions: {
      loadPaymentHisotryPage
    }
  } = props;

  const [searchKey, setSearchKey] = useState('');

  const { formatMessage: f } = useIntl();

  const isAuthen = useSelector(isAuthenticated);
  const isToken = useSelector(getToken);

  const headerFormatter = (col) => (
    <TranslateMessage id={col.text} />
  );

  const renderPriceColumn = (col, row) => {
    const value = '¥' + `${row.price !== 0 ? Util.formatThousandsNumber(row.price) : 0}`;
    return (<span>{value}</span>);
  };

  const columns = [
    {
      dataField: 'name',
      text: 'label.plan',
      headerFormatter
    },
    {
      dataField: 'price',
      text: 'label.amount',
      headerFormatter,
      formatter: renderPriceColumn
    },
    {
      dataField: 'createdDate',
      text: 'label.date',
      headerFormatter,
      formatter: (col, row) => (moment.utc(row.createdDate).local().format('YYYY/MM/DD').toString())
    },
    {
      dataField: 'status',
      text: 'label.status',
      headerFormatter,
      formatter: (col, row) => row.status === 'SUCCESS' ? f({ id: 'label.ok' }) : row.status === 'PENDING' ? f({ id: 'label.beforePayment' }) : f({ id: 'label.ng' })
    }
  ];

  useEffect(() => {
    if (isAuthen && isToken) {
      loadPaymentHisotryPage({ page: 1, size: 10 });
    }
  }, [isAuthen, isToken]);

  /**
     * Total label for tables
     * @param from
     * @param to
     * @param size
     * @returns {*}
     */
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {f({ id: 'label.tableTotal' }, { from, to, size })}
    </span>
  );

  /**
     * Call data on page changed
     * @param page
     * @param sizePerPage
     */
  const handlePageChange = (page, sizePerPage) => {
    const newQueries = { ...queries };
    newQueries.page = page;
    newQueries.size = sizePerPage;
    newQueries.search_key = searchKey;
    loadPaymentHisotryPage(newQueries);
  };

  const pageListRenderer = ({
    pages,
    onPageChange
  }) => {
    // just exclude <, <<, >>, >
    const pageWithoutIndication = pages.filter(p => typeof p.page !== 'string');
    return (
      <ul className="pagination react-bootstrap-table-page-btns-ul" style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <li className={`page-item ${queries.page <= 1 ? 'disabled' : ''}`} onClick={ () => onPageChange(1) } >
          <a className="page-link">{'<<'}</a>
        </li>
        <li className={`page-item ${queries.page <= 1 ? 'disabled' : ''}`} onClick={ () => onPageChange(queries.page - 1) } >
          <a className="page-link">{'<'}</a>
        </li>
        {
          pageWithoutIndication.map(p => {
            if (p.page >= (queries.page - 3) && p.page <= (queries.page + 3)) {
              return (
                <li
                  className={`page-item ${queries.page === p.page ? 'active' : ''}`}
                  key={`page-${p.page}`}
                  onClick={ () => onPageChange(p.page) }
                >
                  <a className="page-link">
                    { p.page }
                  </a>
                </li>
              );
            }
            if (p.page - 4 === queries.page || p.page === queries.page - 4) {
              return (
                <li
                  className={`page-item ${queries.page === p.page ? 'active' : ''}`}
                  key={`page-${p.page}`}
                  onClick={ () => onPageChange(p.page) }
                >
                  <a className="page-link">
                    {'...'}
                  </a>
                </li>
              );
            }
          })
        }
        <li className={`page-item ${queries.page >= totalPages ? 'disabled' : ''}`} onClick={ () => onPageChange(queries.page + 1) } >
          <a className="page-link">{'>'}</a>
        </li>
        <li className={`page-item ${queries.page >= totalPages ? 'disabled' : ''}`} onClick={ () => onPageChange(totalPages) } >
          <a className="page-link">{'>>'}</a>
        </li>
      </ul>
    );
  };

  /**
  * Table Pagination factory
  */
  const pagination = paginationFactory({
    page: queries.page,
    sizePerPage: queries.size,
    totalSize: totalRecords,
    paginationSize: totalPages,
    alwaysShowAllBtns: true,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    pageListRenderer: pageListRenderer,
    onPageChange: handlePageChange
  });

  // trigger event when the table has any data change here
  const onTableChange = (type, newState) => {
    // handle any data change here
    if (type === 'search') {
      const newQueries = { ...queries };
      newQueries.search_key = newState.searchText.trim();
      newQueries.page = 1;
      setSearchKey(newState.searchText.trim());
      loadPaymentHisotryPage(newQueries);
    }
  };

  return (
    <>
      <ToolkitProvider
        keyField='id'
        bootstrap4
        search
        data={list}
        columns={columns}
      >
        {
          props => (
            <>
              <div className="row mb-3">
                <div className="col-sm-12 col-md-8 mt-sm-0 mt-2">
                  <InputSearch
                    id="user-group-search"
                    searchText={queries.search_key}
                    onSearch={onTableChange}
                  />
                </div>
              </div>
              {
                isLoading && <div style={Style.spin}>
                  <Spinner color='primary' style={{ marginRight: 20 }} /> <TranslateMessage id='progress.loading'/>
                </div>
              }
              {(!isLoading && list) &&
              <React.Fragment>
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  remote={{
                    filter: false,
                    pagination: list && list.length > 0,
                    sort: true,
                    cellEdit: false
                  }}
                  {...props.baseProps}
                  pagination={list && list.length > 0 ? pagination : null}
                  onTableChange={onTableChange}
                  noDataIndication={<TranslateMessage id="text.noData" />}
                  bordered={false}
                  tabIndexCell
                />
              </React.Fragment>
              }
            </>
          )
        }
      </ToolkitProvider>
    </>
  );
};

UserTablePaymentHistory.propTypes = {
  paymentActions: PropTypes.object.isRequired,
  list: PropTypes.array,
  queries: PropTypes.object,
  page: PropTypes.number,
  totalRecords: PropTypes.number,
  totalPages: PropTypes.number,
  searchProps: PropTypes.object,
  baseProps: PropTypes.object,
  isLoading: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    list: getTransactionList(state),
    queries: getPagination(state),
    totalRecords: getTransactionTotalRecords(state),
    totalPages: getTransactionTotalPages(state),
    isLoading: getIsLoading(state)
  };
};

export default connect(mapStateToProps)(
  props => (
    <ActionContext.Consumer>
      {({ paymentActions }) => (
        <UserTablePaymentHistory
          {...props}
          paymentActions={paymentActions}
        />
      )}
    </ActionContext.Consumer>
  )
);
