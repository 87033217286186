import { all, put, select, takeEvery } from 'redux-saga/effects';
import HomeTypes from './HomeTypes';
import createRequest from '../../api/httpRequest';
import API_CONTENT_GROUP from '../../api/content-group';
import API_CONTENT from '../../api/content';
import API_SEARCH from '../../api/search';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';
import { getCompanyDomain } from '../company/companySelector';
import { isAuthenticated } from '../session/sessionSelector';

function * loadPublicContentGroups () {
  yield takeEvery(HomeTypes.GET_PUBLIC_CONTENT_GROUP_REQUEST, _loadPublicContentGroups);
}

function * _loadPublicContentGroups ({ payload }) {
  const domain = yield select(getCompanyDomain);
  const request = snakecaseKeys({ ...payload, domain });
  const isAuthen = yield select(isAuthenticated);
  const api = isAuthen ? API_CONTENT_GROUP.GET_USER_CONTENT_GROUPS : API_CONTENT_GROUP.GET_PUBLIC_CONTENT_GROUPS;
  yield createRequest({
    api: {
      ...api,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: HomeTypes.GET_PUBLIC_CONTENT_GROUP_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
      yield put({
        type: HomeTypes.GET_HASHTAG_CONTENT_GROUP_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: HomeTypes.GET_PUBLIC_CONTENT_GROUP_ERROR,
        payload: status
      });
    }
  });
}

function * loadContentByContentGroup () {
  yield takeEvery(HomeTypes.GET_CONTENT_BY_CONTENT_GROUP_REQUEST, _loadContentByContentGroup);
}

function * _loadContentByContentGroup ({ payload }) {
  const { contentGroupId } = payload;
  const domain = yield select(getCompanyDomain);
  const request = snakecaseKeys({ ...payload, domain });
  yield createRequest({
    api: {
      ...API_CONTENT.GET_CONTENT_BY_CONTENT_GROUP,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: HomeTypes.GET_CONTENT_BY_CONTENT_GROUP_SUCCESS,
        payload: Util.toCamelCaseKey({
          ...response.data,
          contentGroupId
        })
      });
      if (response.data.content && response.data.content.length === 0 && payload.page > 1) {
        yield put({
          type: HomeTypes.GET_CONTENT_BY_CONTENT_GROUP_REQUEST,
          payload: {
            ...payload,
            page: payload.page - 1
          }
        });
      }
    },
    onError: function * ({ status }) {
      yield put({
        type: HomeTypes.GET_CONTENT_BY_CONTENT_GROUP_ERROR,
        payload: { status, contentGroupId }
      });
    }
  });
}

function * searchContentByContentGroup () {
  yield takeEvery(HomeTypes.SEARCH_CONTENT_BY_CONTENT_GROUP_REQUEST, _searchContentByContentGroup);
}

function * _searchContentByContentGroup ({ payload }) {
  const domain = yield select(getCompanyDomain);
  const { searchRequests, pagination } = payload;
  const { contentGroupId } = pagination;
  const request = snakecaseKeys({ ...pagination, domain });
  const data = snakecaseKeys([...searchRequests]);

  const isAuthen = yield select(isAuthenticated);
  const api = isAuthen ? API_SEARCH.GET_DATA : API_SEARCH.GET_PUBLIC_DATA;
  yield createRequest({
    api: {
      ...api,
      data: [...data],
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: HomeTypes.SEARCH_CONTENT_BY_CONTENT_GROUP_SUCCESS,
        payload: Util.toCamelCaseKey({
          ...response.data,
          contentGroupId
        })
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: HomeTypes.SEARCH_CONTENT_BY_CONTENT_GROUP_ERROR,
        payload: { status, contentGroupId }
      });
    }
  });
}

function * searchContentFullText () {
  yield takeEvery(HomeTypes.SEARCH_FULL_TEXT_BY_CONTENT_GROUP_REQUEST, _searchContentFullText);
}

function * _searchContentFullText ({ payload }) {
  const domain = yield select(getCompanyDomain);
  const isAuthen = yield select(isAuthenticated);
  const { contentGroupId } = payload;
  const request = snakecaseKeys({ ...payload, domain: domain });
  const api = isAuthen ? API_SEARCH.SEARCH_FULL_TEXT_AUTHEN : API_SEARCH.SEARCH_FULL_TEXT;
  yield createRequest({
    api: {
      ...api,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: HomeTypes.SEARCH_FULL_TEXT_BY_CONTENT_GROUP_SUCCESS,
        payload: Util.toCamelCaseKey({
          ...response.data,
          contentGroupId
        })
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: HomeTypes.SEARCH_FULL_TEXT_BY_CONTENT_GROUP_ERROR,
        payload: { status, contentGroupId }
      });
    }
  });
}

function * loadContentByHashtag () {
  yield takeEvery(HomeTypes.GET_CONTENT_BY_HASHTAG_REQUEST, _loadContentByHashtag);
}

function * _loadContentByHashtag ({ payload }) {
  const { contentGroupId } = payload.params;
  const domain = yield select(getCompanyDomain);
  const request = snakecaseKeys({ ...payload.params, domain });
  const isAuthen = yield select(isAuthenticated);
  const api = isAuthen ? API_CONTENT.GET_CONTENT_BY_HASH_TAG : API_CONTENT.GET_CONTENT_PUBLIC_BY_HASH_TAG;
  yield createRequest({
    api: {
      ...api,
      params: { ...request },
      data: {
        ids: payload.data
      }
    },
    onSuccess: function * (response) {
      yield put({
        type: HomeTypes.GET_CONTENT_BY_HASHTAG_SUCCESS,
        payload: Util.toCamelCaseKey({
          ...response.data,
          contentGroupId
        })
      });
      if (response.data.content && response.data.content.length === 0 && payload.page > 1) {
        yield put({
          type: HomeTypes.GET_CONTENT_BY_HASHTAG_REQUEST,
          payload: {
            ...payload,
            page: payload.page - 1
          }
        });
      }
    },
    onError: function * ({ status }) {
      yield put({
        type: HomeTypes.GET_CONTENT_BY_HASHTAG_ERROR,
        payload: { status, contentGroupId }
      });
    }
  });
}
export default function * contentGroupSaga () {
  yield all([
    loadPublicContentGroups(),
    loadContentByContentGroup(),
    searchContentByContentGroup(),
    searchContentFullText(),
    loadContentByHashtag()
  ]);
}
