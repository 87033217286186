import { SCROLL_TO_TOP, SET_SCROLLBAR } from './scrollbarActionTypes';
import { Map } from 'immutable';

const initialState = Map({});

function scrollbarReducer (state = initialState, action) {
  const { payload: { name, scrollbar } = {} } = action;
  let _scrollbar;
  switch (action.type) {
    case SET_SCROLLBAR:
      return state.set(name, scrollbar);

    case SCROLL_TO_TOP:
      _scrollbar = state.get(name);
      _scrollbar && _scrollbar.scrollToTop && _scrollbar.scrollToTop();

      return state;

    default:
      return state;
  }
}

export default scrollbarReducer;
