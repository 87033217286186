import AppStyle from './AppStyle';

export default {
  Containers: {
    DefaultStyle: {
      padding: '0 15px 15px 15px'
    }
  },
  NotificationItem: {
    DefaultStyle: {
      transform: 'none',
      willChange: 'none',
      padding: 18,
      marginTop: 15,
      backgroundColor: '#FFFFFF',
      color: '#FFFFFF',
      border: 'none',
      borderRadius: 0,
      boxShadow:
        '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'
    },
    success: {
      backgroundColor: AppStyle.colors.success.light
    },
    warning: {
      backgroundColor: AppStyle.colors.warning.light
    },
    error: {
      backgroundColor: AppStyle.colors.danger.light
    },
    info: {
      backgroundColor: AppStyle.colors.info.light
    }
  },
  Title: {
    DefaultStyle: {
      color: '#FFFFFF'
    }
  },
  Dismiss: {
    DefaultStyle: {
      display: 'none'
    }
  }
};
