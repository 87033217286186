import WatermarkTypes from './WatermarkTypes';

const initialState = {
  watermarkDetail: {
    typeWatermark: '',
    backgroundColor: '',
    image: '',
    link: '',
    message: '',
    textColor: '',
    isShow: true
  }
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  const newState = { ...state };
  switch (type) {
    case WatermarkTypes.GET_WATERMARK_SUCCESS:
      newState.watermarkDetail = {
        ...payload,
        backgroundColor: payload.backgroundColor ? payload.backgroundColor : '',
        textColor: payload.textColor ? payload.textColor : ''
      };
      return newState;
  }
  return newState;
}
