import TabTypes from './TabTypes';

const initialState = {
  tabSetting: {},
  buttonDetail: {}
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  const newState = { ...state };
  switch (type) {
    case TabTypes.GET_TAB_SUCCESS:
      newState.tabSetting = { ...payload };
      return newState;
    case TabTypes.GET_BUTTON_SUCCESS:
      newState.buttonDetail = { ...payload };
      return newState;
  }
  return newState;
}
