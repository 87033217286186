import LocaleTypes from './LocaleTypes';

export function setIntl (intl) {
  return {
    type: LocaleTypes.SET_INTL,
    payload: { intl }
  };
}

export function changeLocale (locale) {
  return {
    type: LocaleTypes.CHANGE_LOCALE,
    payload: { locale }
  };
}
