/**
 * Define ApiStatus mapping with server
 * @type {{status: number, message: string}}
 */

export const OK = { status: 200, message: 'Request complete' };
export const NO_RESULT = { status: 201, message: 'No more result.' };

export const ERR_BAD_REQUEST = { status: 400, message: 'Bad request' };
export const ERR_UNAUTHORIZED = { status: 401, message: 'Unauthorized' };
export const ERR_FORBIDDEN = { status: 403, message: 'Forbidden' };
export const ERR_NOT_FOUND = { status: 404, message: 'Not Found' };
export const ERR_METHOD_NOT_ALLOWED = { status: 405, message: 'Method Not Allowed' };
export const ERR_BAD_PARAMS = { status: 406, message: 'Bad parameters' };
export const ERR_ALREADY_EXISTED = { status: 407, message: 'Already existed' };
export const ERR_DATE_FORMAT = { status: 408, message: 'Wrong date format MM/dd/yyyy' };
export const ERR_DATE_RANGE = { status: 409, message: 'The from date must be before the to date' };
export const ERR_INVALID_ACCESS_KEY = { status: 410, message: 'Invalid access key' };
export const ERR_CANNOT_EXPORT_CSV_LOGS = { status: 411, message: 'Cannot export CSV logs' };
export const ERR_INVALID_FILE = { status: 412, message: 'Invalid file' };
export const ERR_EMAIL_ALREADY_EXISTED = { status: 413, message: 'Email Already existed' };
export const ERR_DOMAIN_ALREADY_EXISTED = { status: 414, message: 'Domain Already existed' };
export const ERR_USER_GROUP_MAX_SESSION = { status: 427, message: 'The user group has reached its maximum number of users' };
export const ERR_COMPANY_MAX_SESSION = { status: 428, message: 'The company has reached its maximum number of users' };
export const ERR_NOT_AUTO_LOGIN = { status: 431, message: 'Not auto login' };
export const ERR_INTERNAL_SERVER = { status: 500, message: 'Internal server error' };
export const ERR_CREATE_MODEL = { status: 501, message: 'Create model error' };
export const ERR_BOOKEND_API = { status: 502, message: 'Bookend Api Error' };
export const ERR_INVALID_TOKEN = { status: 601, message: 'Access token is invalid' };
export const ERR_TOKEN_EXPIRED = { status: 602, message: 'Access token is expired' };
export const ERR_SESSION_DATA_INVALID = { status: 603, message: 'Session data invalid' };
export const ERR_SESSION_NOT_FOUND = { status: 604, message: 'Session not found' };
export const ERR_ACCOUNT_INVALID = { status: 605, message: 'Invalid account' };
export const ERR_CREATE_USER_SESSION = { status: 606, message: 'Create User Session fail' };
export const ERR_CREATE_ADMIN_SESSION = { status: 607, message: 'Create Admin Session fail' };
export const ERR_INVALID_PASSWORD = { status: 608, message: 'Wrong password' };
export const ERR_INVALID_ROLE = { status: 609, message: 'Invalid role access. You do not have permission to do this action' };
export const ERR_REFRESH_TOKEN = { status: 610, message: 'Can not refresh access token' };
export const ERR_UPDATE_USER = { status: 611, message: 'Can update user' };
export const LOGIN_SUCCESS = { status: 200, message: 'Login success' };
export const LOGOUT_SUCCESS = { status: 200, message: 'Logout success' };
export const CREATE_USER_SUCCESS = {
  status: 200,
  message: 'Register user success'
};
export const ACTIVE_USER_SUCCESS = {
  status: 200,
  message: 'Active user success'
};
export const SEND_EMAIL_SUCCESS = {
  status: 200,
  message: 'Send email success'
};
export const RESET_USER_PASSWORD_SUCCESS = {
  status: 200,
  message: 'Reset password success'
};
export const CHANGE_USER_PASSWORD_SUCCESS = {
  status: 200,
  message: 'Change password success'
};
export const ERR_INCORRECT_MODEL_DATA = { status: 700, message: 'Incorrect model data' };
export const ERR_USER_NOT_FOUND = { status: 701, message: 'User not found.' };
export const ERR_PASSWORD_NOT_MATCH = { status: 702, message: "Password doesn't match" };
export const ERR_COMPANY_NOT_FOUND = { status: 703, message: 'Company not found' };
export const ERR_EMAIL_ALREADY_EXISTS = { status: 704, message: 'Email already exists' };
export const ERR_USER_GROUP_NOT_FOUND = { status: 713, message: 'User group not found' };
export const ERR_REACH_MAX_SESSION = { status: 750, message: 'Session reach Maximum' };
export const ERR_IP_ADDRESS_NOT_FOUND = { status: 786, message: 'IP Address not found' };
export const ERR_USER_ACCESS_EXPIRED = { status: 811, message: 'User access to the system has expired' };
export const ERR_USER_BLOCKED = { status: 830, message: 'User is blocked' };
