import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, matchPath } from 'react-router-dom';
import { routes as originRoutes } from 'configs/routes';
import { ActionContext, SessionContext, LayoutContext, RouterContext } from './MainContainer';
import { IMAGE_PATH, OPTION_LOGIN, USER_CONFIG } from 'configs/AppConfig';
import find from 'lodash/find';

import { getMenuRoutes, getRoutePath } from 'state/route/routeSelector';
import { getPageFavicon, getViewSettings } from 'state/company/companySelector';

import { connect, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/styles';
import DefaultAppBar from 'components/core/DefaultAppBar';
import { Typography } from '@material-ui/core';
import TranslateMessage from 'components/common/TranslateMessage/TranslateMessage';
import AppDrawer from 'components/core/AppDrawer';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    minHeight: '45px'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(1),
    width: '100%',
    transition: 'all 0.3s ease-out, transform 0.3s ease-out'
  },
  header: {
    marginBottom: theme.spacing(1)
  },
  footer: {
    marginTop: theme.spacing(3),
    flexShrink: 0
  }
}));

const AuthenticatedRoute = props => {
  const {
    pageFavicon,
    children,
    routePath,
    session: { isAuthenticated, user },
    actions: {
      sessionActions: { cacheRoutePath }
    },
    router: { location }
  } = props;
  const theme = useTheme();
  const width = window.innerWidth;
  const themeWidth = theme.breakpoints.values;
  const classes = useStyles();
  const [drawerValue, setDrawerValue] = useState('normal');

  const viewSettings = useSelector(getViewSettings) || {};
  const isOpenType = viewSettings.accountType === OPTION_LOGIN.OPEN_TYPE;
  const showSubscriptionStatus = viewSettings.showSubscriptionStatus;
  const urlUpload = process.env.REACT_APP_UPLOAD_PATH + '/';
  /**
   * Toggle drawer to various size
   */
  const toggleDrawer = () => {
    if (width >= themeWidth.md) {
      if (drawerValue === 'normal' || drawerValue === 'mobile') {
        setDrawerValue('mini');
      } else {
        setDrawerValue('normal');
      }
    } else {
      if (drawerValue === 'normal' || drawerValue === 'mini') {
        setDrawerValue('mobile');
      } else {
        setDrawerValue('mini');
      }
    }
  };

  /**
   * Watch layout changing
   */
  useLayoutEffect(() => {
    /**
     * Update drawer size
     * Func updateSize
     */
    function updateSize () {
      const width = window.innerWidth;
      if (width < themeWidth.md) {
        setDrawerValue('mobile');
      } else if (width < themeWidth.lg) {
        setDrawerValue('mini');
      } else {
        setDrawerValue('normal');
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    // Clear event listener on page will destroyed
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      cacheRoutePath();
    }
  }, []);

  useEffect(() => {
    // Set Favicon
    if (pageFavicon) {
      const favicon = document.getElementById('favicon');
      favicon.href = `${urlUpload}${IMAGE_PATH.FAVICON}${pageFavicon}`;
      const faviconApple = document.getElementById('appleTouchIcon');
      faviconApple.href = `${urlUpload}${IMAGE_PATH.FAVICON}${pageFavicon}`;
    }
  }, [pageFavicon]);

  if (!isAuthenticated) {
    return <Redirect to={originRoutes.unauthorizedRoutes.routes[0].path} push />;
  } else {
    const userConfig = find(USER_CONFIG, { roleLevel: user.roleLevel });

    // const customRoutes = routes.filter(el => el.layoutId.startsWith(originRoutes.authorizedRoutes.name, 0));
    const customRoutes = [...originRoutes.authorizedRoutes.routes, ...originRoutes.publicRoutes.routes].filter(item => {
      if (isOpenType && item.ignoreLoginSetting && item.ignoreLoginSetting.includes(OPTION_LOGIN.OPEN_TYPE)) {
        return false;
      }
      if (showSubscriptionStatus) {
        return true;
      } else {
        return !item.showWithSubscription;
      }
    });

    const route = find(customRoutes, route => {
      return matchPath(location.pathname, { path: route.path, exact: true }) &&
        route.accessRole && route.accessRole.indexOf(user.roleLevel) > -1 && route;
    });

    if (userConfig && route) {
      return (
        <div className={classes.root}>
          <DefaultAppBar location={location} toggleDrawer={toggleDrawer} />
          <AppDrawer location={location} drawerValue={drawerValue} routePath={routePath}/>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <div className={classes.header}>
              <Typography variant="h6"><TranslateMessage id={route.title} /></Typography>
            </div>
            <div style={{ flex: '1 0 auto' }}>
              {children}
            </div>
            <div className={classes.footer}>
              <Typography variant="body2">
                &copy;{new Date().getFullYear()} <TranslateMessage id="app.name" />
              </Typography>
            </div>
          </main>
        </div>
      );
    } else {
      return <Redirect to={userConfig.landingPage} push />;
    }
  }
};

AuthenticatedRoute.propTypes = {
  session: PropTypes.any,
  layout: PropTypes.any,
  actions: PropTypes.any,
  children: PropTypes.node,
  router: PropTypes.any,
  pageFavicon: PropTypes.any,
  routes: PropTypes.any,
  routePath: PropTypes.any
};

const mapStateToProps = (state) => {
  return {
    menuRoutes: getMenuRoutes(state),
    pageFavicon: getPageFavicon(state),
    routePath: getRoutePath(state)
  };
};

export default connect(mapStateToProps)(props => (
  <RouterContext.Consumer>
    {router => (
      <ActionContext.Consumer>
        {actions => (
          <SessionContext.Consumer>
            {session => (
              <LayoutContext.Consumer>
                {layout => (
                  <AuthenticatedRoute
                    {...props}
                    session={session}
                    router={router}
                    layout={layout}
                    actions={actions}
                  />
                )}
              </LayoutContext.Consumer>
            )}
          </SessionContext.Consumer>
        )}
      </ActionContext.Consumer>
    )}
  </RouterContext.Consumer>
));
