import React from 'react';
import PropTypes from 'prop-types';
import PublicFooter from '../core/PublicFooter';
import { connect, useSelector } from 'react-redux';
import { ActionContext, RouterContext } from './MainContainer';
import PublicAppBar from '../core/PublicAppBar';
import { makeStyles } from '@material-ui/core';

const DefaultLayout = ({ children, useAppBar, router: { location } }) => {
  const style = useSelector((state) => state.search.bodySetting) || {};
  const useStyle = makeStyles((theme) => (
    {
      body: {
        backgroundColor: `${style.backgroundColor}!important`
      }
    }
  ));

  const classes = useStyle();

  return (
    <div className={`wrapper ${classes.body}`}>
      {useAppBar && (<PublicAppBar location={location} />)}
      <main role="main" className={`flex-shrink-0 footer-offset ${classes.body}`}>
        <div className="container-fluid p-3 pt-5">
          {children}
        </div>
      </main>
      <PublicFooter />
    </div>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.any,
  useAppBar: PropTypes.bool,
  router: PropTypes.any
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(props => (
  <RouterContext.Consumer>
    {router => (
      <ActionContext.Consumer>
        {() => (
          <DefaultLayout
            {...props}
            router={router}
          />
        )}
      </ActionContext.Consumer>
    )}
  </RouterContext.Consumer>
));
