import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import {
  Navbar,
  NavbarBrand,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav
} from 'reactstrap';
import TranslateMessage from '../common/TranslateMessage/TranslateMessage';
import { AccountCircle, Person, ExitToApp, PersonAdd, VpnKey } from '@material-ui/icons';
import { getMenuRoutes, getRoutePath } from '../../state/route/routeSelector';
import { getAuthUser, isAuthenticated, isGoogleLogin } from '../../state/session/sessionSelector';
import { getPageTitle, getPageLogo, getPageFavicon } from '../../state/search/searchSelector';
import { ActionContext } from '../layout/MainContainer';
import LoginModal from 'components/modal/LoginModal';
import LoginEndUserForm from 'views/end-user/form/LoginEndUserForm';
import md5 from 'md5';
import { AUTHEN_TYPES, IMAGE_PATH, OPTION_LOGIN, USER_CONFIG } from 'configs/AppConfig';
import Util from 'utils/Util';
import { makeStyles } from '@material-ui/core';
// import notFoundImage from '../../assets/img/not-found.png';
import { getCompanyDomain, getGoogleId, getShowLinkRegisterUser, getViewSettings } from 'state/company/companySelector';
import SignupModal from 'components/modal/SignupModal';
import SignupEndUserForm from 'views/end-user/form/SignupEndUserForm';
import { useMedia } from 'react-use';

const PublicAppBar = (props) => {
  const {
    sessionActions: { logout, login, registerAccountUser },
    authUser,
    pageTitle,
    pageLogo,
    pageFavicon,
    isAuthen,
    domain,
    isShowRegisterUser
  } = props;
  const [showLoginFormModal, setShowLoginFormModal] = useState(false);
  const [showRegisterFormModal, setShowRegisterFormModal] = useState(false);

  const style = useSelector((state) => state.search.headerSetting) || {};
  const viewSettings = useSelector(getViewSettings) || {};
  const isOpenType = viewSettings.accountType === OPTION_LOGIN.OPEN_TYPE;
  const below768 = useMedia('(max-width: 768px)');

  const useStyle = makeStyles((theme) => (
    {
      header: {
        backgroundColor: `${style.backgroundColor}!important`,
        color: style.color || '#d0d0d0',
        padding: '30px 1.3rem',
        '& .nav-link': {
          color: `${style.color || '#d0d0d0'}!important`,
          whiteSpace: 'unset',
          wordBreak: 'break-word',
          wordWrap: 'break-word'
        }
      },
      headerItem: {
        display: 'flex',
        flexFlow: 'column nowrap',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        padding: '0 0.5rem',
        color: style.color || '#d0d0d0',
        cursor: 'pointer'
      },
      logo: {
        width: '250px',
        height: '100%',
        // maxWidth: '30%',
        maxHeight: '70px',
        objectFit: 'contain'
      },
      logoBox: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'wrap',
        '&::after': {
          marginLeft: '1rem',
          marginRight: '1rem',
          borderTop: '4px dotted #ebebeb',
          content: '""',
          flexGrow: 1
        }
      },
      pageTitle: {
        margin: '0 1rem!important',
        padding: 0,
        color: `${style.color || '#fff'}!important`,
        whiteSpace: 'inherit',
        wordBreak: 'break-word',
        wordWrap: 'break-word'
      }
    }
  ));

  const classes = useStyle();

  const homeUrl = window.location.origin;
  const history = useHistory();
  const urlUpload = process.env.REACT_APP_UPLOAD_PATH + '/';

  useEffect(() => {
    // Set Favicon
    if (pageFavicon) {
      const favicon = document.getElementById('favicon');
      favicon.href = `${urlUpload}${IMAGE_PATH.FAVICON}${pageFavicon}`;
      const faviconApple = document.getElementById('appleTouchIcon');
      faviconApple.href = `${urlUpload}${IMAGE_PATH.FAVICON}${pageFavicon}`;
    }
  }, [pageFavicon]);

  const handleLoginFormSubmit = async (data, authenType) => {
    setShowLoginFormModal(false);
    let request;
    if (authenType === AUTHEN_TYPES.EMAIL) {
      request = {
        password: md5(data.password),
        rememberMe: data.rememberMe,
        email: data.email,
        loginType: AUTHEN_TYPES.EMAIL,
        loginRole: USER_CONFIG.USER.roleLevel
      };
    } else if (authenType === AUTHEN_TYPES.GOOGLE_LOGIN) {
      request = data;
    } else {
      const userTrace = await Util.getUserTrace();
      const ip = userTrace.ip;
      request = {
        ip: ip,
        loginType: AUTHEN_TYPES.IP
      };
    }
    login(request);
  };

  const handleRegisterFormSubmit = data => {
    setShowRegisterFormModal(false);
    const request = {
      passwordHash: md5(data.password),
      domain: domain,
      fullName: data.name
    };
    request.email = data.email;
    registerAccountUser(request);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <header>
      <Navbar dark expand="md" className={classes.header}>
        <div className="container-fluid p-0" style={{ justifyContent: 'flex-start' }}>
          <span className={classes.logoBox}>
            <Link to={'/'}>
              {pageLogo && pageLogo !== '' && pageLogo !== 'null'
                ? (
                  <img className={`pr-1 ${classes.logo}`} width="40px" src={`${urlUpload}${IMAGE_PATH.LOGO}${pageLogo}`} />
                )
                : (
                  // <img className={`pr-1 ${classes.logo}`} width="40px" src={notFoundImage} />
                  <></>
                )
              }
            </Link>
            {
              pageTitle && <NavbarBrand href={homeUrl} className={classes.pageTitle}>
                <span>{pageTitle}</span>
              </NavbarBrand>
            }
          </span>
          {
            isOpenType
              ? null
              : authUser && isAuthen
                ? (
                  <Collapse isOpen={true} navbar style={{ flexGrow: 'unset' }}>
                    <Nav className="ml-auto" navbar>
                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret className="cs-dropdown-toggle text-right">
                          <span>{authUser.name ? `${authUser.name}` : `${authUser.fullName}`}</span>
                          <AccountCircle className="ml-2" />
                        </DropdownToggle>
                        <DropdownMenu right>
                          {
                            authUser.roleLevel !== 4 ? (
                              <DropdownItem className="cs-dropdown-item" onClick={() => {
                                history.push(`/${authUser.roleLevel === 1 ? 'admin' : 'user'}/profile`);
                              }}>
                                <Person className="mr-2" />
                                <TranslateMessage id="label.profile" />
                              </DropdownItem>
                            ) : null
                          }
                          <DropdownItem className="cs-dropdown-item" onClick={() => { handleLogout() }}>
                            <ExitToApp className="mr-2" />
                            <TranslateMessage id="label.logout" />
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Nav>
                  </Collapse>
                )
                : <>
                  {isShowRegisterUser && <div
                    className={classes.headerItem}
                    onClick={() => history.push('/register')}
                  >
                    <PersonAdd className="mr-2" />
                    {!below768 && <TranslateMessage id="menu.registerUser" />}
                  </div>}
                  <div
                    className={classes.headerItem}
                    onClick={() => setShowLoginFormModal(true)}
                  >
                    <VpnKey />
                    {!below768 && <TranslateMessage id="menu.login" />}
                  </div>
                </>
          }
        </div>
      </Navbar>
      <LoginModal
        isOpen={showLoginFormModal}
        toggle={() => setShowLoginFormModal(false)}
        size="md"
        backdrop={'static'}
      >
        <LoginEndUserForm
          onCancel={() => setShowLoginFormModal(false)}
          onSubmit={handleLoginFormSubmit}
        />
      </LoginModal>
      <SignupModal
        isOpen={showRegisterFormModal}
        toggle={() => setShowRegisterFormModal(false)}
        size="md"
        backdrop={'static'}
      >
        <SignupEndUserForm
          onCancel={() => setShowLoginFormModal(false)}
          onSubmit={handleRegisterFormSubmit}
        />
      </SignupModal>
    </header>
  );
};

PublicAppBar.defaultProps = {};

PublicAppBar.propTypes = {
  location: PropTypes.object,
  sessionActions: PropTypes.object.isRequired,
  menuRoutes: PropTypes.array.isRequired,
  routePath: PropTypes.string.isRequired,
  authUser: PropTypes.object,
  isAuthen: PropTypes.bool,
  pageTitle: PropTypes.any,
  pageLogo: PropTypes.any,
  pageFavicon: PropTypes.any,
  isGoogleLogin: PropTypes.bool,
  googleId: PropTypes.any,
  domain: PropTypes.any,
  isShowRegisterUser: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    menuRoutes: getMenuRoutes(state),
    routePath: getRoutePath(state),
    authUser: getAuthUser(state),
    pageTitle: getPageTitle(state),
    pageLogo: getPageLogo(state),
    pageFavicon: getPageFavicon(state),
    isAuthen: isAuthenticated(state),
    isGoogleLogin: isGoogleLogin(state),
    googleId: getGoogleId(state),
    domain: getCompanyDomain(state),
    isShowRegisterUser: getShowLinkRegisterUser(state)
  };
};

export default connect(mapStateToProps)(
  props => (
    <ActionContext.Consumer>
      {({ sessionActions }) => (
        <PublicAppBar
          {...props}
          sessionActions={sessionActions}
        />
      )}
    </ActionContext.Consumer>
  )
);
