import { all, put, takeEvery } from 'redux-saga/effects';
import ExceedSessionTypes from './ExceedSessionTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/exceed-session';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';
import { PAGINATION } from 'configs/AppConfig';

function * loadExceedSessionPage () {
  yield takeEvery(ExceedSessionTypes.GET_PAGE_EXCEED_SESSION_REQUEST, _loadExceedSessionPage);
}

function * _loadExceedSessionPage ({ payload }) {
  const request = snakecaseKeys({ ...PAGINATION, ...payload });
  yield createRequest({
    api: {
      ...API.GET_EXCEED_SESSION_PAGE,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: ExceedSessionTypes.GET_PAGE_EXCEED_SESSION_SUCCESS,
        payload: Util.toCamelCaseKey({ ...payload, ...response.data })
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: ExceedSessionTypes.GET_PAGE_EXCEED_SESSION_ERROR,
        payload: status
      });
    }
  });
}

export default function * couponSaga () {
  yield all([
    loadExceedSessionPage()
  ]);
}
