import React from 'react';
import PropTypes from 'prop-types';

const FormTitle = ({ children }) => {
  return (
    <h5 className="d-flex justify-content-between text-nowrap text-info">
      <span>{children}</span>
      <span className="border-info border-bottom w-100 ml-3 mb-2"/>
    </h5>
  );
};

FormTitle.propTypes = {
  children: PropTypes.any
};

export default FormTitle;
