import LoginTypes from './LoginTypes';

const initialState = {
  loginSetting: {}
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  const newState = { ...state };
  switch (type) {
    case LoginTypes.GET_CUSTOM_LOGIN_SUCCESS:
      newState.loginSetting = { ...payload };
      return newState;
  }
  return newState;
}
