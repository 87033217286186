import UserTypes from './UserTypes';

export function loadUserList (data) {
  return {
    type: UserTypes.GET_USERS_REQUEST,
    payload: data
  };
}

export function loadUserDetail (data) {
  return {
    type: UserTypes.GET_USER_DETAIL_REQUEST,
    payload: data
  };
}

export function addUser (data) {
  return {
    type: UserTypes.ADD_USER_REQUEST,
    payload: data
  };
}

export function updateUser (data) {
  return {
    type: UserTypes.UPDATE_USER_REQUEST,
    payload: data
  };
}

export function deleteUser (data) {
  return {
    type: UserTypes.DELETE_USER_REQUEST,
    payload: data
  };
}

export function exportUsers (data) {
  return {
    type: UserTypes.EXPORT_USERS_REQUEST,
    payload: data
  };
}

export function loadUserSubscriptionPlanPaging (data) {
  return {
    type: UserTypes.GET_USER_SUBCRIPTION_PLAN_PAGE_REQUEST,
    payload: data
  };
}
