import StorageHelper from '../../utils/StorageHelper';
import { STORAGE_KEYS } from '../../configs/AppConfig';

export const getCompanyList = state => state.company.companies;
export const getQueriesPaging = state => state.company.queries;
export const getTotal = state => state.company.total;
export const getTotalPages = state => state.company.totalPages;

export const getSelected = state => state.company.selected;

export const getCompanyDetail = state => state.company.companyDetail;
export const getCompanyUserDetail = state => state.company.companyUserDetail;
export const getCompanyDomain = state => state.company.companyDomain || StorageHelper.getSessionItem(STORAGE_KEYS.domain);
export const getEndUserCompanyDetail = state => state.company.endUserCompanyDetail;
export const getViewSettings = state => state.company.viewSettings;
export const getIsLoadSetting = state => state.company.isLoadSetting;

export const getAccountType = state => state.company.accountType;
export const getPasswordPolicy = state => state.company.strictPassword;
export const getOptionImportUser = state => state.company.optionImportUser;
export const getAllowLoginByIpAddress = state => state.company.allowLoginByIpAddress;

export const getTitleOfEmailField = state => state.company.titleOfEmailField;
export const getTitleOfPasswordField = state => state.company.titleOfPasswordField;

export const getPageLogo = state => state.company.pageLogo;
export const getPageFavicon = state => state.company.pageFavicon;
export const getCompanyName = state => state.company.companyName;

export const getGoogleOption = state => state.company.googleOption;
export const getGoogleId = state => state.company.googleId;
export const getGoogleAutoLoad = state => state.company.googleAutoLoad;
export const getAllowForgotPassword = state => state.company.allowForgotPassword;

export const getIsSaving = state => state.company.isSaving;
export const getIsChecking = state => state.company.isChecking;
export const getPaymentSetting = state => state.company.paymentSetting;
export const getShowSubscriptionStatus = state => state.company.showSubscriptionStatus;
export const getShowLinkRegisterUser = state => state.company.showLinkRegisterUser;
