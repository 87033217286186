import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'react-jss';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { CookiesProvider } from 'react-cookie';

import ConnectedIntlProvider from './ConnectedIntlProvider';
import store from '../state/store';
import AppStyle from '../assets/styles/AppStyle';
import { registerLocale } from 'react-datepicker';
import { enUS, ja } from 'date-fns/locale';

import MainContainer from '../components/layout/MainContainer';

registerLocale('en', enUS);
registerLocale('ja', ja);

const theme = createTheme({
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"'
    ].join(',')
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        width: '100%',
        marginTop: '0.25rem',
        fontSize: '80%',
        fontFamily: 'inherit',
        lineHeight: 'inherit',
        color: '#6c757d',
        error: {
          color: `${AppStyle.colors.danger.light} !important`
        }
      }
    }
  }
});

const App = () => (
  <CookiesProvider>
    <Provider store={store}>
      <ConnectedIntlProvider onError={error => error}>
        <Router>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={AppStyle}>
              <MainContainer />
            </ThemeProvider>
          </MuiThemeProvider>
        </Router>
      </ConnectedIntlProvider>
    </Provider>
  </CookiesProvider>
);

export default App;
