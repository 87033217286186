import { combineReducers } from 'redux';
import { filterActions } from 'redux-ignore';

import errorReducer from './handler/errorReducer';
import ErrorTypes from './handler/ErrorTypes';

import layoutReducer from './layout/layoutReducer';
import LayoutTypes from './layout/LayoutTypes';

import sessionReducer from './session/sessionReducer';
import SessionTypes from './session/SessionTypes';

import localeReducer from './locale/localeReducer';
import LocaleTypes from './locale/LocaleTypes';

import routeReducer from './route/routeReducer';
import RouteTypes from './route/RouteTypes';

import notificationReducer from './notification/notificationReducer';
import NotificationTypes from './notification/NotificationTypes';

import scrollbarReducer from './scrollbar/scrollbarReducer';
import * as ScrollbarActionTypes from './scrollbar/scrollbarActionTypes';

import accountReducer from './account/accountReducer';
import AccountTypes from './account/AccountTypes';

import contentReducer from './content/contentReducer';
import ContentTypes from './content/ContentTypes';

import importReducer from './import/importReducer';
import ImportTypes from './import/ImportTypes';

import importUserReducer from './import-user/importUserReducer';
import ImportUserTypes from './import-user/ImportUserTypes';

import companyReducer from './company/companyReducer';
import CompanyTypes from './company/CompanyTypes';

import uploadReducer from './upload/uploadReducer';
import UploadTypes from './upload/UploadTypes';

import searchReducer from './search/searchReducer';
import SearchTypes from './search/SearchTypes';

import messageReducer from './message/messageReducer';
import MessageTypes from './message/MessageTypes';

import userGroupReducer from './user-group/userGroupReducer';
import UserGroupTypes from './user-group/UserGroupTypes';

import { reducer as formReducer, actionTypes as FormTypes } from 'redux-form';
import userReducer from './user/userReducer';
import UserTypes from './user/UserTypes';

import contentGroupReducer from './content-group/contentGroupReducer';
import ContentGroupTypes from './content-group/ContentGroupTypes';

import buttonReducer from './button/buttonReducer';
import ButtonTypes from './button/ButtonTypes';

import bannerReducer from './banner/bannerReducer';
import BannerTypes from './banner/BannerTypes';

import sortReducer from './sort/sortReducer';
import SortTypes from './sort/SortTypes';

import templateReducer from './template/templateReducer';
import TemplateTypes from './template/TemplateTypes';

import inputReducer from './input/inputReducer';
import InputTypes from './input/InputTypes';

import tabReducer from './tab/tabReducer';
import TabTypes from './tab/TabTypes';

import searchResultReducer from './search-result/searchResultReducer';
import SearchResultTypes from './search-result/SearchResultTypes';

import contentDetailReducer from './content-detail/contentDetailReducer';
import ContentDetailTypes from './content-detail/ContentDetailTypes';

import homeReducer from './home/homeReducer';
import HomeTypes from './home/HomeTypes';

import customLoginReducer from './login/loginReducer';
import CustomLoginTypes from './login/LoginTypes';

import emailTemplateReducer from './email-template/emailTemplateReducer';
import EmailTemplateTypes from './email-template/EmailTemplateTypes';

import watermarkReducer from './watermark/watermarkReducer';
import WatermarkTypes from './watermark/WatermarkTypes';

import subscriptionReducer from './subscriptions/SubscriptionReducer';
import SubscriptionTypes from './subscriptions/SubscriptionTypes';

import couponReducer from './coupon/couponReducer';
import CouponTypes from './coupon/CouponTypes';

import paymentReducer from './payment/paymentReducer';
import PaymentTypes from './payment/PaymentTypes';

import hashtagReducer from './hashtag/hashtagReducer';
import HashtagTypes from './hashtag/HashtagTypes';

import exceedSessionReducer from './exceed-session/exceedSessionReducer';
import ExceedSessionTypes from './exceed-session/ExceedSessionTypes';

const rootReducer = combineReducers({
  error: filterActions(errorReducer, Object.values(ErrorTypes)),
  layout: filterActions(layoutReducer, Object.values(LayoutTypes)),
  session: filterActions(sessionReducer, Object.values(SessionTypes)),
  locale: filterActions(localeReducer, Object.values(LocaleTypes)),
  route: filterActions(routeReducer, Object.values(RouteTypes)),
  notification: filterActions(notificationReducer, Object.values(NotificationTypes)),
  scrollbar: filterActions(scrollbarReducer, Object.values(ScrollbarActionTypes)),
  form: filterActions(formReducer, Object.values(FormTypes)),
  account: filterActions(accountReducer, Object.values(AccountTypes)),
  content: filterActions(contentReducer, Object.values(ContentTypes)),
  import: filterActions(importReducer, Object.values(ImportTypes)),
  importUser: filterActions(importUserReducer, Object.values(ImportUserTypes)),
  company: filterActions(companyReducer, Object.values(CompanyTypes)),
  upload: filterActions(uploadReducer, Object.values(UploadTypes)),
  search: filterActions(searchReducer, Object.values(SearchTypes)),
  message: filterActions(messageReducer, Object.values(MessageTypes)),
  userGroup: filterActions(userGroupReducer, Object.values(UserGroupTypes)),
  contentGroup: filterActions(contentGroupReducer, Object.values(ContentGroupTypes)),
  user: filterActions(userReducer, Object.values(UserTypes)),
  button: filterActions(buttonReducer, Object.values(ButtonTypes)),
  banner: filterActions(bannerReducer, Object.values(BannerTypes)),
  watermark: filterActions(watermarkReducer, Object.values(WatermarkTypes)),
  sort: filterActions(sortReducer, Object.values(SortTypes)),
  template: filterActions(templateReducer, Object.values(TemplateTypes)),
  input: filterActions(inputReducer, Object.values(InputTypes)),
  tab: filterActions(tabReducer, Object.values(TabTypes)),
  home: filterActions(homeReducer, Object.values(HomeTypes)),
  customLogin: filterActions(customLoginReducer, Object.values(CustomLoginTypes)),
  searchResult: filterActions(searchResultReducer, Object.values(SearchResultTypes)),
  emailTemplate: filterActions(emailTemplateReducer, Object.values(EmailTemplateTypes)),
  contentDetail: filterActions(contentDetailReducer, Object.values(ContentDetailTypes)),
  subscription: filterActions(subscriptionReducer, Object.values(SubscriptionTypes)),
  coupon: filterActions(couponReducer, Object.values(CouponTypes)),
  payment: filterActions(paymentReducer, Object.values(PaymentTypes)),
  hashtag: filterActions(hashtagReducer, Object.values(HashtagTypes)),
  exceedSession: filterActions(exceedSessionReducer, Object.values(ExceedSessionTypes))

});

export default rootReducer;
