import ImportUserTypes from './ImportUserTypes';
import { PAGINATION } from '../../configs/AppConfig';

const initialState = {
  importData: [],
  selected: [],
  pagination: {
    ...PAGINATION,
    totalPages: 0,
    totalRecords: 0
  },
  progressingFiles: []
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  switch (type) {
    case ImportUserTypes.GET_IMPORT_DATA_SUCCESS:
      newState.importData = [...payload.content];
      newState.pagination.totalRecords = payload.totalElements;
      newState.pagination.totalPages = payload.totalPages;
      newState.pagination.size = payload.size;
      newState.pagination.page = payload.number + 1;
      newState.pagination = { ...newState.pagination };
      return newState;
    case ImportUserTypes.CHECK_IMPORT_PROGRESS_SUCCESS:
      newState.progressingFiles = [...payload];
      return newState;
    default:
      newState = state;
      break;
  }
  return newState;
}
