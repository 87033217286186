export default {
  LOG_IN: {
    url: 'auth/login',
    method: 'POST'
  },
  LOG_IN_BY_IP_ADDRESS: {
    url: 'auth/ip-address-login',
    method: 'POST'
  },
  AUTO_LOG_IN_BY_IP_ADDRESS: {
    url: 'auth/ip-address-auto-login',
    method: 'POST'
  },
  LOGIN_WITH_GOOGLE: {
    url: '/auth/social-login',
    method: 'POST'
  },
  LOG_OUT: {
    url: 'auth/logout',
    method: 'GET'
  },
  REGISTER: {
    url: '/auth/register',
    method: 'POST'
  },
  GET_AUTH_INFO: {
    url: '/auth/info',
    method: 'GET'
  },
  SIGN_UP: {
    url: '/auth/sign-up',
    method: 'POST'
  }
};
