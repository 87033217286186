export default {
  GET_WATERMARK_DETAIL: {
    url: '/watermark',
    method: 'GET'
  },
  UPDATE_WATERMARK: {
    url: '/watermark',
    method: 'PUT'
  },
  RESET_WATERMARK: {
    url: '/watermark/reset',
    method: 'PUT'
  }
};
