import { all, put, takeEvery } from 'redux-saga/effects';
import HashtagTypes from './HashtagTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/hashtag';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';
import NotificationTypes from 'state/notification/NotificationTypes';

function * loadHashtagList () {
  yield takeEvery(HashtagTypes.GET_LIST_HASHTAG_REQUEST, _loadHashtagList);
}

function * _loadHashtagList ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_HASHTAG_LIST
    },
    onSuccess: function * (response) {
      yield put({
        type: HashtagTypes.GET_LIST_HASHTAG_SUCCESS,
        payload: Util.toCamelCaseKey([...response.data])
      });
    }
  });
}

//  Load Search Box
function * loadSearchBoxHashtag () {
  yield takeEvery(HashtagTypes.GET_SEARCH_BOX_HASHTAG_REQUEST, _loadSearchBoxHashtag);
}

function * _loadSearchBoxHashtag ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_SEARCH_BOX
    },
    onSuccess: function * (response) {
      yield put({
        type: HashtagTypes.GET_SEARCH_BOX_HASHTAG_SUCCESS,
        payload: Util.toCamelCaseKey({ ...response.data })
      });
    }
  });
}

function * updateHashtagDetail () {
  yield takeEvery(HashtagTypes.UPDATE_HASHTAG_REQUEST, _updateHashtagDetail);
}
function * _updateHashtagDetail ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.UPDATE_HASHTAG_DETAIL,
      url: API.UPDATE_HASHTAG_DETAIL.url + payload.id,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: HashtagTypes.GET_LIST_HASHTAG_REQUEST
      });
    }
  });
}

//  update Search Box
function * updateSearchBoxHashtag () {
  yield takeEvery(HashtagTypes.UPDATE_SEARCH_BOX_HASHTAG_REQUEST, _updateSearchBoxHashtag);
}
function * _updateSearchBoxHashtag ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.UPDATE_SEARCH_BOX,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    }
  });
}

//  update Multiple Select Hashtag
function * updateMultipleSelectHashtag () {
  yield takeEvery(HashtagTypes.UPDATE_MULTIPLE_SELECT_HASH_TAG_REQUEST, _updateMultipleSelectHashtag);
}
function * _updateMultipleSelectHashtag ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.UPDATE_MULTIPLE_SELECT_HASH_TAG,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    }
  });
}

function * addHashtag () {
  yield takeEvery(HashtagTypes.ADD_HASHTAG_REQUEST, _addHashtag);
}
function * _addHashtag ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.ADD_HASHTAG,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: HashtagTypes.GET_LIST_HASHTAG_REQUEST
      });
    }
  });
}

function * deleteHashtag () {
  yield takeEvery(HashtagTypes.DELETE_HASHTAG_REQUEST, _deleteHashtag);
}
function * _deleteHashtag ({ payload }) {
  yield createRequest({
    api: {
      ...API.DELETE_HASHTAG,
      url: API.DELETE_HASHTAG.url + payload.id
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.delete.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: HashtagTypes.GET_LIST_HASHTAG_REQUEST
      });
    }
  });
}

function * getPublicHashtag () {
  yield takeEvery(HashtagTypes.GET_LIST_HASHTAG_USER_REQUEST, _getPublicHashtag);
}

function * _getPublicHashtag ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_PUBLIC_HASH_TAG,
      // url: API.GET_PUBLIC_HASH_TAG.url + payload
      params: {
        companyId: payload
      }
    },
    onSuccess: function * (response) {
      yield put({
        type: HashtagTypes.GET_LIST_HASHTAG_USER_SUCCESS,
        payload: Util.toCamelCaseKey([...response.data])
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: HashtagTypes.GET_LIST_HASHTAG_USER_ERROR,
        payload: status
      });
    }
  });
}

function * getHashtagSetting () {
  yield takeEvery(HashtagTypes.GET_HASHTAG_SETTING_REQUEST, _getHashtagSetting);
}

function * _getHashtagSetting ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_HASHTAG_SETTING
    },
    onSuccess: function * (response) {
      yield put({
        type: HashtagTypes.GET_HASHTAG_SETTING_SUCCESS,
        payload: Util.toCamelCaseKey({ ...response.data })
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: HashtagTypes.GET_HASHTAG_SETTING_ERROR,
        payload: status
      });
    }
  });
}
function * updateHashtagSetting () {
  yield takeEvery(HashtagTypes.UPDATE_HASHTAG_SETTING_REQUEST, _updateHashtagSetting);
}

function * _updateHashtagSetting ({ payload }) {
  yield createRequest({
    api: {
      ...API.UPDATE_HASHTAG_SETTING,
      data: snakecaseKeys({ ...payload })
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: HashtagTypes.GET_HASHTAG_SETTING_REQUEST
      });
      yield put({
        type: HashtagTypes.UPDATE_HASHTAG_SETTING_SUCCESS
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: HashtagTypes.UPDATE_HASHTAG_SETTING_ERROR,
        payload: status
      });
      yield put({
        type: HashtagTypes.GET_HASHTAG_SETTING_REQUEST
      });
    }
  });
}

export default function * hashtagSaga () {
  yield all([
    loadHashtagList(),
    loadSearchBoxHashtag(),
    updateHashtagDetail(),
    updateSearchBoxHashtag(),
    addHashtag(),
    updateMultipleSelectHashtag(),
    deleteHashtag(),
    getPublicHashtag(),
    getHashtagSetting(),
    updateHashtagSetting()
  ]);
}
