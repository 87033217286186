import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup } from 'reactstrap';
import TranslateMessage from '../common/TranslateMessage/TranslateMessage';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import InputFieldButtonRight from 'components/common/Input/InputFieldButtonRight';
import { YupValidations } from 'utils/validate';
import Util from 'utils/Util';

const ChangePasswordForm = props => {
  const {
    onSubmit,
    strictPassword
  } = props;

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().trim().required(Util.translate('validate.required')),
    newPassword: strictPassword ? YupValidations.password : YupValidations.weakPassword,
    confirmPassword: YupValidations.confirmPassword
  });

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {() => {
        return (
          <Form autoComplete="off" aria-autocomplete="none">
            <Field
              name="currentPassword"
              label="label.currentPassword"
              type="password"
              component={InputFieldButtonRight}
            />
            <Field
              name="newPassword"
              label="label.newPassword"
              type="password"
              component={InputFieldButtonRight}
            />
            <Field
              name="confirmPassword"
              label="label.confirmPassword"
              type="password"
              component={InputFieldButtonRight}
            />
            <FormGroup className="text-right mt-5">
              <Button
                className="btn btn-info"
                variant="contained"
                color="primary"
                type="submit"
              >
                <TranslateMessage id="label.save" />
              </Button>
            </FormGroup>
          </Form>
        );
      }}
    </Formik>
  );
};

ChangePasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  strictPassword: PropTypes.bool
};

export default ChangePasswordForm;
