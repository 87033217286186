const _prefix = '@button/';

export default {
  ADD_BUTTON_REQUEST: _prefix + 'ADD_BUTTON_REQUEST',
  UPDATE_BUTTON_REQUEST: _prefix + 'UPDATE_BUTTON_REQUEST',
  DELETE_BUTTON_REQUEST: _prefix + 'DELETE_BUTTON_REQUEST',
  GET_BUTTON_REQUEST: _prefix + 'GET_BUTTON_REQUEST',
  GET_BUTTON_SUCCESS: _prefix + 'GET_BUTTON_SUCCESS',

  GET_LIST_BUTTON_REQUEST: _prefix + 'GET_LIST_BUTTON_REQUEST',
  GET_LIST_BUTTON_SUCCESS: _prefix + 'GET_LIST_BUTTON_SUCCESS',

  GET_ALL_BUTTON_REQUEST: _prefix + 'GET_ALL_BUTTON_REQUEST',
  GET_ALL_BUTTON_SUCCESS: _prefix + 'GET_ALL_BUTTON_SUCCESS'
};
