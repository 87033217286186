import BannerTypes from './BannerTypes';

export function getBannerDetail (data) {
  return {
    type: BannerTypes.GET_BANNER_REQUEST,
    payload: data
  };
}
export function updateBanner (data) {
  return {
    type: BannerTypes.UPDATE_BANNER_REQUEST,
    payload: data
  };
}
export function resetBanner (data) {
  return {
    type: BannerTypes.RESET_BANNER_REQUEST,
    payload: data
  };
}
