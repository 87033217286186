import NotificationTypes from './NotificationTypes';

const initialState = null;

export default function notificationReducer (state = initialState, action) {
  const { type, payload: { provider } = {} } = action;
  switch (type) {
    case NotificationTypes.SET_UP_NOTIFICATION:
      return provider;

    default:
      return state;
  }
}
