import WatermarkTypes from './WatermarkTypes';

export function getWatermarkDetail (data) {
  return {
    type: WatermarkTypes.GET_WATERMARK_REQUEST,
    payload: data
  };
}
export function updateWatermark (data) {
  return {
    type: WatermarkTypes.UPDATE_WATERMARK_REQUEST,
    payload: data
  };
}
export function resetWatermark (data) {
  return {
    type: WatermarkTypes.RESET_WATERMARK_REQUEST,
    payload: data
  };
}
