const _prefix = '@upload/';

export default {
  GET_UPLOADED_FILES_REQUEST: _prefix + 'GET_UPLOADED_FILES_REQUEST',
  GET_UPLOADED_FILES_ERROR: _prefix + 'GET_UPLOADED_FILES_ERROR',

  GET_DISCLOSURE_GROUP_UPLOADED_FILES_SUCCESS: _prefix + 'GET_DISCLOSURE_GROUP_UPLOADED_FILES_SUCCESS',

  GET_PATIENT_GROUP_UPLOADED_FILES_SUCCESS: _prefix + 'GET_PATIENT_GROUP_UPLOADED_FILES_SUCCESS',

  UPDATE_DISCLOSURE_FILES_QUERIES: _prefix + 'UPDATE_DISCLOSURE_FILES_QUERIES',
  UPDATE_PATIENT_FILES_QUERIES: _prefix + 'UPDATE_PATIENT_FILES_QUERIES',

  UPLOAD_FILE_REQUEST: _prefix + 'UPLOAD_FILE_REQUEST',
  UPLOAD_FILE_SUCCESS: _prefix + 'UPLOAD_FILE_SUCCESS',

  IMPORT_CSV_REQUEST: _prefix + 'IMPORT_CSV_REQUEST',
  IMPORT_CSV_SUCCESS: _prefix + 'IMPORT_CSV_SUCCESS',

  CHECK_IMPORT_PROGRESS_REQUEST: _prefix + 'CHECK_IMPORT_PROGRESS_REQUEST',
  CHECK_IMPORT_PROGRESS_SUCCESS: _prefix + 'CHECK_IMPORT_PROGRESS_SUCCESS',

  DOWNLOAD_CSV_TEMPLATE: _prefix + 'DOWNLOAD_CSV_TEMPLATE'
};
