import MessageTypes from './MessageTypes';
import { PAGINATION, SEARCHBOX_TYPE } from '../../configs/AppConfig';

const initialState = {
  pageTitle: {
    titlePage: ''
  },

  searchBoxs: [],
  allSearchBoxs: [],

  ListContentDetailParam: [],

  isSearchBoxDetailLoad: true,
  SearchBoxDetail: {
    searchFields: '',
    label: '',
    type: SEARCHBOX_TYPE.COMMON_SEARCH.id,
    operation: 1,
    matchValue: ['']
  },

  ContentDetailParamDetail: {
    title: '',
    field: '',
    size: 1
  },
  IsContentDetailParamDetailLoad: true,

  sbPagination: {
    ...PAGINATION,
    totalPages: 0,
    totalRecords: 0
  },

  tabs: [],
  tabInfo: {
    title: '',
    searchBoxTabList: []
  },
  IsTabInfoLoad: true,
  searchResultContents: [],
  tabPagination: {
    ...PAGINATION,
    totalPages: 0,
    totalRecords: 0

  },
  queries: {
    page: 1,
    size: 10,
    sort: false
  },
  contentInfo: {
    title: '',
    field: 0,
    size: 1,
    ordinalNumber: 0
  },
  IsContentInfoLoad: true,
  searchResultSettingInfo: {
  }
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  switch (type) {
    case MessageTypes.GET_PAGE_TITLE_SUCCESS:
      newState.pageTitle = { ...payload };
      return newState;
    case MessageTypes.UPDATE_PAGE_TITLE_SUCCESS:
      newState.pageTitle = { ...payload };
      return newState;
    case MessageTypes.GET_SEARCH_BOX_SUCCESS:
      newState.searchBoxs = [...payload.content];
      newState.sbPagination.totalRecords = payload.totalElements;
      newState.sbPagination.totalPages = payload.totalPages;
      newState.sbPagination.size = payload.size;
      newState.sbPagination.page = payload.number + 1;
      newState.sbPagination = { ...newState.sbPagination };
      return newState;
    case MessageTypes.GET_SEARCH_BOX_ALL_SUCCESS:
      newState.allSearchBoxs = [...payload];
      return newState;
    case MessageTypes.GET_SEARCH_BOX_DETAIL_SUCCESS:
      if (payload.matchValue === null) {
        payload.matchValue = '';
      }
      newState.SearchBoxDetail = { ...payload };
      newState.isSearchBoxDetailLoad = false;
      return newState;
    case MessageTypes.GET_SEARCH_BOX_DETAIL_REQUEST:
      newState.isSearchBoxDetailLoad = true;
      return newState;
    case MessageTypes.GET_CONTENT_DETAIL_PARAM_SUCCESS:
      newState.ContentDetailParamDetail = { ...payload };
      newState.IsContentDetailParamDetailLoad = false;
      return newState;
    case MessageTypes.GET_CONTENT_DETAIL_PARAM_REQUEST:
      newState.IsContentDetailParamDetailLoad = true;
      return newState;
    case MessageTypes.GET_LIST_CONTENT_DETAIL_PARAM_SUCCESS:
      newState.ListContentDetailParam = [...payload];
      return newState;
    case MessageTypes.GET_TAB_SUCCESS:
      newState.tabs = [...payload];
      return newState;
    case MessageTypes.GET_TAB_DETAIL_SUCCESS:
      newState.tabInfo = { ...payload };
      newState.IsTabInfoLoad = false;
      return newState;
    case MessageTypes.GET_TAB_DETAIL_REQUEST:
      newState.IsTabInfoLoad = true;
      return newState;
    case MessageTypes.GET_SEARCH_RESULT_SUCCESS:
      newState.searchResultContents = [...payload];
      return newState;
    case MessageTypes.GET_CONTENT_PARAM_DETAIL_SUCCESS:
      newState.contentInfo = { ...payload };
      newState.IsContentInfoLoad = false;
      return newState;
    case MessageTypes.GET_CONTENT_PARAM_DETAIL_REQUEST:
      newState.IsContentInfoLoad = true;
      return newState;
    case MessageTypes.GET_SEARCH_RESULT_DETAIL_SUCCESS:
      newState.searchResultSettingInfo = { ...payload };
      return newState;
    default:
      newState = state;
      break;
  }
  return newState;
}
