import React from 'react';
import PropTypes from 'prop-types';
import { getMenuRoutes, getRoutePath } from '../../state/route/routeSelector';
import { getAuthUser, isGoogleLogin } from '../../state/session/sessionSelector';
import { getCompanyName, getGoogleId } from 'state/company/companySelector';
import { connect } from 'react-redux';
import { ActionContext } from '../layout/MainContainer';
import TranslateMessage from '../common/TranslateMessage/TranslateMessage';
import { Link, useHistory } from 'react-router-dom';
import { AccountCircle, ExitToApp, Person, Menu } from '@material-ui/icons';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import './styles/AppBar.css';
import { USER_CONFIG } from 'configs/AppConfig';

const DefaultAppBar = (props) => {
  const {
    sessionActions: { logout },
    authUser,
    toggleDrawer,
    companyName
  } = props;

  const history = useHistory();

  const handleLogout = () => {
    logout();
  };

  return (
    <header className="toolbar bg-info">
      <nav className="toolbar__navigation">
        <div>
          <button className="btn btn-link" onClick={toggleDrawer}>
            <Menu className="text-white" />
          </button>
        </div>
        <div className="toolbar__logo ml-2">
          <Link to={authUser.roleLevel === USER_CONFIG.SYSTEM_ADMIN.roleLevel ? '/admin/companies' : '/company/dashboard'}>
            {
              companyName
                ? <span>{companyName}</span>
                : <TranslateMessage id="app.logo" />
            }
          </Link>
        </div>
        <div className="spacer" />
        <div className="toolbar__navigation-item">
          <ul>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret className="cs-dropdown-toggle">
                <TranslateMessage id="label.account" />
                <AccountCircle className="ml-2" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem className="cs-dropdown-item" onClick={() => {
                  history.push(`/${authUser.roleLevel === USER_CONFIG.SYSTEM_ADMIN.roleLevel ? 'admin' : 'company'}/profile`);
                }}>
                  <Person className="mr-2" />
                  <TranslateMessage id="label.profile" />
                </DropdownItem>
                <DropdownItem className="cs-dropdown-item" onClick={() => { handleLogout() }}>
                  <ExitToApp className="mr-2" />
                  <TranslateMessage id="label.logout" />
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </ul>
        </div>
      </nav>
    </header>
  );
};

DefaultAppBar.defaultProps = {};

DefaultAppBar.propTypes = {
  location: PropTypes.object.isRequired,
  sessionActions: PropTypes.object.isRequired,
  menuRoutes: PropTypes.array.isRequired,
  routePath: PropTypes.string,
  authUser: PropTypes.object.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  companyName: PropTypes.string,
  isGoogleLogin: PropTypes.bool,
  googleId: PropTypes.any

};

const mapStateToProps = (state) => {
  return {
    menuRoutes: getMenuRoutes(state),
    routePath: getRoutePath(state),
    authUser: getAuthUser(state),
    companyName: getCompanyName(state),
    isGoogleLogin: isGoogleLogin(state),
    googleId: getGoogleId(state)

  };
};

export default connect(mapStateToProps)(
  props => (
    <ActionContext.Consumer>
      {({ sessionActions }) => (
        <DefaultAppBar
          {...props}
          sessionActions={sessionActions}
        />
      )}
    </ActionContext.Consumer>
  )
);
