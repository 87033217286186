import { put, take, takeEvery } from 'redux-saga/effects';
import ErrorTypes from './ErrorTypes';
import SessionTypes from '../session/SessionTypes';
import NotificationTypes from '../notification/NotificationTypes';
import * as APIStatus from '../../configs/ApiStatus';
const { ERR_NOT_AUTO_LOGIN } = APIStatus;

export default function * handleApiError () {
  yield takeEvery(ErrorTypes.API_RESPONSE_ERROR, showApiError);
}

function * showApiError (action) {
  const { response, callback, presentAction } = action;
  const ignoreMessage = [ERR_NOT_AUTO_LOGIN.status];
  if (response) {
    const { status: httpStatus } = response;
    const {
      data: { status: apiStatus }
    } = response;
    if (httpStatus === APIStatus.ERR_UNAUTHORIZED.status || httpStatus === APIStatus.ERR_INVALID_TOKEN.status ||
      apiStatus === APIStatus.ERR_UNAUTHORIZED.status || apiStatus === APIStatus.ERR_INVALID_TOKEN.status ||
      httpStatus === APIStatus.ERR_TOKEN_EXPIRED.status || apiStatus === APIStatus.ERR_TOKEN_EXPIRED.status) {
      // cache route to restore session after login
      yield put({ type: SessionTypes.CACHE_PREV_LOGIN_ROUTE });
      // Unauthorized or Access Token is expired => reauthorize
      yield put({ type: SessionTypes.REAUTHORIZE });

      if (presentAction) {
        yield take(SessionTypes.LOG_IN_SUCCESS);
        yield put(presentAction);
      }
    }

    if (ignoreMessage.includes(httpStatus) || ignoreMessage.includes(apiStatus)) {
      return;
    }
    if (httpStatus === APIStatus.OK.status) {
      if (apiStatus === 848 || apiStatus === 601 || apiStatus === 602) {
        return;
      } else {
        if (apiStatus !== 401) {
          if (response.changeMessage) {
            yield _showNotification(response.changeMessage);
          } else {
            yield _showNotification(apiStatus);
          }
        }
      }
    } else {
      if (httpStatus !== 401) {
        yield _showNotification(httpStatus);
      }
    }
  } else {
    // show unknown error
    yield _showNotification('unknown');
  }

  callback && callback(response);
}

function * _showNotification (error) {
  yield put({
    type: NotificationTypes.SHOW_NOTIFICATION,
    payload: {
      config: {
        message: `api.error.${error}`,
        level: 'error',
        autoDismiss: 3,
        position: 'tc'
      }
    }
  });
}
