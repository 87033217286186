import ErrorTypes from './ErrorTypes';

const initialState = {
  error: undefined,
  errorInfo: undefined
};

export default function errorReducer (state = initialState, action) {
  const { type, payload: { error, errorInfo } = {} } = action;
  switch (type) {
    case ErrorTypes.SET_ERROR:
      return Object.assign({}, state, {
        error,
        errorInfo
      });

    default:
      return state;
  }
}
