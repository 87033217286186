/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { routes as originRoutes } from 'configs/routes';
import { ActionContext } from './MainContainer';
import find from 'lodash/find';
import { APP_URL, IMAGE_PATH, OPTION_LOGIN, STORAGE_KEYS, USER_CONFIG } from 'configs/AppConfig';
import { getCachedRoutePath } from 'state/route/routeSelector';
import { connect, useSelector } from 'react-redux';
import {
  getAllowForgotPassword,
  getPageFavicon,
  getViewSettings,
  getIsLoadSetting,
  getGoogleOption,
  getShowLinkRegisterUser
} from 'state/company/companySelector';
import StorageHelper from 'utils/StorageHelper';
import { Grid } from '@material-ui/core';
import { Spinner } from 'reactstrap';
import TranslateMessage from 'components/common/TranslateMessage/TranslateMessage';
import { makeStyles } from '@material-ui/styles';
import { getAuthUser, isAuthenticated } from 'state/session/sessionSelector';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    paddingTop: '40px',
    paddingBottom: '40px',
    backgroundColor: '#f5f5f5',
    minHeight: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logoBox: {
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '400px',
    '& img': {
      maxWidth: '100%',
      maxHeight: 160
    }
  }
}));

const UnauthenticatedRoute = props => {
  const {
    children,
    cachedRoute,
    pageFavicon,
    isLoadSetting,
    isShowRegisterUser,
    sessionActions: { clearAuthInfo }
  } = props;
  const classes = useStyles();

  const urlUpload = process.env.REACT_APP_UPLOAD_PATH + '/';
  const search = window.location.search;
  const pathname = window.location.pathname;
  const viewSettings = useSelector(getViewSettings) || {};
  const googleOption = useSelector(getGoogleOption);
  const authUser = useSelector(getAuthUser);
  const isAuthen = useSelector(isAuthenticated);

  const isOpenType = viewSettings.accountType === OPTION_LOGIN.OPEN_TYPE;

  const isAdminLogin = pathname === '/admin-login';
  const isGoogleLogin = pathname.includes('/login') && search && search === '?google=true&fl=true';

  useEffect(() => {
    // Set Favicon
    if (pageFavicon) {
      const favicon = document.getElementById('favicon');
      favicon.href = `${urlUpload}${IMAGE_PATH.FAVICON}${pageFavicon}`;
      const faviconApple = document.getElementById('appleTouchIcon');
      faviconApple.href = `${urlUpload}${IMAGE_PATH.FAVICON}${pageFavicon}`;
    }
  }, [pageFavicon]);

  if (isLoadSetting || !googleOption) {
    return (
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh', backgroundColor: '#FFF' }}
      >
        <Spinner color="primary" style={{ marginRight: 20 }} /> <TranslateMessage id="progress.loading" />
      </Grid>);
  } else {
    if ((isOpenType && !isAdminLogin) || (pathname === '/register' && !isShowRegisterUser)) {
      return <Redirect to={'/'} push />;
    } else {
      if (!isAuthen || isGoogleLogin) {
        if (isGoogleLogin) {
          authUser && authUser.roleLevel && clearAuthInfo();
          StorageHelper.removeCookie(STORAGE_KEYS.session);
          StorageHelper.removeLocalItem(STORAGE_KEYS.user);
        }
        return <div className={classes.root}>
          {viewSettings && viewSettings.logo && (
            <div className={classes.logoBox}>
              <img
                src={`${APP_URL.UPLOAD_PATH}/${IMAGE_PATH.LOGO}${viewSettings.logo}`}
                alt={viewSettings.companyName}
              />
            </div>
          )}
          {children}
        </div>;
      } else {
        const userConfig = authUser && find(USER_CONFIG, { roleLevel: authUser.roleLevel });
        if (cachedRoute.pathname) {
          return <Redirect to={{ pathname: cachedRoute.pathname, search: cachedRoute.search }} push />;
        }
        if (userConfig) {
          return <Redirect to={userConfig.landingPage} push />;
        }
        return <Redirect to={originRoutes.authorizedRoutes.routes[0].path} push />;
      }
    }
  }
};

UnauthenticatedRoute.propsType = {
  sessionActions: PropTypes.object,
  routes: PropTypes.array,
  cachedRoute: PropTypes.object,
  pageFavicon: PropTypes.any,
  isAllowForgotPassword: PropTypes.bool,
  children: PropTypes.any,
  isLoadSetting: PropTypes.bool,
  isShowRegisterUser: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    cachedRoute: getCachedRoutePath(state),
    pageFavicon: getPageFavicon(state),
    isAllowForgotPassword: getAllowForgotPassword(state),
    isLoadSetting: getIsLoadSetting(state),
    isShowRegisterUser: getShowLinkRegisterUser(state)
  };
};

export default connect(mapStateToProps)(props => (
  <ActionContext.Consumer>
    {({ sessionActions }) => <UnauthenticatedRoute
      {...props}
      sessionActions={sessionActions}
    />
    }
  </ActionContext.Consumer>
));
