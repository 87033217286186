import UserGroupTypes from './UserGroupTypes';

export function loadUserGroupListAll (data) {
  return {
    type: UserGroupTypes.GET_LIST_USER_GROUPS_REQUEST,
    payload: data
  };
}

export function loadUserGroupList (data) {
  return {
    type: UserGroupTypes.GET_USER_GROUPS_REQUEST,
    payload: data
  };
}

export function loadUserGroupDetail (data) {
  return {
    type: UserGroupTypes.GET_USER_GROUP_DETAIL_REQUEST,
    payload: data
  };
}

export function addUserGroup (data) {
  return {
    type: UserGroupTypes.ADD_USER_GROUP_REQUEST,
    payload: data
  };
}

export function updateUserGroup (data) {
  return {
    type: UserGroupTypes.UPDATE_USER_GROUP_REQUEST,
    payload: data
  };
}

export function deleteUserGroup (data) {
  return {
    type: UserGroupTypes.DELETE_USER_GROUP_REQUEST,
    payload: data
  };
}
