import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import StorageHelper from 'utils/StorageHelper';
import { STORAGE_KEYS, USER_CONFIG } from 'configs/AppConfig';

const SelectField = (props) => {
  const { field, form, options, label, placeholder, disabled, col, onSelected, isClearable = false } = props;
  const { name, value } = field;
  const { errors, touched } = form;
  const showError = errors[name] && touched[name];

  const { formatMessage: f } = useIntl();

  const [selectedOption, setSelectedOption] = useState();

  const authUser = useSelector((state) => state.session.user) || StorageHelper.getLocalObject(STORAGE_KEYS.user) || {};
  const isAdmin = authUser.roleLevel === USER_CONFIG.SYSTEM_ADMIN.roleLevel || authUser.roleLevel === USER_CONFIG.COMPANY_ADMIN.roleLevel;
  const inputSetting = useSelector((state) => state.search.inputSetting) || {};
  const inputTheme = useMemo(() => {
    if (authUser && isAdmin) {
      return {};
    } else {
      return inputSetting;
    }
  }, [authUser]);

  const useStyle = makeStyles((theme) => ({
    unPaddingTop: {
      paddingTop: 0
    },
    alignCenter: {
      alignItems: 'center'
    },
    inputBox: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '0!important',
      '& label': {
        minWidth: 100,
        paddingRight: '0.5rem',
        margin: 0
      }
    },
    colInput: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    input: {
      width: '100%',
      margin: '0.5rem 0!important',
      color: inputTheme.color,
      backgroundColor: inputTheme.backgroundColor,
      borderRadius: 4,
      '&:hover': {
        color: inputTheme.hoverColor,
        backgroundColor: inputTheme.hoverBackgroundColor
      }
    }
  }));

  const classes = useStyle();

  useEffect(() => {
    const initOption = options.find(option => option.value === value || option.value === toString(value));
    initOption && setSelectedOption(initOption);
  }, [options, value]);

  const handleSelectedOptionChange = (option) => {
    setSelectedOption(option);
    const selectedValue = option ? option.value : option;

    const changeEvent = {
      target: {
        name: name,
        value: selectedValue
      }
    };
    field.onChange(changeEvent);
    onSelected && onSelected(selectedValue);
  };

  return (
    <FormGroup className={`${classes.inputBox} ${col !== 12 && classes.colInput}`}>
      {label && <Label
        for={name}
        className={classes.unPaddingTop}
      >{label}</Label>}
      <Select
        // menuIsOpen={true}
        id={`${name}-${(new Date()).getTime()}`}
        {...field}
        value={selectedOption}
        onChange={handleSelectedOptionChange}
        isClearable={isClearable}
        placeholder={placeholder}
        isDisabled={disabled}
        options={options}
        noOptionsMessage={() => f({ id: 'text.noOptions' })}
        height={inputTheme.height ? inputTheme.height : 36}
        size={inputTheme.fontSize || '1rem'}
        styles={{
          control: (base) => ({
            ...base,
            overflow: 'hidden',
            borderRadius: 'unset',
            boxShadow: 'none',
            alignItems: 'center',
            fontSize: inputTheme.fontSize || '',
            height: inputTheme.height || '',
            borderColor: name !== 'lang' && inputTheme.borderColor ? inputTheme.borderColor : '#ced4da',
            '&:hover, &:focus, &:active': {
              borderColor: inputTheme.hoverBorderColor ? inputTheme.hoverBorderColor : '#ced4da'
            }
          }),
          menu: (base) => ({
            ...base,
            zIndex: 100,
            fontSize: inputTheme.fontSize || ''
          }),
          dropdownIndicator: (base) => ({
            ...base,
            padding: '0 8px!important',
            color: '#aaa',
            height: inputTheme.height || 36,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }),
          placeholder: () => ({
            height: inputTheme.height || 36,
            color: 'hsl(0,0%,50%)',
            display: 'flex',
            alignItems: 'center',
            marginLeft: 2,
            marginRight: 2
          }),
          input: () => ({
            height: inputTheme.height || 36,
            '& input': {
              height: inputTheme.height || 36
            },
            '& div': {
              height: inputTheme.height || 36
            }
          }),
          singleValue: () => ({
            height: inputTheme.height || 36,
            display: 'flex',
            alignItems: 'center'
          }),
          indicatorSeparator: () => ({
            display: 'none'
          }),
          valueContainer: (base) => ({
            ...base,
            padding: '0px 8px'
          })
        }}

        className={`${showError ? 'is-invalid' : ''} ${classes.input}`}
      />
      <ErrorMessage name={name} component={FormFeedback} />
    </FormGroup>
  );
};

SelectField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,

  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  col: PropTypes.number,
  onSelected: PropTypes.func,
  isClearable: PropTypes.bool
};

SelectField.defaultProps = {
  label: '',
  placeholder: '',
  disabled: false,
  options: [],
  onSelected: null
};

export default SelectField;
