import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { AUTHEN_TYPES, STORAGE_KEYS } from 'configs/AppConfig';
import { Button } from 'reactstrap';
import TranslateMessage from '../../../components/common/TranslateMessage/TranslateMessage';
import googleLogo from '../../../assets/img/icons/google.svg';
import { makeStyles } from '@material-ui/core';
import StorageHelper from 'utils/StorageHelper';

const useStyles = makeStyles(theme => ({
  logo: {
    marginRight: '10px',
    width: '20px',
    height: '20px'
  }
}));
const GoogleLoginButton = (props) => {
  const { onSubmit, isShowViewBook, contentDetailId, searchRequest, activeTab, selectedContentGroup, oAuth2, googleId } = props;
  const classes = useStyles();
  const search = window.location.search;
  const pathname = window.location.pathname;
  const url = `${window.location.origin}/login/`;
  const redirectUri = encodeURIComponent(url);

  const handleCacheStorage = () => {
    const data = {
      cacheRoute: window.location.pathname,
      isShowViewBook: isShowViewBook,
      contentDetailId: contentDetailId,
      searchRequest: searchRequest,
      activeTab: activeTab,
      selectedContentGroup: selectedContentGroup
    };
    StorageHelper.setSessionObject(STORAGE_KEYS.cacheStorage, data);
  };
  const handleOAuth2 = () => {
    handleCacheStorage();
    if (oAuth2 && googleId) {
      oAuth2.requestCode();
      window.open(`https://accounts.google.com/o/oauth2/v2/auth?gsiwebsdk=3&client_id=${googleId}&scope=profile%20email&redirect_uri=${redirectUri}&prompt=&access_type=offline&response_type=code&include_granted_scopes=true&enable_serial_consent=true`, '_self');
    }
  };

  useEffect(() => {
    if (search && search.includes('?code=')) {
      const code = search.split('&')[0].replace('?code=', '');
      if (code && window.location.origin) {
        const params = {
          authorizeCode: decodeURIComponent(code),
          loginType: AUTHEN_TYPES.GOOGLE_LOGIN,
          redirectUrl: `${window.location.origin}/login/`
        };
        onSubmit(params, AUTHEN_TYPES.GOOGLE_LOGIN);
      } else {
        console.log('code is null');
      }
    }
  }, []);

  useEffect(() => {
    if (search && (search === '?google=true' || search === '?google=true&fl=true') && pathname.includes('/login')) {
      if (oAuth2 && googleId) {
        oAuth2.requestCode();
        window.open(`https://accounts.google.com/o/oauth2/v2/auth?gsiwebsdk=3&client_id=${googleId}&scope=profile%20email&redirect_uri=${redirectUri}&prompt=&access_type=offline&response_type=code&include_granted_scopes=true&enable_serial_consent=true`, '_self');
      }
    }
  }, [oAuth2]);

  return (
    <div>
      <Button
        outline
        variant="contained"
        color="secondary"
        type="button"
        block
        onClick={handleOAuth2}
      > <span>
          <img src={googleLogo} className={classes.logo} style={{ width: '20px', height: '20px', marginRight: '10px' }}/>
          <TranslateMessage id="menu.loginGoogle" />
        </span>
      </Button>
    </div>
  );
};

GoogleLoginButton.propTypes = {
  onSubmit: PropTypes.func,
  isShowViewBook: PropTypes.bool,
  contentDetailId: PropTypes.any,
  searchRequest: PropTypes.any,
  activeTab: PropTypes.any,
  selectedContentGroup: PropTypes.any,
  oAuth2: PropTypes.any,
  googleId: PropTypes.any.isRequired
};

export default GoogleLoginButton;
