export default {

  CHECK_IMPORT_PROGRESS: {
    url: '/import-user/check-import-progress',
    method: 'GET'
  },
  DOWNLOAD_FILE_LOGS: {
    url: '/import-user/download-file-error',
    method: 'GET'
  },
  DOWNLOAD_TEMPLATE: {
    url: '/import-user/download-template',
    method: 'GET'
  },
  GET_IMPORT_DATA: {
    url: '/import-user/page',
    method: 'GET'
  },
  DELETE_IMPORT: {
    url: '/import-user/public/delete-progress',
    method: 'DELETE'
  },
  UPLOAD_CONTENT_CSV: {
    url: '/import-user/upload-csv',
    method: 'POST'
  },

  GET_IMPORT_DETAIL: {
    url: '/import-user/detail/',
    method: 'GET'
  },
  ADD_IMPORT: {
    url: '/import-user/add',
    method: 'POST'
  },
  UPDATE_IMPORT: {
    url: '/import-user/update/',
    method: 'PUT'
  },
  UPLOAD_IMPORT: {
    url: '/import-user/upload-add',
    method: 'POST'
  },
  EXPORT_LOGS: {
    url: '/import-user/export',
    method: 'GET'
  }
};
