const _prefix = '@import/';

export default {
  GET_IMPORT_DATA_REQUEST: _prefix + 'GET_IMPORT_DATA_REQUEST',
  GET_IMPORT_DATA_SUCCESS: _prefix + 'GET_IMPORT_DATA_SUCCESS',
  GET_IMPORT_DATA_ERROR: _prefix + 'GET_IMPORT_DATA_ERROR',

  GET_IMPORT_DETAIL_REQUEST: _prefix + 'GET_IMPORT_DETAIL_REQUEST',
  GET_IMPORT_DETAIL_SUCCESS: _prefix + 'GET_IMPORT_DETAIL_SUCCESS',

  UPDATE_PAGINATION: _prefix + 'UPDATE_PAGINATION',

  DOWNLOAD_TEMPLATE: _prefix + 'DOWNLOAD_TEMPLATE',
  DOWNLOAD_FILE_LOGS: _prefix + 'DOWNLOAD_FILE_LOGS',

  UPLOAD_CONTENT_CSV: _prefix + 'UPLOAD_CONTENT_CSV',
  SUCCESS_UPLOAD_CONTENT_CSV: _prefix + 'SUCCESS_UPLOAD_CONTENT_CSV',
  FAILURE_UPLOAD_CONTENT_CSV: _prefix + 'FAILURE_UPLOAD_CONTENT_CSV',

  IMPORT_CONTENT_CSV: _prefix + 'IMPORT_CONTENT_CSV',

  CHECK_IMPORT_PROGRESS_REQUEST: _prefix + 'CHECK_IMPORT_PROGRESS_REQUEST',
  CHECK_IMPORT_PROGRESS_SUCCESS: _prefix + 'CHECK_IMPORT_PROGRESS_SUCCESS'
};
