import { all, put, takeEvery } from 'redux-saga/effects';
import EmailSenderTypes from './EmailTemplateTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/email-template';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';

function * getEmailTemplate () {
  yield takeEvery(EmailSenderTypes.GET_EMAIL_TEMPLATE_REQUEST, _getEmailTemplate);
}

function * _getEmailTemplate ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  const api = {
    ...API.GET_EMAIL_TEMPLATE,
    params: { ...request }
  };
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      yield put({
        type: EmailSenderTypes.GET_EMAIL_TEMPLATE_SUCCESS,
        payload: Util.toCamelCaseKey({ ...response.data, ...payload })
      });
    }
  });
}

function * updateEmailTemplate () {
  yield takeEvery(EmailSenderTypes.UPDATE_EMAIL_TEMPLATE_REQUEST, _updateEmailTemplate);
}

function * _updateEmailTemplate ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.UPDATE_EMAIL_TEMPLATE,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: EmailSenderTypes.GET_EMAIL_TEMPLATE_REQUEST,
        payload: { type: payload.sentEmailType }
      });
    }
  });
}

function * resetEmailTemplate () {
  yield takeEvery(EmailSenderTypes.RESET_EMAIL_TEMPLATE_REQUEST, _resetEmailTemplate);
}

function * _resetEmailTemplate () {
  yield createRequest({
    api: {
      ...API.RESET_EMAIL_TEMPLATE
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    }
  });
}

export default function * bannerSaga () {
  yield all([
    getEmailTemplate(),
    updateEmailTemplate(),
    resetEmailTemplate()
  ]);
}
