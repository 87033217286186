import InputTypes from './InputTypes';

// Button management
export function loadInputSetting (data) {
  return {
    type: InputTypes.GET_INPUT_REQUEST,
    payload: data
  };
}
export function updateInputSetting (data) {
  return {
    type: InputTypes.UPDATE_INPUT_REQUEST,
    payload: data
  };
}
