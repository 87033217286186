import Loadable from 'react-loadable';
import forIn from 'lodash/forIn';
import AuthenticatedRoute from 'components/layout/AuthenticatedLayout';
import UnauthenticatedRoute from 'components/layout/UnauthenticatedLayout';
import PublicRoute from 'components/layout/PublicLayout';
import NoMatch from 'components/page/NoMatch';
import FlashScreen from 'components/page/FlashScreen';
import uniqBy from 'lodash/uniqBy';
import warning from 'warning';
import Loading from 'components/layout/LoadingRoute';
import { OPTION_LOGIN, USER_CONFIG } from './AppConfig';
import ViewOnlineLayout from 'components/layout/ViewOnlineLayout';
import HomeLayout from 'components/layout/HomeLayout';

const LoginPage = Loadable({
  loader: () => import('../views/auth/UserLogin'),
  loading: Loading
});
const SignupPage = Loadable({
  loader: () => import('../views/auth/UserSignUp'),
  loading: Loading
});
const AdminLoginPage = Loadable({
  loader: () => import('../views/auth/AdminLogin'),
  loading: Loading
});

const ForgotPasswordPage = Loadable({
  loader: () => import('../views/auth/ForgotPassword'),
  loading: Loading
});

const AdminCompanyPage = Loadable({
  loader: () => import('../views/admin/Company'),
  loading: Loading
});

const AdminCompanyDetailPage = Loadable({
  loader: () => import('../views/admin/CompanyDetail'),
  loading: Loading
});

const AdminCompanyCreatePage = Loadable({
  loader: () => import('../views/admin/CompanyCreate'),
  loading: Loading
});

const AdminAccountSettingPage = Loadable({
  loader: () => import('../views/admin/AccountSetting'),
  loading: Loading
});

const DashboardPage = Loadable({
  loader: () => import('../views/company/DashboardPage'),
  loading: Loading
});

const CompanyContentPage = Loadable({
  loader: () => import('../views/company/Content'),
  loading: Loading
});
const CompanyContentGroupPage = Loadable({
  loader: () => import('../views/company/ContentGroup'),
  loading: Loading
});

const CompanyMessagePage = Loadable({
  loader: () => import('../views/company/Message'),
  loading: Loading
});

const CompanyCustomizeTemplatePage = Loadable({
  loader: () => import('../views/company/CustomizeTemplate'),
  loading: Loading
});

const CompanyUserGroupPage = Loadable({
  loader: () => import('../views/company/UserGroup'),
  loading: Loading
});

const CompanyAccountSettingPage = Loadable({
  loader: () => import('../views/company/AccountSetting'),
  loading: Loading
});

const CompanyUserPage = Loadable({
  loader: () => import('../views/company/User'),
  loading: Loading
});

const CompanySubscriptionPage = Loadable({
  loader: () => import('../views/company/Subscription'),
  loading: Loading
});

const CompanyCouponPage = Loadable({
  loader: () => import('../views/company/Coupon'),
  loading: Loading
});

const SearchViewBook = Loadable({
  loader: () => import('../components/search/SearchViewBook'),
  loading: Loading
});

// Public Pages
const SearchPage = Loadable({
  loader: () => import('../views/end-user/Search'),
  loading: Loading
});

const HomePage = Loadable({
  loader: () => import('../views/end-user/Home'),
  loading: Loading
});
const HomeSubscriptionDetail = Loadable({
  loader: () => import('../components/home/HomeSubscriptionDetail'),
  loading: Loading
});
const EndUserProfilePage = Loadable({
  loader: () => import('../views/end-user/UserProfile'),
  loading: Loading
});
const UserSubscription = Loadable({
  loader: () => import('../views/end-user/UserSubscription'),
  loading: Loading
});
const DetailContentPage = Loadable({
  loader: () => import('../views/end-user/DetailContentPage'),
  loading: Loading
});
const OrderEntryPage = Loadable({
  loader: () => import('../views/end-user/OrderEntry'),
  loading: Loading
});
const VeritransConfirmPage = Loadable({
  loader: () => import('../views/end-user/VeritransConfirmPage'),
  loading: Loading
})
const ThankYouPage = Loadable({
  loader: () => import('../views/end-user/ThankYouPage'),
  loading: Loading
});
const PublicDetailContent = Loadable({
  loader: () => import('../views/end-user/PublicDetailContent'),
  loading: Loading
});
export const NoMatchPage = NoMatch;
export const FlashScreenPage = FlashScreen;

const routes = {
  authorizedRoutes: {
    name: 'authenticated', // use to define id when create routes
    layout: AuthenticatedRoute,
    routes: [
      {
        path: '/admin/profile',
        name: 'menu.admin.profile',
        title: 'label.profile',
        menuIconClass: 'business',
        component: AdminAccountSettingPage,
        showInMenu: false,
        accessRole: [USER_CONFIG.SYSTEM_ADMIN.roleLevel]
      },
      {
        path: '/admin/companies',
        name: 'menu.company',
        title: 'menu.company',
        menuIconClass: 'business',
        component: AdminCompanyPage,
        showInMenu: true,
        accessRole: [USER_CONFIG.SYSTEM_ADMIN.roleLevel]
      },
      {
        path: '/admin/:id',
        name: 'menu.company.detail',
        title: 'title.companyDetail',
        menuIconClass: 'business',
        component: AdminCompanyDetailPage,
        showInMenu: false,
        accessRole: [USER_CONFIG.SYSTEM_ADMIN.roleLevel]
      },
      {
        path: '/create',
        name: 'menu.company.create',
        title: 'title.addCompany',
        menuIconClass: 'business',
        component: AdminCompanyCreatePage,
        showInMenu: false,
        accessRole: [USER_CONFIG.SYSTEM_ADMIN.roleLevel]
      },
      {
        path: '/company/dashboard',
        name: 'menu.company.dashboard',
        title: 'title.dashboard',
        menuIconClass: 'assessment',
        component: DashboardPage,
        showInMenu: true,
        accessRole: [USER_CONFIG.COMPANY_ADMIN.roleLevel]
      },
      {
        path: '/company/contents',
        name: 'menu.contents',
        title: 'menu.contents',
        menuIconClass: 'storage',
        component: CompanyContentPage,
        showInMenu: true,
        accessRole: [USER_CONFIG.COMPANY_ADMIN.roleLevel]
      },
      {
        path: '/company/contents-group',
        name: 'menu.contents-group',
        title: 'menu.contents-group',
        menuIconClass: 'widgets',
        component: CompanyContentGroupPage,
        showInMenu: true,
        accessRole: [USER_CONFIG.COMPANY_ADMIN.roleLevel]
      },
      {
        path: '/company/user',
        name: 'menu.user',
        title: 'menu.userManagement',
        menuIconClass: 'person',
        component: CompanyUserPage,
        showInMenu: true,
        accessRole: [USER_CONFIG.COMPANY_ADMIN.roleLevel],
        ignoreLoginSetting: [OPTION_LOGIN.OPEN_TYPE]
      },
      {
        path: '/company/user-group',
        name: 'menu.user_group',
        title: 'menu.userGroupManagement',
        menuIconClass: 'group',
        component: CompanyUserGroupPage,
        showInMenu: true,
        accessRole: [USER_CONFIG.COMPANY_ADMIN.roleLevel],
        ignoreLoginSetting: [OPTION_LOGIN.OPEN_TYPE]
      },
      {
        path: '/company/messages',
        name: 'menu.messages',
        title: 'menu.messages',
        menuIconClass: 'settings_overscan',
        component: CompanyMessagePage,
        showInMenu: true,
        accessRole: [USER_CONFIG.COMPANY_ADMIN.roleLevel]
      },
      {
        path: '/company/customize-template',
        name: 'menu.customizeTemplate',
        title: 'menu.customizeTemplate',
        menuIconClass: 'view_quilt',
        component: CompanyCustomizeTemplatePage,
        showInMenu: true,
        accessRole: [USER_CONFIG.COMPANY_ADMIN.roleLevel]
      },
      {
        path: '/company/subscription',
        name: 'menu.company.subscription',
        title: 'label.subscription',
        menuIconClass: 'subscriptions',
        component: CompanySubscriptionPage,
        showInMenu: true,
        accessRole: [USER_CONFIG.COMPANY_ADMIN.roleLevel],
        showWithSubscription: true
      },
      {
        path: '/company/coupon',
        name: 'menu.company.coupon',
        title: 'label.coupon',
        menuIconClass: 'discount',
        component: CompanyCouponPage,
        showInMenu: true,
        accessRole: [USER_CONFIG.COMPANY_ADMIN.roleLevel],
        showWithSubscription: true
      },
      {
        path: '/company/profile',
        name: 'menu.company.profile',
        title: 'label.accountSetting',
        menuIconClass: 'group',
        component: CompanyAccountSettingPage,
        showInMenu: false,
        accessRole: [USER_CONFIG.COMPANY_ADMIN.roleLevel]
      }
    ]
  },
  unauthorizedRoutes: {
    name: 'unauthenticated',
    layout: UnauthenticatedRoute,
    routes: [
      {
        path: '/login',
        name: 'auth.login',
        title: 'menu.login',
        component: LoginPage
      },
      {
        path: '/register',
        name: 'auth.signup',
        title: 'menu.register',
        component: SignupPage
      },
      {
        path: '/admin-login',
        name: 'auth.adminLogin',
        title: 'menu.login',
        component: AdminLoginPage
      },
      {
        path: '/forget-password',
        name: 'auth.forgotPassword',
        title: 'label.forgotPassword',
        component: ForgotPasswordPage
      }
    ]
  },
  publicRoutes: {
    name: 'public',
    layout: PublicRoute,
    routes: [
      {
        path: '/search',
        name: 'search',
        title: 'label.search',
        component: SearchPage
      },
      // {
      //   path: '/search/:contentId',
      //   name: 'search.content',
      //   title: 'label.search',
      //   component: DetailContentPage
      // }
    ]
  },
  noLayoutRoutes: {
    name: 'public',
    routes: [
      {
        path: '/content/:contentToken',
        name: 'content.token',
        title: 'label.search',
        component: PublicDetailContent
      },
      {
        path: '/direct-view/:viewToken',
        name: 'user.view.token',
        title: 'label.viewOnline',
        component: SearchViewBook,
      },
    ]
  },
  viewOnlineRoutes: {
    name: 'viewOnline',
    layout: ViewOnlineLayout,
    routes: [
      {
        path: '/view-online/:id/:contentGroupId',
        name: 'user.view.contentGroup',
        title: 'label.viewOnline',
        component: SearchViewBook,
        showInMenu: false,
        accessRole: [USER_CONFIG.USER.roleLevel]
      },
      {
        path: '/view-online/:id',
        name: 'user.view.content',
        title: 'label.viewOnline',
        component: SearchViewBook,
        showInMenu: false,
        accessRole: [USER_CONFIG.USER.roleLevel, USER_CONFIG.GROUP_USER.roleLevel]
      },  
    ]
  },
  HomeRoutes: {
    name: 'home',
    layout: HomeLayout,
    routes: [
      {
        path: '/',
        name: 'home',
        title: 'label.home',
        component: HomePage,
        showInMenu: false,
        exact: true,
        accessRole: []
      },
      {
        path: '/home-search',
        name: 'home.search',
        title: 'label.home',
        component: HomePage,
        showInMenu: false,
        accessRole: []
      },
      {
        path: '/profile',
        name: 'menu.userProfile',
        title: 'label.profile',
        component: EndUserProfilePage
      },
      {
        path: '/subscription',
        name: 'menu.subscription',
        title: 'label.subscription',
        component: UserSubscription,
        showWithSubscription: true,
        requiredLogin: true
      },
      {
        path: '/subscription/:subscriptionId',
        name: 'menu.subscription',
        title: 'label.subscription',
        component: HomeSubscriptionDetail,
        showWithSubscription: true,
        requiredLogin: true
      },
      {
        path: '/order-entry/:subscriptionId',
        name: 'menu.userProfile',
        title: 'label.orderEntry',
        component: OrderEntryPage,
        showSidebar: false,
        showInMenu: false,
        requiredLogin: true
      },
      {
        path: '/veritrans-payment/confirm',
        name: 'menu.userProfile',
        title: 'label.profile',
        component: VeritransConfirmPage,
        showSidebar: false,
        showInMenu: false,
        requiredLogin: true
      },
      {
        path: '/thank-you',
        name: 'menu.userProfile',
        title: 'label.thankyou',
        component: ThankYouPage,
        showSidebar: false,
        showInMenu: false,
        requiredLogin: true
      },
      {
        path: '/home-search/:contentGroupId',
        name: 'home.search.contentGroup',
        title: 'label.home',
        component: HomePage,
        showInMenu: false,
        accessRole: []
      },
      {
        path: '/home-search/:contentGroupId/:contentId',
        name: 'home.search.content',
        title: 'label.home',
        component: HomePage,
        showInMenu: false,
        accessRole: []
      },
      {
        path: '/:contentGroupId/:contentId',
        name: 'home.content',
        title: 'label.home',
        component: HomePage,
        showInMenu: false,
        accessRole: []
      },
      {
        path: '/:contentGroupId',
        name: 'home.contentGroup',
        title: 'label.home',
        component: HomePage,
        showInMenu: false,
        accessRole: []
      },
    ]
  }
};

function _createRoutes (routes = [], path = '', layout, id) {
  const flattenedRoutes = [];
  routes.forEach(route => {
    const {
      path: subPath,
      layout: subLayout,
      exact,
      routes: subRoutes,
      component,
      relativePath,
      redirect,
      ...rest
    } = route;
    if (component) {
      flattenedRoutes.push(
        Object.assign(
          {
            layoutId: id, // use to compare layout when change route
            layout: subLayout || layout,
            path: path + subPath,
            component: component,
            ...rest
          },
          subRoutes &&
          !!subRoutes[0] && {
            exact: false,
            routes: _createRoutes(
              subRoutes,
              path + subPath,
              subLayout || layout,
              id
            )
          },
          !subRoutes && {
            exact: exact || true
          },
          redirect && {
            redirect: relativePath ? redirect : path + subPath + redirect
          }
        )
      );
    } else {
      warning(
        false,
        "Missing route's component, config will be ignore %s",
        route.path
      );
    }
  });

  return flattenedRoutes;
}

export default function createRoutes () {
  const flattenedRoutes = [];
  let i = 0;
  forIn(routes, ({ name = '', layout, routes: layoutRoutes }) => {
    layoutRoutes &&
      flattenedRoutes.push(
        ..._createRoutes(layoutRoutes, '', layout, `${name}_${i++}`)
      );
  });

  // push no match route
  const filteredDuplicate = uniqBy(flattenedRoutes, 'path');
  warning(
    filteredDuplicate.length === flattenedRoutes.length,
    'There are duplicate path in routes config, only first config are accepted all another config will be ignored'
  );

  return filteredDuplicate;
}

export function flattenRoutes (routes = [], init = []) {
  return routes.reduce((accumulator, { routes, ...rest }) => {
    accumulator.push(rest);
    routes && routes.length > 0 && flattenRoutes(routes, accumulator);
    return accumulator;
  }, init);
}

function _filterMenuRoutes (routes = [], path = '') {
  const menu = [];
  if (routes) {
    routes.forEach(route => {
      if (route.showInMenu) {
        const _route = {};
        if (route.routes) {
          const sub = _filterMenuRoutes(route.routes, path + route.path);
          sub.length > 1 && (_route.routes = sub);
        }

        menu.push(
          Object.assign(
            {},
            {
              path: path + route.path,
              name: route.name || '',
              title: route.title || '',
              exact: route.exact || true,
              showWithSubscription: route.showWithSubscription || false,
              iconClass: route.menuIconClass,
              accessRole: route.accessRole || [],
              ignoreLoginSetting: route.ignoreLoginSetting || []
            },
            _route
          )
        );
      }
    });
  }

  return menu;
}

export function filterMenuRoutes () {
  const menuRoutes = [];
  forIn(routes, ({ layout, routes }) => {
    routes && menuRoutes.push(..._filterMenuRoutes(routes));
  });

  return menuRoutes;
}

export { routes };
