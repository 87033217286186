import { all, put, select, takeEvery } from 'redux-saga/effects';
import ImportUserTypes from './ImportUserTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/import-user';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';
import { getCompanyDomain } from '../company/companySelector';
import { getPagination } from './importUserSelector';
import { saveAs } from 'file-saver';
import StorageHelper from 'utils/StorageHelper';
import { STORAGE_KEYS, PAGINATION } from 'configs/AppConfig';

function * loadImportData () {
  yield takeEvery(ImportUserTypes.GET_IMPORT_DATA_REQUEST, _loadImportData);
}

function * _loadImportData ({ payload }) {
  const domain = yield select(getCompanyDomain);
  const request = snakecaseKeys({ ...payload, domain });
  yield createRequest({
    api: {
      ...API.GET_IMPORT_DATA,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: ImportUserTypes.GET_IMPORT_DATA_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: ImportUserTypes.GET_IMPORT_DATA_ERROR,
        payload: status
      });
    }
  });
}

function * uploadContentCSV () {
  yield takeEvery(ImportUserTypes.UPLOAD_USER_CSV, _uploadContentCSV);
}

function * _uploadContentCSV ({ payload }) {
  const data = new FormData();
  data.append('file', payload.file);
  yield createRequest({
    api: {
      ...API.UPLOAD_CONTENT_CSV,
      header: {
        'Content-Type': 'multipart/form-data',
        'X-Access-Token': StorageHelper.getCookie(STORAGE_KEYS.session)
      },
      data: data
    },
    onSuccess: function * (response) {
      payload.callback && payload.callback(response);
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.upload.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: ImportUserTypes.GET_IMPORT_DATA_REQUEST,
        payload: {
          ...PAGINATION
        }
      });
      yield put({
        type: ImportUserTypes.CHECK_IMPORT_PROGRESS_REQUEST
      });
    },
    onError: (response) => {
      payload.callback && payload.callback(response);
    },
    onRequestError: (response) => {
      payload.callback && payload.callback(response);
    }
  });
}

function * downloadTemplate () {
  yield takeEvery(ImportUserTypes.DOWNLOAD_TEMPLATE, _downloadTemplate);
}

function * _downloadTemplate ({ payload }) {
  yield createRequest({
    api: {
      ...API.DOWNLOAD_TEMPLATE
    },
    onSuccess: function (response) {
      const blob = new Blob(['\ufeff', response], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, payload);
    }
  });
}

function * downloadFileLogs () {
  yield takeEvery(ImportUserTypes.DOWNLOAD_FILE_LOGS, _downloadFileLogs);
}

function * _downloadFileLogs ({ payload }) {
  const { id, fileName } = payload;
  yield createRequest({
    api: {
      ...API.DOWNLOAD_FILE_LOGS,
      params: {
        id: id
      }
    },
    onSuccess: function (response) {
      const blob = new Blob(['\ufeff', response], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, fileName);
    }
  });
}

function * checkImportProgress () {
  yield takeEvery(ImportUserTypes.CHECK_IMPORT_PROGRESS_REQUEST, _checkImportProgress);
}

function * _checkImportProgress () {
  yield createRequest({
    api: {
      ...API.CHECK_IMPORT_PROGRESS
    },
    onSuccess: function * (response) {
      // const progressFiles = yield select(getProgressingFiles);
      const pagination = yield select(getPagination);
      if (response.data.length === 0) {
        yield put({
          type: ImportUserTypes.GET_IMPORT_DATA_REQUEST,
          payload: pagination
        });
      } else {
        // yield put({
        //   type: ImportUserTypes.CHECK_IMPORT_PROGRESS_REQUEST
        // });
      }
      yield put({
        type: ImportUserTypes.CHECK_IMPORT_PROGRESS_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

export default function * importUserSaga () {
  yield all([
    loadImportData(),
    uploadContentCSV(),
    downloadTemplate(),
    downloadFileLogs(),
    checkImportProgress()
  ]);
}
