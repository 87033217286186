import ContentDetailTypes from './ContentDetailTypes';

const initialState = {
  buttonDetail: {},
  setting: {}
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  const newState = { ...state };
  switch (type) {
    case ContentDetailTypes.GET_SETTING_SUCCESS:
      newState.setting = { ...payload };
      return newState;
    case ContentDetailTypes.GET_BUTTON_SUCCESS:
      newState.buttonDetail = { ...payload };
      return newState;
  }
  return newState;
}
