import EmailSenderTypes from './EmailTemplateTypes';

export function loadEmailTemplate (data) {
  return {
    type: EmailSenderTypes.GET_EMAIL_TEMPLATE_REQUEST,
    payload: data
  };
}
export function updateEmailTemplate (data) {
  return {
    type: EmailSenderTypes.UPDATE_EMAIL_TEMPLATE_REQUEST,
    payload: data
  };
}
export function resetEmailTemplate (data) {
  return {
    type: EmailSenderTypes.RESET_EMAIL_TEMPLATE_REQUEST,
    payload: data
  };
}
