import ButtonTypes from './ButtonTypes';

// Button management
export function loadListButton (data) {
  return {
    type: ButtonTypes.GET_LIST_BUTTON_REQUEST,
    payload: data
  };
}
export function loadAllButton (data) {
  return {
    type: ButtonTypes.GET_ALL_BUTTON_REQUEST,
    payload: data
  };
}
export function addButton (data) {
  return {
    type: ButtonTypes.ADD_BUTTON_REQUEST,
    payload: data
  };
}
export function getButtonDetail (data) {
  return {
    type: ButtonTypes.GET_BUTTON_REQUEST,
    payload: data
  };
}
export function updateButton (data) {
  return {
    type: ButtonTypes.UPDATE_BUTTON_REQUEST,
    payload: data
  };
}
export function deleteButton (data) {
  return {
    type: ButtonTypes.DELETE_BUTTON_REQUEST,
    payload: data
  };
}
