import UserTypes from './UserTypes';
import { PAGINATION } from '../../configs/AppConfig';

const initialState = {
  users: [],
  total: 0,
  totalPages: 0,
  queries: {
    search_key: '',
    sort_field: '',
    ...PAGINATION,
    sort: false
  },
  userDetail: {
    fullName: '',
    email: '',
    createDate: '',
    role: '',
    password: '',
    param01: '',
    param02: '',
    param03: '',
    param04: '',
    param05: '',
    isAccountStatus: false,
    userGroupId: '',
    contentGroups: []
  },
  userSubscriptionPlans: [],
  queriesUserSubscriptionPlan: {
    search_key: '',
    sort_field: '',
    ...PAGINATION,
    sort: false
  },
  totalUserSubscriptionPlan: 0,
  totalPagesUserSubscriptionPlan: 0,
  isUserDetailLoad: true,
  statusAddUpdate: null
};

export default function userReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  switch (type) {
    case UserTypes.GET_USERS_SUCCESS:
      newState.users = [...payload.content];
      newState.totalPages = payload.totalPages;
      newState.total = payload.totalElements;
      return newState;
    case UserTypes.UPDATE_USER_QUERIES:
      newState.queries.search_key = payload.search_key;
      newState.queries.sort_field = payload.sort_field;
      newState.queries.sort = payload.sort;
      newState.queries.page = payload.page;
      newState.queries.size = payload.size;
      return newState;
    case UserTypes.GET_USER_DETAIL_SUCCESS:
      for (const key in payload) {
        if (!payload[key]) {
          payload[key] = '';
        }
      }
      newState.userDetail = {
        ...newState.userDetail,
        ...payload,
        password: payload.email ? '@Aaa123456' : ''
      };
      newState.isUserDetailLoad = false;
      return newState;
    case UserTypes.GET_USER_DETAIL_REQUEST:
      newState.isUserDetailLoad = true;
      return newState;
    case UserTypes.GET_USER_SUBCRIPTION_PLAN_PAGE_SUCCESS:
      newState.userSubscriptionPlans = [...payload.content];
      newState.totalPagesUserSubscriptionPlan = payload.totalPages;
      newState.totalUserSubscriptionPlan = payload.totalElements;
      return newState;
    case UserTypes.UPDATE_USER_SUBCRIPTION_PLAN_PAGE_QUERIES:
      newState.queriesUserSubscriptionPlan.search_key = payload.search_key;
      newState.queriesUserSubscriptionPlan.sort_field = payload.sort_field;
      newState.queriesUserSubscriptionPlan.sort = payload.sort;
      newState.queriesUserSubscriptionPlan.page = payload.page;
      newState.queriesUserSubscriptionPlan.size = payload.size;
      return newState;
    default:
      newState = state;
      break;
  }
  // return new state
  return newState;
}
