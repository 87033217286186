import CompanyTypes from './CompanyTypes';

export function loadCompanies (data) {
  return {
    type: CompanyTypes.GET_COMPANIES_REQUEST,
    payload: data
  };
}

export function loadCompanyDetail (data) {
  return {
    type: CompanyTypes.GET_COMPANY_DETAIL_REQUEST,
    payload: data
  };
}

export function createCompany (data) {
  return {
    type: CompanyTypes.CREATE_COMPANIES_REQUEST,
    payload: data
  };
}

export function updateCompanyDetail (data) {
  return {
    type: CompanyTypes.UPDATE_COMPANY_DETAIL_REQUEST,
    payload: data
  };
}

export function updateSelected (selected, isSelected) {
  return {
    type: CompanyTypes.UPDATE_SELECTED,
    payload: { selected, isSelected }
  };
}

export function updateSelectedAll (selected, isSelected) {
  return {
    type: CompanyTypes.UPDATE_SELECTED_ALL,
    payload: { selected, isSelected }
  };
}

export function uploadCompanyLogo (payload) {
  return {
    type: CompanyTypes.UPLOAD_COMPANY_LOGO_REQUEST,
    payload: payload
  };
}

export function uploadCompanyFavicon (payload) {
  return {
    type: CompanyTypes.UPLOAD_COMPANY_FAVICON_REQUEST,
    payload: payload
  };
}

export function deleteCompany (payload) {
  return {
    type: CompanyTypes.DELETE_COMPANY_REQUEST,
    payload: payload
  };
}

export function setCompanyDomain (payload) {
  return {
    type: CompanyTypes.SET_COMPANY_DOMAIN,
    payload: payload
  };
}

export function loadEndUserCompanyDetail (payload) {
  return {
    type: CompanyTypes.GET_END_USER_COMPANY_DETAIL_REQUEST,
    payload: payload
  };
}

export function loadSetting (payload) {
  return {
    type: CompanyTypes.GET_SETTING_REQUEST,
    payload: payload
  };
}

export function verifyPaymentVeritrans (payload) {
  return {
    type: CompanyTypes.VERIFY_PAYMENT_VERITRANS_REQUEST,
    payload: payload
  };
}

export function updatePaymentVeritrans (payload) {
  return {
    type: CompanyTypes.UPDATE_PAYMENT_VERITRANS_REQUEST,
    payload: payload
  };
}
