import ContentTypes from './ContentTypes';
import { PAGINATION } from '../../configs/AppConfig';

const initialState = {
  viewerToken: '',
  contentData: [],
  selected: [],
  pagination: {
    ...PAGINATION,
    totalPages: 0,
    totalRecords: 0
  },
  isContentDetailLoad: true,
  contentDetail: {
    author: '',
    title: '',
    contentGroupIds: [],
    beContentsId: '',
    toc: '',
    keywords: '',
    param1: '',
    param2: '',
    param3: '',
    param4: '',
    param5: '',
    description: '',
    distributorName: '',
    fileName: '',
    view: false,
    print: false,
    printableContent: 0
  },
  uploadContents: [],
  haveContentDetail: true,
  enablePrint: 0,
  isErrorViewer: false,
  contentType: 'pdf',
  watermark: {}
};

const dataReducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  let existedFile;
  let newUploadContents;
  switch (type) {
    case ContentTypes.GET_VIEWER_TOKEN_SUCCESS:
      newState.viewerToken = payload.tokenId;
      newState.enablePrint = payload.contentInfo.printableContent || false;
      newState.contentType = payload.contentInfo.extensionType || 'pdf';
      newState.watermark = payload.watermark;
      return newState;
    case ContentTypes.GET_VIEWER_TOKEN_ERROR:
      newState.isErrorViewer = true;
      return newState;
    case ContentTypes.GET_VIEWER_TOKEN:
      newState.isErrorViewer = false;
      return newState;
    case ContentTypes.GET_CONTENT_DATA_SUCCESS:
      newState.contentData = [...payload.content];
      newState.pagination.totalRecords = payload.totalElements;
      newState.pagination.totalPages = payload.totalPages;
      newState.pagination.size = payload.size;
      newState.pagination.page = payload.number + 1;
      newState.pagination = { ...newState.pagination };
      return newState;
    case ContentTypes.GET_CONTENT_DETAIL_REQUEST:
      newState.haveContentDetail = true;
      newState.isContentDetailLoad = true;
      return newState;
    case ContentTypes.GET_CONTENT_DETAIL_SUCCESS:
      for (const key in payload) {
        if (!payload[key] && payload[key] !== 0) {
          payload[key] = '';
        }
      }
      newState.haveContentDetail = true;
      newState.contentDetail = { ...payload };
      newState.isContentDetailLoad = false;
      return newState;
    case ContentTypes.GET_CONTENT_DETAIL_ERROR:
      newState.contentDetail = null;
      newState.haveContentDetail = false;
      return newState;
    case ContentTypes.SET_UPLOAD_PROGRESS:
      existedFile = newState.uploadContents.filter(file => file.id === payload.id)[0];
      if (existedFile) {
        existedFile.progress = payload.progress;
        newState.uploadContents = [...newState.uploadContents];
      } else {
        newState.uploadContents = [...newState.uploadContents, payload];
      }
      return newState;
    case ContentTypes.CLOSE_UPLOAD_PROGRESS:
      newUploadContents = newState.uploadContents.filter(file => file.progress !== 100 && !file.isError);
      newState.uploadContents = newUploadContents;
      return newState;
    case ContentTypes.FAILURE_UPLOAD_CONTENT:
      existedFile = newState.uploadContents.filter(file => file.id === payload.id)[0];
      existedFile.isError = true;
      newState.uploadContents = [...newState.uploadContents];
      return newState;
    default:
      newState = state;
      break;
  }

  // return new state
  return newState;
};

export default dataReducer;
