import ContentGroupTypes from './ContentGroupTypes';

export function loadContentGroupData (data) {
  return {
    type: ContentGroupTypes.GET_CONTENT_GROUP_DATA_REQUEST,
    payload: data
  };
}

export function addContentGroup (data) {
  return {
    type: ContentGroupTypes.ADD_CONTENT_GROUP_REQUEST,
    payload: data
  };
}

export function updateContentGroup (data) {
  return {
    type: ContentGroupTypes.UPDATE_CONTENT_GROUP_REQUEST,
    payload: data
  };
}

export function deleteContentGroup (data) {
  return {
    type: ContentGroupTypes.DELETE_CONTENT_GROUP_REQUEST,
    payload: data
  };
}

export function loadContentGroupDetail (data) {
  return {
    type: ContentGroupTypes.GET_CONTENT_GROUP_DETAIL_REQUEST,
    payload: data
  };
}

export function loadContentGroupAll (data) {
  return {
    type: ContentGroupTypes.GET_CONTENT_GROUP_ALL_REQUEST,
    payload: data
  };
}

export function resetContentGroup (data) {
  return {
    type: ContentGroupTypes.RESET_CONTENT_GROUP_REQUEST,
    payload: data
  };
}

export function loadFavoritesRecentlyRead (data) {
  return {
    type: ContentGroupTypes.GET_FAVORITES_RECENTLY_REQUEST,
    payload: data
  };
}
export function updateRefCode () {
  return {
    type: ContentGroupTypes.UPDATE_REF_CODE_REQUEST
  };
}
