import React, { useState } from 'react';
import { Button, Spinner } from 'reactstrap';
import TranslateMessage from 'components/common/TranslateMessage/TranslateMessage';
import { getIsLoading, getUserInfo } from 'state/account/accountSelector';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ActionContext } from 'components/layout/MainContainer';
import CommonModal from '../../../components/modal/CommonModal';
import { useIntl } from 'react-intl';
import { Box } from '@material-ui/core';

const Style = {
  spin: {
    display: 'flex',
    justifyContent: 'center'
  }
};
const UserCancelAccount = (props) => {
  const {
    accountActions: { cancelAccount },
    isLoading,
    accountDetail
  } = props;

  const { formatMessage: f } = useIntl();
  const [isShowm, setShow] = useState(false);

  const handelCancelAccount = () => {
    cancelAccount(accountDetail.id);
  };

  return (
    <React.Fragment>
      <Button
        className='btn btn-info mr-1'
        variant='contained'
        color='danger'
        type='button'
        onClick={() => setShow(true)}
      >
        <TranslateMessage id="button.withDraw" />
      </Button>
      <CommonModal
        title={f({ id: 'title.cancelAccount' })}
        isOpen={isShowm}
        toggle={() => !isLoading ? setShow(false) : null}
      >
        {
          isLoading && <div style={Style.spin}>
            <Spinner color='primary' style={{ marginRight: 20 }} /> <TranslateMessage id='progress.loading'/>
          </div>
        }
        {!isLoading && <React.Fragment>
          <TranslateMessage id='text.confirmCancelUser'/>
          <Box style={{ marginTop: '10px', textAlign: 'end', padding: '10px' }}>
            <Button
              className='btn btn-info mr-1'
              variant='contained'
              color='danger'
              type='button'
              onClick={handelCancelAccount}
              disabled={isLoading}
            >
              <TranslateMessage id='button.yesCancel'/>
            </Button>
            <Button
              className='btn btn-info mr-1'
              variant='contained'
              type='button'
              onClick={() => setShow(false)}
            >
              <TranslateMessage id='button.cancelAccount'/>
            </Button>
          </Box>
        </React.Fragment>}
      </CommonModal>
    </React.Fragment>
  );
};

UserCancelAccount.propTypes = {
  accountActions: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  accountDetail: PropTypes.any
};

const mapStateToProps = (state) => {
  return {
    accountDetail: getUserInfo(state),
    isLoading: getIsLoading(state)
  };
};

export default connect(mapStateToProps)(
  props => (
    <ActionContext.Consumer>
      {({ accountActions }) => (
        <UserCancelAccount {...props} accountActions={accountActions} />
      )}
    </ActionContext.Consumer>
  )
);
