import PaymentTypes from './PaymentTypes';
import { PAGINATION, STORAGE_KEYS } from '../../configs/AppConfig';
import StorageHelper from 'utils/StorageHelper';

const initialState = {
  contentHtml: '',
  redirectUrl: '',
  isPaymentError: false,
  isPaymentSuccess: false,
  isUpdateCardSuccess: false,
  isLoading: true,
  transactionList: [],
  pagination: {
    ...PAGINATION,
    search_key: '',
    totalPages: 0,
    totalRecords: 0
  },
  isCharging: false,
  isLoadingConfirmPayment: false,
  redirectUrlVeritrans: null,
  isRedirectUrlVeritrans: true,
  isUpdatingCard: false
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  const newState = { ...state };
  switch (type) {
    case PaymentTypes.AUTHORIZE_CHARGE_REQUEST:
      newState.isCharging = true;
      return newState;
    case PaymentTypes.AUTHORIZE_CHARGE_SUCCESS:
      newState.contentHtml = payload;
      newState.isPaymentSuccess = true;
      newState.isCharging = false;
      return newState;
    case PaymentTypes.AUTHORIZE_CHARGE_ERROR:
      newState.isPaymentError = true;
      newState.isCharging = false;
      return newState;
    case PaymentTypes.RESET_PAYMENT_ERROR:
      newState.isPaymentError = false;
      return newState;
    case PaymentTypes.RESET_HTML:
      newState.contentHtml = null;
      return newState;
    case PaymentTypes.RESET_PAYMENT_STATUS:
      newState.isUpdateCardSuccess = false;
      newState.isPaymentError = false;
      newState.isPaymentSuccess = false;
      return newState;
    case PaymentTypes.GET_TRANSACTION_SUCCESS:
      newState.transactionList = [...payload.content];
      newState.pagination.totalRecords = payload.totalElements;
      newState.pagination.totalPages = payload.totalPages;
      newState.pagination.size = payload.pageable.pageSize;
      newState.pagination.page = payload.pageable.pageNumber + 1;
      newState.pagination = { ...state.pagination, ...newState.pagination };
      newState.isLoading = false;
      return newState;
    case PaymentTypes.GET_TRANSACTION_ERROR:
      newState.isLoading = false;
      return newState;
    case PaymentTypes.GET_TRANSACTION_REQUEST:
      newState.isLoading = true;
      return newState;
    case PaymentTypes.VERITRANS_CREATE_ORDER_RECEIVE:
      newState.redirectUrlVeritrans = '';
      return newState;
    case PaymentTypes.VERITRANS_CREATE_ORDER_RECEIVE_SUCCESS:
      newState.redirectUrlVeritrans = payload;
      newState.isRedirectUrlVeritrans = true;
      return newState;
    case PaymentTypes.VERITRANS_CREATE_ORDER_RECEIVE_ERROR:
      newState.redirectUrlVeritrans = '';
      newState.isRedirectUrlVeritrans = false;
      return newState;
    case PaymentTypes.VERITRANS_CONFIRM_ORDER:
      newState.isLoadingConfirmPayment = true;
      return newState;
    case PaymentTypes.VERITRANS_CONFIRM_ORDER_SUCCESS:
      newState.isLoadingConfirmPayment = false;
      newState.isPaymentSuccess = true;
      return newState;
    case PaymentTypes.VERITRANS_CONFIRM_ORDER_ERROR:
      newState.isLoadingConfirmPayment = false;
      newState.isPaymentSuccess = false;
      newState.isPaymentError = true;
      return newState;
    case PaymentTypes.SET_CACHE_COUPON_CODE:
      StorageHelper.setSessionItem(STORAGE_KEYS.couponCode, payload);
      return newState;
    case PaymentTypes.SET_CACHE_SUBSCRIPTION_ID:
      StorageHelper.setSessionItem(STORAGE_KEYS.subsId, payload);
      return newState;
    case PaymentTypes.RESET_CREATE_ORDER_RECEIVE_VERITRANS:
      newState.redirectUrlVeritrans = null;
      newState.isRedirectUrlVeritrans = false;
      newState.isPaymentError = false;
      newState.isPaymentSuccess = false;
      // StorageHelper.removeSessionItem(STORAGE_KEYS.subsId);
      // StorageHelper.removeSessionItem(STORAGE_KEYS.couponCode);
      return newState;
  }
  return newState;
}
