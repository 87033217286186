import LayoutTypes from './LayoutTypes';

const initialState = {
  setting: {}
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  const newState = { ...state };
  switch (type) {
    case LayoutTypes.GET_LAYOUT_SETTING_SUCCESS:
      newState.setting = { ...payload };
      return newState;
  }
  return newState;
}
