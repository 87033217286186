import CouponTypes from './CouponTypes';

export function loadCouponList () {
  return {
    type: CouponTypes.GET_LIST_COUPON_REQUEST
  };
}

export function loadCouponDetail (data) {
  return {
    type: CouponTypes.GET_COUPON_DETAIL_REQUEST,
    payload: data
  };
}

export function updateCouponDetail (data) {
  return {
    type: CouponTypes.UPDATE_COUPON_REQUEST,
    payload: data
  };
}

export function addCoupon (data) {
  return {
    type: CouponTypes.ADD_COUPON_REQUEST,
    payload: data
  };
}

export function deleteCoupon (data) {
  return {
    type: CouponTypes.DELETE_COUPON_REQUEST,
    payload: data
  };
}

export function loadCouponPage (data) {
  return {
    type: CouponTypes.GET_COUPON_PAGE_REQUEST,
    payload: data
  };
}

export function loadCouponUser (data) {
  return {
    type: CouponTypes.GET_COUPON_USER_REQUEST,
    payload: data
  };
}

export function setCouponUserNull () {
  return {
    type: CouponTypes.SET_COUPON_USER_NULL
  };
}
