const _prefix = '@manage-hashtag/';

export default {
  DELETE_HASHTAG_REQUEST: _prefix + 'DELETE_HASHTAG_REQUEST',
  DELETE_HASHTAG_SUCCESS: _prefix + 'DELETE_HASHTAG_SUCCESS',
  DELETE_HASHTAG_ERROR: _prefix + 'DELETE_HASHTAG_ERROR',

  // GET_HASHTAG_PAGE_REQUEST: _prefix + 'GET_HASHTAG_PAGE_REQUEST',
  // GET_HASHTAG_PAGE_SUCCESS: _prefix + 'GET_HASHTAG_PAGE_SUCCESS',
  // GET_HASHTAG_PAGE_ERROR: _prefix + 'GET_HASHTAG_PAGE_ERROR',

  // GET_HASHTAG_USER_REQUEST: _prefix + 'GET_HASHTAG_USER_REQUEST',
  // GET_HASHTAG_USER_SUCCESS: _prefix + 'GET_HASHTAG_USER_SUCCESS',
  // GET_HASHTAG_USER_ERROR: _prefix + 'GET_HASHTAG_USER_ERROR',

  // SET_HASHTAG_USER_NULL: _prefix + 'SET_HASHTAG_USER_NULL'

  GET_LIST_HASHTAG_REQUEST: _prefix + 'GET_LIST_HASHTAG_REQUEST',
  GET_LIST_HASHTAG_SUCCESS: _prefix + 'GET_LIST_HASHTAG_SUCCESS',
  GET_LIST_HASHTAG_ERROR: _prefix + 'GET_LIST_HASHTAG_ERROR',

  GET_SEARCH_BOX_HASHTAG_REQUEST: _prefix + 'GET_SEARCH_BOX_HASHTAG_REQUEST',
  GET_SEARCH_BOX_HASHTAG_SUCCESS: _prefix + 'GET_SEARCH_BOX_HASHTAG_SUCCESS',
  GET_SEARCH_BOX_HASHTAG_ERROR: _prefix + 'GET_SEARCH_BOX_HASHTAG_ERROR',

  ADD_HASHTAG_REQUEST: _prefix + 'ADD_HASHTAG_REQUEST',
  ADD_HASHTAG_SUCCESS: _prefix + 'ADD_HASHTAG_SUCCESS',
  ADD_HASHTAG_ERROR: _prefix + 'ADD_HASHTAG_ERROR',

  UPDATE_HASHTAG_REQUEST: _prefix + 'UPDATE_HASHTAG_REQUEST',
  UPDATE_HASHTAG_SUCCESS: _prefix + 'UPDATE_HASHTAG_SUCCESS',
  UPDATE_HASHTAG_ERROR: _prefix + 'UPDATE_HASHTAG_ERROR',

  UPDATE_SEARCH_BOX_HASHTAG_REQUEST: _prefix + 'UPDATE_SEARCH_BOX_HASHTAG_REQUEST',
  UPDATE_SEARCH_BOX_HASHTAG_SUCCESS: _prefix + 'UPDATE_SEARCH_BOX_HASHTAG_SUCCESS',
  UPDATE_SEARCH_BOX_HASHTAG_ERROR: _prefix + 'UPDATE_SEARCH_BOX_HASHTAG_ERROR',

  UPDATE_MULTIPLE_SELECT_HASH_TAG_REQUEST: _prefix + 'UPDATE_MULTIPLE_SELECT_HASH_TAG_REQUEST',
  UPDATE_MULTIPLE_SELECT_HASH_TAG_SUCCESS: _prefix + 'UPDATE_MULTIPLE_SELECT_HASH_TAG_SUCCESS',
  UPDATE_MULTIPLE_SELECT_HASH_TAG_ERROR: _prefix + 'UPDATE_MULTIPLE_SELECT_HASH_TAG_ERROR',

  GET_LIST_HASHTAG_USER_REQUEST: _prefix + 'GET_LIST_HASHTAG_USER_REQUEST',
  GET_LIST_HASHTAG_USER_SUCCESS: _prefix + 'GET_LIST_HASHTAG_USER_SUCCESS',
  GET_LIST_HASHTAG_USER_ERROR: _prefix + 'GET_LIST_HASHTAG_USER_ERROR',

  GET_HASHTAG_SETTING_REQUEST: _prefix + 'GET_HASHTAG_SETTING_REQUEST',
  GET_HASHTAG_SETTING_SUCCESS: _prefix + 'GET_HASHTAG_SETTING_SUCCESS',
  GET_HASHTAG_SETTING_ERROR: _prefix + 'GET_HASHTAG_SETTING_ERROR',

  UPDATE_HASHTAG_SETTING_REQUEST: _prefix + 'UPDATE_HASHTAG_SETTING_REQUEST',
  UPDATE_HASHTAG_SETTING_SUCCESS: _prefix + 'UPDATE_HASHTAG_SETTING_SUCCESS',
  UPDATE_HASHTAG_SETTING_ERROR: _prefix + 'UPDATE_HASHTAG_SETTING_ERROR'

};
