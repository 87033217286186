import { all, put, takeEvery } from 'redux-saga/effects';
import TemplateTypes from './TemplateTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/template';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';

// Header management

function * loadHeaderConfiguration () {
  yield takeEvery(TemplateTypes.GET_HEADER_REQUEST, _loadHeaderConfiguration);
}
function * _loadHeaderConfiguration ({ payload }) {
  yield createRequest({
    api: { ...API.GET_HEADER_SETTING },
    onSuccess: function * (response) {
      yield put({
        type: TemplateTypes.GET_HEADER_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}
function * updateHeader () {
  yield takeEvery(TemplateTypes.UPDATE_HEADER_REQUEST, _updateHeader);
}
function * _updateHeader ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.UPDATE_HEADER,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: TemplateTypes.GET_HEADER_REQUEST,
        payload: {}
      });
    }
  });
}

// Body management

function * loadBodyConfiguration () {
  yield takeEvery(TemplateTypes.GET_BODY_REQUEST, _loadBodyConfiguration);
}
function * _loadBodyConfiguration ({ payload }) {
  yield createRequest({
    api: { ...API.GET_BODY_SETTING },
    onSuccess: function * (response) {
      yield put({
        type: TemplateTypes.GET_BODY_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}
function * updateBody () {
  yield takeEvery(TemplateTypes.UPDATE_BODY_REQUEST, _updateBody);
}
function * _updateBody ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.UPDATE_BODY,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: TemplateTypes.GET_BODY_REQUEST,
        payload: {}
      });
    }
  });
}

// Footer management

function * loadFooterConfiguration () {
  yield takeEvery(TemplateTypes.GET_FOOTER_REQUEST, _loadFooterConfiguration);
}
function * _loadFooterConfiguration ({ payload }) {
  yield createRequest({
    api: { ...API.GET_FOOTER_SETTING },
    onSuccess: function * (response) {
      yield put({
        type: TemplateTypes.GET_FOOTER_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}
function * updateFooter () {
  yield takeEvery(TemplateTypes.UPDATE_FOOTER_REQUEST, _updateFooter);
}
function * _updateFooter ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.UPDATE_FOOTER,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: TemplateTypes.GET_FOOTER_REQUEST,
        payload: {}
      });
    }
  });
}

// Footer management

function * loadLinkDetail () {
  yield takeEvery(TemplateTypes.GET_LINK_REQUEST, _loadLinkDetail);
}
function * _loadLinkDetail ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_LINK_DETAIL,
      url: API.GET_LINK_DETAIL.url + payload
    },
    onSuccess: function * (response) {
      yield put({
        type: TemplateTypes.GET_LINK_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}
function * createLink () {
  yield takeEvery(TemplateTypes.CREATE_LINK_REQUEST, _createLink);
}
function * _createLink ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.CREATE_LINK,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: TemplateTypes.GET_FOOTER_REQUEST,
        payload: {}
      });
    }
  });
}
function * updateLink () {
  yield takeEvery(TemplateTypes.UPDATE_LINK_REQUEST, _updateLink);
}
function * _updateLink ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.UPDATE_LINK,
      url: API.UPDATE_LINK.url + payload.id,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: TemplateTypes.GET_FOOTER_REQUEST,
        payload: {}
      });
    }
  });
}
function * deleteLink () {
  yield takeEvery(TemplateTypes.DELETE_LINK_REQUEST, _deleteLink);
}
function * _deleteLink ({ payload }) {
  yield createRequest({
    api: {
      ...API.DELETE_LINK,
      url: API.DELETE_LINK.url + payload
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.delete.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: TemplateTypes.GET_FOOTER_REQUEST,
        payload: {}
      });
    }
  });
}

export default function * buttonSaga () {
  yield all([
    loadHeaderConfiguration(),
    updateHeader(),
    loadBodyConfiguration(),
    updateBody(),
    loadFooterConfiguration(),
    updateFooter(),
    loadLinkDetail(),
    createLink(),
    updateLink(),
    deleteLink()
  ]);
}
