const _prefix = '@manage-coupon/';

export default {
  GET_LIST_COUPON_REQUEST: _prefix + 'GET_LIST_COUPON_REQUEST',
  GET_LIST_COUPON_SUCCESS: _prefix + 'GET_LIST_COUPON_SUCCESS',
  GET_LIST_COUPON_ERROR: _prefix + 'GET_LIST_COUPON_ERROR',

  GET_COUPON_DETAIL_REQUEST: _prefix + 'GET_COUPON_DETAIL_REQUEST',
  GET_COUPON_DETAIL_SUCCESS: _prefix + 'GET_COUPON_DETAIL_SUCCESS',
  GET_COUPON_DETAIL_ERROR: _prefix + 'GET_COUPON_DETAIL_ERROR',

  UPDATE_COUPON_REQUEST: _prefix + 'UPDATE_COUPONL_REQUEST',
  UPDATE_COUPON_SUCCESS: _prefix + 'UPDATE_COUPON_SUCCESS',
  UPDATE_COUPON_ERROR: _prefix + 'UPDATE_COUPON_ERROR',

  DELETE_COUPON_REQUEST: _prefix + 'DELETE_COUPON_REQUEST',
  DELETE_COUPON_SUCCESS: _prefix + 'DELETE_COUPON_SUCCESS',
  DELETE_COUPON_ERROR: _prefix + 'DELETE_COUPON_ERROR',

  ADD_COUPON_REQUEST: _prefix + 'ADD_COUPON_REQUEST',
  ADD_COUPON_SUCCESS: _prefix + 'ADD_COUPON_SUCCESS',
  ADD_COUPON_ERROR: _prefix + 'ADD_COUPON_ERROR',

  GET_COUPON_PAGE_REQUEST: _prefix + 'GET_COUPON_PAGE_REQUEST',
  GET_COUPON_PAGE_SUCCESS: _prefix + 'GET_COUPON_PAGE_SUCCESS',
  GET_COUPON_PAGE_ERROR: _prefix + 'GET_COUPON_PAGE_ERROR',

  GET_COUPON_USER_REQUEST: _prefix + 'GET_COUPON_USER_REQUEST',
  GET_COUPON_USER_SUCCESS: _prefix + 'GET_COUPON_USER_SUCCESS',
  GET_COUPON_USER_ERROR: _prefix + 'GET_COUPON_USER_ERROR',

  SET_COUPON_USER_NULL: _prefix + 'SET_COUPON_USER_NULL'
};
