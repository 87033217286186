import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

const mapStateToProps = (state) => {
  const {
    locale: { locale, messages }
  } = state;
  return { locale, messages };
};

export default connect(mapStateToProps)(
  props => (
    <IntlProvider
      {...props}
      onError={() => console.log()}
    />
  )
);
