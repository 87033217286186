export default {
  GET_COMPANY_LIST: {
    url: '/company/page',
    method: 'GET'
  },
  GET_COMPANY_DETAIL: {
    url: '/company/detail/',
    method: 'GET'
  },
  CREATE_COMPANY: {
    url: '/company/add',
    method: 'POST'
  },
  UPDATE_COMPANY: {
    url: '/company/update/',
    method: 'PUT'
  },
  DELETE_COMPANY: {
    url: '/company/delete/',
    method: 'DELETE'
  },
  UPLOAD_LOGO: {
    url: '/company/upload-logo',
    method: 'POST'
  },
  UPLOAD_FAVICON: {
    url: '/company/upload-favicon',
    method: 'POST'
  },
  UPDATE_COMPANY_TERM: {
    url: '/company/update-term',
    method: 'PUT'
  },
  UPDATE_PAGE_TITLE: {
    url: '/company/update-page-title',
    method: 'PUT'
  },
  GET_END_USER_COMPANY_DETAIL: {
    url: '/company/public/get-detail',
    method: 'GET'
  },
  GET_SETTING: {
    url: '/company/public/view-settings',
    method: 'GET'
  },
  VERIFY_PAYMENT_VERITRANS: {
    url: '/company/check-api-setting-veritrans',
    method: 'POST'
  },
  UPDATE_PAYMENT_VERITRANS: {
    url: '/company/update-payment',
    method: 'PUT'
  }
};
