const _prefix = '@search-result/';

export default {
  GET_BUTTON_REQUEST: _prefix + 'GET_BUTTON_REQUEST',
  GET_BUTTON_SUCCESS: _prefix + 'GET_BUTTON_SUCCESS',

  UPDATE_BUTTON_REQUEST: _prefix + 'UPDATE_BUTTON_REQUEST',
  CREATE_BUTTON_REQUEST: _prefix + 'CREATE_BUTTON_REQUEST',
  DELETE_BUTTON_REQUEST: _prefix + 'DELETE_BUTTON_REQUEST'
};
