export default {
  GET_EMAIL_TEMPLATE: {
    url: '/custom-sent-email',
    method: 'GET'
  },
  UPDATE_EMAIL_TEMPLATE: {
    url: '/custom-sent-email',
    method: 'PUT'
  },
  RESET_EMAIL_TEMPLATE: {
    url: '/custom-sent-email/reset',
    method: 'PUT'
  }
};
