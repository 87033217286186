import { PATTERNS } from '../configs/AppConfig';
import * as Yup from 'yup';
import Util from './Util';

const _checkPattern = (value, pattern, message) => {
  return !!value && !!pattern && !pattern.test(value) ? message : undefined;
};

export const required = value => (value ? undefined : 'required');

export const email = value => _checkPattern(value, PATTERNS.EMAIL_PATTERN, 'invalid');

export const positiveNumberMoreThan1 = value => {
  return (value < 1) ? 'moreThanZero' : undefined;
};

export const password = value => {
  if (!PATTERNS.PASSWORD_PATTERN.test(value)) {
    return 'minLength';
  } else if (!PATTERNS.PASSWORD_UPPER.test(value)) {
    return 'uppercase';
  } else if (!PATTERNS.PASSWORD_LOWER.test(value)) {
    return 'lowercase';
  } else if (!PATTERNS.PASSWORD_NUMBER.test(value)) {
    return 'number';
  }
  return undefined;
};

export const domain = value => _checkPattern(value, PATTERNS.DOMAIN_PATTERN, 'invalid');

export const ip = value => _checkPattern(value, PATTERNS.IP_PATTERN, 'invalid');

export const fileSize = imageFile => {
  if (imageFile && imageFile.size) {
    // Get image size in kilobytes
    const imageFileKb = imageFile.size / 1024;
    const maxSize = 2048;
    if (imageFileKb > maxSize) {
      return 'maxSize';
    }
  }
};

export const confirmPassword = (value, { newPassword, password }) => {
  if (newPassword) {
    return value !== newPassword ? 'mismatch' : undefined;
  } else {
    return value !== password ? 'mismatch' : undefined;
  }
};

export const endDate = (endDate, { startDate }) => !startDate || !endDate ? undefined : new Date(endDate) > new Date(startDate) ? undefined : 'invalid';

export const YupValidations = {
  password: Yup.string()
    .required(Util.translate('validate.required'))
    .test('passwordPolicy', Util.translate('validate.password.spaces'), (value) => {
      if (value && value.includes(' ')) {
        return false;
      } else {
        return true;
      }
    })
    .matches(PATTERNS.PASSWORD_PATTERN, {
      message: Util.translate('validate.password.minLength')
    })
    .max(32, Util.translate('validate.max_length_32'))
    .matches(PATTERNS.PASSWORD_UPPER, {
      message: Util.translate('validate.password.uppercase')
    })
    .matches(PATTERNS.PASSWORD_LOWER, {
      message: Util.translate('validate.password.lowercase')
    })
    .matches(PATTERNS.PASSWORD_NUMBER, {
      message: Util.translate('validate.password.number')
    }),
  weakPassword: Yup.string()
    .required(Util.translate('validate.required'))
    .min(4, Util.translate('validate.min_length_4'))
    .max(16, Util.translate('validate.max_length_16'))
    .test('passwordPolicy', Util.translate('validate.password.spaces'), (value) => {
      if (value && value.includes(' ')) {
        return false;
      } else {
        return true;
      }
    }),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), Yup.ref('newPassword')], Util.translate('validate.confirmPassword.mismatch'))
    .required(Util.translate('validate.required'))
    .test('passwordPolicy', Util.translate('validate.password.spaces'), (value) => {
      if (value && value.includes(' ')) {
        return false;
      } else {
        return true;
      }
    }),
  ipAddress: Yup.string().trim()
    .required(Util.translate('validate.required'))
    .test('subnet', Util.translate('validate.subnet'), (ipAddress) => {
      const subnetMask = ipAddress && ipAddress.split('/')[1];
      if (subnetMask < 24 || subnetMask > 32) {
        return false;
      } else {
        return true;
      }
    })
    .test('ipAddress', Util.translate('validate.ip.invalid'), (ipAddress) => {
      const ip = ipAddress && ipAddress.split('/')[0];
      return PATTERNS.IP_PATTERN.test(ip);
    }),
  email: Yup.string().trim()
    .max(255, Util.translate('validate.max_length_255'))
    .required(Util.translate('validate.required'))
    .matches(PATTERNS.EMAIL_PATTERN, {
      message: Util.translate('validate.email.invalid')
    }).matches(PATTERNS.BLANK_SPACES, {
      message: Util.translate('validate.blankSpaces')
    }),
  username: Yup.string().trim()
    .max(64, Util.translate('validate.max_length_64'))
    .min(6, Util.translate('validate.min_length_6'))
    .required(Util.translate('validate.required'))
    .matches(PATTERNS.USERNAME_PATTERN, {
      message: Util.translate('validate.username.invalid')
    })
    .matches(PATTERNS.BLANK_SPACES, {
      message: Util.translate('validate.blankSpaces')
    }),
  userGroupName: Yup.string()
    .required(Util.translate('validate.required'))
    .matches(PATTERNS.BLANK_SPACES, {
      message: Util.translate('validate.blankSpaces')
    })
    .max(64, Util.translate('validate.max_length_64')),
  emailOrUsername: Yup.string()
    .required(Util.translate('validate.required'))
    .min(6, Util.translate('validate.min_length_6'))
    .max(255, Util.translate('validate.max_length_255'))
    .matches(PATTERNS.EMAIL_OR_USERNAME_PATTERN, {
      message: Util.translate('validate.emailOrUsername.invalid')
    })
    .matches(PATTERNS.BLANK_SPACES, {
      message: Util.translate('validate.blankSpaces')
    })

};
