import CompanyTypes from './CompanyTypes';
import StorageHelper from '../../utils/StorageHelper';
import { STORAGE_KEYS, PAGINATION } from '../../configs/AppConfig';

const initialState = {
  companies: [],
  isSaving: false,
  isChecking: false,
  total: 0,
  totalPages: 0,
  queries: {
    ...PAGINATION,
    search_key: '',
    sort_field: '',
    sort: false
  },
  selected: [],
  companyDetail: {
    name: '',
    email: '',
    domain: '',
    maxContent: 1,
    maxUser: 1,
    ownerId: '0',
    settingLogin: '',
    apiKey: '',
    beLoginName: '',
    bePassword: '',
    beViewerHost: '',
    beWebsiteHost: '',
    emailSender: ''
  },
  companyDomain: '',
  endUserCompanyDetail: {},
  companyUserDetail: {
    email: '',
    fullName: ''
  },
  strictPassword: true,
  optionImportUser: 1,
  pageLogo: '',
  pageFavicon: '',
  companyName: '',
  viewSettings: null,
  googleOption: 'NONE',
  googleId: '',
  googleAutoLoad: null,
  accountType: '',
  titleOfPasswordField: '',
  titleOfEmailField: '',
  allowForgotPassword: true,
  allowLoginByIpAddress: false,
  isLoadSetting: true,
  paymentSetting: {},
  showSubscriptionStatus: false,
  showLinkRegisterUser: false
};

export default function companyReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  switch (type) {
    case CompanyTypes.UPDATE_PAYMENT_VERITRANS_REQUEST:
      newState.isSaving = true;
      return newState;
    case CompanyTypes.UPDATE_PAYMENT_VERITRANS_SUCCESS:
      newState.isSaving = false;
      return newState;
    case CompanyTypes.UPDATE_PAYMENT_VERITRANS_ERROR:
      newState.isSaving = false;
      return newState;
    case CompanyTypes.VERIFY_PAYMENT_VERITRANS_REQUEST:
      newState.isChecking = true;
      return newState;
    case CompanyTypes.VERIFY_PAYMENT_VERITRANS_SUCCESS:
      newState.isChecking = false;
      return newState;
    case CompanyTypes.VERIFY_PAYMENT_VERITRANS_ERROR:
      newState.isChecking = false;
      return newState;
    case CompanyTypes.GET_COMPANIES_SUCCESS:
      newState.companies = [...payload.content];
      newState.totalPages = payload.totalPages;
      newState.total = payload.totalElements;
      return newState;
    case CompanyTypes.UPDATE_COMPANIES_QUERIES:
      newState.queries.page = payload.page;
      newState.queries.size = payload.size;
      newState.queries.search_key = payload.search_key;
      return newState;
    case CompanyTypes.UPDATE_SELECTED: {
      const data = { ...payload };
      const index = newState.selected.findIndex(x => x.id === data.selected.id);
      if (data.isSelected) {
        if (index === -1) {
          newState.selected = [...newState.selected, data.selected];
        }
      } else {
        if (index !== -1) {
          newState.selected.splice(index, 1);
          newState.selected = [...newState.selected];
        }
      }
      return newState;
    }
    case CompanyTypes.UPDATE_SELECTED_ALL: {
      const data = { ...payload };
      if (data.isSelected) {
        const index = newState.selected.findIndex(x => x.id === data.selected.id);
        if (index === -1) {
          for (let i = 0; i < data.selected.length; i++) {
            newState.selected = [...newState.selected, data.selected[i]];
          }
        }
      } else {
        for (let j = 0; j < data.selected.length; j++) {
          const index = newState.selected.findIndex(x => x.id === data.selected[j].id);
          if (index !== -1) {
            newState.selected.splice(index, 1);
            newState.selected = [...newState.selected];
          }
        }
      }
      return newState;
    }
    case CompanyTypes.GET_COMPANY_DETAIL_REQUEST:
      newState.companyDetail = {};
      return newState;
    case CompanyTypes.GET_COMPANY_DETAIL_SUCCESS:
      newState.companyDetail = { ...payload.company };
      newState.companyUserDetail = { ...payload.user };
      newState.paymentSetting = { ...payload.paymentSetting };
      return newState;
    case CompanyTypes.SET_COMPANY_DOMAIN:
      newState.companyDomain = payload;
      StorageHelper.setSessionItem(STORAGE_KEYS.domain, payload);
      return newState;
    case CompanyTypes.GET_END_USER_COMPANY_DETAIL_SUCCESS:
      newState.endUserCompanyDetail = { ...payload };
      return newState;
    case CompanyTypes.GET_SETTING_REQUEST:
      newState.isLoadSetting = true;
      return newState;
    case CompanyTypes.GET_SETTING_ERROR:
      newState.isLoadSetting = false;
      return newState;
    case CompanyTypes.GET_SETTING_SUCCESS:
      newState.isLoadSetting = false;
      newState.accountType = payload.accountType;
      newState.optionImportUser = payload.optionImportUser;
      newState.strictPassword = payload.strictPassword;

      newState.googleOption = payload.googleOption;
      newState.googleId = payload.googleId;
      newState.googleAutoLoad = payload.googleAutoLoad;

      newState.pageLogo = payload.logo;
      newState.pageFavicon = payload.favicon;
      newState.companyName = payload.companyName;

      newState.allowLoginByIpAddress = payload.loginByIpAddress;

      newState.titleOfEmailField = payload.titleOfEmailField;
      newState.titleOfPasswordField = payload.titleOfPasswordField;

      newState.viewSettings = payload;

      newState.allowForgotPassword = payload.isActiveForgotPassword;

      newState.showSubscriptionStatus = payload.showSubscriptionStatus;

      newState.showLinkRegisterUser = payload.showLinkRegisterUser;
      return newState;
    default:
      newState = state;
      break;
  }
  // return new state
  return newState;
}
