export default {
  GET_LIST_SORT: {
    url: '/sort-field/public',
    method: 'GET'
  },
  GET_ALL_SORT: {
    url: '/sort-field/public',
    method: 'GET'
  },
  ADD_SORT: {
    url: '/sort-field/add',
    method: 'POST'
  },
  GET_SORT_DETAIL: {
    url: '/sort-field/detail/',
    method: 'GET'
  },
  UPDATE_SORT: {
    url: '/sort-field/update/',
    method: 'PUT'
  },
  UPDATE_SORT_ORDINAL: {
    url: '/sort-field/update',
    method: 'PUT'
  },
  DELETE_SORT: {
    url: '/sort-field/delete/',
    method: 'DELETE'
  },
  RESET_SORT: {
    url: '/sort-field/reset-sort-field',
    method: 'PUT'
  }
};
