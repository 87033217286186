import UploadTypes from './UploadTypes';

export function loadUploadedFiles (data) {
  return {
    type: UploadTypes.GET_UPLOADED_FILES_REQUEST,
    payload: data
  };
}

export function uploadFile (data) {
  return {
    type: UploadTypes.UPLOAD_FILE_REQUEST,
    payload: data
  };
}

export function importCSV (data) {
  return {
    type: UploadTypes.IMPORT_CSV_REQUEST,
    payload: data
  };
}

export function checkImportProgress (data) {
  return {
    type: UploadTypes.CHECK_IMPORT_PROGRESS_REQUEST,
    payload: data
  };
}

export function downloadCSVTemplate (data) {
  return {
    type: UploadTypes.DOWNLOAD_CSV_TEMPLATE,
    payload: data
  };
}
