import React, { useEffect, useState } from 'react';
import {
  Button, FormGroup
} from 'reactstrap';
import TranslateMessage from '../../../components/common/TranslateMessage/TranslateMessage';
import { connect } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { AUTHEN_TYPES, GOOGLE_OPTION, OPTION_LOGIN } from '../../../configs/AppConfig';
import { ActionContext } from '../../../components/layout/MainContainer';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Util from '../../../utils/Util';
import * as Yup from 'yup';
import InputFieldButtonRight from 'components/common/Input/InputFieldButtonRight';
import { makeStyles } from '@material-ui/core';
import {
  getAllowLoginByIpAddress,
  getTitleOfEmailField,
  getTitleOfPasswordField
} from 'state/search/searchSelector';
import { getAccountType, getAllowForgotPassword, getGoogleId, getGoogleOption } from 'state/company/companySelector';
import GoogleLoginButton from 'components/common/Button/GoogleLoginButton';

const useStyles = makeStyles(theme => ({
  form: {
    maxWidth: '400px',
    margin: 'auto',
    '& input': {
      minHeight: 'unset!important',
      fontSize: '1rem',
      borderColor: '#ced4da',
      flex: 'none'
    },
    '& input:focus': {
      borderColor: '#80bdff',
      outline: 0,
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)!important'
    },
    '& input:hover': {
      borderColor: '#ced4da'
    },
    logo: {
      marginRight: '10px',
      width: '20px',
      height: '20px'
    }
  }
}));

const LoginEndUserForm = props => {
  const {
    onSubmit,
    accountType,
    allowLoginByIpAddress,
    titleOfEmailField,
    titleOfPasswordField,
    googleOption,
    googleId,
    isShowViewBook,
    contentDetailId,
    searchRequest,
    activeTab,
    selectedContentGroup,
    isActiveForgotPassword
  } = props;

  const classes = useStyles();
  const hideForgotPassword = !isActiveForgotPassword;
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(Util.translate('validate.required')),
    password: Yup.string().required(Util.translate('validate.required'))
  });

  const { formatMessage: f } = useIntl();
  const [OAuth2, setOAuth2] = useState();

  const submit = data => {
    const request = data;
    onSubmit(request, AUTHEN_TYPES.EMAIL);
  };

  const doIpLogin = async () => {
    const userTrace = await Util.getUserTrace();
    const ip = userTrace.ip;
    const request = {
      ip: ip,
      loginType: AUTHEN_TYPES.IP
    };
    onSubmit(request, AUTHEN_TYPES.IP);
  };

  useEffect(() => {
    /* global google */
    if (window.google && window.location.origin && googleOption !== GOOGLE_OPTION.NONE && googleId) {
      const oAuth2 = google.accounts.oauth2.initCodeClient({
        client_id: googleId,
        scope: 'profile email',
        ux_mode: 'redirect',
        select_account: false,
        prompt: '',
        redirect_uri: `${window.location.origin}/login/`,
        state: '',
        response_type: 'code',
        flow_name: 'GeneralOAuthFlow',
        grant_type: 'authorization_code'
      });
      setOAuth2(oAuth2);
    }
  }, [googleId]);

  const doGoogleLogin = (data) => {
    onSubmit(data, AUTHEN_TYPES.GOOGLE_LOGIN);
  };

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{
        email: '',
        password: '',
        rememberMe: false
      }}
      onSubmit={submit}
      validationSchema={validationSchema}
    >
      {formikProps => {
        return (
          <Form className={classes.form} autoComplete="off" aria-autocomplete="none">
            <div className="row">
              <div className="col-sm-12">
                <Field
                  component={InputFieldButtonRight}
                  name="email"
                  label={titleOfEmailField && titleOfEmailField !== ''
                    ? titleOfEmailField
                    : f({ id: accountType === OPTION_LOGIN.ALL ? 'label.emailOrUsername' : accountType === OPTION_LOGIN.EMAIL ? 'label.email' : 'label.username' })
                  }
                  placeholder={titleOfEmailField && titleOfEmailField !== ''
                    ? titleOfEmailField
                    : f({ id: accountType === OPTION_LOGIN.ALL ? 'label.emailOrUsername' : accountType === OPTION_LOGIN.EMAIL ? 'label.email' : 'label.username' })
                  }
                />
              </div>
              <div className="col-sm-12">
                <Field
                  component={InputFieldButtonRight}
                  name="password"
                  label={titleOfPasswordField && titleOfPasswordField !== ''
                    ? titleOfPasswordField
                    : f({ id: 'label.password' })
                  }
                  placeholder={titleOfPasswordField && titleOfPasswordField !== ''
                    ? titleOfPasswordField
                    : f({ id: 'label.password' })
                  }
                  type="password"
                />
              </div>
              <div className="col-sm-12">
                <FormGroup>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    block
                  >
                    <TranslateMessage id="menu.login" />
                  </Button>
                </FormGroup>
              </div>
              {
                allowLoginByIpAddress && (
                  <React.Fragment>
                    <div className="col-sm-12 ">
                      <hr data-content={f({ id: 'conjunction.or' }).toUpperCase()} className="hr-text" />
                    </div>
                    <div className="col-sm-12">
                      <FormGroup>
                        <Button
                          variant="contained"
                          color="secondary"
                          type="button"
                          block
                          onClick={doIpLogin}
                        >
                          <TranslateMessage id="menu.loginIp" />
                        </Button>
                      </FormGroup>
                    </div>
                  </React.Fragment>
                )
              }
              {googleId && googleOption !== GOOGLE_OPTION.NONE && OAuth2 &&
                <React.Fragment>
                  <div className="col-sm-12">
                    <FormGroup>
                      <GoogleLoginButton
                        onSubmit={doGoogleLogin}
                        isShowViewBook={isShowViewBook}
                        contentDetailId={contentDetailId}
                        searchRequest={searchRequest}
                        activeTab={activeTab}
                        selectedContentGroup={selectedContentGroup}
                        oAuth2={OAuth2}
                        googleId={googleId}
                      />
                    </FormGroup>
                  </div>
                </React.Fragment>
              }
              {
                !hideForgotPassword && (
                  <div className="col-sm-12 text-center mb-3">
                    <Link to="/forget-password">
                      <TranslateMessage id="label.forgotPassword" />
                    </Link>
                  </div>
                )
              }
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

LoginEndUserForm.propTypes = {
  onSubmit: PropTypes.func,
  allowLoginByIpAddress: PropTypes.bool,
  titleOfEmailField: PropTypes.string,
  titleOfPasswordField: PropTypes.string,
  googleOption: PropTypes.string,
  googleId: PropTypes.any,
  isShowViewBook: PropTypes.bool,
  contentDetailId: PropTypes.any,
  searchRequest: PropTypes.any,
  activeTab: PropTypes.any,
  selectedContentGroup: PropTypes.any,
  accountType: PropTypes.any,
  isActiveForgotPassword: PropTypes.bool
};
const mapStateToProps = (state) => {
  return {
    allowLoginByIpAddress: getAllowLoginByIpAddress(state),
    titleOfEmailField: getTitleOfEmailField(state),
    titleOfPasswordField: getTitleOfPasswordField(state),
    googleOption: getGoogleOption(state),
    googleId: getGoogleId(state),
    accountType: getAccountType(state),
    isActiveForgotPassword: getAllowForgotPassword(state)
  };
};

export default connect(mapStateToProps)(
  props => (
    <ActionContext.Consumer>
      {() => (
        <LoginEndUserForm {...props} />
      )}
    </ActionContext.Consumer>
  ));
