import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { NoMatchPage } from 'configs/routes';
import { ActionContext, SessionContext, LayoutContext, RouterContext } from './MainContainer';
import { IMAGE_PATH, OPTION_LOGIN, USER_CONFIG } from 'configs/AppConfig';

import { getMenuRoutes } from 'state/route/routeSelector';
import { connect, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/styles';
import { getPageFavicon, getViewSettings, getIsLoadSetting } from 'state/company/companySelector';
import { Spinner } from 'reactstrap';
import { Grid } from '@material-ui/core';
import TranslateMessage from 'components/common/TranslateMessage/TranslateMessage';
import { isSessionValidating } from 'state/session/sessionSelector';

const ViewOnlineLayout = props => {
  const {
    pageFavicon,
    children,
    session: { isAuthenticated, user = {} },
    actions: {
      sessionActions: { cacheRoutePath }
    }
  } = props;
  const theme = useTheme();
  const themeWidth = theme.breakpoints.values;
  const [drawerValue, setDrawerValue] = useState('normal');

  const isLoadSetting = useSelector(getIsLoadSetting);
  const isValidatingSession = useSelector(isSessionValidating);
  const viewSettings = useSelector(getViewSettings) || {};
  const isOpenType = viewSettings.accountType === OPTION_LOGIN.OPEN_TYPE;

  const urlUpload = process.env.REACT_APP_UPLOAD_PATH + '/';

  useEffect(() => {
    // Set Favicon
    if (pageFavicon) {
      const favicon = document.getElementById('favicon');
      favicon.href = `${urlUpload}${IMAGE_PATH.FAVICON}${pageFavicon}`;
      const faviconApple = document.getElementById('appleTouchIcon');
      faviconApple.href = `${urlUpload}${IMAGE_PATH.FAVICON}${pageFavicon}`;
    }
  }, [pageFavicon]);
  /**
   * Watch layout changing
   */
  useLayoutEffect(() => {
    /**
     * Update drawer size
     * Func updateSize
     */
    function updateSize () {
      const width = window.innerWidth;
      if (width < themeWidth.md) {
        setDrawerValue('mobile');
      } else if (width < themeWidth.lg) {
        setDrawerValue('mini');
      } else {
        setDrawerValue('normal');
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    // Clear event listener on page will destroyed
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      cacheRoutePath();
    }
  }, []);

  useEffect(() => {
  }, [drawerValue]);

  if (isLoadSetting || !viewSettings || isValidatingSession) {
    return (
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh', backgroundColor: '#FFF' }}
      >
        <Spinner color="primary" style={{ marginRight: 20 }} /> <TranslateMessage id="progress.loading" />
      </Grid>);
  } else {
    if (isAuthenticated && user && (user.roleLevel === USER_CONFIG.SYSTEM_ADMIN.roleLevel || user.roleLevel === USER_CONFIG.COMPANY_ADMIN.roleLevel)) {
      return <NoMatchPage/>;
    }
    if (isAuthenticated || isOpenType) {
      return children;
    } else {
      return <Redirect to={'/login'} push />;
    }
  }
};

ViewOnlineLayout.propTypes = {
  session: PropTypes.any,
  layout: PropTypes.any,
  actions: PropTypes.any,
  children: PropTypes.any,
  router: PropTypes.any,
  pageFavicon: PropTypes.any
};

const mapStateToProps = (state) => {
  return {
    menuRoutes: getMenuRoutes(state),
    pageFavicon: getPageFavicon(state)
  };
};

export default connect(mapStateToProps)(props => (
  <RouterContext.Consumer>
    {router => (
      <ActionContext.Consumer>
        {actions => (
          <SessionContext.Consumer>
            {session => (
              <LayoutContext.Consumer>
                {layout => (
                  <ViewOnlineLayout
                    {...props}
                    session={session}
                    router={router}
                    layout={layout}
                    actions={actions}
                  />
                )}
              </LayoutContext.Consumer>
            )}
          </SessionContext.Consumer>
        )}
      </ActionContext.Consumer>
    )}
  </RouterContext.Consumer>
));
