export default {
  GET_COUPON_LIST: {
    url: '/manage-coupon',
    method: 'GET'
  },
  GET_COUPON_DETAIL: {
    url: '/manage-coupon/',
    method: 'GET'
  },
  UPDATE_COUPON_DETAIL: {
    url: '/manage-coupon/',
    method: 'PUT'
  },
  ADD_COUPON: {
    url: '/manage-coupon/add',
    method: 'POST'
  },
  GET_COUPON_PAGE: {
    url: '/manage-coupon/page',
    method: 'GET'
  },
  GET_MANAGE_COUPON_USER: {
    url: '/manage-coupon/user/get',
    method: 'GET'
  },
  DELETE_COUPON: {
    url: '/manage-coupon/',
    method: 'DELETE'
  }
};
