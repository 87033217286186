import React from 'react';
import ActionButton from '../common/Button/ActionButton';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getViewSettings } from 'state/company/companySelector';
import { OPTION_LOGIN } from 'configs/AppConfig';

const NoMatchStyle = {
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 9999,
    backgroundColor: '#FFFFFF'
  },
  error: {
    fontSize: 180,
    textAlign: 'center'
  }
};

const NoMatch = () => {
  const history = useHistory();
  const viewSettings = useSelector(getViewSettings) || {};
  const isOpenType = viewSettings.accountType === OPTION_LOGIN.OPEN_TYPE;

  const goBack = () => {
    history && history.goBack();
  };

  return (
    <div
      style={NoMatchStyle.root}
      className="d-flex flex-column align-content-center justify-content-center text-center pb-md-5"
    >
      <div className="pb-5">
        <h1 style={NoMatchStyle.error}>404</h1>
        <h3 className="d-none d-sm-block">SO SORRY, PAGE NOT FOUND</h3>
        <div className="d-sm-none">
          <h3>SO SORRY</h3>
          <h3>PAGE NOT FOUND</h3>
        </div>
        {
          isOpenType
            ? null
            : <ActionButton type="primary" onClick={goBack}>
          Go back
            </ActionButton>
        }
      </div>
    </div>
  );
};

export default NoMatch;
