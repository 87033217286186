import { all, put, takeEvery } from 'redux-saga/effects';
import WatermarkTypes from './WatermarkTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/watermark';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';

function * getWatermarkDetail () {
  yield takeEvery(WatermarkTypes.GET_WATERMARK_REQUEST, _getWatermarkDetail);
}

function * _getWatermarkDetail () {
  const api = {
    ...API.GET_WATERMARK_DETAIL
  };
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      yield put({
        type: WatermarkTypes.GET_WATERMARK_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * updateWatermark () {
  yield takeEvery(WatermarkTypes.UPDATE_WATERMARK_REQUEST, _updateWatermark);
}

function * _updateWatermark ({ payload }) {
  yield createRequest({
    api: {
      ...API.UPDATE_WATERMARK,
      data: snakecaseKeys(payload)
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: WatermarkTypes.GET_WATERMARK_REQUEST,
        payload: {}
      });
    }
  });
}

function * resetWatermark () {
  yield takeEvery(WatermarkTypes.RESET_WATERMARK_REQUEST, _resetWatermark);
}

function * _resetWatermark () {
  yield createRequest({
    api: {
      ...API.RESET_WATERMARK
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    }
  });
}

export default function * watermarkSaga () {
  yield all([
    getWatermarkDetail(),
    updateWatermark(),
    resetWatermark()
  ]);
}
