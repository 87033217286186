import SortTypes from './SortTypes';
import { PAGINATION } from '../../configs/AppConfig';

const initialState = {
  sorts: [],
  allSorts: [],
  sortDetail: {},
  pagination: { ...PAGINATION }
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  const newState = { ...state };
  switch (type) {
    case SortTypes.GET_SORT_SUCCESS:
      newState.sortDetail = { ...payload };
      return newState;
    case SortTypes.GET_ALL_SORT_SUCCESS:
      newState.allSorts = [...payload];
      return newState;
    case SortTypes.GET_LIST_SORT_SUCCESS:
      newState.sorts = [...payload.content];
      newState.pagination.totalRecords = payload.totalElements;
      newState.pagination.totalPages = payload.totalPages;
      newState.pagination.size = payload.size;
      newState.pagination.page = payload.number + 1;
      newState.pagination = { ...newState.pagination };
      return newState;
  }
  return newState;
}
