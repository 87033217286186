import HomeTypes from './HomeTypes';

export function loadPublicContentGroups (data) {
  return {
    type: HomeTypes.GET_PUBLIC_CONTENT_GROUP_REQUEST,
    payload: data
  };
}

export function loadContentByContentGroup (data) {
  return {
    type: HomeTypes.GET_CONTENT_BY_CONTENT_GROUP_REQUEST,
    payload: data
  };
}

export function searchContentByContentGroup (data) {
  return {
    type: HomeTypes.SEARCH_CONTENT_BY_CONTENT_GROUP_REQUEST,
    payload: data
  };
}

export function searchFullText (data) {
  return {
    type: HomeTypes.SEARCH_FULL_TEXT_BY_CONTENT_GROUP_REQUEST,
    payload: data
  };
}

export function clearContents (data) {
  return {
    type: HomeTypes.CLEAR_CONTENTS,
    payload: data
  };
}

export function loadContentByHashtag (data) {
  return {
    type: HomeTypes.GET_CONTENT_BY_HASHTAG_REQUEST,
    payload: data
  };
}

export function setShowHomeSearch (data) {
  return {
    type: HomeTypes.SET_SHOW_HOME_SEARCH,
    payload: data
  };
}

export function setSearchRequest (data) {
  return {
    type: HomeTypes.SET_SEARCH_REQUEST,
    payload: data
  };
}

export function setSearchRequestFullTextSearch (data) {
  return {
    type: HomeTypes.SET_SEARCH_REQUEST_FULL_TEXT_SEARCH,
    payload: data
  };
}

export function setFulltextSearch (data) {
  return {
    type: HomeTypes.SET_FULLTEXT_SEARCH,
    payload: data
  };
}
