export default {
  GET_CONTENT_DATA: {
    url: '/content/page',
    method: 'GET'
  },
  GET_CONTENT_DETAIL: {
    url: '/content/detail/',
    method: 'GET'
  },
  ADD_CONTENT: {
    url: '/content/add',
    method: 'POST'
  },
  UPDATE_CONTENT: {
    url: '/content/update/',
    method: 'PUT'
  },
  DELETE_CONTENT: {
    url: '/content/delete/',
    method: 'DELETE'
  },
  GET_IMPORT_DATA: {
    url: '/data-patient',
    method: 'GET'
  },
  UPLOAD_CONTENT: {
    url: '/content/upload-add',
    method: 'POST'
  },
  REPLACE_CONTENT: {
    url: '/content/replace-content/',
    method: 'PUT'
  },
  UPLOAD_CONTENT_CSV: {
    url: '/import-csv-file/upload-csv',
    method: 'POST'
  },
  IMPORT_CSV: {
    url: '/import-csv-file/upload-csv',
    method: 'POST'
  },
  GET_VIEWER_TOKEN: {
    url: '/content/public/viewer-token',
    method: 'GET'
  },
  GET_CONTENT_BY_CONTENT_GROUP: {
    url: '/content/public/page',
    method: 'GET'
  },
  EXPORT_CONTENTS: {
    url: '/content/export-csv',
    method: 'GET'
  },
  ADD_FAVORITE_CONTENT: {
    url: '/content/favorites/add',
    method: 'POST'
  },
  REMOVE_FAVORITE_CONTENT: {
    url: '/content/favorites/delete/',
    method: 'DELETE'
  },
  UPDATE_CONTENT_DETAIL_ID: {
    url: '/content/update-detail-id/',
    method: 'PUT'
  },
  UPDATE_CONTENT_TOKEN_ID: {
    url: '/content/update-token/',
    method: 'PUT'
  },
  GET_CONTENT_PUBLIC_BY_HASH_TAG: {
    url: '/content/public/search-hash-tag',
    method: 'POST'
  },
  GET_CONTENT_BY_HASH_TAG: {
    url: '/content/search-hash-tag',
    method: 'POST'
  }
};
