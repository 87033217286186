export const getContentGroupList = state => state.contentGroup.contentGroupData;
export const getPagination = state => state.contentGroup.pagination;
export const getContentGroupTotal = state => state.contentGroup.pagination.totalRecords;
export const getContentGroupTotalPages = state => state.contentGroup.pagination.totalPages;

export const getContentGroupDetail = state => state.contentGroup.contentGroupDetail;
export const getIsContentGroupDetailLoad = state => state.contentGroup.isContentGroupDetailLoad;
export const getContentGroupAll = state => state.contentGroup.contentGroupAll;

export const getFavoritesRecentlyRead = state => state.contentGroup.favoritesRecentlyRead;
