import RouteTypes from './RouteTypes';

export function changeLayoutRoute (layoutId, layoutRoute) {
  return {
    type: RouteTypes.CHANGE_LAYOUT_ROUTE,
    payload: { layoutId, layoutRoute }
  };
}

export function setHistory (history) {
  return {
    type: RouteTypes.SET_HISTORY,
    payload: { history }
  };
}

export function updateRoute (routeTitle, routePath) {
  return {
    type: RouteTypes.UPDATE_ROUTE,
    payload: { routeTitle, routePath }
  };
}

export function changeRoute (pathname, search) {
  return {
    type: RouteTypes.CHANGE_ROUTE,
    payload: { pathname, search }
  };
}

// use to restore previous route after login
export function cacheRoute (pathname, search) {
  return {
    type: RouteTypes.CACHE_ROUTE,
    payload: { pathname, search }
  };
}
