import { all, put, takeEvery } from 'redux-saga/effects';
import ContentDetailTypes from './ContentDetailTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/content-detail';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';

// Search Result management

function * getSetting () {
  yield takeEvery(ContentDetailTypes.GET_SETTING_REQUEST, _getSetting);
}

function * _getSetting ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_SETTING
    },
    onSuccess: function * (response) {
      yield put({
        type: ContentDetailTypes.GET_SETTING_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * updateSetting () {
  yield takeEvery(ContentDetailTypes.UPDATE_SETTING_REQUEST, _updateSetting);
}

function * _updateSetting ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.UPDATE_SETTING,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: ContentDetailTypes.GET_SETTING_REQUEST,
        payload: {}
      });
    }
  });
}

function * getButton () {
  yield takeEvery(ContentDetailTypes.GET_BUTTON_REQUEST, _getButton);
}

function * _getButton ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_BUTTON,
      url: API.GET_BUTTON.url + payload
    },
    onSuccess: function * (response) {
      yield put({
        type: ContentDetailTypes.GET_BUTTON_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * addButton () {
  yield takeEvery(ContentDetailTypes.CREATE_BUTTON_REQUEST, _addButton);
}

function * _addButton ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.ADD_BUTTON,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: ContentDetailTypes.GET_SETTING_REQUEST,
        payload: {}
      });
    }
  });
}

function * updateButton () {
  yield takeEvery(ContentDetailTypes.UPDATE_BUTTON_REQUEST, _updateButton);
}

function * _updateButton ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.UPDATE_BUTTON,
      url: API.UPDATE_BUTTON.url + payload.id,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: ContentDetailTypes.GET_SETTING_REQUEST,
        payload: {}
      });
    }
  });
}

function * deleteButton () {
  yield takeEvery(ContentDetailTypes.DELETE_BUTTON_REQUEST, _deleteButton);
}

function * _deleteButton ({ payload }) {
  yield createRequest({
    api: {
      ...API.DELETE_BUTTON,
      url: API.DELETE_BUTTON.url + payload
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.delete.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: ContentDetailTypes.GET_SETTING_REQUEST,
        payload: {}
      });
    }
  });
}

export default function * contentDetailSaga () {
  yield all([
    getButton(),
    addButton(),
    updateButton(),
    getSetting(),
    updateSetting(),
    deleteButton()
  ]);
}
