import { all, put, select, takeEvery } from 'redux-saga/effects';
import createRequest from 'api/httpRequest';
import API from '../../api/veritrans';
import PaymentTypes from './PaymentTypes';
import Util from 'utils/Util';
import NotificationTypes from 'state/notification/NotificationTypes';
import HomeTypes from 'state/home/HomeTypes';
// import SubscriptionTypes from 'state/subscription/SubscriptionTypes';
import SessionTypes from 'state/session/SessionTypes';
import SubscriptionTypes from 'state/subscriptions/SubscriptionTypes';
import snakecaseKeys from 'snakecase-keys';
import { getCompanyDomain } from 'state/company/companySelector';
// import { getAuthUser } from 'state/session/sessionSelector';

// const Multipayment = window.Multipayment;

function * authorizeCharge () {
  yield takeEvery(PaymentTypes.AUTHORIZE_CHARGE_REQUEST, _authorizeCharge);
}

function * _authorizeCharge ({ payload }) {
  const request = {
    coupon_code: payload.couponCode,
    subscription_plan_id: payload.subscriptionPlanId,
    token: payload.token
  };
  yield createRequest({
    api: {
      ...API.AUTHORIZE_CHARGE,
      data: { ...request }

    },
    onSuccess: function * (response) {
      yield put({
        type: PaymentTypes.AUTHORIZE_CHARGE_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });

      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.register.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: SessionTypes.GET_AUTH_INFO_REQUEST
      });
      if (payload.updateContentGroup) {
        yield put({
          type: HomeTypes.GET_PUBLIC_CONTENT_GROUP_REQUEST
        });
        yield put({
          type: SubscriptionTypes.GET_PAGE_USER_SUBSCRIPTION_PLAN_REQUEST,
          payload: payload.queries
        });
        yield put({
          type: SubscriptionTypes.GET_HOME_SUBSCRIPTION_PLAN_LIST_REQUEST,
          payload: { size: 100 }
        });
      } else {
        yield put({
          type: SubscriptionTypes.GET_PAGE_USER_SUBSCRIPTION_PLAN_REQUEST,
          payload: payload.queries
        });
      }
    },
    onError: function * () {
      yield put({
        type: PaymentTypes.AUTHORIZE_CHARGE_ERROR
      });
    }
  });
}

function * veritransCreateOrderReceive () {
  yield takeEvery(PaymentTypes.VERITRANS_CREATE_ORDER_RECEIVE, _veritransCreateOrderReceive);
}

function * _veritransCreateOrderReceive ({ payload }) {
  const request = {
    coupon_code: payload.couponCode,
    subscription_plan_id: payload.subscriptionPlanId,
    redirect_url: payload.redirectUrl,
    state: payload.state,
    card_holder_email: payload.cardHolderEmail,
    card_holder_name: payload.cardHolderName,
    customer_ip: payload.customerIp,
    token: payload.token
  };

  yield createRequest({
    api: {
      ...API.VERITRANS_ORDER_RECEIVE,
      data: { ...request }
    },
    onSuccess: function * ({ data }) {
      yield put({
        type: PaymentTypes.VERITRANS_CREATE_ORDER_RECEIVE_SUCCESS,
        payload: Util.toCamelCaseKey(data)
      });
      if (data === 'ok') {
        yield put({
          type: SessionTypes.GET_AUTH_INFO_REQUEST
        });
      }
    },
    onError: function * ({ status }) {
      yield put({
        type: PaymentTypes.VERITRANS_CREATE_ORDER_RECEIVE_ERROR
      });
      // if (status === 201 && !window.location.origin.includes('admin')) {
      //   yield put(changeRoute('/'));
      // }
    }
  });
}

function * veritransConfirmOrder () {
  yield takeEvery(PaymentTypes.VERITRANS_CONFIRM_ORDER, _veritransConfirmOrder);
}

function * _veritransConfirmOrder ({ payload }) {
  const domain = yield select(getCompanyDomain);
  yield createRequest({
    api: {
      ...API.VERITRANS_CONFIRM_ORDER,
      data: {
        order_id: payload.orderId,
        coupon_code: payload.couponCode
      }
    },
    onSuccess: function * (response) {
      if (response.status === 200) {
        yield put({
          type: SubscriptionTypes.GET_HOME_SUBSCRIPTION_PLAN_LIST_REQUEST,
          payload: {
            size: 100,
            domain: domain
          }
        });
        yield put({
          type: NotificationTypes.SHOW_NOTIFICATION,
          payload: {
            config: {
              message: `api.register.${response.status}`,
              level: 'success',
              autoDismiss: 3,
              position: 'tc'
            }
          }
        });
        yield put({
          type: PaymentTypes.VERITRANS_CONFIRM_ORDER_SUCCESS,
          payload: {}
        });
        yield put({
          type: SessionTypes.GET_AUTH_INFO_REQUEST
        });
      } else {
        yield put({
          type: PaymentTypes.VERITRANS_CONFIRM_ORDER_ERROR
        });
      }
    },
    onError: function * ({ status }) {
      yield put({
        type: PaymentTypes.VERITRANS_CONFIRM_ORDER_ERROR
      });
      // if (status === 201 && !window.location.origin.includes('admin')) {
      //   yield put(changeRoute('/'));
      // }
    }
  });
}

function * veritransUpdateCard () {
  yield takeEvery(PaymentTypes.UPDATE_VERITRANS_CARD_REQUEST, _veritransUpdateCard);
}

function * _veritransUpdateCard ({ payload }) {
  const request = {
    token: payload.token
  };
  yield createRequest({
    api: {
      ...API.UPDATE_AN_ADD_CARD,
      data: { ...request }

    },
    onSuccess: function * (response) {
      if (response.data) {
        yield put({
          type: PaymentTypes.UPDATE_VERITRANS_CARD_SUCCESS,
          payload: Util.toCamelCaseKey(response.data)
        });
        yield put({
          type: NotificationTypes.SHOW_NOTIFICATION,
          payload: {
            config: {
              message: `api.update.${response.status}`,
              level: 'success',
              autoDismiss: 3,
              position: 'tc'
            }
          }
        });
        yield put({
          type: SessionTypes.GET_AUTH_INFO_REQUEST
        });
      }
    },
    onError: function * () {
      yield put({
        type: PaymentTypes.UPDATE_VERITRANS_CARD_SUCCESS
      });
    }
  });
}

function * loadPaymentHisotryPage () {
  yield takeEvery(PaymentTypes.GET_TRANSACTION_REQUEST, _loadPaymentHisotryPage);
}
function * _loadPaymentHisotryPage ({ payload }) {
  const request = snakecaseKeys({ ...payload });

  yield createRequest({
    api: { ...API.GET_PAYMENT_HISTORY_PAGE, params: { ...request } },
    onSuccess: function * (response) {
      yield put({
        type: PaymentTypes.GET_TRANSACTION_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: PaymentTypes.GET_TRANSACTION_ERROR,
        payload: status
      });
    }
  });
}

export default function * paymentSaga () {
  yield all([
    authorizeCharge(),
    veritransUpdateCard(),
    loadPaymentHisotryPage(),
    veritransCreateOrderReceive(),
    veritransConfirmOrder()
  ]);
}
