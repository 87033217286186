
export default {
  AUTHORIZE_CHARGE: {
    url: '/veritrans/charge',
    method: 'POST'
  },
  UPDATE_AN_ADD_CARD: {
    url: '/veritrans',
    method: 'POST'
  },
  GET_PAYMENT_HISTORY_PAGE: {
    url: '/transaction/page',
    method: 'GET'
  },
  VERITRANS_ORDER_RECEIVE: {
    url: '/veritrans/order-receive',
    method: 'POST'
  },
  VERITRANS_CONFIRM_ORDER: {
    url: '/veritrans/confirm-order',
    method: 'POST'
  },
  VERITRANS_UPDATE_CARD: {
    url: '/veritrans/add-card',
    method: 'POST'
  }
};
