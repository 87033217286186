import PaymentTypes from './PaymentTypes';

// Payment management
export function authorizeCharge (data) {
  return {
    type: PaymentTypes.AUTHORIZE_CHARGE_REQUEST,
    payload: data
  };
}
export function resetHtml () {
  return {
    type: PaymentTypes.RESET_HTML
  };
}

export function resetPaymentError () {
  return {
    type: PaymentTypes.RESET_PAYMENT_ERROR
  };
}

export function resetPaymentStatus () {
  return {
    type: PaymentTypes.RESET_PAYMENT_STATUS
  };
}

export function veritransUpdateCard (data) {
  return {
    type: PaymentTypes.UPDATE_VERITRANS_CARD_REQUEST,
    payload: data
  };
}

export function loadPaymentHisotryPage (data) {
  return {
    type: PaymentTypes.GET_TRANSACTION_REQUEST,
    payload: data
  };
}

export function veritransCreateOrderReceive (data) {
  return {
    type: PaymentTypes.VERITRANS_CREATE_ORDER_RECEIVE,
    payload: data
  };
}
export function veritransConfirmOrder (data) {
  return {
    type: PaymentTypes.VERITRANS_CONFIRM_ORDER,
    payload: data
  };
}
export function setCacheCouponCode (payload) {
  return {
    type: PaymentTypes.SET_CACHE_COUPON_CODE,
    payload: payload
  };
}
export function setCacheSubscriptionId (payload) {
  return {
    type: PaymentTypes.SET_CACHE_SUBSCRIPTION_ID,
    payload: payload
  };
}
export function resetCreateOrderReceiveVeritrans () {
  return {
    type: PaymentTypes.RESET_CREATE_ORDER_RECEIVE_VERITRANS
  };
}
