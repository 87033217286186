export default {
  GET_INPUT_SETTING: {
    url: '/input/detail',
    method: 'GET'
  },
  UPDATE_INPUT: {
    url: '/input/update',
    method: 'PUT'
  }
};
