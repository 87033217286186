import { all, takeEvery, put } from 'redux-saga/effects';
import createRequest from '../../api/httpRequest';
import Util from '../../utils/Util';
import API from '../../api/user';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys/index';
import UserTypes from './UserTypes';
import { saveAs } from 'file-saver';

function * loadUserList () {
  yield takeEvery(UserTypes.GET_USERS_REQUEST, _loadUserList);
}

function * _loadUserList ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_USER_LIST,
      params: payload
    },
    onSuccess: function * (data) {
      yield put({
        type: UserTypes.UPDATE_USER_QUERIES,
        payload: payload
      });
      yield put({
        type: UserTypes.GET_USERS_SUCCESS,
        payload: Util.toCamelCaseKey(data.data)
      });
    }
  });
}

function * loadUserDetail () {
  yield takeEvery(UserTypes.GET_USER_DETAIL_REQUEST, _loadUserDetail);
}

function * _loadUserDetail ({ payload }) {
  const api = { ...API.GET_USER_DETAIL };
  api.url += payload;
  yield createRequest({
    api: api,
    onSuccess: function * (data) {
      yield put({
        type: UserTypes.GET_USER_DETAIL_SUCCESS,
        payload: Util.toCamelCaseKey(data.data)
      });
    }
  });
}

function * addUser () {
  yield takeEvery(UserTypes.ADD_USER_REQUEST, _addUser);
}

function * _addUser ({ payload }) {
  yield createRequest({
    api: {
      ...API.ADD_USER,
      data: snakecaseKeys(payload.data)
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: UserTypes.GET_USERS_REQUEST,
        payload: payload.queries
      });
    }
  });
}

function * updateUserInfo () {
  yield takeEvery(UserTypes.UPDATE_USER_REQUEST, _updateUserInfo);
}

function * _updateUserInfo ({ payload }) {
  const api = { ...API.UPDATE_USER_INFO };
  api.url += payload.data.id;
  yield createRequest({
    api: {
      ...api,
      data: snakecaseKeys(payload.data)
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: UserTypes.GET_USERS_REQUEST,
        payload: payload.queries
      });
    }
  });
}

function * deleteUser () {
  yield takeEvery(UserTypes.DELETE_USER_REQUEST, _deleteUser);
}

function * _deleteUser ({ payload }) {
  const api = { ...API.DELETE_USER };
  api.url += payload.data.id;
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.delete.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: UserTypes.GET_USERS_REQUEST,
        payload: payload.queries
      });
    }
  });
}

function * exportUsers () {
  yield takeEvery(UserTypes.EXPORT_USERS_REQUEST, _exportUsers);
}

function * _exportUsers ({ payload }) {
  yield createRequest({
    api: {
      ...API.EXPORT_USERS
    },
    onSuccess: function (response) {
      const blob = new Blob(['\ufeff', response], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, payload);
    }
  });
}

function * loadUserSubscriptionPlanPaging () {
  yield takeEvery(UserTypes.GET_USER_SUBCRIPTION_PLAN_PAGE_REQUEST, _loadUserSubscriptionPlanPaging);
}

function * _loadUserSubscriptionPlanPaging ({ payload }) {
  const api = {
    ...API.GET_USER_SUBCRIPTION_PLAN_PAGE,
    params: payload
  };
  api.url += payload.userId;
  yield createRequest({
    api: api,
    onSuccess: function * (data) {
      yield put({
        type: UserTypes.UPDATE_USER_SUBCRIPTION_PLAN_PAGE_QUERIES,
        payload: payload
      });
      yield put({
        type: UserTypes.GET_USER_SUBCRIPTION_PLAN_PAGE_SUCCESS,
        payload: Util.toCamelCaseKey(data.data)
      });
    }
  });
}

export default function * userSaga () {
  yield all([
    loadUserList(),
    loadUserDetail(),
    addUser(),
    updateUserInfo(),
    deleteUser(),
    exportUsers(),
    loadUserSubscriptionPlanPaging()
  ]);
}
