import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import { alpha } from '@material-ui/core/styles';
import AppStyle from '../../../assets/styles/AppStyle';

export const ActionButtonType = [
  'default',
  'primary',
  'success',
  'info',
  'warning',
  'danger'
];

const ActionButtonStyle = theme => ({
  root: {
    textTransform: 'none'
  },
  raised_default: {
    color: theme.colors.text.dark,
    backgroundColor: theme.colors.default.light,
    '&:hover': {
      backgroundColor: theme.colors.default.dark
    }
  },
  raised_primary: {
    backgroundColor: theme.colors.primary.light,
    '&:hover': {
      backgroundColor: theme.colors.primary.dark
    }
  },
  raised_success: {
    backgroundColor: theme.colors.success.light,
    '&:hover': {
      backgroundColor: theme.colors.success.dark
    }
  },
  raised_info: {
    backgroundColor: theme.colors.info.light,
    '&:hover': {
      backgroundColor: theme.colors.info.dark
    }
  },
  raised_warning: {
    backgroundColor: theme.colors.warning.light,
    '&:hover': {
      backgroundColor: theme.colors.warning.dark
    }
  },
  raised_danger: {
    backgroundColor: theme.colors.danger.light,
    '&:hover': {
      backgroundColor: theme.colors.danger.dark
    }
  },
  fab_default: {
    fontSize: '1rem',
    color: theme.colors.text.dark,
    backgroundColor: theme.colors.default.light,
    '&:hover': {
      backgroundColor: theme.colors.default.dark
    }
  },
  fab_primary: {
    fontSize: '1rem',
    backgroundColor: theme.colors.primary.light,
    '&:hover': {
      backgroundColor: theme.colors.primary.dark
    }
  },
  fab_success: {
    fontSize: '1rem',
    backgroundColor: theme.colors.success.light,
    '&:hover': {
      backgroundColor: theme.colors.success.dark
    }
  },
  fab_info: {
    fontSize: '1rem',
    backgroundColor: theme.colors.info.light,
    '&:hover': {
      backgroundColor: theme.colors.info.dark
    }
  },
  fab_warning: {
    fontSize: '1rem',
    backgroundColor: theme.colors.warning.light,
    '&:hover': {
      backgroundColor: theme.colors.warning.dark
    }
  },
  fab_danger: {
    fontSize: '1rem',
    backgroundColor: theme.colors.danger.light,
    '&:hover': {
      backgroundColor: theme.colors.danger.dark
    }
  },
  extendedFab_default: {
    fontSize: '1rem',
    color: theme.colors.text.dark,
    backgroundColor: theme.colors.default.light,
    '&:hover': {
      backgroundColor: theme.colors.default.dark
    }
  },
  extendedFab_primary: {
    fontSize: '1rem',
    backgroundColor: theme.colors.primary.light,
    '&:hover': {
      backgroundColor: theme.colors.primary.dark
    }
  },
  extendedFab_success: {
    fontSize: '1rem',
    backgroundColor: theme.colors.success.light,
    '&:hover': {
      backgroundColor: theme.colors.success.dark
    }
  },
  extendedFab_info: {
    fontSize: '1rem',
    backgroundColor: theme.colors.info.light,
    '&:hover': {
      backgroundColor: theme.colors.info.dark
    }
  },
  extendedFab_warning: {
    fontSize: '1rem',
    backgroundColor: theme.colors.warning.light,
    '&:hover': {
      backgroundColor: theme.colors.warning.dark
    }
  },
  extendedFab_danger: {
    fontSize: '1rem',
    backgroundColor: theme.colors.danger.light,
    '&:hover': {
      backgroundColor: theme.colors.danger.dark
    }
  },
  outlined_default: {
    color: theme.colors.text.dark,
    borderColor: theme.colors.default.light,
    '&:hover': {
      backgroundColor: alpha(theme.colors.default.dark, 0.12),
      borderColor: theme.colors.default.dark
    }
  },
  outlined_primary: {
    color: theme.colors.primary.light,
    borderColor: theme.colors.primary.light,
    '&:hover': {
      backgroundColor: alpha(theme.colors.primary.dark, 0.12),
      borderColor: theme.colors.primary.dark
    }
  },
  outlined_success: {
    color: theme.colors.success.light,
    borderColor: theme.colors.success.light,
    '&:hover': {
      backgroundColor: alpha(theme.colors.success.dark, 0.12),
      borderColor: theme.colors.success.dark
    }
  },
  outlined_info: {
    color: theme.colors.info.light,
    borderColor: theme.colors.info.light,
    '&:hover': {
      backgroundColor: alpha(theme.colors.info.dark, 0.12),
      borderColor: theme.colors.info.dark
    }
  },
  outlined_warning: {
    color: theme.colors.warning.light,
    borderColor: theme.colors.warning.light,
    '&:hover': {
      backgroundColor: alpha(theme.colors.warning.dark, 0.12),
      borderColor: theme.colors.warning.dark
    }
  },
  outlined_danger: {
    color: theme.colors.danger.light,
    borderColor: theme.colors.danger.light,
    '&:hover': {
      backgroundColor: alpha(theme.colors.danger.dark, 0.12),
      borderColor: theme.colors.danger.dark
    }
  },
  outlined_disabled: {
    color: theme.colors.default.dark,
    borderColor: theme.colors.default.dark
  },
  flat_default: {
    color: theme.colors.text.dark,
    '&:hover': {
      backgroundColor: alpha(theme.colors.default.dark, 0.12)
    }
  },
  flat_primary: {
    color: theme.colors.primary.light,
    '&:hover': {
      backgroundColor: alpha(theme.colors.primary.dark, 0.12)
    }
  },
  flat_success: {
    color: theme.colors.success.light,
    '&:hover': {
      backgroundColor: alpha(theme.colors.success.dark, 0.12)
    }
  },
  flat_info: {
    color: theme.colors.info.light,
    '&:hover': {
      backgroundColor: alpha(theme.colors.info.dark, 0.12)
    }
  },
  flat_warning: {
    color: theme.colors.warning.light,
    '&:hover': {
      backgroundColor: alpha(theme.colors.warning.dark, 0.12)
    }
  },
  flat_danger: {
    color: theme.colors.danger.light,
    '&:hover': {
      backgroundColor: alpha(theme.colors.danger.dark, 0.12)
    }
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -(theme.button.circularSize / 2),
    marginLeft: -(theme.button.circularSize / 2),
    zIndex: 1
  },
  progress_default: {
    color: theme.colors.default.dark
  },
  progress_primary: {
    color: theme.colors.primary.dark
  },
  progress_success: {
    color: theme.colors.success.dark
  },
  progress_info: {
    color: theme.colors.info.dark
  },
  progress_warning: {
    color: theme.colors.warning.dark
  },
  progress_danger: {
    color: theme.colors.danger.dark
  },
  progress_disabled: {
    color: theme.colors.default.dark
  }
});

const ActionButton = props => {
  const {
    classes,
    className,
    submitButton,
    disabled,
    loading,
    type,
    block,
    style,
    children,
    theme,
    delay,
    onClick,
    ...rest
  } = props;

  const [delayAction, changeDelayAction] = useState(null);

  const handleOnClick = e => {
    if (delay > 0) {
      !!delayAction && clearTimeout(delayAction);
      changeDelayAction(setTimeout(() => onClick && onClick(e), delay));
    } else {
      onClick && onClick(e);
    }
  };

  useEffect(() => {
    !!delayAction && clearTimeout(delayAction);
  }, []);

  return (
    <Button
      color="primary"
      disabled={disabled || loading}
      onClick={handleOnClick}
      block={block}
      className={classNames(
        classes.root,
        (disabled || loading),
        className
      )}
      type={submitButton ? 'submit' : 'button'}
      style={style}
      {...rest}
    >
      {children}
      {loading && (
        <CircularProgress
          size={AppStyle.button.circularSize}
          thickness={AppStyle.button.circularThickness}
          className={classNames(
            classes.progress,
            classes[`progress_${type}`],
            disabled && classes.progress_disabled
          )}
        />
      )}
    </Button>
  );
};

ActionButton.defaultProps = {
  type: ActionButtonType[0],
  submitButton: false,
  disabled: false,
  loading: false,
  delay: 0,
  block: false
};

ActionButton.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.oneOf(ActionButtonType),
  submitButton: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  delay: PropTypes.number,
  block: PropTypes.bool,
  style: PropTypes.any,
  children: PropTypes.any,
  theme: PropTypes.any
};

export default injectSheet(ActionButtonStyle)(ActionButton);
