import CouponTypes from './CouponTypes';
import { PAGINATION } from '../../configs/AppConfig';

const initialState = {
  isCouponDetailLoad: true,
  couponList: [],
  pagination: {
    ...PAGINATION,
    searchKey: ''
  },
  totalPages: 0,
  totalRecords: 0,
  isLoading: false,
  couponDetail: null
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  switch (type) {
    case CouponTypes.GET_COUPON_PAGE_SUCCESS:
      newState.couponList = [...payload.content];
      newState.totalRecords = payload.totalElements;
      newState.totalPages = payload.totalPages;
      newState.pagination.size = payload.pageSize;
      newState.pagination.page = payload.pageNumber;
      newState.pagination.searchKey = payload.searchKey || '';
      newState.pagination = { ...newState.pagination };
      return newState;
    case CouponTypes.GET_LIST_COUPON_SUCCESS:
      newState.couponList = [...payload];
      return newState;
    case CouponTypes.GET_COUPON_DETAIL_SUCCESS:
      newState.couponDetail = { ...payload };
      newState.isCouponDetailLoad = false;
      return newState;
    case CouponTypes.GET_COUPON_USER_REQUEST:
      newState.couponDetail = null;
      newState.isLoading = true;
      return newState;
    case CouponTypes.GET_COUPON_USER_SUCCESS:
      newState.couponDetail = { ...payload };
      newState.isLoading = false;
      return newState;
    case CouponTypes.GET_COUPON_USER_ERROR:
      newState.isLoading = false;
      return newState;
    case CouponTypes.SET_COUPON_USER_NULL:
      newState.couponDetail = null;
      return newState;
    default:
      newState = state;
      break;
  }

  // return new state
  return newState;
}
