import React from 'react';
import {
  Button, FormGroup
} from 'reactstrap';
import TranslateMessage from '../../../components/common/TranslateMessage/TranslateMessage';
import { connect } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { AUTHEN_TYPES, OPTION_LOGIN, PATTERNS } from '../../../configs/AppConfig';
import { ActionContext } from '../../../components/layout/MainContainer';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Util from '../../../utils/Util';
import * as Yup from 'yup';
import InputFieldButtonRight from 'components/common/Input/InputFieldButtonRight';
import { makeStyles } from '@material-ui/core';
import {
  getTitleOfEmailField,
  getTitleOfPasswordField
} from 'state/search/searchSelector';
import { getAccountType } from 'state/company/companySelector';
import { YupValidations } from 'utils/validate';

const useStyles = makeStyles(theme => ({
  form: {
    maxWidth: '400px',
    margin: 'auto',
    '& input': {
      minHeight: 'unset!important',
      fontSize: '1rem',
      borderColor: '#ced4da',
      flex: 'none'
    },
    '& input:focus': {
      borderColor: '#80bdff',
      outline: 0,
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)!important'
    },
    '& input:hover': {
      borderColor: '#ced4da'
    },
    logo: {
      marginRight: '10px',
      width: '20px',
      height: '20px'
    }
  }
}));

const SignupEndUserForm = props => {
  const {
    onSubmit,
    accountType,
    titleOfEmailField,
    titleOfPasswordField,
    isLoading
  } = props;

  const classes = useStyles();
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim()
      .max(64, Util.translate('validate.max_length_64'))
      .min(6, Util.translate('validate.min_length_6'))
      .required(Util.translate('validate.required'))
      .matches(PATTERNS.USERNAME_PATTERN, {
        message: Util.translate('validate.name.invalid')
      }),
    // email: Yup.string().required(Util.translate('validate.required')),
    email: accountType === OPTION_LOGIN.ALL
      ? YupValidations.emailOrUsername
      : accountType === OPTION_LOGIN.EMAIL
        ? YupValidations.email
        : YupValidations.username,
    password: Yup.string().required(Util.translate('validate.required')),
    confirmPassword: YupValidations.confirmPassword
  });

  const { formatMessage: f } = useIntl();

  const submit = data => {
    const request = data;
    onSubmit(request, AUTHEN_TYPES.EMAIL);
  };

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
      }}
      onSubmit={submit}
      validationSchema={validationSchema}
    >
      {formikProps => {
        return (
          <Form className={classes.form} autoComplete="off" aria-autocomplete="none">
            <div className="row">
              <div className="col-sm-12">
                <Field
                  component={InputFieldButtonRight}
                  name="name"
                  label={f({ id: 'label.name' })}
                  placeholder={f({ id: 'label.name' })}
                />
                <Field
                  component={InputFieldButtonRight}
                  name="email"
                  label={titleOfEmailField && titleOfEmailField !== ''
                    ? titleOfEmailField
                    : f({ id: accountType === OPTION_LOGIN.ALL ? 'label.emailOrUsername' : accountType === OPTION_LOGIN.EMAIL ? 'label.email_address' : 'label.username' })
                  }
                  placeholder={titleOfEmailField && titleOfEmailField !== ''
                    ? titleOfEmailField
                    : f({ id: accountType === OPTION_LOGIN.ALL ? 'label.emailOrUsername' : accountType === OPTION_LOGIN.EMAIL ? 'label.email_address' : 'label.username' })
                  }
                />
              </div>
              <div className="col-sm-12">
                <Field
                  component={InputFieldButtonRight}
                  name="password"
                  label={titleOfPasswordField && titleOfPasswordField !== ''
                    ? titleOfPasswordField
                    : f({ id: 'label.password' })
                  }
                  placeholder={titleOfPasswordField && titleOfPasswordField !== ''
                    ? titleOfPasswordField
                    : f({ id: 'label.password' })
                  }
                  type="password"
                />
                <Field
                  component={InputFieldButtonRight}
                  name="confirmPassword"
                  label="label.confirmPassword"
                  type="password"
                  placeholder={f({ id: 'label.confirmPassword' })}
                />
              </div>
              <div className="col-sm-12">
                <FormGroup>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"s
                    block
                    disabled={isLoading}
                  >
                    <TranslateMessage id="menu.register" />
                  </Button>
                </FormGroup>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

SignupEndUserForm.propTypes = {
  onSubmit: PropTypes.func,
  allowLoginByIpAddress: PropTypes.bool,
  titleOfEmailField: PropTypes.string,
  titleOfPasswordField: PropTypes.string,
  googleOption: PropTypes.string,
  googleId: PropTypes.any,
  isShowViewBook: PropTypes.bool,
  contentDetailId: PropTypes.any,
  searchRequest: PropTypes.any,
  activeTab: PropTypes.any,
  selectedContentGroup: PropTypes.any,
  accountType: PropTypes.any,
  isActiveForgotPassword: PropTypes.bool,
  isLoading: PropTypes.bool
};
const mapStateToProps = (state) => {
  return {
    titleOfEmailField: getTitleOfEmailField(state),
    titleOfPasswordField: getTitleOfPasswordField(state),
    accountType: getAccountType(state)
  };
};

export default connect(mapStateToProps)(
  props => (
    <ActionContext.Consumer>
      {() => (
        <SignupEndUserForm {...props} />
      )}
    </ActionContext.Consumer>
  ));
