import { all, put, takeEvery } from 'redux-saga/effects';
import SearchResultTypes from './SearchResultTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/search-result';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';
import MessageTypes from 'state/message/MessageTypes';

// Search Result management

function * getButton () {
  yield takeEvery(SearchResultTypes.GET_BUTTON_REQUEST, _getButton);
}

function * _getButton ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_BUTTON,
      url: API.GET_BUTTON.url + payload
    },
    onSuccess: function * (response) {
      yield put({
        type: SearchResultTypes.GET_BUTTON_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * addButton () {
  yield takeEvery(SearchResultTypes.CREATE_BUTTON_REQUEST, _addButton);
}

function * _addButton ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.ADD_BUTTON,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: MessageTypes.GET_SEARCH_RESULT_DETAIL_REQUEST,
        payload: {}
      });
    }
  });
}

function * updateButton () {
  yield takeEvery(SearchResultTypes.UPDATE_BUTTON_REQUEST, _updateButton);
}

function * _updateButton ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.UPDATE_BUTTON,
      url: API.UPDATE_BUTTON.url + payload.id,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: MessageTypes.GET_SEARCH_RESULT_DETAIL_REQUEST,
        payload: {}
      });
    }
  });
}

function * deleteButton () {
  yield takeEvery(SearchResultTypes.DELETE_BUTTON_REQUEST, _deleteButton);
}

function * _deleteButton ({ payload }) {
  yield createRequest({
    api: {
      ...API.DELETE_BUTTON,
      url: API.DELETE_BUTTON.url + payload
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.delete.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: MessageTypes.GET_SEARCH_RESULT_DETAIL_REQUEST,
        payload: {}
      });
    }
  });
}

export default function * searchResultSaga () {
  yield all([
    getButton(),
    addButton(),
    updateButton(),
    deleteButton()
  ]);
}
