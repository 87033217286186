import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PublicDetailContent from 'views/end-user/PublicDetailContent';
import ViewOnlineLayout from './ViewOnlineLayout';
import SearchViewBook from 'components/search/SearchViewBook';
import { NoMatchPage } from 'configs/routes';

const NoLayoutRoute = () => {
  return (
    <Switch>
      <Route
        path="/404"
        render={
          () => <NoMatchPage/>
        }
      />
      <Route
        path="/content/:contentToken"
        render={
          () => {
            return <PublicDetailContent />;
          }
        }
      />
      <Route
        path="/search/:contentId"
        render={
          () => {
            return <PublicDetailContent />;
          }
        }
      />
      <Route
        path="/direct-view/:viewToken"
        render={
          () => {
            return <ViewOnlineLayout>
              <SearchViewBook />
            </ViewOnlineLayout>;
          }
        }
      />
      <Route
        path="/view-online/:id/:contentGroupId"
        render={
          () => {
            return <ViewOnlineLayout>
              <SearchViewBook />
            </ViewOnlineLayout>;
          }
        }
      />
      <Route
        path="/view-online/:id"
        render={
          () => {
            return <ViewOnlineLayout>
              <SearchViewBook />
            </ViewOnlineLayout>;
          }
        }
      />
      <Route
        path="/:contentGroupId/:contentId"
        exact
        render={
          () => {
            return <PublicDetailContent />;
          }
        }
      />
      <Route
        path="/:contentId"
        exact
        render={
          () => {
            return <PublicDetailContent />;
          }
        }
      />
      <Route
        path="*"
      >
        <Redirect to="/404" push />
      </Route>
    </Switch>
  );
};
export default NoLayoutRoute;
