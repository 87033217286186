import React, { useEffect } from 'react';
import { ActionContext } from '../../components/layout/MainContainer';
import TranslateMessage from '../../components/common/TranslateMessage/TranslateMessage';
import UserAccountSettingForm from '../../components/form/UserAccountSettingForm';
import FormTitle from '../../components/common/Title/FormTitle';
import { getIsCancelAccount, getPasswordPolicy } from 'state/search/searchSelector';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UserCard from './form/UserCard';
import { getShowSubscriptionStatus } from 'state/company/companySelector';
import { isAuthenticated } from 'state/session/sessionSelector';
import { useHistory } from 'react-router-dom';
import UserCancelAccount from './form/UserCancelAccount';

const UserProfile = props => {
  const {
    strictPassword,
    showSubscriptionStatus,
    isAuthen,
    allowCancelAccount
  } = props;
  const history = useHistory();
  useEffect(() => {
    if (!isAuthen) {
      history.push('/login');
    }
  }, [isAuthen]);
  return (
    <div className="card">
      <div className="card-body">
        <FormTitle>
          <TranslateMessage id="title.userProfile" />
        </FormTitle>
        <UserAccountSettingForm strictPassword={strictPassword} />
        {
          (showSubscriptionStatus)
            ? <React.Fragment>
              <FormTitle>
                <TranslateMessage id="title.userCard" />
              </FormTitle>
              <UserCard />
            </React.Fragment>
            : null
        }
        {
          allowCancelAccount &&
        <React.Fragment>
          <FormTitle>
            <TranslateMessage id="title.cancelAccount" />
          </FormTitle>
          <UserCancelAccount/>
        </React.Fragment>
        }
      </div>
    </div>
  );
};

UserProfile.propTypes = {
  strictPassword: PropTypes.bool,
  showSubscriptionStatus: PropTypes.bool,
  isAuthen: PropTypes.bool,
  allowCancelAccount: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    strictPassword: getPasswordPolicy(state),
    showSubscriptionStatus: getShowSubscriptionStatus(state),
    isAuthen: isAuthenticated(state),
    allowCancelAccount: getIsCancelAccount(state)
  };
};

export default connect(mapStateToProps)(
  props => (
    <ActionContext.Consumer>
      {() => (
        <UserProfile
          {...props}
        />
      )}
    </ActionContext.Consumer>
  )
);
