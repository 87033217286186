export const getTabs = state => state.search.tabs;
export const getPagination = state => state.search.pagination;
export const getData = state => state.search.data;
export const getIsDataLoad = state => state.search.IsDataLoad;
export const getTab = state => state.search.tabs;
export const getContentInfo = state => state.search.contentDetail;
export const getIsContentInfoLoad = state => state.search.IsContentDetailLoad;
export const getSearchSetting = state => state.search.searchSetting;
export const getLoginSetting = state => state.search.settingLogin;
export const getOptions = state => state.search.options;
export const getPageTitle = state => state.search.pageTitle;
export const getPageLogo = state => state.search.pageLogo;
export const getPageFavicon = state => state.search.pageFavicon;
export const getContentDetailParams = state => state.search.contentDetailParams;
export const getBeViewerHost = state => state.search.beViewerHost;
export const getPrintViewer = state => state.search.enablePrint;

export const getHeaderSetting = state => state.search.headerSetting;
export const getBodySetting = state => state.search.bodySetting;
export const getFooterSetting = state => state.search.footerSetting;
export const getInputSetting = state => state.search.inputSetting;
export const getContentDetailSetting = state => state.search.contentDetailSetting;
export const getTabSetting = state => state.search.tabSetting;
export const getLayoutSetting = state => state.search.layoutSetting;
export const getCompanyName = state => state.search.companyName;
export const getSidebarSetting = state => state.search.sidebarSetting;
export const getBannerSetting = state => state.search.bannerSetting;
export const getMultipleSelectHashtag = state => state.search.multipleSelectHashtag;

export const getFullTextSearchContents = state => state.search.ftsContents;
export const getFullTextSearchPagination = state => state.search.ftsPagination;

export const getAccountType = state => state.search.accountType;
export const getPasswordPolicy = state => state.search.strictPassword;
export const getAllowLoginByIpAddress = state => state.search.allowLoginByIpAddress;

export const getTitleOfEmailField = state => state.search.titleOfEmailField;
export const getTitleOfPasswordField = state => state.search.titleOfPasswordField;

export const getMoreLessBtnSetting = state => state.search.moreLessBtnSetting;
export const getAllowForgotPassword = state => state.search.allowForgotPassword;
export const getSelectedTabId = state => state.search.selectedTabId;

export const getSearchTermPage = state => state.search.searchTermPage;
export const isLoadingPage = state => state.search.isLoading;

export const getTotalPages = state => state.search.totalPages;
export const getTotalRecords = state => state.search.totalRecords;
export const getHashtagSetting = state => state.search.hashtagSetting;
export const getIsCancelAccount = state => state.search.isCancelAccount;
