import HashtagTypes from './HashtagTypes';

const initialState = {
  hashtagList: [],
  hashtagsUser: [],
  hashtagSettings: {},
  searchBoxDetail: {},
  searchFields: '',
  isLoading: false
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  switch (type) {
    case HashtagTypes.GET_LIST_HASHTAG_SUCCESS:
      newState.hashtagList = payload;
      newState.isLoading = false;
      return newState;
    case HashtagTypes.GET_SEARCH_BOX_HASHTAG_SUCCESS:
      newState.searchBoxDetail = { ...payload };
      newState.searchFields = payload.searchFields;
      return newState;
    case HashtagTypes.GET_LIST_HASHTAG_USER_REQUEST:
      newState.isLoading = true;
      return newState;
    case HashtagTypes.GET_LIST_HASHTAG_USER_SUCCESS:
      newState.hashtagsUser = payload;
      newState.isLoading = false;
      return newState;
    case HashtagTypes.GET_LIST_HASHTAG_USER_ERROR:
      newState.hashtagsUser = [];
      newState.isLoading = false;
      return newState;
    case HashtagTypes.GET_HASHTAG_SETTING_REQUEST:
      newState.isLoading = true;
      return newState;
    case HashtagTypes.GET_HASHTAG_SETTING_SUCCESS:
      newState.isLoading = false;
      newState.hashtagSettings = { ...payload };
      return newState;
    case HashtagTypes.GET_HASHTAG_SETTING_ERROR:
      newState.isLoading = false;
      newState.hashtagSettings = {};
      return newState;
    case HashtagTypes.UPDATE_HASHTAG_SETTING_REQUEST:
      newState.isLoading = true;
      return newState;
    case HashtagTypes.UPDATE_HASHTAG_SETTING_SUCCESS:
    case HashtagTypes.UPDATE_HASHTAG_SETTING_ERROR:
      newState.isLoading = false;
      return newState;
    default:
      newState = state;
      break;
  }

  // return new state
  return newState;
}
