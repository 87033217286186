import SubscriptionTypes from './SubscriptionTypes';
import { PAGINATION } from '../../configs/AppConfig';

const initialState = {
  subscriptionPage: [],
  allSubscriptions: [],
  sortDetail: {},
  pagination: { ...PAGINATION },
  paginationDiscount: { ...PAGINATION },
  isListLoad: true,
  subscriptionList: [],
  subscriptionAll: [],
  homeSubscriptionList: [],
  queries: { ...PAGINATION },
  totalPages: 0,
  totalRecords: 0,
  subscriptionDetail: {
    name: '',
    duration: 0,
    price: 0,
    subscriptionContentGroups: [],
    subscriptionIds: [],
    durationType: 0
  },
  isSaving: false,
  isOpenPopUp: false,
  isLoading: false,
  isLoadDetail: false,
  subscriptionOrdinalDetail: null,
  subscriptionOrdinalList: [],
  isSubscriptionDetailLoad: false,
  subscriptionRegistered: {
    price: 0
  },
  isLoadHomeSubscriptionList: false,
  isLoadFail: false
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  const newState = { ...state };
  switch (type) {
    case SubscriptionTypes.GET_SUBSCRIPTION_PLAN_SUCCESS:
      newState.isListLoad = false;
      newState.subscriptionAll = [...payload];
      return newState;
    case SubscriptionTypes.GET_PAGE_SUBSCRIPTION_PLAN_REQUEST:
      newState.pagination.search_key = payload.search_key || '';
      newState.isLoading = true;
      return newState;
    case SubscriptionTypes.GET_PAGE_SUBSCRIPTION_PLAN_SUCCESS:
      newState.subscriptionPage = [...payload.content];
      newState.pagination.totalRecords = payload.totalElements;
      newState.pagination.totalPages = payload.totalPages;
      newState.pagination.size = payload.pageSize;
      newState.pagination.page = payload.pageNumber;
      newState.pagination = { ...newState.pagination };
      newState.isLoading = false;
      return newState;
    case SubscriptionTypes.GET_PAGE_SUBSCRIPTION_PLAN_ERROR:
      newState.isLoading = false;
      return newState;
    case SubscriptionTypes.OPEN_SUBSCRIPTION_POPUP:
      newState.isOpenPopUp = true;
      return newState;
    case SubscriptionTypes.CLOSE_SUBSCRIPTION_POPUP:
      newState.isOpenPopUp = false;
      return newState;
    case SubscriptionTypes.ADD_SUBSCRIPTION_PLAN_REQUEST:
      newState.isSaving = true;
      return newState;
    case SubscriptionTypes.ADD_SUBSCRIPTION_PLAN_SUCCESS:
      newState.isOpenPopUp = false;
      newState.isSaving = false;
      return newState;
    case SubscriptionTypes.ADD_SUBSCRIPTION_PLAN_ERROR:
      newState.isSaving = false;
      return newState;
    case SubscriptionTypes.UPDATE_SUBSCRIPTION_PLAN_DETAIL_REQUEST:
      newState.isSaving = true;
      return newState;
    case SubscriptionTypes.UPDATE_SUBSCRIPTION_PLAN_DETAIL_SUCCESS:
      newState.isSaving = false;
      return newState;
    case SubscriptionTypes.UPDATE_SUBSCRIPTION_PLAN_DETAIL_ERROR:
      newState.isSaving = false;
      return newState;
    case SubscriptionTypes.GET_SUBSCRIPTION_PLAN_DETAIL_REQUEST:
      newState.isLoadDetail = true;
      newState.isLoadFail = false;
      return newState;
    case SubscriptionTypes.GET_SUBSCRIPTION_PLAN_DETAIL_SUCCESS:
      for (const key in payload) {
        if (!payload[key] && payload[key] !== 0) {
          payload[key] = '';
        }
      }
      newState.subscriptionDetail = { ...payload };
      newState.isLoadDetail = false;
      newState.isLoadFail = false;
      return newState;
    case SubscriptionTypes.GET_SUBSCRIPTION_PLAN_DETAIL_ERROR:
      newState.isLoadDetail = false;
      newState.subscriptionDetail = null;
      newState.isLoadFail = true;
      return newState;
    case SubscriptionTypes.GET_DISCOUNT_ORDINAL_PAGE_REQUEST:
      newState.paginationDiscount.search_key = payload.search_key || '';
      newState.isLoading = true;
      return newState;
    case SubscriptionTypes.GET_DISCOUNT_ORDINAL_PAGE_SUCCESS:
      newState.subscriptionOrdinalList = [...payload.content];
      newState.paginationDiscount.totalRecords = payload.totalElements;
      newState.paginationDiscount.totalPages = payload.totalPages;
      newState.paginationDiscount.size = payload.pageSize;
      newState.paginationDiscount.page = payload.pageNumber;
      newState.paginationDiscount = { ...newState.paginationDiscount };
      newState.isLoading = false;
      return newState;
    case SubscriptionTypes.GET_DISCOUNT_ORDINAL_PAGE_ERROR:
      newState.isLoading = false;
      return newState;
    case SubscriptionTypes.GET_DISCOUNT_ORDINAL_DETAIL_REQUEST:
      newState.isLoadDetail = true;
      return newState;
    case SubscriptionTypes.GET_DISCOUNT_ORDINAL_DETAIL_SUCCESS:
      for (const key in payload) {
        if (!payload[key] && payload[key] !== 0) {
          payload[key] = '';
        }
      }
      newState.subscriptionOrdinalDetail = { ...payload };
      newState.isLoadDetail = false;
      return newState;
    case SubscriptionTypes.GET_DISCOUNT_ORDINAL_DETAIL_ERROR:
      newState.isLoadDetail = false;
      return newState;
    case SubscriptionTypes.GET_PAGE_USER_SUBSCRIPTION_PLAN_REQUEST:
      newState.isListLoad = true;
      return newState;
    case SubscriptionTypes.GET_PAGE_USER_SUBSCRIPTION_PLAN_SUCCESS:
      newState.subscriptionList = [...payload.content];
      newState.pagination.totalRecords = payload.totalElements;
      newState.pagination.totalPages = payload.totalPages;
      newState.pagination.size = payload.pageSize;
      newState.pagination.page = payload.pageNumber;
      newState.pagination = { ...newState.pagination };
      newState.isListLoad = false;
      return newState;
    case SubscriptionTypes.GET_PAGE_USER_SUBSCRIPTION_PLAN_ERROR:
      newState.isListLoad = false;
      return newState;
    case SubscriptionTypes.GET_HOME_SUBSCRIPTION_PLAN_LIST_REQUEST:
      newState.isListLoad = true;
      return newState;
    case SubscriptionTypes.GET_HOME_SUBSCRIPTION_PLAN_LIST_SUCCESS:
      newState.homeSubscriptionList = [...payload.content];
      newState.queries.totalRecords = payload.totalElements;
      newState.queries.totalPages = payload.totalPages;
      newState.queries.size = payload.pageSize;
      newState.queries.page = payload.pageNumber;
      newState.queries = { ...newState.pagination };
      newState.isListLoad = false;
      newState.isLoadHomeSubscriptionList = false;
      return newState;
    case SubscriptionTypes.EMPTY_HOME_SUBSCRIPTION_PLAN_LIST:
      newState.homeSubscriptionList = [];
      return newState;
    case SubscriptionTypes.GET_HOME_SUBSCRIPTION_PLAN_LIST_ERROR:
      newState.isListLoad = false;
      return newState;
    case SubscriptionTypes.GET_PAGE_SUBSCRIPTION_PLAN_USER_REGISTERED_REQUEST:
      newState.isListLoad = true;
      newState.pagination.search_key = payload.search_key || '';
      newState.pagination.page = payload.page;
      newState.pagination.size = payload.size;
      newState.pagination = { ...newState.pagination };
      return newState;
    case SubscriptionTypes.GET_PAGE_SUBSCRIPTION_PLAN_USER_REGISTERED_SUCCESS:
      newState.subscriptionList = [...payload.content];
      newState.pagination.totalRecords = payload.totalElements;
      newState.pagination.totalPages = payload.totalPages;
      newState.pagination.size = payload.pageSize;
      newState.pagination.page = payload.pageNumber;
      newState.pagination = { ...newState.pagination };
      newState.isListLoad = false;
      return newState;
    case SubscriptionTypes.GET_PAGE_SUBSCRIPTION_PLAN_USER_REGISTERED_ERROR:
      newState.isListLoad = false;
      return newState;
    case SubscriptionTypes.GET_DETAIL_SUBSCRIPTION_PLAN_USER_REGISTERED_SUCCESS:
      for (const key in payload) {
        if (!payload[key] && payload[key] !== 0) {
          payload[key] = '';
        }
      }
      // newState.subscriptionDetail = { ...payload };
      newState.subscriptionRegistered = { ...payload };
      newState.isSubscriptionDetailLoad = false;
      return newState;
    case SubscriptionTypes.GET_DETAIL_SUBSCRIPTION_PLAN_USER_REGISTERED_REQUEST:
      newState.isSubscriptionDetailLoad = true;
      return newState;
    case SubscriptionTypes.GET_DETAIL_SUBSCRIPTION_PLAN_USER_REGISTERED_ERROR:
      newState.isSubscriptionDetailLoad = true;
      return newState;
  }
  return newState;
}
