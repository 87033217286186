import axios from 'axios';
import { DOWNLOAD_FILE_TYPES, REQUEST, EXCEPT_ERRORS } from '../configs/AppConfig';
import ErrorTypes from '../state/handler/ErrorTypes';
import { put, select } from 'redux-saga/effects';
import { getToken } from '../state/session/sessionSelector';
import queryString from 'query-string';

const instance = axios.create({
  method: 'GET', // default send GET request
  baseURL: (process.env.REACT_APP_BACKEND_URL || process.env.REACT_APP_SERVER_URL) + '/api/v1', // user for docker run
  // baseURL: window.Configs.SERVER_URL + '/api/v1',
  // headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
  // converts an object to x-www-form-urlencoded serialization
  // transformRequest: [
  //   function(data, headers) {
  //     // try to get token from local storage
  //     let token = StorageHelper.getLocalItem(STORAGE_KEYS.session);
  //     if (token) {
  //       headers[REQUEST.ACCESS_HEADER] = token;
  //     }
  //
  //     let str = [];
  //     for (let p in data) {
  //       if (data.hasOwnProperty(p) && data[p]) {
  //         str.push(encodeURIComponent(p) + '=' + encodeURIComponent(data[p]));
  //       }
  //     }
  //
  //     return str.join('&');
  //   },
  // ],
  paramsSerializer: (params) => queryString.stringify(params)
});

/**
 * Send request with URL & HTTP method are configured in 'config' param
 * @param api: request config example {url: '/api/v1/apps', method: 'GET', ...}
 * @param onSuccess: only called when request return code 200 and API status code is 200
 * @param onError: called when request return code 200 but API status code other 200
 * @param onRequestError: called when request return HTTP error code [ex: 400, 401, 403, 404, 500...]
 * By default an action type `ErrorTypes.API_RESPONSE_ERROR` will be dispatch if onRequestError doesn't defined or return truthy value
 * It mean if you need to do something in onRequestError callback but don't want dispatch error action so just return falsy value
 * @returns the request response
 */
export default function * createRequest ({
  api,
  onSuccess,
  onError,
  onRequestError,
  ignoreErrorMessage
}) {
  try {
    const token = yield select(getToken);

    if (token) {
      instance.defaults.headers.common[REQUEST.ACCESS_HEADER] = token;
    } else {
      delete instance.defaults.headers.common[REQUEST.ACCESS_HEADER];
    }
    const response = yield instance.request(api);
    const { data, headers } = response;
    const contentType = headers['content-type'];
    if (data.status === 200 || DOWNLOAD_FILE_TYPES.includes(contentType)) {
      // execute onSuccess callback
      yield onSuccess && onSuccess(data, response);
    } else {
      // execute onError callback
      yield onError && onError(data, response);
      // dispatch ErrorTypes.API_RESPONSE_ERROR if onError is undefined or return true
      const exceptError = EXCEPT_ERRORS.find(item => item === data.status);

      if (!exceptError && !ignoreErrorMessage) {
        yield put({ type: ErrorTypes.API_RESPONSE_ERROR, response: response });
        yield onError && onError(data);
      }
      // yield (!onError || (!!onError && chain)) &&
      //   put({ type: ErrorTypes.API_RESPONSE_ERROR, response: response });
    }

    return response;
  } catch (error) {
    const { response } = error;
    yield onError && onError(error);
    console.error(error);

    // happen when request return HTTP error code [ex: 400, 401, 403, 404, 500...]
    const chain = yield onRequestError && onRequestError(response, error);

    // dispatch ErrorTypes.API_RESPONSE_ERROR if onRequestError is undefined or return truth value
    if (!onRequestError || (!!onRequestError && chain)) {
      yield put({
        type: ErrorTypes.API_RESPONSE_ERROR,
        response: response
      });
    }
  }
}
