import React, { useState, useEffect } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import TranslateMessage from 'components/common/TranslateMessage/TranslateMessage';
import UserTableSubscription from '../../components/end-user/subscription/UserTableSubscription';
import UserTablePaymentHistory from 'components/end-user/subscription/UserTablePaymentHistory';
import { isAuthenticated } from 'state/session/sessionSelector';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getShowSubscriptionStatus } from 'state/company/companySelector';

const UserSubscription = () => {
  /* eslint-disable no-tabs */
  const [activeTab, setActiveTab] = useState('1');
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const history = useHistory();
  const isAuthen = useSelector(isAuthenticated);
  const showSubscription = useSelector(getShowSubscriptionStatus);
  useEffect(() => {
    if (!isAuthen) {
      history.push('/login');
    }
  }, [isAuthen]);
  useEffect(() => {
    if (!showSubscription) {
      history.push('/');
    }
  }, [showSubscription]);

  return (
    <div className='card'>
      <div className='card-body'>
        <Nav tabs pills className="mb-3">
          <NavItem>
            <NavLink
              className={classnames({ 'text-white': activeTab === '1' })}
              onClick={() => { toggle('1') }}
              active={activeTab === '1'}
              style={{ cursor: 'pointer' }}
            >
              <TranslateMessage id="label.subscription" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ 'text-white': activeTab === '2' })}
              onClick={() => { toggle('2') }}
              active={activeTab === '2'}
              style={{ cursor: 'pointer' }}
            >
              <TranslateMessage id="label.paymentHistory" />
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <UserTableSubscription />
          </TabPane>
          <TabPane tabId="2">
            <UserTablePaymentHistory />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default UserSubscription;
