import { all, put, takeEvery } from 'redux-saga/effects';
import MessageTypes from './MessageTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/message';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';
import { PAGINATION } from '../../configs/AppConfig';

// Content detail params management

function * getListContentDetailParam () {
  yield takeEvery(MessageTypes.GET_LIST_CONTENT_DETAIL_PARAM_REQUEST, _getListContentDetailParam);
}

function * _getListContentDetailParam ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_LIST_CONTENT_DETAIL_PARAM
    },
    onSuccess: function * (response) {
      yield put({
        type: MessageTypes.GET_LIST_CONTENT_DETAIL_PARAM_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * addContentDetail () {
  yield takeEvery(MessageTypes.ADD_CONTENT_DETAIL_PARAM_REQUEST, _addContentDetail);
}

function * _addContentDetail ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.ADD_CONTENT_DETAIL_PARAM,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: MessageTypes.GET_LIST_CONTENT_DETAIL_PARAM_REQUEST,
        payload: payload.pagination
      });
    }
  });
}

function * getContentDetail () {
  yield takeEvery(MessageTypes.GET_CONTENT_DETAIL_PARAM_REQUEST, _getContentDetail);
}

function * _getContentDetail ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_CONTENT_DETAIL_PARAM,
      url: API.GET_CONTENT_DETAIL_PARAM.url + payload
    },
    onSuccess: function * (response) {
      yield put({
        type: MessageTypes.GET_CONTENT_DETAIL_PARAM_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * updateContentDetailParam () {
  yield takeEvery(MessageTypes.UPDATE_CONTENT_DETAIL_PARAM_REQUEST, _updateContentDetailParam);
}

function * _updateContentDetailParam ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.UPDATE_CONTENT_DETAIL_PARAM,
      url: API.UPDATE_CONTENT_DETAIL_PARAM.url + payload.id,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: MessageTypes.GET_LIST_CONTENT_DETAIL_PARAM_REQUEST,
        payload: payload.pagination
      });
    }
  });
}

function * updateOrdinalContentDetailParam () {
  yield takeEvery(MessageTypes.UPDATE_ORDINAL_CONTENT_DETAIL_PARAM_REQUEST, _updateOrdinalContentDetailParam);
}

function * _updateOrdinalContentDetailParam ({ payload }) {
  const request = snakecaseKeys([...payload]);
  yield createRequest({
    api: {
      ...API.UPDATE_ORDINAL_CONTENT_DETAIL_PARAM,
      data: [...request]
    },
    onSuccess: function * (response) {
      yield put({
        type: MessageTypes.GET_LIST_CONTENT_DETAIL_PARAM_REQUEST,
        payload: payload.pagination
      });
    }
  });
}

function * deleteContentDetailParam () {
  yield takeEvery(MessageTypes.DELETE_CONTENT_DETAIL_PARAM_REQUEST, _deleteContentDetailParam);
}

function * _deleteContentDetailParam ({ payload }) {
  const api = { ...API.DELETE_CONTENT_DETAIL_PARAM };
  api.url += payload.data.id;
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.delete.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: MessageTypes.GET_LIST_CONTENT_DETAIL_PARAM_REQUEST,
        payload: payload.pagination
      });
    }
  });
}

// Page Title Management
function * getPageTitle () {
  yield takeEvery(MessageTypes.GET_PAGE_TITLE_REQUEST, _getPageTitle);
}

function * _getPageTitle ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_PAGE_TITLE
    },
    onSuccess: function * (response) {
      yield put({
        type: MessageTypes.GET_PAGE_TITLE_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: MessageTypes.GET_PAGE_TITLE_ERROR,
        payload: status
      });
    }
  });
}

function * updatePageTitle () {
  yield takeEvery(MessageTypes.UPDATE_PAGE_TITLE_REQUEST, _updatePageTitle);
}

function * _updatePageTitle ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.UPDATE_PAGE_TITLE,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: MessageTypes.UPDATE_PAGE_TITLE_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    }
  });
}

// Search Box Management
function * getSearchBox () {
  yield takeEvery(MessageTypes.GET_SEARCH_BOX_REQUEST, _getSearchBox);
}

function * _getSearchBox ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GET_SEACH_BOX,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: MessageTypes.GET_SEARCH_BOX_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * getAllSearchBox () {
  yield takeEvery(MessageTypes.GET_SEARCH_BOX_ALL, _getAllSearchBox);
}

function * _getAllSearchBox ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GET_ALL_SEACH_BOX,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: MessageTypes.GET_SEARCH_BOX_ALL_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * getSearchBoxDetail () {
  yield takeEvery(MessageTypes.GET_SEARCH_BOX_DETAIL_REQUEST, _getSearchBoxDetail);
}

function * _getSearchBoxDetail ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_SEACH_BOX_DETAIL,
      url: API.GET_SEACH_BOX_DETAIL.url + payload
    },
    onSuccess: function * (response) {
      yield put({
        type: MessageTypes.GET_SEARCH_BOX_DETAIL_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * addSearchBox () {
  yield takeEvery(MessageTypes.ADD_SEARCH_BOX_REQUEST, _addSearchBox);
}

function * _addSearchBox ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.ADD_SEACH_BOX,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: MessageTypes.GET_SEARCH_BOX_REQUEST,
        payload: {
          ...PAGINATION
        }
      });
    }
  });
}

function * updateSearchBox () {
  yield takeEvery(MessageTypes.UPDATE_SEARCH_BOX_REQUEST, _updateSearchBox);
}

function * _updateSearchBox ({ payload }) {
  const request = snakecaseKeys({ ...payload.body });
  const api = { ...API.UPDATE_SEACH_BOX };
  api.url += payload.id;
  yield createRequest({
    api: {
      ...api,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: MessageTypes.GET_SEARCH_BOX_REQUEST,
        payload: {
          ...PAGINATION
        }
      });
      yield put({
        type: MessageTypes.GET_SEARCH_BOX_ALL,
        payload: {}
      });
      yield put({
        type: MessageTypes.GET_TAB_REQUEST,
        payload: {}
      });
    }
  });
}

function * deleteSearchBox () {
  yield takeEvery(MessageTypes.DELETE_SEARCH_BOX_REQUEST, _deleteSearchBox);
}

function * _deleteSearchBox ({ payload }) {
  yield createRequest({
    api: {
      ...API.DELETE_SEACH_BOX,
      url: API.DELETE_SEACH_BOX.url + payload
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.delete.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: MessageTypes.GET_SEARCH_BOX_REQUEST,
        payload: {
          ...PAGINATION
        }
      });
    }
  });
}

// Layout Management - Tab
function * getTab () {
  yield takeEvery(MessageTypes.GET_TAB_REQUEST, _getTab);
}

function * _getTab ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_TAB
    },
    onSuccess: function * (response) {
      yield put({
        type: MessageTypes.GET_TAB_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * getTabDetail () {
  yield takeEvery(MessageTypes.GET_TAB_DETAIL_REQUEST, _getTabDetail);
}

function * _getTabDetail ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_TAB_DETAIL,
      url: API.GET_TAB_DETAIL.url + payload
    },
    onSuccess: function * (response) {
      yield put({
        type: MessageTypes.GET_TAB_DETAIL_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * addTab () {
  yield takeEvery(MessageTypes.ADD_TAB_REQUEST, _addTab);
}

function * _addTab ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.ADD_TAB,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: MessageTypes.GET_TAB_REQUEST,
        payload: {
          PAGINATION
        }
      });

      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    }
  });
}

function * updateTab () {
  yield takeEvery(MessageTypes.UPDATE_TAB_REQUEST, _updateTab);
}

function * _updateTab ({ payload }) {
  const request = snakecaseKeys({ ...payload.body });
  yield createRequest({
    api: {
      ...API.UPDATE_TAB,
      url: API.UPDATE_TAB.url + payload.id,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: MessageTypes.GET_TAB_REQUEST,
        payload: {
          PAGINATION
        }
      });

      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    }
  });
}

function * updateOrdinalTab () {
  yield takeEvery(MessageTypes.UPDATE_ORDINAL_TAB_REQUEST, _updateOrdinalTab);
}

function * _updateOrdinalTab ({ payload }) {
  const request = snakecaseKeys([...payload]);
  yield createRequest({
    api: {
      ...API.UPDATE_ORDINAL_TAB,
      data: [...request]
    },
    onSuccess: function * (response) {
      yield put({
        type: MessageTypes.GET_TAB_REQUEST,
        payload: payload.pagination
      });
    }
  });
}

function * deleteTab () {
  yield takeEvery(MessageTypes.DELETE_TAB_REQUEST, _deleteTab);
}

function * _deleteTab ({ payload }) {
  yield createRequest({
    api: {
      ...API.DELETE_TAB,
      url: API.DELETE_TAB.url + payload
    },
    onSuccess: function * (response) {
      yield put({
        type: MessageTypes.GET_TAB_REQUEST,
        payload: {
          PAGINATION
        }
      });

      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.delete.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    }
  });
}

// Search result management
function * loadSearchResultContents () {
  yield takeEvery(MessageTypes.GET_SEARCH_RESULT_REQUEST, _loadSearchResultContents);
}

function * _loadSearchResultContents ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_SEACH_RESULT_LIST_CONTENT,
      params: payload
    },
    onSuccess: function * (data) {
      yield put({
        type: MessageTypes.UPDATE_SEARCH_RESULT_QUERIES,
        payload: payload
      });
      yield put({
        type: MessageTypes.GET_SEARCH_RESULT_SUCCESS,
        payload: Util.toCamelCaseKey(data.data)
      });
    }
  });
}

function * updateDetailSearchResult () {
  yield takeEvery(MessageTypes.UPDATE__SEARCH_RESULT_SETTING_REQUEST, _updateDetailSearchResult);
}

function * _updateDetailSearchResult ({ payload }) {
  const api = { ...API.UPDATE_DETAIL_SEACH_RESULT };
  yield createRequest({
    api: {
      ...api,
      data: snakecaseKeys(payload.data)
    },
    onSuccess: function * (data) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${data.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: MessageTypes.GET_SEARCH_RESULT_REQUEST,
        payload: payload.queries
      });
    }
  });
}

function * updateOrdinalSearchResultSetting () {
  yield takeEvery(MessageTypes.UPDATE_ORDINAL_SEARCH_RESULT_SETTING_REQUEST, _updateOrdinalSearchResultSetting);
}

function * _updateOrdinalSearchResultSetting ({ payload }) {
  const request = snakecaseKeys([...payload]);
  yield createRequest({
    api: {
      ...API.UPDATE_ORDINAL_SEARCH_RESULT,
      data: [...request]
    },
    onSuccess: function * (response) {
      yield put({
        type: MessageTypes.GET_SEARCH_RESULT_REQUEST,
        payload: payload.pagination
      });
    }
  });
}

function * loadContentParamDetail () {
  yield takeEvery(MessageTypes.GET_CONTENT_PARAM_DETAIL_REQUEST, _loadContentParamDetail);
}
function * _loadContentParamDetail ({ payload }) {
  const api = { ...API.GET_CONTENT_DETAIL };
  api.url += payload;
  yield createRequest({
    api: api,
    onSuccess: function * (data) {
      yield put({
        type: MessageTypes.GET_CONTENT_PARAM_DETAIL_SUCCESS,
        payload: Util.toCamelCaseKey(data.data)
      });
    }
  });
}
function * updateContentParamDetail () {
  yield takeEvery(MessageTypes.UPDATE_CONTENT_PARAM_REQUEST, _updateContentParamDetail);
}

function * _updateContentParamDetail ({ payload }) {
  const api = { ...API.UPDATE_CONTENT_PARAM };
  api.url += payload.data.id;
  yield createRequest({
    api: {
      ...api,
      data: snakecaseKeys(payload.data)
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: MessageTypes.GET_SEARCH_RESULT_REQUEST,
        payload: payload.queries
      });
    }
  });
}
function * addContentParam () {
  yield takeEvery(MessageTypes.ADD_CONTENT_PARAM_REQUEST, _addContentParam);
}

function * _addContentParam ({ payload }) {
  yield createRequest({
    api: {
      ...API.ADD_CONTENT_PARAM,
      data: snakecaseKeys(payload.data)
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: MessageTypes.GET_SEARCH_RESULT_REQUEST,
        payload: {
          ...PAGINATION
        }
      });
    }
  });
}

function * deleteContentParam () {
  yield takeEvery(MessageTypes.DELETE_CONTENT_PARAM_REQUEST, _deleteContentParam);
}
function * _deleteContentParam ({ payload }) {
  const api = { ...API.DELETE_CONTENT_PARAM };
  api.url += payload.data.id;
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.delete.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: MessageTypes.GET_SEARCH_RESULT_REQUEST,
        payload: payload.pagination
      });
    }
  });
}
function * loadSearchResultDetail () {
  yield takeEvery(MessageTypes.GET_SEARCH_RESULT_DETAIL_REQUEST, _loadSearchResultDetail);
}
function * _loadSearchResultDetail ({ payload }) {
  const api = { ...API.GET_SEACH_RESULT_SETTING_DETAIL };
  yield createRequest({
    api: api,
    onSuccess: function * (data) {
      yield put({
        type: MessageTypes.GET_SEARCH_RESULT_DETAIL_SUCCESS,
        payload: Util.toCamelCaseKey(data.data)
      });
    }
  });
}

export default function * searchSaga () {
  yield all([
    getPageTitle(),
    updatePageTitle(),

    getSearchBox(),
    getAllSearchBox(),
    getSearchBoxDetail(),
    addSearchBox(),
    updateSearchBox(),
    deleteSearchBox(),

    getTab(),
    getTabDetail(),
    addTab(),
    updateTab(),
    deleteTab(),
    updateOrdinalTab(),

    loadSearchResultContents(),
    updateDetailSearchResult(),
    loadContentParamDetail(),
    updateContentParamDetail(),
    addContentParam(),
    deleteContentParam(),
    loadSearchResultDetail(),
    updateOrdinalSearchResultSetting(),

    addContentDetail(),
    getContentDetail(),
    updateContentDetailParam(),
    getListContentDetailParam(),
    deleteContentDetailParam(),
    updateOrdinalContentDetailParam()
  ]);
}
