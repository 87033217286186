export default {
  GET_IMPORT_DATA: {
    url: '/import-csv-file/page',
    method: 'GET'
  },
  GET_IMPORT_DETAIL: {
    url: '/import-csv-file/detail/',
    method: 'GET'
  },
  ADD_IMPORT: {
    url: '/import-csv-file/add',
    method: 'POST'
  },
  UPDATE_IMPORT: {
    url: '/import-csv-file/update/',
    method: 'PUT'
  },
  DELETE_IMPORT: {
    url: '/import-csv-file/delete/',
    method: 'DELETE'
  },
  UPLOAD_IMPORT: {
    url: '/import-csv-file/upload-add',
    method: 'POST'
  },
  EXPORT_LOGS: {
    url: '/import-csv-file/export',
    method: 'GET'
  },
  DOWNLOAD_TEMPLATE: {
    url: '/import-csv-file/download-template',
    method: 'GET'
  },
  DOWNLOAD_FILE_LOGS: {
    url: '/import-csv-file/download-file-error',
    method: 'GET'
  },
  UPLOAD_CONTENT_CSV: {
    url: '/import-csv-file/upload-csv',
    method: 'POST'
  },
  CHECK_IMPORT_PROGRESS: {
    url: '/import-csv-file/check-import-progress',
    method: 'GET'
  },
  IMPORT_CONTENT_CSV: {
    url: '/import-csv-file/import-csv',
    method: 'POST'
  }
};
