import InputTypes from './InputTypes';

const initialState = {
  inputSetting: {}
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  const newState = { ...state };
  switch (type) {
    case InputTypes.GET_INPUT_SUCCESS:
      newState.inputSetting = { ...payload };
      return newState;
  }
  return newState;
}
