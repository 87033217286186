import { all, put, takeEvery } from 'redux-saga/effects';
import ButtonTypes from './ButtonTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/button';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';
import { PAGINATION } from '../../configs/AppConfig';

// Button management

function * getListButton () {
  yield takeEvery(ButtonTypes.GET_LIST_BUTTON_REQUEST, _getListButton);
}

function * _getListButton ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GET_LIST_BUTTON,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: ButtonTypes.GET_LIST_BUTTON_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * getAllButton () {
  yield takeEvery(ButtonTypes.GET_ALL_BUTTON_REQUEST, _getAllButton);
}

function * _getAllButton ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GET_ALL_BUTTON,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: ButtonTypes.GET_ALL_BUTTON_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * getButtonDetail () {
  yield takeEvery(ButtonTypes.GET_BUTTON_REQUEST, _getButtonDetail);
}

function * _getButtonDetail ({ payload }) {
  const api = {
    ...API.GET_BUTTON_DETAIL,
    url: API.GET_BUTTON_DETAIL.url + payload
  };
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      yield put({
        type: ButtonTypes.GET_BUTTON_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * addButton () {
  yield takeEvery(ButtonTypes.ADD_BUTTON_REQUEST, _addButton);
}

function * _addButton ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.ADD_BUTTON,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: ButtonTypes.GET_LIST_BUTTON_REQUEST,
        payload: PAGINATION
      });
    },
    onError: function * (error) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.error.${error.status}`,
            level: 'error',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    }
  });
}

function * updateButton () {
  yield takeEvery(ButtonTypes.UPDATE_BUTTON_REQUEST, _updateButton);
}

function * _updateButton ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.UPDATE_BUTTON,
      url: API.UPDATE_BUTTON.url + payload.id,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: ButtonTypes.GET_LIST_BUTTON_REQUEST,
        payload: PAGINATION
      });
    }
  });
}

function * deleteButton () {
  yield takeEvery(ButtonTypes.DELETE_BUTTON_REQUEST, _deleteButton);
}

function * _deleteButton ({ payload }) {
  const api = { ...API.DELETE_BUTTON };
  api.url += payload;
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.delete.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: ButtonTypes.GET_LIST_BUTTON_REQUEST,
        payload: PAGINATION
      });
    }
  });
}

export default function * buttonSaga () {
  yield all([
    getListButton(),
    getAllButton(),
    getButtonDetail(),
    addButton(),
    updateButton(),
    deleteButton()
  ]);
}
