import SessionTypes from './SessionTypes';
import StorageHelper from '../../utils/StorageHelper';
import { STORAGE_KEYS } from '../../configs/AppConfig';

const _isAuthenticated = !!StorageHelper.getCookie(STORAGE_KEYS.session);
const initialState = {
  isAuthenticated: false,
  isSessionValidating: _isAuthenticated, // for rendering flash screen
  isRegistering: false,
  isRegistered: false,
  user: {}
};

export default function sessionReducer (state = initialState, action) {
  let newState;
  const { type, payload } = action;
  switch (type) {
    case SessionTypes.RESTORE_SESSION_SUCCESS:
      return Object.assign({}, state, {
        isAuthenticated: true,
        isSessionValidating: false,
        user: payload
      });
    case SessionTypes.LOG_IN_REQUEST:
      return Object.assign({}, state, {
        isLoading: true
      });
    case SessionTypes.LOG_IN_SUCCESS:
      return Object.assign({}, state, {
        isAuthenticated: true,
        isSessionValidating: false,
        authToken: payload.accessToken,
        user: payload.user,
        isLoading: false
      });

    case SessionTypes.RESTORE_SESSION_FAILURE:
    case SessionTypes.REAUTHORIZE:
    case SessionTypes.LOG_IN_ERROR:
    case SessionTypes.LOG_OUT_SUCCESS:
    case SessionTypes.CLEAR_AUTH:
      return Object.assign({}, state,
        {
          isAuthenticated: false,
          isSessionValidating: false,
          authToken: '',
          isLoading: false,
          user: {}
        }
      );
    case SessionTypes.REGISTER_ACCOUNT_USER_REQUEST:
      return Object.assign({}, state, {
        isRegistering: true
      });
    case SessionTypes.REGISTER_ACCOUNT_USER_SUCCESS:
      return Object.assign({}, state, {
        isRegistering: false,
        isRegistered: true
      });
    case SessionTypes.REGISTER_ACCOUNT_USER_ERROR:
      return Object.assign({}, state, {
        isRegistering: false,
        isRegistered: false
      });
    case SessionTypes.RESET_USER_REGISTERED:
      return Object.assign({}, state, {
        isRegistering: false,
        isRegistered: false
      });
    default:
      newState = state;
  }

  return newState;
}
