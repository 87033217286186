import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import CreditCardInput from 'react-credit-card-input';
import { Button } from 'reactstrap';
import { ActionContext } from 'components/layout/MainContainer';
import Util from 'utils/Util';
import { getBeUserId, getToken } from 'state/session/sessionSelector';

const UserCardForm = props => {
  const {
    paymentActions: { veritransUpdateCard },
    notificationActions: { showNotification }
  } = props;
  const [isValidCard, setIsValidCard] = useState(false);
  const { formatMessage: f } = useIntl();
  const [cardNumber, setCardNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [isUpdateCard, setIsUpdateCard] = useState(false);
  // const [isAuthorizeLoading, setIsAuthorizeLoading] = useState(false);
  const token = useSelector(getToken);
  const beUserId = useSelector(getBeUserId);

  const authUser = useSelector((state) => state.session.user) || {};

  useEffect(() => {
    if (authUser && authUser.cardNumber) {
      setIsUpdateCard(false);
    }
  }, [authUser]);

  const submitCard = () => {
    if (!authUser.cardNumber || isUpdateCard) {
      if (isValidCard) {
        if (cardNumber.length === 0 || cvc.length === 0 || expiry.length === 0) {
          showNotification({
            message: 'toast.error.creditCardEmpty',
            level: 'error',
            autoDismiss: 3,
            position: 'tc'
          });
        } else {
          // setIsAuthorizeLoading(true);
          const tokenApiKey = Util.decrypt(beUserId, token, authUser.tokenApiKey);
          const request = {
            cardNumber: cardNumber.replaceAll(' ', ''),
            cardExpire: expiry.replaceAll(' ', ''),
            securityCode: cvc,
            tokenApiKey: tokenApiKey,
            lang: authUser.lang,
            authUser,
            isUpdateCard: true
          };
          const errorMessage = Util.handleVeritransPayment(request, veritransUpdateCard);
          if (errorMessage) {
            showNotification({
              message: errorMessage,
              level: 'error',
              autoDismiss: 3,
              position: 'tc'
            });
          }
        }
      } else {
        showNotification({
          message: 'api.error.841',
          level: 'error',
          autoDismiss: 3,
          position: 'tc'
        });
      }
    } else {
      console.log('error');
    }
    setIsUpdateCard(false);
  };

  // const execPurchase = data => {
  //   const { resultCode, tokenObject = {} } = data;
  //   const { token } = tokenObject;
  //   if (resultCode !== '000') {
  //     showNotification({
  //       message: 'toast.error.payment',
  //       level: 'error',
  //       autoDismiss: 3,
  //       position: 'tc'
  //     });
  //   } else {
  //     veritransUpdateCard({
  //       token
  //     });
  //   }
  // };

  const showUpdateCard = () => {
    setCardNumber('');
    setExpiry('');
    setCvc('');
    setIsValidCard(true);
    setIsUpdateCard(() => true);
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-sm-12 col-md-6 py-2">
          {
            (!authUser.cardNumber || isUpdateCard) && <CreditCardInput
              customTextLabels={{
                invalidCardNumber: f({ id: 'validate.card.invalidCardNumber' }),
                expiryError: {
                  invalidExpiryDate: f({ id: 'validate.card.invalidExpiryDate' }),
                  monthOutOfRange: f({ id: 'validate.card.monthOutOfRange' }),
                  yearOutOfRange: f({ id: 'validate.card.yearOutOfRange' }),
                  dateOutOfRange: f({ id: 'validate.card.dateOutOfRange' })
                },
                invalidCvc: f({ id: 'validate.card.invalidCvc' }),
                invalidZipCode: f({ id: 'validate.card.invalidZipCode' }),
                cardNumberPlaceholder: f({ id: 'validate.card.cardNumberPlaceholder' })
              }}
              onError={({ err }) => {
                console.log(`credit card input error: ${err}`);
                setIsValidCard(false);
              }}
              containerStyle={{ display: 'flex', flexDirection: 'column' }}
              fieldStyle={{ border: '1px solid green', justifyContent: 'space-between', borderRadius: 0 }}
              inputStyle={{ paddingTop: 7 }}
              cardNumberInputProps={{
                value: cardNumber,
                onChange: e => {
                  setCardNumber(e.target.value);
                  setIsValidCard(true);
                }
              }}
              cardExpiryInputProps={{
                value: expiry,
                onChange: e => {
                  setExpiry(e.target.value);
                  setIsValidCard(true);
                }
              }}
              cardCVCInputProps={{
                value: cvc,
                onChange: e => {
                  setCvc(e.target.value);
                  setIsValidCard(true);
                }
              }}
              fieldClassName="input"
            />
          }
          {
            authUser.cardNumber && <React.Fragment>
              {
                !isUpdateCard && <div style={{ display: 'flex', width: '100%' }}>
                  <i className="fas fa-credit-card mr-1" style={{
                    display: 'flex',
                    alignItems: 'center'
                  }} /> {authUser.cardNumber}
                </div>
              }
            </React.Fragment>
          }
        </div>
        <div className="col-sm-12 col-md-12 py-2">
          {
            isUpdateCard && <Button
              className={'btn btn-warning'}
              variant="contained"
              color="primary"
              type="button"
              onClick={() => {
                setIsUpdateCard(() => false);
                setCardNumber('');
                setExpiry('');
                setCvc('');
                setIsValidCard(true);
              }}
            >
              {f({ id: 'button.cancel' })}
            </Button>
          }
          <Button
            className={'btn btn-info'}
            variant="contained"
            color="primary"
            type="button"
            onClick={() => {
              if (!isUpdateCard && authUser.cardNumber) {
                showUpdateCard();
              } else {
                submitCard();
              }
            }}
          >
            {f({ id: 'button.update' })}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

UserCardForm.propTypes = {
  paymentActions: PropTypes.object,
  notificationActions: PropTypes.object
};

const UserCard = props => <ActionContext.Consumer>
  {({
    paymentActions, notificationActions
  }) => (
    <UserCardForm
      paymentActions={paymentActions}
      notificationActions={notificationActions}
      {...props}
    />
  )}
</ActionContext.Consumer>;

export default UserCard;
