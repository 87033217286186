import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// update site header [title tag] when route is changed
const AppTitle = ({ intl, routeTitle = '', companyName }) =>
  intl && intl.formatMessage ? (
    <Helmet>
      {
        companyName
          ? (
            <title>
              { companyName }
            </title>
          )
          : (
            <title>
              {routeTitle
                ? `
              ${intl.formatMessage({ id: routeTitle, defaultMessage: routeTitle })}
              -
              ${intl.formatMessage({
                  id: 'app.name',
                  defaultMessage: 'URSS'
                })}
              `
                : `
              ${intl.formatMessage({
                  id: 'app.name',
                  defaultMessage: 'URSS'
                })}
              `}
            </title>
          )
      }
    </Helmet>
  ) : null;

AppTitle.propTypes = {
  intl: PropTypes.object,
  routeTitle: PropTypes.string,
  companyName: PropTypes.any
};

export default React.memo(props => <AppTitle {...props} />);
