import TemplateTypes from './TemplateTypes';

const initialState = {
  header: {},
  body: {},
  footer: {},
  linkDetail: {}
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  const newState = { ...state };
  switch (type) {
    case TemplateTypes.GET_HEADER_SUCCESS:
      newState.header = { ...payload };
      return newState;
    case TemplateTypes.GET_BODY_SUCCESS:
      newState.body = { ...payload };
      return newState;
    case TemplateTypes.GET_FOOTER_SUCCESS:
      newState.footer = { ...payload };
      return newState;
    case TemplateTypes.GET_LINK_SUCCESS:
      newState.linkDetail = { ...payload };
      return newState;
  }
  return newState;
}
