import React from 'react';
import { Grid } from '@material-ui/core';
import { Spinner } from 'reactstrap';
import TranslateMessage from '../common/TranslateMessage/TranslateMessage';

const Loading = ({ error, retry, pastDelay }) => {
  if (error) {
    return (
      <div>
        Error! <button onClick={retry}>Retry</button>
      </div>
    );
  } else if (pastDelay) {
    return (
      <Grid
        container
        spacing={0}
        direction='row'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '100vh', backgroundColor: '#FFF' }}
      >
        <Spinner color='primary' style={{ marginRight: 20 }} /> <TranslateMessage id='progress.loading'/>
      </Grid>
    );
  } else {
    return null;
  }
};

export default Loading;
