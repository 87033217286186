import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { LocaleContext } from '../../layout/MainContainer';

const TranslateMessage = ({ className, style, id, defaultMessage }) => (
  <FormattedMessage id={id} defaultMessage={defaultMessage || id}>
    {message => (
      <span className={className} style={style}>
        {message}
      </span>
    )}
  </FormattedMessage>
);

TranslateMessage.defaultProps = {
  defaultMessage: null,
  className: null,
  style: null
};

TranslateMessage.propTypes = {
  id: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string,
  className: PropTypes.any,
  style: PropTypes.object
};

const TranslateMessageComponent = props => (
  <LocaleContext.Consumer>
    {({ locale }) => <TranslateMessage locale={locale} {...props} />}
  </LocaleContext.Consumer>
);

export default TranslateMessageComponent;
