import SubscriptionTypes from './SubscriptionTypes';

// Subscription management
export function getSubscriptionPlans (data) {
  return {
    type: SubscriptionTypes.GET_SUBSCRIPTION_PLAN_REQUEST,
    payload: data
  };
}
export function getSubscriptionDetail (data) {
  return {
    type: SubscriptionTypes.GET_SUBSCRIPTION_PLAN_DETAIL_REQUEST,
    payload: data
  };
}
export function updateSubscriptionPlan (data) {
  return {
    type: SubscriptionTypes.UPDATE_SUBSCRIPTION_PLAN_DETAIL_REQUEST,
    payload: data
  };
}
export function deleteSubscriptionPlan (data) {
  return {
    type: SubscriptionTypes.DELETE_SUBSCRIPTION_PLAN_DETAIL_REQUEST,
    payload: data
  };
}
export function addSubscriptionPlan (data) {
  return {
    type: SubscriptionTypes.ADD_SUBSCRIPTION_PLAN_REQUEST,
    payload: data
  };
}
export function getPageSubscriptionPlan (data) {
  return {
    type: SubscriptionTypes.GET_PAGE_SUBSCRIPTION_PLAN_REQUEST,
    payload: data
  };
}
export function cancelUserRegistered (data) {
  return {
    type: SubscriptionTypes.CANCEL_SUBSCRIPTION_REGISTERED_REQUEST,
    payload: data
  };
}
export function getPageUserSubscriptionPlans (data) {
  return {
    type: SubscriptionTypes.GET_PAGE_USER_SUBSCRIPTION_PLAN_REQUEST,
    payload: data
  };
}
export function getHomeSubscriptionPlans (data) {
  return {
    type: SubscriptionTypes.GET_HOME_SUBSCRIPTION_PLAN_LIST_REQUEST,
    payload: data
  };
}
export function getDetailUserRegistered (data) {
  return {
    type: SubscriptionTypes.GET_DETAIL_SUBSCRIPTION_PLAN_USER_REGISTERED_REQUEST,
    payload: data
  };
}
export function getUserRegisteredPage (data) {
  return {
    type: SubscriptionTypes.GET_PAGE_SUBSCRIPTION_PLAN_USER_REGISTERED_REQUEST,
    payload: data
  };
}
export function openSubscriptionPopUp () {
  return {
    type: SubscriptionTypes.OPEN_SUBSCRIPTION_POPUP
  };
}
export function closeSubscriptionPopUp () {
  return {
    type: SubscriptionTypes.CLOSE_SUBSCRIPTION_POPUP
  };
}
export function loadDiscountOrdinalPage (data) {
  return {
    type: SubscriptionTypes.GET_DISCOUNT_ORDINAL_PAGE_REQUEST,
    payload: data
  };
}
export function loadDiscountOrdinalDetail (data) {
  return {
    type: SubscriptionTypes.GET_DISCOUNT_ORDINAL_DETAIL_REQUEST,
    payload: data
  };
}
export function addDiscountOrdinal (data) {
  return {
    type: SubscriptionTypes.ADD_DISCOUNT_ORDINAL_REQUEST,
    payload: data
  };
}
export function updateDiscountOrdinal (data) {
  return {
    type: SubscriptionTypes.UPDATE_DISCOUNT_ORDINAL_REQUEST,
    payload: data
  };
}
export function deleteDiscountOrdinal (data) {
  return {
    type: SubscriptionTypes.DELETE_DISCOUNT_ORDINAL_REQUEST,
    payload: data
  };
}
