import { all, put, select, takeEvery } from 'redux-saga/effects';
import ContentGroupTypes from './ContentGroupTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/content-group';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';
import { getCompanyDomain } from '../company/companySelector';
import { getPagination } from 'state/content-group/contentGroupSelector';
import { CONTENT_GROUP_TYPE, PAGINATION } from 'configs/AppConfig';

function * loadContentGroupData () {
  yield takeEvery(ContentGroupTypes.GET_CONTENT_GROUP_DATA_REQUEST, _loadContentGroupData);
}

function * _loadContentGroupData ({ payload }) {
  const domain = yield select(getCompanyDomain);
  const request = snakecaseKeys({ ...payload, domain });
  yield createRequest({
    api: {
      ...API.GET_CONTENT_GROUP_DATA,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: ContentGroupTypes.GET_CONTENT_GROUP_DATA_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: ContentGroupTypes.GET_CONTENT_GROUP_DATA_ERROR,
        payload: status
      });
    }
  });
}

function * loadContentGroupAllData () {
  yield takeEvery(ContentGroupTypes.GET_CONTENT_GROUP_ALL_REQUEST, _loadContentGroupAllData);
}

function * _loadContentGroupAllData ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_CONTENT_GROUP_ALL_DATA,
      params: snakecaseKeys({ ...payload })
    },
    onSuccess: function * (response) {
      yield put({
        type: ContentGroupTypes.GET_CONTENT_GROUP_ALL_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: ContentGroupTypes.GET_CONTENT_GROUP_ALL_ERROR,
        payload: status
      });
    }
  });
}

function * loadContentGroupDetail () {
  yield takeEvery(ContentGroupTypes.GET_CONTENT_GROUP_DETAIL_REQUEST, _loadContentGroupDetail);
}

function * _loadContentGroupDetail ({ payload }) {
  const api = {
    ...API.GET_CONTENT_GROUP_DETAIL,
    url: API.GET_CONTENT_GROUP_DETAIL.url + payload
  };
  yield createRequest({
    api: api,
    onSuccess: function * (data) {
      yield put({
        type: ContentGroupTypes.GET_CONTENT_GROUP_DETAIL_SUCCESS,
        payload: Util.toCamelCaseKey(data.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: ContentGroupTypes.GET_CONTENT_GROUP_DETAIL_ERROR,
        payload: status
      });
    }
  });
}

function * _deleteContentGroup ({ payload }) {
  const pagination = yield select(getPagination);
  yield createRequest({
    api: {
      ...API.DELETE_CONTENT_GROUP,
      url: API.DELETE_CONTENT_GROUP.url + payload.data.id
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.delete.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: ContentGroupTypes.GET_CONTENT_GROUP_DATA_REQUEST,
        payload: {
          ...pagination
        }
      });
    }
  });
}

function * deleteContentGroup () {
  yield takeEvery(ContentGroupTypes.DELETE_CONTENT_GROUP_REQUEST, _deleteContentGroup);
}

function * _addContentGroup ({ payload }) {
  const request = snakecaseKeys({ ...payload });

  yield createRequest({
    api: {
      ...API.ADD_CONTENT_GROUP,
      data: request.data
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: ContentGroupTypes.GET_CONTENT_GROUP_DATA_REQUEST,
        payload: {
          ...PAGINATION
        }
      });
    }
  });
}

function * addContentGroup () {
  yield takeEvery(ContentGroupTypes.ADD_CONTENT_GROUP_REQUEST, _addContentGroup);
}

function * _updateContentGroup ({ payload }) {
  const request = snakecaseKeys({ ...payload.data });
  const pagination = yield select(getPagination);
  yield createRequest({
    api: {
      ...API.UPDATE_CONTENT_GROUP,
      data: request,
      url: API.UPDATE_CONTENT_GROUP.url + payload.data.id
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      if (Util.toCamelCaseKey(response.data).type === CONTENT_GROUP_TYPE.NORMAL) {
        yield put({
          type: ContentGroupTypes.GET_CONTENT_GROUP_DATA_REQUEST,
          payload: {
            ...pagination
          }
        });
      } else {
        yield put({
          type: ContentGroupTypes.GET_FAVORITES_RECENTLY_REQUEST,
          payload: {}
        });
      }
    }
  });
}

function * updateContentGroup () {
  yield takeEvery(ContentGroupTypes.UPDATE_CONTENT_GROUP_REQUEST, _updateContentGroup);
}

function * _resetContentGroup () {
  yield createRequest({
    api: {
      ...API.RESET_CONTENT_GROUP
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    }
  });
}
function * resetContentGroup () {
  yield takeEvery(ContentGroupTypes.RESET_CONTENT_GROUP_REQUEST, _resetContentGroup);
}

function * _getFavoritesRecentlyRead () {
  yield createRequest({
    api: {
      ...API.GET_FAVORITES_RECENTLY_READ
    },
    onSuccess: function * (response) {
      yield put({
        type: ContentGroupTypes.GET_FAVORITES_RECENTLY_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * getFavoritesRecentlyRead () {
  yield takeEvery(ContentGroupTypes.GET_FAVORITES_RECENTLY_REQUEST, _getFavoritesRecentlyRead);
}

function * _updateRefCode () {
  yield createRequest({
    api: {
      ...API.UPDATE_REF_CODE
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    }
  });
}

function * updateRefCode () {
  yield takeEvery(ContentGroupTypes.UPDATE_REF_CODE_REQUEST, _updateRefCode);
}

export default function * contentGroupSaga () {
  yield all([
    loadContentGroupData(),
    loadContentGroupDetail(),
    loadContentGroupAllData(),
    addContentGroup(),
    deleteContentGroup(),
    updateContentGroup(),
    resetContentGroup(),
    getFavoritesRecentlyRead(),
    updateRefCode()
  ]);
}
