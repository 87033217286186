import { all, put, select, takeEvery } from 'redux-saga/effects';
import SearchTypes from './SearchTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/search';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';
import { getCompanyDomain } from '../company/companySelector';
import { isAuthenticated } from '../session/sessionSelector';

function * getData () {
  yield takeEvery(SearchTypes.GET_DATA_REQUEST, _getData);
}

function * _getData ({ payload }) {
  const domain = yield select(getCompanyDomain);
  const { searchRequests, pagination } = payload;
  const request = snakecaseKeys({ ...pagination, ...domain });
  yield createRequest({
    api: {
      ...API.GET_DATA,
      data: snakecaseKeys([...searchRequests]),
      params: {
        page: request.page,
        size: request.size,
        domain: domain,
        sort: request.sort,
        sort_by: request.sort_by,
        is_new_search: request.is_new_search
      }
    },
    onSuccess: function * (response) {
      yield put({
        type: SearchTypes.GET_DATA_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SearchTypes.GET_DATA_ERROR,
        payload: status
      });
    }
  });
}

function * getPublicData () {
  yield takeEvery(SearchTypes.GET_PUBLIC_DATA_REQUEST, _getPublicData);
}

function * _getPublicData ({ payload }) {
  const domain = yield select(getCompanyDomain);
  const { searchRequests, pagination } = payload;
  const request = snakecaseKeys({ ...pagination, ...domain });
  yield createRequest({
    api: {
      ...API.GET_PUBLIC_DATA,
      data: snakecaseKeys([...searchRequests]),
      params: {
        page: request.page,
        size: request.size,
        domain: domain,
        sort: request.sort,
        sort_by: request.sort_by,
        is_new_search: request.is_new_search
      }
    },
    onSuccess: function * (response) {
      yield put({
        type: SearchTypes.GET_PUBLIC_DATA_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SearchTypes.GET_PUBLIC_DATA_ERROR,
        payload: status
      });
    }
  });
}

function * getDetail () {
  yield takeEvery(SearchTypes.GET_CONTENT_INFO_REQUEST, _getDetail);
}

function * _getDetail ({ payload }) {
  const referer = document.referrer;
  const domain = yield select(getCompanyDomain);
  const { contentId, contentToken, contentGroupId } = payload;
  const request = snakecaseKeys({ domain, detailId: contentToken, contentGroupId, id: contentId });
  yield createRequest({
    api: {
      ...API.GET_INFO,
      url: API.GET_INFO.url,
      params: { ...request },
      headers: {
        'cus-referer': referer || ''
      }
    },
    onSuccess: function * (response) {
      yield put({
        type: SearchTypes.GET_CONTENT_INFO_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SearchTypes.GET_CONTENT_INFO_ERROR,
        payload: status
      });
    }
  });
}

function * loadOptions () {
  yield takeEvery(SearchTypes.GET_OPTIONS_REQUEST, _loadOptions);
}

function * _loadOptions ({ payload }) {
  yield put({
    type: SearchTypes.GET_OPTIONS_SUCCESS,
    payload: {
      requiredLogin: 1
    }
  });
}

function * searchFullText () {
  yield takeEvery(SearchTypes.SEARCH_FULL_TEXT_REQUEST, _searchFullText);
}

function * _searchFullText ({ payload }) {
  const domain = yield select(getCompanyDomain);
  const isAuthen = yield select(isAuthenticated);
  const request = snakecaseKeys({ ...payload, domain: domain });
  const api = isAuthen ? API.SEARCH_FULL_TEXT_AUTHEN : API.SEARCH_FULL_TEXT;
  yield createRequest({
    api: {
      ...api,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: SearchTypes.SEARCH_FULL_TEXT_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SearchTypes.SEARCH_FULL_TEXT_ERROR,
        payload: status
      });
    }
  });
}

function * loadSearchTermPage () {
  yield takeEvery(SearchTypes.GET_SEARCH_TERM_PAGE_REQUEST, _loadSearchTermPage);
}

function * _loadSearchTermPage ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GET_SEARCH_TERM_PAGE,
      params: request
    },
    onSuccess: function * (response) {
      yield put({
        type: SearchTypes.GET_SEARCH_TERM_PAGE_SUCCESS,
        payload: Util.toCamelCaseKey({ ...payload, ...response.data })
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SearchTypes.GET_SEARCH_TERM_PAGE_ERROR,
        payload: status
      });
    }
  });
}
export default function * searchSaga () {
  yield all([
    getData(),
    getPublicData(),
    getDetail(),
    loadOptions(),
    searchFullText(),
    loadSearchTermPage()
  ]);
}
