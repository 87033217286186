import React, { cloneElement, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { ActionContext, RouterContext } from './MainContainer';
import HomeHeader from 'components/home/HomeHeader';
import { Box, Grid, makeStyles } from '@material-ui/core';
import HomeSidebar from 'components/home/HomeSidebar';
import {
  getBodySetting,
  getLayoutSetting,
  getLoginSetting
} from 'state/search/searchSelector';
import {
  getAuthUser,
  isAuthenticated
} from 'state/session/sessionSelector';
import {
  getCompanyDomain, getViewSettings, getIsLoadSetting
} from 'state/company/companySelector';
import {
  getFulltextSearch,
  getHashtagContentGroups,
  getPublicContentGroups,
  getSearchRequest,
  getShowFavorite,
  getShowHomeSearch
} from 'state/home/homeSelector';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import TranslateMessage from 'components/common/TranslateMessage/TranslateMessage';
import {
  AUTHEN_TYPES,
  HIDE_HOME_SIDEBAR,
  LAYOUT_SETTING,
  PUBLIC_PAGE,
  SETTING_LOGIN,
  SORT_FIELD_TYPE,
  TAB_TYPE,
  USER_CONFIG
} from 'configs/AppConfig';
import find from 'lodash/find';
import Util from 'utils/Util';
import { useMedia } from 'react-use';
import UserProfile from 'views/end-user/UserProfile';
import PublicFooter from 'components/core/PublicFooter';
import { getAllSort } from 'state/sort/sortSelector';
import UserSubscription from 'views/end-user/UserSubscription';

const HomeLayout = props => {
  const {
    children,
    layoutSetting,
    isLoadSetting,
    contentGroups,
    hashtagContentGroups,
    isAuthen,
    authUser,
    domain,
    loginSetting,
    showFavorite,
    listSorts,
    bodySetting,
    showHomeSearch,
    searchRequest,
    fulltextSearch,
    homeActions: {
      loadPublicContentGroups,
      loadContentByContentGroup: loadContents,
      clearContents,
      searchContentByContentGroup: searchContents,
      searchFullText,
      loadContentByHashtag,
      setShowHomeSearch,
      setSearchRequest,
      setFulltextSearch,
      setSearchRequestFullTextSearch
    },
    sortActions: { loadAllSort },
    routeActions: { changeRoute },
    sessionActions: { login },
    hashtagActions: { getPublicHashtag }
  } = props;
  const style = {};

  const userConfig = authUser ? find(USER_CONFIG, { roleLevel: authUser.roleLevel }) : {};
  const initSort = {
    sort: undefined,
    sortBy: undefined
  };

  const history = useHistory();
  const below768 = useMedia('(max-width: 768px)');
  const pathname = window.location.pathname;

  const useStyle = makeStyles((theme) => (
    {
      body: {
        backgroundColor: `${style.backgroundColor}!important`,
        display: 'flex',
        flexDirection: 'row',
        flex: '1 0 0px',
        fontSize: below768 ? '12px' : '16px',
        minHeight: 'fit-content',
        '& em': {
          color: 'red'
        }
      },
      contain: {
        flex: '1 0 0px'
      }
    }
  ));

  const classes = useStyle();

  const { contentId, contentGroupId, contentToken } = useParams();

  const [isOpenSidebar, setIsOpenSidebar] = useState(!below768);
  // const [selectedContentGroup, setSelectedContentGroup] = useState({});
  const [sortOrder, setSortOrder] = useState(initSort);
  const [pageSize, setPageSize] = useState(10);
  // const [showTabSearch, setShowTabSearch] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showSubscription, setShowSubscription] = useState(false);
  const [selectHashtags, setSelectHashtags] = useState([]);
  // const [fulltextSearch, setFulltextSearch] = useState();
  const [isDisableSort, setIsDisableSort] = useState(false);
  const [showLoginFormModal, setShowLoginFormModal] = useState(false);
  const listDomainSystemAdmin = process.env.REACT_APP_DOMAIN_SYSTEM_ADMIN.split(',');

  const viewSettings = useSelector(getViewSettings) || {};

  const showContentDetail = useMemo(() => {
    if ((contentId && contentGroupId) || contentToken) {
      return true;
    } else {
      return false;
    }
  }, [contentId, contentGroupId, contentToken]);

  const isHideSidebar = useMemo(() => {
    let res = !viewSettings.showSidebar;
    for (const path of [...PUBLIC_PAGE, ...HIDE_HOME_SIDEBAR]) {
      if (pathname === path) {
        res = true;
      }
    }
    return res;
  }, [pathname]);

  const contentSearchResultSettingResponse = viewSettings.contentSearchResultSettingResponse || {};
  const hideSidebarDetail = !contentSearchResultSettingResponse.showSidebar && showContentDetail;

  useEffect(() => {
    if (listDomainSystemAdmin.indexOf(domain) > -1) {
      changeRoute('/admin-login');
    } else {
      if (isAuthen && (authUser.roleLevel === USER_CONFIG.SYSTEM_ADMIN.roleLevel || authUser.roleLevel === USER_CONFIG.COMPANY_ADMIN.roleLevel)) {
        return;
      }
      loadAllSort({ type: SORT_FIELD_TYPE.SORT_FIELD_HOME });
    }
  }, [domain]);
  useEffect(() => {
    if (pathname.includes('search')) {
      clearContents();
      setShowHomeSearch(true);
      setIsDisableSort(false);
      below768 && setIsOpenSidebar(false);
      setSortOrder({});
    } else {
      clearContents();
      setShowHomeSearch(false);
      setIsDisableSort(false);
      setSortOrder({});
    }

    if (pathname.includes('profile')) {
      setShowProfile(true);
    } else {
      setShowProfile(false);
    }
    if (pathname === '/subscription') {
      setShowSubscription(true);
    } else {
      setShowSubscription(false);
    }
    if (pathname.includes('/order-entry') || pathname.includes('/veritrans-payment') || pathname.includes('/thank-you')) {
      setIsOpenSidebar(false);
    }
  }, [pathname]);
  useEffect(() => {
    if (loginSetting || loginSetting === 0) {
      if (!isAuthen && loginSetting === SETTING_LOGIN.LOGINREQUIRE.value) {
        changeRoute('/login');
      } else {
        clearContents();
        loadPublicContentGroups();
      }
    }
  }, [isAuthen, loginSetting]);
  useEffect(() => {
    if (loginSetting || loginSetting === 0) {
      doAutoIpLogin();
    }
  }, [loginSetting]);
  useEffect(() => {
    if (listSorts && listSorts.length > 0) {
      const defaultSort = listSorts.find(item => item.isSortFieldDefault);
      defaultSort && ((defaultSort.field !== 12 && !pathname.includes('search')) || (!pathname.includes('search'))) && setSortOrder({
        sort: defaultSort.isAcsDefault,
        sortBy: defaultSort.field
      });
    }
  }, [listSorts]);

  useEffect(() => {
    clearContents();
    if (!showHomeSearch) {
      setSearchRequest([]);
      setFulltextSearch(null);
      setSearchRequestFullTextSearch([]);
    }
  }, [showHomeSearch]);

  useEffect(() => {
    clearContents();
  }, [searchRequest, fulltextSearch]);

  useEffect(() => {
    if (bodySetting && bodySetting.companyId) {
      getPublicHashtag(bodySetting.companyId);
    }
  }, [bodySetting, isAuthen]);
  const toggleSidebar = () => {
    setIsOpenSidebar(!isOpenSidebar);
  };
  const changeSort = (sort) => {
    clearContents();
    setSortOrder(sort);
  };
  const onSelectContentGroup = (contentGroupId, isBackHome) => {
    const searchPath = '/home-search';
    if (contentGroupId) {
      setSearchRequest(searchRequest);
    }
    if (isBackHome) {
      history.push(`${showHomeSearch ? searchPath : '/'}`);
    } else {
      history.push(`${showHomeSearch ? searchPath : ''}/${contentGroupId}`);
    }
  };
  const doAutoIpLogin = async () => {
    const userTrace = await Util.getUserTrace();
    if (userTrace) {
      const ip = userTrace.ip;
      login({
        ip: ip,
        loginType: AUTHEN_TYPES.AUTO_IP
      });
    }
  };
  const handleLoadContent = (contentGroup, page, size) => {
    const numItems = contentGroup.isWrapLine ? contentGroup.itemSize : contentGroup.numRow * contentGroup.itemSize;

    const params = {
      contentGroupId: contentGroup.id,
      page: page,
      size: size || numItems,
      ...sortOrder
    };
    loadContents(params);
  };
  const onSearch = (form, contentGroupId) => {
    if (form.tabType === TAB_TYPE.FULLTEXT_SEARCH.id) {
      setFulltextSearch(form.value);
    } else {
      setFulltextSearch(null);
      let searchRequests = [];
      for (const item in form) {
        let value = form[item];

        if (Array.isArray(value) && value.length > 0) {
          let str = '';
          for (let item of value) {
            if (!item) {
              item = '';
            }
            if (item && item.value) {
              str += `${str === '' ? '' : ','}${item.value}`;
            } else {
              str += `${str === '' ? '' : ','}${item === '' ? '' : Util.formatDate(item, 'YYYY/MM/DD')}`;
            }
          }
          value = str;
        }

        if (value && value !== '' && value.length !== 0) {
          const param = {
            value: value,
            searchBoxId: parseInt(item.replace('search', ''), 10)
          };
          searchRequests = [...searchRequests, param];
        }
      }
      setSearchRequest(searchRequests);
    }
  };
  const handleChangeSearchRequest = (form) => {
    onSearch(form);
    // clearContents();
  };
  const handleSearchContent = (contentGroup, page, size, fulltextSearchValue, searchValues, isNewSearch) => {
    const numItems = contentGroup.isWrapLine ? contentGroup.itemSize : contentGroup.numRow * contentGroup.itemSize;

    if (fulltextSearchValue) {
      searchFullText({
        searchKey: fulltextSearchValue,
        page: page,
        size: size || numItems,
        contentGroupId: contentGroup.id,
        isNewSearch: isNewSearch,
        ...sortOrder
      });
    } else {
      const params = {
        searchRequests: searchValues,
        pagination: {
          page: page,
          size: size || numItems,
          contentGroupId: contentGroup.id,
          isNewSearch: isNewSearch,
          ...sortOrder
        }
      };
      searchContents(params);
    }
  };

  const handleLoadContentByHashtag = (contentGroup, page, size, hashtagList, isNewSearch) => {
    const numItems = contentGroup.isWrapLine ? contentGroup.itemSize : contentGroup.numRow * contentGroup.itemSize;
    const request = {
      params: {
        contentGroupId: contentGroup.id,
        page: page,
        size: size || numItems,
        isNewSearch: isNewSearch,
        ...sortOrder
      },
      data: hashtagList
    };
    loadContentByHashtag(request);
  };

  if (isAuthen && (authUser.roleLevel === USER_CONFIG.SYSTEM_ADMIN.roleLevel || authUser.roleLevel === USER_CONFIG.COMPANY_ADMIN.roleLevel)) {
    return <Redirect to={userConfig.landingPage} push />;
  }

  if (isLoadSetting) {
    return (
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh', backgroundColor: '#FFF' }}
      >
        <Spinner color="primary" style={{ marginRight: 20 }} /> <TranslateMessage id="progress.loading" />
      </Grid>);
  } else {
    if (loginSetting === SETTING_LOGIN.LOGINREQUIRE.value && !isAuthen) {
      return <Redirect to={'/login'} push />;
    } else {
      if (layoutSetting === LAYOUT_SETTING.SEARCH_LAYOUT.id && !pathname.includes('profile')) {
        return <Redirect to={'/search'} push />;
      } else {
        return (
          <div className={'wrapper'}>
            <HomeHeader
              toggleSidebar={toggleSidebar}
              showLoginFormModal={showLoginFormModal}
              setShowLoginFormModal={setShowLoginFormModal}
              isOpenSidebar={isOpenSidebar}
              showSidebar={showContentDetail ? !hideSidebarDetail : !isHideSidebar}
              loadPublicContentGroups={loadPublicContentGroups}
            />
            <div role="main" className={`${classes.body} ${(!isOpenSidebar || below768 || isHideSidebar) && 'body-full'}`}>
              {
                showProfile
                  ? (
                    <Box width="100%">
                      <UserProfile />
                    </Box>
                  )
                  : showSubscription ? (
                    <Box width="100%">
                      <UserSubscription/>
                    </Box>
                  ) : (
                    <React.Fragment>
                      <HomeSidebar
                        isOpenSidebar={isOpenSidebar}
                        setIsOpenSidebar={setIsOpenSidebar}
                        contentGroups={contentGroups}
                        onSelectContentGroup={onSelectContentGroup}
                        // selectedItem={selectedContentGroup}
                        sort={sortOrder}
                        pageSize={pageSize}
                        changeSort={changeSort}
                        isDisableSort={isDisableSort}
                        listSorts={listSorts}
                        setShowLoginFormModal={setShowLoginFormModal}
                        handleLoadContent={handleLoadContent}
                        showSidebar={showContentDetail ? !hideSidebarDetail : !isHideSidebar}
                        isDisableSortCreatedDate={pathname.includes('home-search') || selectHashtags.length > 0}
                      />
                      {cloneElement(children, {
                        sortOrder: sortOrder,
                        contentGroups: contentGroups,
                        hashtagContentGroups: hashtagContentGroups,
                        handleLoadContent: handleLoadContent,
                        handleSearchContent: handleSearchContent,
                        handleLoadContentByHashtag: handleLoadContentByHashtag,
                        onSelectContentGroup: onSelectContentGroup,
                        setPageSize: setPageSize,
                        pageSize: pageSize,
                        showTabSearch: showHomeSearch,
                        hideSearchResult: showHomeSearch ? ((!searchRequest || searchRequest.length <= 0) && !fulltextSearch) : false,
                        handleSearch: handleChangeSearchRequest,
                        searchRequest: searchRequest,
                        fulltextSearch: fulltextSearch,
                        setIsDisableSort: setIsDisableSort,
                        showFavorite: showFavorite,
                        setSearchRequest: setSearchRequest,
                        toggleSidebar: toggleSidebar,
                        setSelectHashtags: setSelectHashtags,
                        selectHashtags: selectHashtags
                        // showContentDetail: showContentDetail,
                        // setshowContentDetail: setshowContentDetail
                      })
                      }
                    </React.Fragment>
                  )
              }
            </div>
            <PublicFooter />
          </div>
        );
      }
    }
  }
};

HomeLayout.propTypes = {
  routes: PropTypes.any,
  router: PropTypes.any,
  layoutSetting: PropTypes.any,
  isLoadSetting: PropTypes.bool,
  searchActions: PropTypes.any,
  routeActions: PropTypes.any,
  contentGroupActions: PropTypes.any,
  homeActions: PropTypes.any,
  sortActions: PropTypes.any,
  hashtagActions: PropTypes.any,
  contentGroups: PropTypes.array,
  authUser: PropTypes.object,
  isAuthen: PropTypes.bool,
  showFavorite: PropTypes.bool,
  domain: PropTypes.string,
  loginSetting: PropTypes.any,
  listSorts: PropTypes.array,
  sessionActions: PropTypes.any,
  children: PropTypes.any,
  scrollbarActions: PropTypes.any,
  bodySetting: PropTypes.any,
  hashtagContentGroups: PropTypes.array,
  showHomeSearch: PropTypes.bool,
  searchRequest: PropTypes.array,
  fulltextSearch: PropTypes.any
};

const mapStateToProps = (state) => {
  return {
    layoutSetting: getLayoutSetting(state),
    isLoadSetting: getIsLoadSetting(state),
    contentGroups: getPublicContentGroups(state),
    isAuthen: isAuthenticated(state),
    authUser: getAuthUser(state),
    domain: getCompanyDomain(state),
    loginSetting: getLoginSetting(state),
    showFavorite: getShowFavorite(state),
    listSorts: getAllSort(state),
    bodySetting: getBodySetting(state),
    hashtagContentGroups: getHashtagContentGroups(state),
    showHomeSearch: getShowHomeSearch(state),
    searchRequest: getSearchRequest(state),
    fulltextSearch: getFulltextSearch(state)
  };
};

export default connect(mapStateToProps)(props => (
  <RouterContext.Consumer>
    {router => (
      <ActionContext.Consumer>
        {({
          searchActions,
          contentGroupActions,
          homeActions,
          sessionActions,
          routeActions,
          scrollbarActions,
          sortActions,
          hashtagActions
        }) => (
          <HomeLayout
            {...props}
            router={router}
            searchActions={searchActions}
            contentGroupActions={contentGroupActions}
            homeActions={homeActions}
            sessionActions={sessionActions}
            routeActions={routeActions}
            scrollbarActions={scrollbarActions}
            sortActions={sortActions}
            hashtagActions={hashtagActions}
          />
        )}
      </ActionContext.Consumer>
    )}
  </RouterContext.Consumer>
));
