export default {
  GET_PAGE_TITLE: {
    url: '/page-title/detail',
    method: 'GET'
  },
  UPDATE_PAGE_TITLE: {
    url: '/page-title/update',
    method: 'PUT'
  },
  GET_SEACH_BOX: {
    url: '/search-box/page',
    method: 'GET'
  },
  GET_ALL_SEACH_BOX: {
    url: '/search-box/list',
    method: 'GET'
  },
  GET_SEACH_BOX_DETAIL: {
    url: '/search-box/detail/',
    method: 'GET'
  },
  UPDATE_SEACH_BOX: {
    url: '/search-box/update/',
    method: 'PUT'
  },
  ADD_SEACH_BOX: {
    url: '/search-box/add',
    method: 'POST'
  },
  DELETE_SEACH_BOX: {
    url: '/search-box/delete/',
    method: 'DELETE'
  },
  GET_TAB: {
    url: '/tab/list',
    method: 'GET'
  },
  GET_TAB_DETAIL: {
    url: '/tab/detail/',
    method: 'GET'
  },
  UPDATE_TAB: {
    url: '/tab/update/',
    method: 'PUT'
  },
  UPDATE_ORDINAL_TAB: {
    url: '/tab/update',
    method: 'PUT'
  },
  ADD_TAB: {
    url: '/tab/add',
    method: 'POST'
  },
  DELETE_TAB: {
    url: '/tab/delete/',
    method: 'DELETE'
  },
  GET_SEACH_RESULT_LIST_CONTENT: {
    url: '/search-result-setting/page-content-param',
    method: 'GET'
  },

  UPDATE_DETAIL_SEACH_RESULT: {
    url: '/search-result-setting/update-search-result',
    method: 'PUT'
  },
  UPDATE_ORDINAL_SEARCH_RESULT: {
    url: '/search-result-setting/update',
    method: 'PUT'
  },
  GET_CONTENT_DETAIL: {
    url: '/search-result-setting/detail-content-param/',
    method: 'GET'
  },
  UPDATE_CONTENT_PARAM: {
    url: '/search-result-setting/update-content-search-result/',
    method: 'PUT'
  },
  ADD_CONTENT_PARAM: {
    url: '/search-result-setting/add',
    method: 'POST'
  },
  DELETE_CONTENT_PARAM: {
    url: '/search-result-setting/delete/',
    method: 'DELETE'
  },
  GET_SEACH_RESULT_SETTING_DETAIL: {
    url: '/search-result-setting/details',
    method: 'GET'
  },
  ADD_CONTENT_DETAIL_PARAM: {
    url: '/content-detail-param/add',
    method: 'POST'
  },
  GET_LIST_CONTENT_DETAIL_PARAM: {
    url: '/content-detail-param/page-detail-param',
    method: 'GET'
  },
  GET_CONTENT_DETAIL_PARAM: {
    url: '/content-detail-param/detail/',
    method: 'GET'
  },
  UPDATE_CONTENT_DETAIL_PARAM: {
    url: '/content-detail-param/update/',
    method: 'PUT'
  },
  UPDATE_ORDINAL_CONTENT_DETAIL_PARAM: {
    url: '/content-detail-param/update',
    method: 'PUT'
  },
  DELETE_CONTENT_DETAIL_PARAM: {
    url: '/content-detail-param/delete/',
    method: 'DELETE'
  }
};
