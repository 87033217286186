import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { FORMS } from '../../configs/AppConfig';
import PropTypes from 'prop-types';
import { ActionContext } from '../layout/MainContainer';
import { Button, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import TranslateMessage from '../common/TranslateMessage/TranslateMessage';
import * as validations from '../../utils/validate';

/**
 * Render input component
 * @param event
 * @returns {*}
 */
const renderField = event => {
  const {
    input,
    label,
    type,
    placeholder,
    readOnly,
    disabled,
    innerRef,
    meta: { touched, error }
  } = event;

  return (
    <FormGroup>
      <Label for={input.name}>
        <TranslateMessage id={label} />
      </Label>
      <Input
        id={input.name}
        invalid={touched && !!error}
        autoComplete="off"
        type={type}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        innerRef={innerRef}
        {...input}
      />
      <FormFeedback className="text-danger">
        {touched && !!error && <TranslateMessage id={`validate.${input.name}.${error}`} />}
      </FormFeedback>
    </FormGroup>
  );
};

const ChangeEmailForm = props => {
  const {
    handleSubmit,
    onSubmit
  } = props;

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Field
        name="email"
        label="label.newEmail"
        type="text"
        component={renderField}
        validate={[validations.required, validations.email]}
      />
      <FormGroup className="text-right mt-5">
        <Button
          className="btn btn-info"
          variant="contained"
          color="primary"
          type="submit"
        >
          <TranslateMessage id="label.save" />
        </Button>
      </FormGroup>
    </form>
  );
};

ChangeEmailForm.propTypes = {
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.any
};

export default reduxForm({
  form: FORMS.changeEmailForm
})(
  props => (
    <ActionContext.Consumer>
      {() => (
        <ChangeEmailForm {...props} />
      )}
    </ActionContext.Consumer>
  )
);
