import LocaleTypes from './LocaleTypes';
import { DEFAULT_LOCALE } from '../../configs/locale';
import enMessages from '../../locales/en.json';
import jaMessage from '../../locales/ja.json';
import Util from '../../utils/Util';

const messages = {
  en: enMessages,
  ja: jaMessage
};

const initialState = {
  intl: undefined,
  locale: Util.getBrowserLanguage() || DEFAULT_LOCALE,
  messages: messages[Util.getBrowserLanguage() || DEFAULT_LOCALE]
};

export default function localeReducer (state = initialState, action) {
  const { type, payload: { intl, locale } = {} } = action;
  switch (type) {
    case LocaleTypes.SET_INTL:
      return Object.assign({}, state, { intl });

    case LocaleTypes.CHANGE_LOCALE:
      return Object.assign({}, state, { locale, messages: messages[locale] });

    default:
      return state;
  }
}
