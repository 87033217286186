import React from 'react';

const FlashScreenStyle = {
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 9999,
    background:
      'linear-gradient(to bottom right, #2297F4, #3BB0FF, #55CAFF, #6EE3FF, #87FCFF)'
  },
  loaderCircle: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 120,
    height: 120,
    borderRadius: '50%',
    boxShadow: 'inset 0 0 0 1px rgba(255, 255, 255, 0.1)',
    marginLeft: -60,
    marginTop: -60
  },
  loaderLineMask: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 60,
    height: 120,
    marginLeft: -60,
    marginTop: -60,
    overflow: 'hidden',
    transformOrigin: '60px 60px',
    maskImage: '-webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0))',
    WebkitMaskImage: '-webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0))',
    animation: 'rotate 1.2s infinite linear'
  },
  loaderLine: {
    width: 120,
    height: 120,
    borderRadius: '50%',
    boxShadow: 'inset 0 0 0 2px rgba(255, 255, 255, 0.8)'
  }
};

const FlashScreen = () => (
  <div style={FlashScreenStyle.root}>
    <div style={FlashScreenStyle.loaderCircle} />
    <div style={FlashScreenStyle.loaderLineMask}>
      <div style={FlashScreenStyle.loaderLine} />
    </div>
  </div>
);

export default FlashScreen;
