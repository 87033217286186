import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { withRouter, matchPath } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import NotificationSystem from 'react-notification-system';
import PropTypes from 'prop-types';
import find from 'lodash/find';

import * as ErrorActions from 'state/handler/ErrorActions';
import * as LayoutActions from 'state/layout/LayoutActions';
import * as SessionActions from 'state/session/SessionActions';
import * as LocaleActions from 'state/locale/LocaleActions';
import * as RouteActions from 'state/route/RouteActions';
import * as NotificationActions from 'state/notification/NotificationActions';
import * as ScrollbarActions from 'state/scrollbar/scrollbarActions';
import * as AccountActions from 'state/account/AccountActions';
import * as ContentActions from 'state/content/ContentActions';
import * as ImportActions from 'state/import/ImportActions';
import * as ImportUserActions from 'state/import-user/ImportUserActions';
import * as CompanyActions from 'state/company/CompanyActions';
import * as UploadActions from 'state/upload/UploadActions';
import * as SearchActions from 'state/search/SearchActions';
import * as UserGroupActions from 'state/user-group/UserGroupActions';
import * as ContentGroupActions from 'state/content-group/ContentGroupActions';
import * as UserActions from 'state/user/UserActions';
import * as MessageActions from 'state/message/MessageActions';
import * as ButtonActions from 'state/button/ButtonActions';
import * as BannerActions from 'state/banner/BannerActions';
import * as SortActions from 'state/sort/SortActions';
import * as TemplateActions from 'state/template/TemplateActions';
import * as InputActions from 'state/input/InputActions';
import * as TabActions from 'state/tab/TabActions';
import * as WatermarkActions from 'state/watermark/WatermarkActions';
import * as HomeActions from 'state/home/HomeActions';
import * as SearchResultActions from 'state/search-result/SearchResultActions';
import * as ContentDetailActions from 'state/content-detail/ContentDetailActions';
import * as CustomLoginActions from 'state/login/LoginActions';
import * as EmailTemplateActions from 'state/email-template/EmailTemplateActions';
import * as SubscriptionActions from 'state/subscriptions/SubscriptionActions';
import * as CouponActions from 'state/coupon/CouponActions';
import * as PaymentActions from 'state/payment/PaymentActions';
import * as HashtagActions from 'state/hashtag/HashtagActions';
import * as ExceedSessionActions from 'state/exceed-session/ExceedSessionActions';
import createRoutes, { flattenRoutes } from 'configs/routes';
import { ADMIN_PATH, LAYOUT_SETTING, MAIN_LAYOUT, USER_CONFIG } from 'configs/AppConfig';
import NotificationStyle from 'assets/styles/NotificationStyle';

import AppTitle from 'components/common/Title/AppTitle';
import ErrorBoundary from './ErrorBoundary';
import { makeStyles } from '@material-ui/styles';
import { getViewSettings, getIsLoadSetting, getShowSubscriptionStatus } from 'state/company/companySelector';
import { getCompanyName } from 'state/search/searchSelector';
import { getAuthUser, isSessionValidating } from 'state/session/sessionSelector';
import LayoutWrapRoute from './LayoutWrapRoute';
import NoLayoutRoute from './NoLayoutRoute';
import { Grid } from '@material-ui/core';
import { Spinner } from 'reactstrap';
import { getRoutePath } from 'state/route/routeSelector';

export const ActionContext = React.createContext(null);
export const SessionContext = React.createContext(null);
export const LayoutContext = React.createContext(null);
export const RouterContext = React.createContext(null);
export const LocaleContext = React.createContext(null);

const MainContainerStyle = makeStyles(theme => (
  {
    root: {
      backgroundColor: '#ffffff',
      width: '100%',
      height: '100%',
      margin: 0
    }
  }
));

const MainContainer = props => {
  const {
    actions,
    session,
    locale,
    layout,
    match,
    location,
    history,
    intl,
    route: { routeTitle },
    error,
    actions: {
      errorActions: { setError },
      localeActions: { setIntl },
      routeActions: { setHistory },
      notificationActions: { setupNotification },
      scrollbarActions: { scrollToTop },
      companyActions: { loadSetting, setCompanyDomain },
      routeActions: { updateRoute }
    }
  } = props;

  const router = { match, location, history };
  const routes = createRoutes();
  const flattenedRoutes = flattenRoutes(routes);

  const classes = MainContainerStyle();

  const path = window.location.pathname.split('/')[1];
  const isAdminPath = ADMIN_PATH.includes(path);
  const hostname = window.location.hostname;

  const viewSettings = useSelector(getViewSettings) || {};
  const isValidatingSession = useSelector(isSessionValidating);
  const isLoadSetting = useSelector(getIsLoadSetting);
  const authUser = useSelector(getAuthUser);
  const companyName = useSelector(getCompanyName);
  const routePath = useSelector(getRoutePath);
  const showSubscriptionStatus = useSelector(getShowSubscriptionStatus);
  const isAdmin = authUser && (authUser.roleLevel === USER_CONFIG.SYSTEM_ADMIN.roleLevel || authUser.roleLevel === USER_CONFIG.COMPANY_ADMIN.roleLevel);
  const [titlePage, setTitlePage] = useState(companyName);

  const isNoLayout = useMemo(() => {
    return viewSettings.viewPage === LAYOUT_SETTING.NO_LAYOUT.id && !isAdminPath && !isAdmin;
  }, [viewSettings, isAdmin]);

  const subRoute = createRoutes().filter(item => {
    if (showSubscriptionStatus) {
      return true;
    } else {
      return !item.showWithSubscription;
    }
  });

  useEffect(() => {
    setIntl(intl);
    setHistory(history);
    updateRouteLayout(location);
    history.listen(location => {
      updateRouteLayout(location);
    });
    scrollToTop && scrollToTop(MAIN_LAYOUT);
  }, []);

  useEffect(() => {
    if (hostname) {
      setCompanyDomain(hostname);
      loadSetting(hostname);
    }
  }, [hostname]);

  const updateRouteLayout = location => {
    const route = find(flattenedRoutes, route =>
      matchPath(location.pathname, { path: route.path, exact: true })
    );
    if (location.pathname !== routePath) {
      const title = route && route.title;
      updateRoute(title, location.pathname);
    }
  };

  const setNotificationRef = ref => {
    setupNotification(ref);
  };

  useEffect(() => {
    if (viewSettings.companyName) {
      if (isAdmin) {
        setTitlePage(companyName);
      } else {
        if (isAdminPath) {
          setTitlePage(companyName);
        } else {
          setTitlePage(viewSettings.titlePage || viewSettings.companyName);
        }
      }
    }
  }, [viewSettings, isAdmin, isAdminPath]);

  return (
    <RouterContext.Provider value={router}>
      <ActionContext.Provider value={actions}>
        <SessionContext.Provider value={session}>
          <LocaleContext.Provider value={locale}>
            <LayoutContext.Provider value={layout}>
              <ErrorBoundary error={error} setError={setError}>
                <AppTitle intl={intl} routeTitle={routeTitle} companyName={titlePage} />
                {
                  isLoadSetting || isValidatingSession
                    ? <Grid
                      container
                      spacing={0}
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      style={{ minHeight: '100vh', backgroundColor: '#FFF' }}
                    >
                      <Spinner color="primary" style={{ marginRight: 20 }} />
                    </Grid>
                    : <div className={classes.root}>
                      {
                        isNoLayout
                          ? <NoLayoutRoute/>
                          : <LayoutWrapRoute routes={subRoute} />
                      }
                      <NotificationSystem
                        ref={setNotificationRef}
                        style={NotificationStyle}
                      />
                    </div>
                }
              </ErrorBoundary>
            </LayoutContext.Provider>
          </LocaleContext.Provider>
        </SessionContext.Provider>
      </ActionContext.Provider>
    </RouterContext.Provider>
  );
};

MainContainer.defaultProps = {};

MainContainer.propTypes = {
  actions: PropTypes.any,
  session: PropTypes.any,
  locale: PropTypes.any,
  layout: PropTypes.any,
  match: PropTypes.any,
  location: PropTypes.any,
  history: PropTypes.any,
  intl: PropTypes.any,
  error: PropTypes.any,
  route: PropTypes.any
};

function mapStateToProps (state) {
  return {
    error: state.error,
    layout: state.layout,
    session: state.session,
    route: state.route,
    locale: state.locale

  };
}

function mapDispatchToProps (dispatch) {
  return {
    actions: {
      errorActions: bindActionCreators(ErrorActions, dispatch),
      layoutActions: bindActionCreators(LayoutActions, dispatch),
      sessionActions: bindActionCreators(SessionActions, dispatch),
      localeActions: bindActionCreators(LocaleActions, dispatch),
      routeActions: bindActionCreators(RouteActions, dispatch),
      notificationActions: bindActionCreators(NotificationActions, dispatch),
      scrollbarActions: bindActionCreators(ScrollbarActions, dispatch),
      accountActions: bindActionCreators(AccountActions, dispatch),
      contentActions: bindActionCreators(ContentActions, dispatch),
      importActions: bindActionCreators(ImportActions, dispatch),
      importUserActions: bindActionCreators(ImportUserActions, dispatch),
      companyActions: bindActionCreators(CompanyActions, dispatch),
      uploadActions: bindActionCreators(UploadActions, dispatch),
      searchActions: bindActionCreators(SearchActions, dispatch),
      userGroupActions: bindActionCreators(UserGroupActions, dispatch),
      contentGroupActions: bindActionCreators(ContentGroupActions, dispatch),
      userActions: bindActionCreators(UserActions, dispatch),
      messageActions: bindActionCreators(MessageActions, dispatch),
      buttonActions: bindActionCreators(ButtonActions, dispatch),
      bannerActions: bindActionCreators(BannerActions, dispatch),
      sortActions: bindActionCreators(SortActions, dispatch),
      templateActions: bindActionCreators(TemplateActions, dispatch),
      inputActions: bindActionCreators(InputActions, dispatch),
      tabActions: bindActionCreators(TabActions, dispatch),
      homeActions: bindActionCreators(HomeActions, dispatch),
      searchResultActions: bindActionCreators(SearchResultActions, dispatch),
      customLoginActions: bindActionCreators(CustomLoginActions, dispatch),
      emailTemplateActions: bindActionCreators(EmailTemplateActions, dispatch),
      watermarkActions: bindActionCreators(WatermarkActions, dispatch),
      contentDetailActions: bindActionCreators(ContentDetailActions, dispatch),
      subscriptionActions: bindActionCreators(SubscriptionActions, dispatch),
      couponActions: bindActionCreators(CouponActions, dispatch),
      paymentActions: bindActionCreators(PaymentActions, dispatch),
      hashtagActions: bindActionCreators(HashtagActions, dispatch),
      exceedSessionActions: bindActionCreators(ExceedSessionActions, dispatch)
    }
  };
}

export default injectIntl(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(MainContainer)
  )
);
