import UploadTypes from '../upload/UploadTypes';
import { PAGINATION } from '../../configs/AppConfig';

const initialState = {
  disclosureFiles: [],
  patientFiles: [],
  contentTotal: 0,
  disclosureTotalPages: 1,
  contentQueries: {
    ...PAGINATION,
    keyWords: '',
    sectionType: 1
  },
  importTotal: 0,
  importTotalPages: 1,
  importQueries: {
    ...PAGINATION,
    keyWords: '',
    sectionType: 2
  },
  progressingFiles: []
};

export default function uploadReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  switch (type) {
    case UploadTypes.GET_DISCLOSURE_GROUP_UPLOADED_FILES_SUCCESS:
      newState.disclosureFiles = [...payload.content];
      newState.contentTotal = payload.totalElements;
      newState.disclosureTotalPages = Math.ceil(payload.totalElements / newState.contentQueries.size);
      return newState;
    case UploadTypes.UPDATE_DISCLOSURE_FILES_QUERIES:
      newState.contentQueries.page = payload.page;
      newState.contentQueries.size = payload.size;
      newState.contentQueries.keyWords = payload.keyWords;
      return newState;
    case UploadTypes.GET_PATIENT_GROUP_UPLOADED_FILES_SUCCESS:
      newState.patientFiles = [...payload.content];
      newState.importTotal = payload.totalElements;
      newState.importTotalPages = Math.ceil(payload.totalElements / newState.importQueries.size);
      return newState;
    case UploadTypes.UPDATE_PATIENT_FILES_QUERIES:
      newState.importQueries.page = payload.page;
      newState.importQueries.size = payload.size;
      newState.importQueries.keyWords = payload.keyWords;
      return newState;
    case UploadTypes.CHECK_IMPORT_PROGRESS_SUCCESS:
      newState.progressingFiles = [...payload];
      return newState;
    default:
      newState = state;
  }
  return newState;
}
