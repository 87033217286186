import ContentDetailTypes from './ContentDetailTypes';

// Search Result management
export function addButton (data) {
  return {
    type: ContentDetailTypes.CREATE_BUTTON_REQUEST,
    payload: data
  };
}
export function updateButton (data) {
  return {
    type: ContentDetailTypes.UPDATE_BUTTON_REQUEST,
    payload: data
  };
}
export function deleteButton (data) {
  return {
    type: ContentDetailTypes.DELETE_BUTTON_REQUEST,
    payload: data
  };
}
export function loadButtonDetail (data) {
  return {
    type: ContentDetailTypes.GET_BUTTON_REQUEST,
    payload: data
  };
}
export function loadSetting (data) {
  return {
    type: ContentDetailTypes.GET_SETTING_REQUEST,
    payload: data
  };
}
export function updateSetting (data) {
  return {
    type: ContentDetailTypes.UPDATE_SETTING_REQUEST,
    payload: data
  };
}
