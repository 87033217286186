const _prefix = '@subscription/';

export default {

  GET_SUBSCRIPTION_PLAN_REQUEST: _prefix + 'GET_SUBSCRIPTION_PLAN_REQUEST',
  GET_SUBSCRIPTION_PLAN_SUCCESS: _prefix + 'GET_SUBSCRIPTION_PLAN_SUCCESS',
  GET_SUBSCRIPTION_PLAN_ERROR: _prefix + 'GET_SUBSCRIPTION_PLAN_ERROR',

  GET_PAGE_SUBSCRIPTION_PLAN_REQUEST: _prefix + 'GET_PAGE_SUBSCRIPTION_PLAN_REQUEST',
  GET_PAGE_SUBSCRIPTION_PLAN_SUCCESS: _prefix + 'GET_PAGE_SUBSCRIPTION_PLAN_SUCCESS',
  GET_PAGE_SUBSCRIPTION_PLAN_ERROR: _prefix + 'GET_PAGE_SUBSCRIPTION_PLAN_ERROR',

  GET_PAGE_USER_SUBSCRIPTION_PLAN_REQUEST: _prefix + 'GET_PAGE_USER_SUBSCRIPTION_PLAN_REQUEST',
  GET_PAGE_USER_SUBSCRIPTION_PLAN_SUCCESS: _prefix + 'GET_PAGE_USER_SUBSCRIPTION_PLAN_SUCCESS',
  GET_PAGE_USER_SUBSCRIPTION_PLAN_ERROR: _prefix + 'GET_PAGE_USER_SUBSCRIPTION_PLAN_ERROR',

  GET_SUBSCRIPTION_PLAN_DETAIL_REQUEST: _prefix + 'GET_SUBSCRIPTION_PLAN_DETAIL_REQUEST',
  GET_SUBSCRIPTION_PLAN_DETAIL_SUCCESS: _prefix + 'GET_SUBSCRIPTION_PLAN_DETAIL_SUCCESS',
  GET_SUBSCRIPTION_PLAN_DETAIL_ERROR: _prefix + 'GET_SUBSCRIPTION_PLAN_DETAIL_ERROR',

  UPDATE_SUBSCRIPTION_PLAN_DETAIL_REQUEST: _prefix + 'UPDATE_SUBSCRIPTION_PLAN_DETAIL_REQUEST',
  UPDATE_SUBSCRIPTION_PLAN_DETAIL_SUCCESS: _prefix + 'UPDATE_SUBSCRIPTION_PLAN_DETAIL_SUCCESS',
  UPDATE_SUBSCRIPTION_PLAN_DETAIL_ERROR: _prefix + 'UPDATE_SUBSCRIPTION_PLAN_DETAIL_ERROR',

  DELETE_SUBSCRIPTION_PLAN_DETAIL_REQUEST: _prefix + 'DELETE_SUBSCRIPTION_PLAN_DETAIL_REQUEST',
  DELETE_SUBSCRIPTION_PLAN_DETAIL_SUCCESS: _prefix + 'DELETE_SUBSCRIPTION_PLAN_DETAIL_SUCCESS',
  DELETE_SUBSCRIPTION_PLAN_DETAIL_ERROR: _prefix + 'DELETE_SUBSCRIPTION_PLAN_DETAIL_ERROR',

  ADD_SUBSCRIPTION_PLAN_REQUEST: _prefix + 'ADD_SUBSCRIPTION_PLAN_REQUEST',
  ADD_SUBSCRIPTION_PLAN_SUCCESS: _prefix + 'ADD_SUBSCRIPTION_PLAN_SUCCESS',
  ADD_SUBSCRIPTION_PLAN_ERROR: _prefix + 'ADD_SUBSCRIPTION_PLAN_ERROR',

  CANCEL_SUBSCRIPTION_REGISTERED_REQUEST: _prefix + 'CANCEL_SUBSCRIPTION_REGISTERED_REQUEST',
  CANCEL_SUBSCRIPTION_REGISTERED_SUCCESS: _prefix + 'CANCEL_SUBSCRIPTION_REGISTERED_SUCCESS',
  CANCEL_SUBSCRIPTION_REGISTERED_ERROR: _prefix + 'CANCEL_SUBSCRIPTION_REGISTERED_ERROR',

  GET_DETAIL_SUBSCRIPTION_PLAN_USER_REGISTERED_REQUEST: _prefix + 'GET_DETAIL_SUBSCRIPTION_PLAN_USER_REGISTERED_REQUEST',
  GET_DETAIL_SUBSCRIPTION_PLAN_USER_REGISTERED_SUCCESS: _prefix + 'GET_DETAIL_SUBSCRIPTION_PLAN_USER_REGISTERED_SUCCESS',
  GET_DETAIL_SUBSCRIPTION_PLAN_USER_REGISTERED_ERROR: _prefix + 'GET_DETAIL_SUBSCRIPTION_PLAN_USER_REGISTERED_ERROR',

  GET_PAGE_SUBSCRIPTION_PLAN_USER_REGISTERED_REQUEST: _prefix + 'GET_PAGE_SUBSCRIPTION_PLAN_USER_REGISTERED_REQUEST',
  GET_PAGE_SUBSCRIPTION_PLAN_USER_REGISTERED_SUCCESS: _prefix + 'GET_PAGE_SUBSCRIPTION_PLAN_USER_REGISTERED_SUCCESS',
  GET_PAGE_SUBSCRIPTION_PLAN_USER_REGISTERED_ERROR: _prefix + 'GET_PAGE_SUBSCRIPTION_PLAN_USER_REGISTERED_ERROR',

  OPEN_SUBSCRIPTION_POPUP: _prefix + 'OPEN_SUBSCRIPTION_POPUP',
  CLOSE_SUBSCRIPTION_POPUP: _prefix + 'CLOSE_SUBSCRIPTION_POPUP',

  GET_DISCOUNT_ORDINAL_PAGE_REQUEST: _prefix + 'GET_DISCOUNT_ORDINAL_PAGE_REQUEST',
  GET_DISCOUNT_ORDINAL_PAGE_SUCCESS: _prefix + 'GET_DISCOUNT_ORDINAL_PAGE_SUCCESS',
  GET_DISCOUNT_ORDINAL_PAGE_ERROR: _prefix + 'GET_DISCOUNT_ORDINAL_PAGE_ERROR',

  GET_DISCOUNT_ORDINAL_DETAIL_REQUEST: _prefix + 'GET_DISCOUNT_ORDINAL_DETAIL_REQUEST',
  GET_DISCOUNT_ORDINAL_DETAIL_SUCCESS: _prefix + 'GET_DISCOUNT_ORDINAL_DETAIL_SUCCESS',
  GET_DISCOUNT_ORDINAL_DETAIL_ERROR: _prefix + 'GET_DISCOUNT_ORDINAL_DETAIL_ERROR',

  ADD_DISCOUNT_ORDINAL_REQUEST: _prefix + 'ADD_DISCOUNT_ORDINAL_REQUEST',
  ADD_DISCOUNT_ORDINAL_SUCCESS: _prefix + 'ADD_DISCOUNT_ORDINAL_SUCCESS',
  ADD_DISCOUNT_ORDINAL_ERROR: _prefix + 'ADD_DISCOUNT_ORDINAL_ERROR',

  UPDATE_DISCOUNT_ORDINAL_REQUEST: _prefix + 'UPDATED_DISCOUNT_ORDINAL_REQUEST',
  UPDATE_DISCOUNT_ORDINAL_SUCCESS: _prefix + 'UPDATE_DISCOUNT_ORDINAL_SUCCESS',
  UPDATE_DISCOUNT_ORDINAL_ERROR: _prefix + 'UPDATE_DISCOUNT_ORDINAL_ERROR',

  DELETE_DISCOUNT_ORDINAL_REQUEST: _prefix + 'DELETE_DISCOUNT_ORDINAL_REQUEST',
  DELETE_DISCOUNT_ORDINAL_SUCCESS: _prefix + 'DELETE_DISCOUNT_ORDINAL_SUCCESS',
  DELETE_DISCOUNT_ORDINAL_ERROR: _prefix + 'DELETE_DISCOUNT_ORDINAL_ERROR',

  GET_HOME_SUBSCRIPTION_PLAN_LIST_REQUEST: _prefix + 'GET_HOME_SUBSCRIPTION_PLAN_LIST_REQUEST',
  GET_HOME_SUBSCRIPTION_PLAN_LIST_SUCCESS: _prefix + 'GET_HOME_SUBSCRIPTION_PLAN_LIST_SUCCESS',
  GET_HOME_SUBSCRIPTION_PLAN_LIST_ERROR: _prefix + 'GET_HOME_SUBSCRIPTION_PLAN_LIST_ERROR',

  EMPTY_HOME_SUBSCRIPTION_PLAN_LIST: _prefix + 'EMPTY_HOME_SUBSCRIPTION_PLAN_LIST'

};
