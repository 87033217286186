import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SessionContext, ActionContext, RouterContext } from './MainContainer';
import { connect, useSelector } from 'react-redux';
import { AUTHEN_TYPES, LAYOUT_SETTING, OPTION_LOGIN, SETTING_LOGIN, USER_CONFIG } from 'configs/AppConfig';
import { Redirect } from 'react-router-dom';
import DefaultLayout from './DefaultLayout';
import {
  getLoginSetting,
  getLayoutSetting
} from 'state/search/searchSelector';
import {
  getCompanyDomain, getViewSettings, getIsLoadSetting
} from 'state/company/companySelector';
import {
  isAuthenticated
} from 'state/session/sessionSelector';
import { Grid } from '@material-ui/core';
import { Spinner } from 'reactstrap';
import TranslateMessage from 'components/common/TranslateMessage/TranslateMessage';
import find from 'lodash/find';
import Util from 'utils/Util';

const PublicRoute = props => {
  const {
    isLoadSetting,
    loginSetting,
    domain,
    layoutSetting,
    isAuthen,
    children,
    actions: {
      routeActions: { changeRoute },
      sessionActions: { login }
    },
    session: { user = {} }
  } = props;
  const listDomainSystemAdmin = process.env.REACT_APP_DOMAIN_SYSTEM_ADMIN.split(',');
  const viewSettings = useSelector(getViewSettings) || {};
  const isOpenType = viewSettings.accountType === OPTION_LOGIN.OPEN_TYPE;

  useEffect(() => {
    if (listDomainSystemAdmin.indexOf(domain) > -1) {
      changeRoute('/admin-login');
    }
  }, []);

  useEffect(() => {
    if (!isOpenType && (loginSetting || loginSetting === 0)) {
      if (!isAuthen && loginSetting === SETTING_LOGIN.LOGINREQUIRE.value) {
        changeRoute('/login');
      }
    }
  }, [isAuthen, loginSetting, isOpenType]);

  useEffect(() => {
    if (!isOpenType && (loginSetting || loginSetting === 0)) {
      doAutoIpLogin();
    }
  }, [loginSetting, isOpenType]);

  const doAutoIpLogin = async () => {
    const userTrace = await Util.getUserTrace();
    const ip = userTrace.ip;
    login({
      ip: ip,
      loginType: AUTHEN_TYPES.AUTO_IP
    });
  };

  const userConfig = find(USER_CONFIG, { roleLevel: user.roleLevel });

  // const customRoutes = routes.filter(el => el.layoutId.startsWith(originRoutes.publicRoutes.name, 0));
  if (isLoadSetting) {
    return (
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh', backgroundColor: '#FFF' }}
      >
        <Spinner color="primary" style={{ marginRight: 20 }} /> <TranslateMessage id="progress.loading" />
      </Grid>);
  } else {
    if (loginSetting === SETTING_LOGIN.LOGINREQUIRE.value && !isAuthen) {
      return <Redirect to={'/login'} push />;
    } else if (layoutSetting === LAYOUT_SETTING.HOME_LAYOUT.id) {
      return <Redirect to={'/'} push />;
    } else {
      if (user.roleLevel === USER_CONFIG.SYSTEM_ADMIN.roleLevel || user.roleLevel === USER_CONFIG.COMPANY_ADMIN.roleLevel) {
        return <Redirect to={userConfig.landingPage} push />;
      } else {
        return (
          <DefaultLayout useAppBar className={'bg-info'}>
            {children}
          </DefaultLayout>
        );
      }
    }
  }
};

PublicRoute.propTypes = {
  routes: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  location: PropTypes.object,
  session: PropTypes.object.isRequired,
  isAuthen: PropTypes.bool,
  isLoadSetting: PropTypes.bool,
  loginSetting: PropTypes.object,
  domain: PropTypes.string,
  children: PropTypes.any,
  layoutSetting: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    isLoadSetting: getIsLoadSetting(state),
    loginSetting: getLoginSetting(state),
    domain: getCompanyDomain(state),
    isAuthen: isAuthenticated(state),
    layoutSetting: getLayoutSetting(state)
  };
};

export default connect(mapStateToProps)(props => (
  <RouterContext.Consumer>
    {({ location }) => (
      <ActionContext.Consumer>
        {actions => (
          <SessionContext.Consumer>
            {session => <PublicRoute
              {...props}
              actions={actions}
              location={location}
              session={session}
            />}
          </SessionContext.Consumer>
        )}
      </ActionContext.Consumer>
    )}
  </RouterContext.Consumer>
));
