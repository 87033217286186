import React, { PureComponent } from 'react';
import AppStyle from '../../assets/styles/AppStyle';
import PropTypes from 'prop-types';

const ErrorBoundaryStyle = {
  details: {
    whiteSpace: 'pre-wrap',
    cursor: 'pointer',
    padding: '1rem',
    backgroundColor: AppStyle.page.background
  }
};

class ErrorBoundary extends PureComponent {
  constructor (props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromProps (props, state) {
    const { hasError } = state;
    const {
      error: { error, errorInfo }
    } = props;
    return hasError && !error && !errorInfo ? { hasError: false } : null;
  }

  static getDerivedStateFromError () {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch (error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    const { setError } = this.props;
    setError && setError(error, errorInfo);
    // You can also log error messages to an error reporting service here
  }

  render () {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      const {
        error: { error, errorInfo }
      } = this.props;
      // Error path
      return (
        <div className="pt-3 pb-5 px-5">
          <h2>Something went wrong.</h2>
          <details style={ErrorBoundaryStyle.details}>
            {error && error.toString()}
            <br />
            {errorInfo && errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return children;
  }
}

ErrorBoundary.propTypes = {
  error: PropTypes.shape({
    error: PropTypes.object,
    errorInfo: PropTypes.object
  }).isRequired,
  setError: PropTypes.func.isRequired,
  children: PropTypes.any
};

export default ErrorBoundary;
