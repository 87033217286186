import LayoutTypes from './LayoutTypes';

// Button management
export function loadLayoutSetting (data) {
  return {
    type: LayoutTypes.GET_LAYOUT_SETTING_REQUEST,
    payload: data
  };
}
export function updateLayoutSetting (data) {
  return {
    type: LayoutTypes.UPDATE_LAYOUT_REQUEST,
    payload: data
  };
}
export function resetLayoutSetting (data) {
  return {
    type: LayoutTypes.RESET_LAYOUT_REQUEST,
    payload: data
  };
}
