/**
 * localStorage ~5MB, saved for infinity or until the user manually deletes it.
 * sessionStorage ~5MB, saved for the life of the CURRENT TAB
 */
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const cookieOption = {
  path: '/'
};

function setCookie (name, value, options = {}) {
  cookies.set(name, value, {
    ...cookieOption,
    ...options
  });
}

function getCookie (name) {
  return cookies.get(name);
}

async function removeCookie (name, options = {}) {
  await cookies.remove(name, { ...cookieOption, ...options });
}

function setLocalItem (name, value) {
  localStorage.setItem(name, value);
}

function setLocalObject (name, obj) {
  setLocalItem(name, JSON.stringify(obj));
}

function getLocalItem (name) {
  return localStorage.getItem(name);
}

function getLocalObject (name) {
  return JSON.parse(getLocalItem(name));
}

function removeLocalItem (name) {
  localStorage.removeItem(name);
}

function setSessionItem (name, value) {
  sessionStorage.setItem(name, value);
}

function setSessionObject (name, obj) {
  setSessionItem(name, JSON.stringify(obj));
}

function getSessionItem (name) {
  return sessionStorage.getItem(name);
}

function getSessionObject (name) {
  return JSON.parse(getSessionItem(name));
}

function removeSessionItem (name) {
  sessionStorage.removeItem(name);
}

export default {
  setLocalItem,
  getLocalItem,
  setLocalObject,
  getLocalObject,
  removeLocalItem,
  setSessionItem,
  getSessionItem,
  setSessionObject,
  getSessionObject,
  removeSessionItem,
  setCookie,
  getCookie,
  removeCookie
};
