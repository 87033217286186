import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const LoginModal = props => {
  const {
    isOpen,
    toggle,
    className,
    children,
    size,
    backdrop,
    onExit
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={className}
      size={size}
      backdrop={backdrop}
      onExit={onExit}
    >
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody>
        {children}
      </ModalBody>
    </Modal>
  );
};

LoginModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  className: PropTypes.object,
  children: PropTypes.any,
  size: PropTypes.string,
  backdrop: PropTypes.string,
  onExit: PropTypes.any
};

export default LoginModal;
