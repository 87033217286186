import TemplateTypes from './TemplateTypes';

// template management
export function getHeaderSetting (data) {
  return {
    type: TemplateTypes.GET_HEADER_REQUEST,
    payload: data
  };
}
export function updateHeader (data) {
  return {
    type: TemplateTypes.UPDATE_HEADER_REQUEST,
    payload: data
  };
}
export function getBodySetting (data) {
  return {
    type: TemplateTypes.GET_BODY_REQUEST,
    payload: data
  };
}
export function updateBody (data) {
  return {
    type: TemplateTypes.UPDATE_BODY_REQUEST,
    payload: data
  };
}
export function getFooterSetting (data) {
  return {
    type: TemplateTypes.GET_FOOTER_REQUEST,
    payload: data
  };
}
export function updateFooter (data) {
  return {
    type: TemplateTypes.UPDATE_FOOTER_REQUEST,
    payload: data
  };
}
export function loadLinkDetail (data) {
  return {
    type: TemplateTypes.GET_LINK_REQUEST,
    payload: data
  };
}
export function createLink (data) {
  return {
    type: TemplateTypes.CREATE_LINK_REQUEST,
    payload: data
  };
}
export function updateLink (data) {
  return {
    type: TemplateTypes.UPDATE_LINK_REQUEST,
    payload: data
  };
}
export function deleteLink (data) {
  return {
    type: TemplateTypes.DELETE_LINK_REQUEST,
    payload: data
  };
}
