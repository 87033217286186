import { all, put, takeEvery } from 'redux-saga/effects';
import InputTypes from './InputTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/input';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';

// Input management

function * getInputSetting () {
  yield takeEvery(InputTypes.GET_INPUT_REQUEST, _getInputSetting);
}

function * _getInputSetting ({ payload }) {
  const api = {
    ...API.GET_INPUT_SETTING
  };
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      yield put({
        type: InputTypes.GET_INPUT_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * updateInput () {
  yield takeEvery(InputTypes.UPDATE_INPUT_REQUEST, _updateInput);
}

function * _updateInput ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.UPDATE_INPUT,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: InputTypes.GET_INPUT_REQUEST,
        payload: {}
      });
    }
  });
}

export default function * inputSaga () {
  yield all([
    getInputSetting(),
    updateInput()
  ]);
}
