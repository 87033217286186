import { CONTENT_GROUP_TYPE } from 'configs/AppConfig';
import HomeTypes from './HomeTypes';

const initialState = {
  contentGroups: [],
  hashtagContentGroups: [],
  showFavorite: false,
  isLoadingHashtag: false,
  isLoadingContentGroups: false,
  showHomeSearch: false,
  searchRequest: [],
  fullTextSearchRequest: [],
  fulltextSearch: ''
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  switch (type) {
    case HomeTypes.EMPTY_PUBLIC_CONTENT_GROUP:
      newState.contentGroups = [];
      return newState;
    case HomeTypes.GET_PUBLIC_CONTENT_GROUP_REQUEST:
      newState.isLoadingContentGroups = true;
      newState.contentGroups = [];
      return newState;
    case HomeTypes.GET_PUBLIC_CONTENT_GROUP_SUCCESS:
      newState.isLoadingContentGroups = false;
      newState.contentGroups = payload;
      newState.showFavorite = !!payload.filter(item => item.default && item.type === CONTENT_GROUP_TYPE.FAVORITE)[0];
      return newState;
    case HomeTypes.GET_HASHTAG_CONTENT_GROUP_SUCCESS:
      newState.hashtagContentGroups = payload;
      newState.showFavorite = !!payload.filter(item => item.default && item.type === CONTENT_GROUP_TYPE.FAVORITE)[0];
      return newState;
    case HomeTypes.GET_CONTENT_BY_CONTENT_GROUP_SUCCESS:
    // case HomeTypes.GET_CONTENT_BY_HASHTAG_SUCCESS:
      newState.contentGroups = newState.contentGroups.map(item => {
        if (item.id === payload.contentGroupId) {
          item.contents = payload.content;
          item.page = payload.pageNumber + 1;
          item.totalPages = payload.totalPages;
          item.isLoading = false;
          item.isReload = false;
          item.isHashtag = false;
        }
        return item;
      });
      return newState;
    case HomeTypes.GET_CONTENT_BY_HASHTAG_SUCCESS:
      newState.isLoadingHashtag = false;
      // newState.contentGroups = newState.contentGroups.map(item => {
      //   if (item.id === payload.contentGroupId) {
      //     item.contents = payload.content;
      //     item.page = payload.pageNumber + 1;
      //     item.totalPages = payload.totalPages;
      //     item.isLoading = false;
      //     item.isReload = false;
      //     item.isHashtag = true;
      //   }
      //   return item;
      // });
      newState.hashtagContentGroups = newState.hashtagContentGroups.map(item => {
        if (item.id === payload.contentGroupId) {
          item.contents = payload.content;
          item.page = payload.pageNumber + 1;
          item.totalPages = payload.totalPages;
          item.isLoading = false;
          item.isReload = false;
          item.isHashtag = true;
        }
        return item;
      });
      return newState;
    case HomeTypes.SEARCH_CONTENT_BY_CONTENT_GROUP_SUCCESS:
    case HomeTypes.SEARCH_FULL_TEXT_BY_CONTENT_GROUP_SUCCESS:
      newState.contentGroups = newState.contentGroups.map(item => {
        if (item.id === payload.contentGroupId) {
          item.contents = payload.content;
          item.page = payload.pageNumber + 1;
          item.totalPages = payload.totalPages;
          item.isLoading = false;
          item.isReload = false;
        }
        return item;
      });
      return newState;
    case HomeTypes.SEARCH_CONTENT_BY_CONTENT_GROUP_REQUEST:
      newState.contentGroups = newState.contentGroups.map(item => {
        if (item.id === payload.pagination.contentGroupId) {
          item.isLoading = true;
          item.isReload = false;
        }
        return item;
      });
      return newState;
    case HomeTypes.SEARCH_FULL_TEXT_BY_CONTENT_GROUP_REQUEST:
    case HomeTypes.GET_CONTENT_BY_CONTENT_GROUP_REQUEST:
    // case HomeTypes.GET_CONTENT_BY_HASHTAG_REQUEST:
      // if (payload.params && payload.data) {
      //   newState.isLoadingHashtag = true;
      //   newState.contentGroups = newState.contentGroups.map(item => {
      //     if (item.id === payload.params.contentGroupId) {
      //       item.isLoading = true;
      //       item.isReload = false;
      //     }
      //     return item;
      //   });
      // } else {
      newState.contentGroups = newState.contentGroups.map(item => {
        if (item.id === payload.contentGroupId) {
          item.isLoading = true;
          item.isReload = false;
        }
        return item;
      });
      // }
      return newState;
    case HomeTypes.GET_CONTENT_BY_HASHTAG_REQUEST:
      newState.isLoadingHashtag = true;
      // newState.contentGroups = newState.contentGroups.map(item => {
      //   if (item.id === payload.params.contentGroupId) {
      //     item.isLoading = true;
      //     item.isReload = false;
      //   }
      //   return item;
      // });
      newState.hashtagContentGroups = newState.hashtagContentGroups.map(item => {
        if (item.id === payload.params.contentGroupId) {
          item.isLoading = true;
          item.isReload = false;
        }
        return item;
      });
      return newState;
    case HomeTypes.GET_CONTENT_BY_CONTENT_GROUP_ERROR:
    case HomeTypes.SEARCH_CONTENT_BY_CONTENT_GROUP_ERROR:
    case HomeTypes.SEARCH_FULL_TEXT_BY_CONTENT_GROUP_ERROR:
      newState.contentGroups = newState.contentGroups.map(item => {
        if (item.id === payload.contentGroupId) {
          item.isLoading = false;
          item.isReload = false;
          item.contents = [];
        }
        return item;
      });
      return newState;
    case HomeTypes.GET_CONTENT_BY_HASHTAG_ERROR:
      newState.isLoadingHashtag = false;
      // newState.contentGroups = newState.contentGroups.map(item => {
      //   if (item.id === payload.contentGroupId) {
      //     item.isLoading = false;
      //     item.isReload = false;
      //     item.contents = [];
      //   }
      //   return item;
      // });
      newState.hashtagContentGroups = newState.hashtagContentGroups.map(item => {
        if (item.id === payload.contentGroupId) {
          item.isLoading = false;
          item.isReload = false;
          item.contents = [];
        }
        return item;
      });
      return newState;
    case HomeTypes.CLEAR_CONTENTS:
      newState.contentGroups = newState.contentGroups.map(item => {
        item.contents = null;
        item.page = 1;
        item.isLoading = false;
        item.isReload = false;
        return item;
      });
      newState.contentGroups = newState.hashtagContentGroups.map(item => {
        item.contents = null;
        item.page = 1;
        item.isLoading = false;
        item.isReload = false;
        return item;
      });
      return newState;
    case HomeTypes.SYNC_CONTENT_OF_CONTENT_GROUP:
      newState.contentGroups = newState.contentGroups.map(item => {
        if (item.contents && item.contents.length > 0) {
          if (item.id === payload.contentGroupId) {
            item.isReload = true;
            item.isLoading = false;
            return item;
          }
        }
        return item;
      });
      newState.contentGroups = newState.hashtagContentGroups.map(item => {
        if (item.contents && item.contents.length > 0) {
          if (item.id === payload.contentGroupId) {
            item.isReload = true;
            item.isLoading = false;
            return item;
          }
        }
        return item;
      });
      return newState;
    case HomeTypes.SET_SHOW_HOME_SEARCH:
      newState.showHomeSearch = payload;
      return newState;
    case HomeTypes.SET_SEARCH_REQUEST:
      newState.searchRequest = payload;
      return newState;
    case HomeTypes.SET_SEARCH_REQUEST_FULL_TEXT_SEARCH:
      newState.fullTextSearchRequest = payload;
      return newState;
    case HomeTypes.SET_FULLTEXT_SEARCH:
      newState.fulltextSearch = payload;
      return newState;
    default:
      newState = state;
      break;
  }
  // return new state
  return newState;
}
