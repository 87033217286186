export default {
  GET_TAB_SETTING: {
    url: '/tab-setting/detail',
    method: 'GET'
  },
  UPDATE_TAB: {
    url: '/tab-setting/update',
    method: 'PUT'
  },
  GET_BUTTON: {
    url: '/tab-setting/detail-button/',
    method: 'GET'
  },
  ADD_BUTTON: {
    url: '/tab-setting/add-button',
    method: 'POST'
  },
  UPDATE_BUTTON: {
    url: '/tab-setting/update-button/',
    method: 'PUT'
  },
  DELETE_BUTTON: {
    url: '/tab-setting/delete-button/',
    method: 'DELETE'
  }
};
