export default {
  GET_HASHTAG_LIST: {
    url: '/hash-tag/get-all-hashtag',
    method: 'GET'
  },
  ADD_HASHTAG: {
    url: '/hash-tag/add',
    method: 'POST'
  },
  UPDATE_HASHTAG_DETAIL: {
    url: '/hash-tag/update/',
    method: 'PUT'
  },
  DELETE_HASHTAG: {
    url: '/hash-tag/delete/',
    method: 'DELETE'
  },
  GET_SEARCH_BOX: {
    url: '/hash-tag/get-search-box',
    method: 'GET'
  },
  UPDATE_SEARCH_BOX: {
    url: '/hash-tag/update-search-box-hash-tag',
    method: 'PUT'
  },
  UPDATE_MULTIPLE_SELECT_HASH_TAG: {
    url: '/hash-tag/multiple-select-hash-tag',
    method: 'PUT'
  },
  GET_PUBLIC_HASH_TAG: {
    url: '/hash-tag/public/get-all-hashtag',
    method: 'GET'
  },
  GET_HASHTAG_SETTING: {
    url: '/hash-tag-setting/get-hash-tag-setting',
    method: 'GET'
  },
  UPDATE_HASHTAG_SETTING: {
    url: '/hash-tag-setting/update',
    method: 'PUT'
  }
};
