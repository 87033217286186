import { all, put, takeEvery, select } from 'redux-saga/effects';
import SubscriptionTypes from './SubscriptionTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/subscription';
// import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';
import NotificationTypes from 'state/notification/NotificationTypes';
import { PAGINATION } from 'configs/AppConfig';
import UserTypes from 'state/user/UserTypes';
import { getCompanyDomain } from 'state/company/companySelector';

// Sort management

function * getSubscriptionPlans () {
  yield takeEvery(SubscriptionTypes.GET_SUBSCRIPTION_PLAN_REQUEST, _getSubscriptionPlans);
}

function * _getSubscriptionPlans () {
  yield createRequest({
    api: {
      ...API.GET_SUBSCRIPTION_PLAN
    },
    onSuccess: function * (response) {
      yield put({
        type: SubscriptionTypes.GET_SUBSCRIPTION_PLAN_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * (response) {
      yield put({
        type: SubscriptionTypes.GET_SUBSCRIPTION_PLAN_ERROR,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * getSubscriptionDetail () {
  yield takeEvery(SubscriptionTypes.GET_SUBSCRIPTION_PLAN_DETAIL_REQUEST, _getSubscriptionDetail);
}

function * _getSubscriptionDetail ({ payload }) {
  const currentDomain = yield select(getCompanyDomain);
  const api = { ...API.GET_SUBSCRIPTION_PLAN_DETAIL };
  api.url += payload;
  api.params = { domain: currentDomain };
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      yield put({
        type: SubscriptionTypes.GET_SUBSCRIPTION_PLAN_DETAIL_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SubscriptionTypes.GET_SUBSCRIPTION_PLAN_DETAIL_ERROR,
        payload: status
      });
    }
  });
}

function * updateSubscriptionPlan () {
  yield takeEvery(SubscriptionTypes.UPDATE_SUBSCRIPTION_PLAN_DETAIL_REQUEST, _updateSubscriptionPlan);
}

function * _updateSubscriptionPlan ({ payload }) {
  const { file, ...rest } = payload.data;
  const data = new FormData();
  data.append('file', file);
  data.append('content-data', new Blob([JSON.stringify(snakecaseKeys(rest))], {
    type: 'application/json'
  }));
  const api = { ...API.UPDATE_SUBSCRIPTION_PLAN_DETAIL };
  api.url += payload.data.id;
  yield createRequest({
    api: {
      ...api,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: SubscriptionTypes.UPDATE_SUBSCRIPTION_PLAN_DETAIL_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
      yield put({
        type: SubscriptionTypes.GET_SUBSCRIPTION_PLAN_DETAIL_REQUEST,
        payload: response.data.id
      });
      yield put({
        type: SubscriptionTypes.GET_PAGE_SUBSCRIPTION_PLAN_REQUEST,
        payload: payload.queries
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SubscriptionTypes.UPDATE_SUBSCRIPTION_PLAN_DETAIL_ERROR,
        payload: status
      });
    }
  });
}

function * deleteSubscriptionPlan () {
  yield takeEvery(SubscriptionTypes.DELETE_SUBSCRIPTION_PLAN_DETAIL_REQUEST, _deleteSubscriptionPlan);
}

function * _deleteSubscriptionPlan ({ payload }) {
  const api = { ...API.DELETE_SUBSCRIPTION_PLAN };
  api.url += payload.data.id;
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.delete.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: SubscriptionTypes.DELETE_SUBSCRIPTION_PLAN_DETAIL_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
      yield put({
        type: SubscriptionTypes.GET_PAGE_SUBSCRIPTION_PLAN_REQUEST,
        payload: payload.queries
      });
    }
  });
}

function * addSubscriptionPlan () {
  yield takeEvery(SubscriptionTypes.ADD_SUBSCRIPTION_PLAN_REQUEST, _addSubscriptionPlan);
}

function * _addSubscriptionPlan ({ payload }) {
  const { file, ...rest } = payload.data;
  const data = new FormData();
  data.append('file', file);
  data.append('content-data', new Blob([JSON.stringify(snakecaseKeys(rest))], {
    type: 'application/json'
  }));
  yield createRequest({
    api: {
      ...API.ADD_SUBSCRIPTION_PLAN,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: SubscriptionTypes.ADD_SUBSCRIPTION_PLAN_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
      yield put({
        type: SubscriptionTypes.GET_PAGE_SUBSCRIPTION_PLAN_REQUEST,
        payload: payload.queries
      });
    },
    onError: function * () {
      yield put({
        type: SubscriptionTypes.ADD_SUBSCRIPTION_PLAN_ERROR
      });
    }
  });
}

function * getPageSubscriptionPlan () {
  yield takeEvery(SubscriptionTypes.GET_PAGE_SUBSCRIPTION_PLAN_REQUEST, _getPageSubscriptionPlan);
}

function * _getPageSubscriptionPlan ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GET_PAGE_SUBSCRIPTION_PLAN,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: SubscriptionTypes.GET_PAGE_SUBSCRIPTION_PLAN_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * cancelUserRegistered () {
  yield takeEvery(SubscriptionTypes.CANCEL_SUBSCRIPTION_REGISTERED_REQUEST, _cancelUserRegistered);
}

function * _cancelUserRegistered ({ payload }) {
  // const request = snakecaseKeys({ ...payload.data });
  yield createRequest({
    api: {
      ...API.CANCEL_SUBSCRIPTION_PLAN_USER_REGISTERED,
      url: API.CANCEL_SUBSCRIPTION_PLAN_USER_REGISTERED.url + payload.data.id,
      params: payload.data.userId ? { user_id: payload.data.userId } : {}
    },
    onSuccess: function * (response) {
      yield put({
        type: SubscriptionTypes.CANCEL_SUBSCRIPTION_REGISTERED_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.cancel.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      if (payload.data.userId) {
        yield put({
          type: UserTypes.GET_USER_DETAIL_REQUEST,
          payload: payload.data.userId
        });
      } else {
        yield put({
          type: SubscriptionTypes.GET_PAGE_SUBSCRIPTION_PLAN_USER_REGISTERED_REQUEST,
          payload: payload.queries
        });
      }
    },
    onError: function * ({ status }) {
      yield put({
        type: SubscriptionTypes.CANCEL_SUBSCRIPTION_REGISTERED_ERROR,
        payload: status
      });
    }
  });
}

function * getPageUserSubscriptionPlans () {
  yield takeEvery(SubscriptionTypes.GET_PAGE_USER_SUBSCRIPTION_PLAN_REQUEST, _getPageUserSubscriptionPlans);
}

function * _getPageUserSubscriptionPlans ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GET_PAGE_USER_SUBSCRIPTION_PLAN,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: SubscriptionTypes.GET_PAGE_USER_SUBSCRIPTION_PLAN_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SubscriptionTypes.GET_PAGE_USER_SUBSCRIPTION_PLAN_ERROR,
        payload: status
      });
    }
  });
}

function * getHomeSubscriptionPlans () {
  yield takeEvery(SubscriptionTypes.GET_HOME_SUBSCRIPTION_PLAN_LIST_REQUEST, _getHomeSubscriptionPlans);
}

function * _getHomeSubscriptionPlans ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GET_PAGE_USER_SUBSCRIPTION_PLAN,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: SubscriptionTypes.GET_HOME_SUBSCRIPTION_PLAN_LIST_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SubscriptionTypes.GET_HOME_SUBSCRIPTION_PLAN_LIST_ERROR,
        payload: status
      });
    }
  });
}
function * getDetailUserRegistered () {
  yield takeEvery(SubscriptionTypes.GET_DETAIL_SUBSCRIPTION_PLAN_USER_REGISTERED_REQUEST, _getDetailUserRegistered);
}

function * _getDetailUserRegistered ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_DETAIL_USER_SUBSCRIPTION_PLAN_REGISTERED,
      url: API.GET_DETAIL_USER_SUBSCRIPTION_PLAN_REGISTERED.url + payload
    },
    onSuccess: function * (response) {
      yield put({
        type: SubscriptionTypes.GET_DETAIL_SUBSCRIPTION_PLAN_USER_REGISTERED_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SubscriptionTypes.GET_DETAIL_SUBSCRIPTION_PLAN_USER_REGISTERED_ERROR,
        payload: status
      });
    }
  });
}

function * getUserRegisteredPage () {
  yield takeEvery(SubscriptionTypes.GET_PAGE_SUBSCRIPTION_PLAN_USER_REGISTERED_REQUEST, _getUserRegisteredPage);
}

function * _getUserRegisteredPage ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GET_PAGE_USER_SUBSCRIPTION_PLAN_REGISTERED,
      params: { ...request }
    },
    onSuccess: function * (response) {
      const result = Util.toCamelCaseKey(response.data);
      yield put({
        type: SubscriptionTypes.GET_PAGE_SUBSCRIPTION_PLAN_USER_REGISTERED_SUCCESS,
        payload: { ...result, contentGroupId: request.content_group_id }
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SubscriptionTypes.GET_PAGE_SUBSCRIPTION_PLAN_USER_REGISTERED_ERROR,
        payload: status
      });
    }
  });
}

function * loadDiscountOrdinalPage () {
  yield takeEvery(SubscriptionTypes.GET_DISCOUNT_ORDINAL_PAGE_REQUEST, _loadDiscountOrdinalPage);
}

function * _loadDiscountOrdinalPage ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GET_DISCOUNT_ORDINAL_PAGE,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: SubscriptionTypes.GET_DISCOUNT_ORDINAL_PAGE_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * () {
      yield put({
        type: SubscriptionTypes.GET_DISCOUNT_ORDINAL_PAGE_ERROR
      });
    }
  });
}

function * loadDiscountOrdinalDetail () {
  yield takeEvery(SubscriptionTypes.GET_DISCOUNT_ORDINAL_DETAIL_REQUEST, _loadDiscountOrdinalDetail);
}

function * _loadDiscountOrdinalDetail ({ payload }) {
  const api = { ...API.GET_DISCOUNT_ORDINAL_DETAIL };
  api.url += payload;
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      yield put({
        type: SubscriptionTypes.GET_DISCOUNT_ORDINAL_DETAIL_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * () {
      yield put({
        type: SubscriptionTypes.GET_DISCOUNT_ORDINAL_DETAIL_ERROR
      });
    }
  });
}

function * addDiscountOrdinal () {
  yield takeEvery(SubscriptionTypes.ADD_DISCOUNT_ORDINAL_REQUEST, _addDiscountOrdinal);
}

function * _addDiscountOrdinal ({ payload }) {
  const request = snakecaseKeys({ ...payload.data });
  yield createRequest({
    api: {
      ...API.ADD_DISCOUNT_ORDINAL,
      data: request
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: SubscriptionTypes.GET_DISCOUNT_ORDINAL_PAGE_REQUEST,
        payload: Util.toCamelCaseKey(PAGINATION)
      });
      yield put({
        type: SubscriptionTypes.ADD_DISCOUNT_ORDINAL_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * () {
      yield put({
        type: SubscriptionTypes.ADD_DISCOUNT_ORDINAL_ERROR
      });
    }
  });
}

function * updateDiscountOrdinal () {
  yield takeEvery(SubscriptionTypes.UPDATE_DISCOUNT_ORDINAL_REQUEST, _updateDiscountOrdinal);
}

function * _updateDiscountOrdinal ({ payload }) {
  const request = snakecaseKeys({ ...payload.data });
  const queries = snakecaseKeys({ ...payload.queries });
  const api = { ...API.UPDATE_DISCOUNT_ORDINAL };
  api.url += payload.data.id;
  yield createRequest({
    api: {
      ...api,
      data: request
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: SubscriptionTypes.GET_DISCOUNT_ORDINAL_PAGE_REQUEST,
        payload: queries
      });
      yield put({
        type: SubscriptionTypes.UPDATE_DISCOUNT_ORDINAL_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * () {
      yield put({
        type: SubscriptionTypes.UPDATE_DISCOUNT_ORDINAL_ERROR
      });
    }
  });
}

function * deleteDiscountOrdinal () {
  yield takeEvery(SubscriptionTypes.DELETE_DISCOUNT_ORDINAL_REQUEST, _deleteDiscountOrdinal);
}

function * _deleteDiscountOrdinal ({ payload }) {
  const api = { ...API.DELETE_DISCOUNT_ORDINAL };
  api.url += payload.data.id;
  const queries = snakecaseKeys({ ...payload.queries });
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.delete.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: SubscriptionTypes.GET_DISCOUNT_ORDINAL_PAGE_REQUEST,
        payload: queries
      });
      yield put({
        type: SubscriptionTypes.DELETE_DISCOUNT_ORDINAL_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * () {
      yield put({
        type: SubscriptionTypes.DELETE_DISCOUNT_ORDINAL_ERROR
      });
    }
  });
}
export default function * sortSaga () {
  yield all([
    getSubscriptionPlans(),
    getSubscriptionDetail(),
    updateSubscriptionPlan(),
    deleteSubscriptionPlan(),
    addSubscriptionPlan(),
    getPageSubscriptionPlan(),
    cancelUserRegistered(),
    getPageUserSubscriptionPlans(),
    getDetailUserRegistered(),
    getUserRegisteredPage(),
    loadDiscountOrdinalPage(),
    loadDiscountOrdinalDetail(),
    addDiscountOrdinal(),
    updateDiscountOrdinal(),
    deleteDiscountOrdinal(),
    getHomeSubscriptionPlans()
  ]);
}
