import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const CommonModal = props => {
  const {
    isOpen,
    toggle,
    className,
    title,
    children,
    size,
    backdrop
  } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={className} size={size} backdrop={backdrop}>
      <ModalHeader toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        {children}
      </ModalBody>
    </Modal>
  );
};

CommonModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  className: PropTypes.object,
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  size: PropTypes.string,
  backdrop: PropTypes.string
};

export default CommonModal;
