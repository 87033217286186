export default {
  GET_LIST_BUTTON: {
    url: '/button/page',
    method: 'GET'
  },
  GET_ALL_BUTTON: {
    url: '/button/list',
    method: 'GET'
  },
  ADD_BUTTON: {
    url: '/button/add',
    method: 'POST'
  },
  GET_BUTTON_DETAIL: {
    url: '/button/detail/',
    method: 'GET'
  },
  UPDATE_BUTTON: {
    url: '/button/update/',
    method: 'PUT'
  },
  DELETE_BUTTON: {
    url: '/button/delete/',
    method: 'DELETE'
  }
};
