const _prefix = '@template/';

export default {
  GET_HEADER_REQUEST: _prefix + 'GET_HEADER_REQUEST',
  GET_HEADER_SUCCESS: _prefix + 'GET_HEADER_SUCCESS',
  UPDATE_HEADER_REQUEST: _prefix + 'UPDATE_HEADER_REQUEST',

  GET_BODY_REQUEST: _prefix + 'GET_BODY_REQUEST',
  GET_BODY_SUCCESS: _prefix + 'GET_BODY_SUCCESS',
  UPDATE_BODY_REQUEST: _prefix + 'UPDATE_BODY_REQUEST',

  GET_FOOTER_REQUEST: _prefix + 'GET_FOOTER_REQUEST',
  GET_FOOTER_SUCCESS: _prefix + 'GET_FOOTER_SUCCESS',
  UPDATE_FOOTER_REQUEST: _prefix + 'UPDATE_FOOTER_REQUEST',

  GET_LINK_REQUEST: _prefix + 'GET_LINK_REQUEST',
  GET_LINK_SUCCESS: _prefix + 'GET_LINK_SUCCESS',
  CREATE_LINK_REQUEST: _prefix + 'CREATE_LINK_REQUEST',
  UPDATE_LINK_REQUEST: _prefix + 'UPDATE_LINK_REQUEST',
  DELETE_LINK_REQUEST: _prefix + 'DELETE_LINK_REQUEST'
};
