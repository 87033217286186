import { all, takeEvery, put } from 'redux-saga/effects';
import UserGroupTypes from './UserGroupTypes';
import createRequest from '../../api/httpRequest';
import Util from '../../utils/Util';
import API from '../../api/user-group';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys/index';

function * loadUserGroupListAll () {
  yield takeEvery(UserGroupTypes.GET_LIST_USER_GROUPS_REQUEST, _loadUserGroupListAll);
}

function * _loadUserGroupListAll ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_USER_GROUPS_ALL,
      params: payload
    },
    onSuccess: function * (data) {
      yield put({
        type: UserGroupTypes.GET_LIST_USER_GROUPS_SUCCESS,
        payload: Util.toCamelCaseKey(data.data)
      });
    }
  });
}

function * loadUserGroupList () {
  yield takeEvery(UserGroupTypes.GET_USER_GROUPS_REQUEST, _loadUserGroupList);
}

function * _loadUserGroupList ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_USER_GROUPS,
      params: payload
    },
    onSuccess: function * (data) {
      yield put({
        type: UserGroupTypes.UPDATE_USER_GROUP_QUERIES,
        payload: payload
      });
      yield put({
        type: UserGroupTypes.GET_USER_GROUPS_SUCCESS,
        payload: Util.toCamelCaseKey(data.data)
      });
    }
  });
}

function * loadUserGroupDetail () {
  yield takeEvery(UserGroupTypes.GET_USER_GROUP_DETAIL_REQUEST, _loadUserGroupDetail);
}

function * _loadUserGroupDetail ({ payload }) {
  const api = { ...API.GET_USER_DETAIL };
  api.url += payload;
  yield createRequest({
    api: api,
    onSuccess: function * (data) {
      yield put({
        type: UserGroupTypes.GET_USER_GROUP_DETAIL_SUCCESS,
        payload: Util.toCamelCaseKey(data.data)
      });
    }
  });
}

function * addUserGroup () {
  yield takeEvery(UserGroupTypes.ADD_USER_GROUP_REQUEST, _addUserGroup);
}

function * _addUserGroup ({ payload }) {
  yield createRequest({
    api: {
      ...API.ADD_USER_GROUP,
      data: snakecaseKeys(payload.data)
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: UserGroupTypes.GET_USER_GROUPS_REQUEST,
        payload: payload.queries
      });
    }
  });
}

function * updateUserGroup () {
  yield takeEvery(UserGroupTypes.UPDATE_USER_GROUP_REQUEST, _updateUserGroup);
}

function * _updateUserGroup ({ payload }) {
  const api = { ...API.UPDATE_USER_GROUP };
  api.url += payload.data.id;
  yield createRequest({
    api: {
      ...api,
      data: snakecaseKeys(payload.data)
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: UserGroupTypes.GET_USER_GROUPS_REQUEST,
        payload: payload.queries
      });
    }
  });
}

function * deleteUserGroup () {
  yield takeEvery(UserGroupTypes.DELETE_USER_GROUP_REQUEST, _deleteUserGroup);
}

function * _deleteUserGroup ({ payload }) {
  const api = { ...API.DELETE_USER_GROUP };
  api.url += payload.data.id;
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.delete.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: UserGroupTypes.GET_USER_GROUPS_REQUEST,
        payload: payload.queries
      });
    }
  });
}

export default function * userGroupSaga () {
  yield all([
    loadUserGroupListAll(),
    loadUserGroupList(),
    loadUserGroupDetail(),
    addUserGroup(),
    updateUserGroup(),
    deleteUserGroup()
  ]);
}
