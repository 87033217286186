import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { ActionContext } from '../layout/MainContainer';
import { getEndUserCompanyDetail } from '../../state/company/companySelector';
import ScrollTopButton from '../common/Scrollbar/ScrollTopButton';
import { makeStyles } from '@material-ui/core';
import { LINK_POSITION, LINK_TYPE } from 'configs/AppConfig';
import { Link } from 'react-router-dom';
// import TranslateMessage from '../common/TranslateMessage/TranslateMessage';

const PublicFooter = props => {
  const setting = useSelector((state) => state.search.footerSetting) || {};
  const upperLinks = setting.links ? setting.links.filter(item => item.positionLink === LINK_POSITION.UPPER_LINK) : [];
  const lowerLinks = setting.links ? setting.links.filter(item => item.positionLink === LINK_POSITION.LOWER_LINK) : [];
  const useStyle = makeStyles((theme) => (
    {
      setting: {
        backgroundColor: setting.backgroundColor,
        color: setting.color,
        fontWeight: 700
      },
      links: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0.8rem 0',
        '& .footer-item': {
          color: setting.color || '#fff',
          flexGrow: 1,
          textAlign: 'center',
          fontSize: setting.fontSize || '0.8rem'
        },
        '& .footer-item a': {
          cursor: 'pointer'
        },
        '& .footer-item a:hover': {
          color: setting.color || '#fff',
          textDecoration: 'underline!important'
        }
      }
    }
  ));

  const classes = useStyle();

  const redirectExternalLink = _url => {
    window.open(_url);
  };

  return (
    <footer className={`footer mt-auto py-3 flex-shrink-0 ${classes.setting}`}>
      <div className="container">
        <div className="row">
          <div className={`col-sm-12 col-md-12 ${classes.links}`}>
            {
              upperLinks && upperLinks.map((item, index) => (
                item.typeLink === LINK_TYPE.EXTERNAL_LINK
                  ? <div key={`upper-external-link-${index}`} className="footer-item">
                    <a onClick={() => redirectExternalLink(item.url)}>
                      {item.label}
                    </a>
                  </div>
                  : <div key={`upper-internal-link-${index}`} className="footer-item">
                    <Link to={item.url}>
                      {item.label}
                    </Link>
                  </div>
              ))
            }
          </div>
          <div className={`col-sm-12 col-md-12 ${classes.links}`}>
            {
              lowerLinks && lowerLinks.map((item, index) => (
                item.typeLink === LINK_TYPE.EXTERNAL_LINK
                  ? <div key={`lower-external-link-${index}`} className="footer-item">
                    <a onClick={() => redirectExternalLink(item.url)}>
                      {item.label}
                    </a>
                  </div>
                  : <div key={`lower-internal-link-${index}`} className="footer-item">
                    <Link to={item.url}>
                      {item.label}
                    </Link>
                  </div>
              ))
            }
          </div>
          <div className="col-sm-12 col-md-12 text-center">
            <small>{setting.copyRight}</small>
          </div>
        </div>
      </div>
      <ScrollTopButton />
    </footer>
  );
};

PublicFooter.propTypes = {
  messageActions: PropTypes.object.isRequired,
  companyActions: PropTypes.object.isRequired,
  companyDetail: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    companyDetail: getEndUserCompanyDetail(state)
  };
};

export default connect(mapStateToProps)(
  props => (
    <ActionContext.Consumer>
      {({ messageActions, companyActions }) => (
        <PublicFooter
          {...props}
          messageActions={messageActions}
          companyActions={companyActions}
        />
      )}
    </ActionContext.Consumer>
  )
);
