import { select, all, takeLatest } from 'redux-saga/effects';
import RouteTypes from './RouteTypes';
import { getHistory } from './routeSelector';

export default function * routeSaga () {
  yield all([changeRouteSaga()]);
}

/**
 * Use to programming change route path
 * Example after login success will change route from /login to cached route path (/cached_route)
 */
function * changeRouteSaga () {
  yield takeLatest(RouteTypes.CHANGE_ROUTE, onChangeRoute);
}

function * onChangeRoute ({ payload: { pathname, search } }) {
  if (pathname) {
    const history = yield select(getHistory);
    history.push({ pathname, search });
  }
}
