import ButtonTypes from './ButtonTypes';
import { PAGINATION } from '../../configs/AppConfig';

const initialState = {
  buttons: [],
  allButtons: [],
  buttonDetail: {},
  pagination: { ...PAGINATION }
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  const newState = { ...state };
  switch (type) {
    case ButtonTypes.GET_BUTTON_SUCCESS:
      newState.buttonDetail = { ...payload };
      return newState;
    case ButtonTypes.GET_ALL_BUTTON_SUCCESS:
      newState.allButtons = [...payload];
      return newState;
    case ButtonTypes.GET_LIST_BUTTON_SUCCESS:
      newState.buttons = [...payload.content];
      newState.pagination.totalRecords = payload.totalElements;
      newState.pagination.totalPages = payload.totalPages;
      newState.pagination.size = payload.size;
      newState.pagination.page = payload.number + 1;
      newState.pagination = { ...newState.pagination };
      return newState;
  }
  return newState;
}
