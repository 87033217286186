import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LANGUAGES } from '../../configs/AppConfig';
import { ActionContext } from '../layout/MainContainer';
import TranslateMessage from '../common/TranslateMessage/TranslateMessage';
import { FormGroup, Button } from 'reactstrap';
import { getUserInfo } from '../../state/account/accountSelector';
import { connect, useSelector } from 'react-redux';
import ChangePasswordModal from '../modal/ChangePasswordModal';
import * as md5 from 'md5';
import ChangeEmailModal from '../modal/ChangeEmailModal';
import InputFieldButtonRight from 'components/common/Input/InputFieldButtonRight';
import SelectField from 'components/common/Select/SelectField';
import * as Yup from 'yup';
import { FastField, Field, Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import Util from 'utils/Util';
import { isGoogleLogin } from 'state/session/sessionSelector';
import { getViewSettings } from 'state/company/companySelector';

const UserAccountSettingForm = props => {
  const {
    accountActions: { loadData, changeUserPassword, updateUserInfo, changeUserEmail },
    accountDetail,
    strictPassword,
    isGoogleLogin
  } = props;

  const viewSettings = useSelector(getViewSettings) || {};

  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [showEmailResetDialog, setShowEmailResetDialog] = useState(false);

  const { formatMessage: f } = useIntl();

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().trim().required(Util.translate('validate.required')).max(64, Util.translate('validate.max_length_64'))
  });

  /**
   * Handle form submission
   * @param data
   */
  const submit = data => {
    const userRequest = {
      id: accountDetail.id,
      fullName: data.fullName,
      lang: data.lang
    };
    updateUserInfo(userRequest);
  };

  /**
   * Handle change password submission
   * @param data
   */
  const changePasswordSubmit = (data) => {
    const request = {
      oldPassword: md5(data.currentPassword),
      newPassword: md5(data.newPassword),
      confirmPassword: md5(data.confirmPassword)
    };
    changeUserPassword(request);
    setShowPasswordDialog(false);
  };

  const changeEmailSubmit = ({ email }) => {
    changeUserEmail({
      id: accountDetail.id,
      email: email.trim()
    });
    setShowEmailResetDialog(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    let languages = [];
    for (const item of LANGUAGES) {
      const language = {
        value: item.value,
        label: f({ id: item.label })
      };
      languages = [...languages, language];
    }
    setLanguageOptions(languages);
  }, [accountDetail]);

  return (
    <React.Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...accountDetail, ...{ password: '' } }}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {formikProps => {
          return (
            <Form autoComplete='off' aria-autocomplete='none'>
              <div className='row'>
                <div className='col-sm-12 col-md-6'>
                  <FastField
                    component={InputFieldButtonRight}
                    name='fullName'
                    label={f({ id: 'label.name' })}
                    placeholder={f({ id: 'label.name' })}
                  />
                </div>
                <div className='col-sm-12 col-md-6'>
                  {accountDetail.email ? <FastField
                    component={InputFieldButtonRight}
                    name='email'
                    label={f({ id: 'label.email' })}
                    placeholder={f({ id: 'label.email' })}
                    disabled={true}
                    onClick={() => setShowEmailResetDialog(true)}
                  />
                    : <FastField
                      component={InputFieldButtonRight}
                      name='username'
                      label={f({ id: 'label.username' })}
                      placeholder={f({ id: 'label.username' })}
                      disabled={true}
                    />}
                </div>
                <div className='col-sm-12 col-md-6'>
                  <Field
                    component={SelectField}
                    name='lang'
                    label={f({ id: 'label.language' })}
                    placeholder={f({ id: 'label.language' })}
                    options={languageOptions}
                  />
                </div>
                {!isGoogleLogin && viewSettings.changePassword && <div className='col-sm-12 col-md-6'>
                  <FastField
                    component={InputFieldButtonRight}
                    name='password'
                    label={f({ id: 'label.password' })}
                    disabled={true}
                    placeholder={'********'}
                    showRightButton={true}
                    rightButtonLabel={f({ id: 'label.change' })}
                    onRightButtonClick={() => {
                      setShowPasswordDialog(true);
                    }}
                  />
                </div>}
              </div>
              <FormGroup className='text-right'>
                <Button
                  className='btn btn-info mr-1'
                  variant='contained'
                  color='primary'
                  type='submit'
                >
                  <TranslateMessage id='button.save' />
                </Button>
              </FormGroup>
            </Form>
          );
        }}
      </Formik>

      <ChangePasswordModal
        isOpen={showPasswordDialog && viewSettings.changePassword}
        toggle={() => setShowPasswordDialog(false)}
        onSubmit={changePasswordSubmit}
        strictPassword={strictPassword}
      />
      <ChangeEmailModal
        isOpen={showEmailResetDialog}
        toggle={() => setShowEmailResetDialog(false)}
        onSubmit={changeEmailSubmit}
      />
    </React.Fragment>
  );
};

UserAccountSettingForm.propTypes = {
  accountActions: PropTypes.object.isRequired,
  accountDetail: PropTypes.object,
  strictPassword: PropTypes.bool,
  isGoogleLogin: PropTypes.bool
};

UserAccountSettingForm.defaultProps = {
  accountDetail: {}
};

const mapStateToProps = (state) => {
  return {
    accountDetail: getUserInfo(state),
    isGoogleLogin: isGoogleLogin(state)
  };
};

export default connect(mapStateToProps)(
  props => (
    <ActionContext.Consumer>
      {({ accountActions }) => (
        <UserAccountSettingForm {...props} accountActions={accountActions} />
      )}
    </ActionContext.Consumer>
  )
);
