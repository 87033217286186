import { all, put, select, takeEvery } from 'redux-saga/effects';
import ImportTypes from './ImportTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/import';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';
import { getCompanyDomain } from '../company/companySelector';
import { getProgressingFiles, getPagination } from './importSelector';
import { saveAs } from 'file-saver';
import StorageHelper from 'utils/StorageHelper';
import { STORAGE_KEYS, PAGINATION } from 'configs/AppConfig';

function * loadImportData () {
  yield takeEvery(ImportTypes.GET_IMPORT_DATA_REQUEST, _loadImportData);
}

function * _loadImportData ({ payload }) {
  const domain = yield select(getCompanyDomain);
  const request = snakecaseKeys({ ...payload, domain });
  yield createRequest({
    api: {
      ...API.GET_IMPORT_DATA,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: ImportTypes.GET_IMPORT_DATA_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: ImportTypes.GET_IMPORT_DATA_ERROR,
        payload: status
      });
    }
  });
}

function * uploadContentCSV () {
  yield takeEvery(ImportTypes.UPLOAD_CONTENT_CSV, _uploadContentCSV);
}

function * _uploadContentCSV ({ payload }) {
  const data = new FormData();
  data.append('file', payload.file);
  // data.append('content_group_ids', new Blob([JSON.stringify(snakecaseKeys(payload.params))], {
  //   type: 'application/json'
  // }));
  yield createRequest({
    api: {
      ...API.UPLOAD_CONTENT_CSV,
      header: {
        'Content-Type': 'multipart/form-data',
        'X-Access-Token': StorageHelper.getCookie(STORAGE_KEYS.session)
      },
      data: data
    },
    onSuccess: function * (response) {
      payload.callback && payload.callback(response);
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.upload.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: ImportTypes.SUCCESS_UPLOAD_CONTENT_CSV,
        payload: {}
      });
      yield put({
        type: ImportTypes.GET_IMPORT_DATA_REQUEST,
        payload: {
          ...PAGINATION
        }
      });
      yield put({
        type: ImportTypes.CHECK_IMPORT_PROGRESS_REQUEST
      });
    },
    onError: function * (response) {
      payload.callback && payload.callback(response);
      yield put({
        type: ImportTypes.FAILURE_UPLOAD_CONTENT_CSV,
        payload: {}
      });
    },
    onRequestError: (response) => {
      payload.callback && payload.callback(response);
    }
  });
}

function * downloadTemplate () {
  yield takeEvery(ImportTypes.DOWNLOAD_TEMPLATE, _downloadTemplate);
}

function * _downloadTemplate ({ payload }) {
  yield createRequest({
    api: {
      ...API.DOWNLOAD_TEMPLATE
    },
    onSuccess: function (response) {
      const blob = new Blob(['\ufeff', response], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, payload);
    }
  });
}

function * downloadFileLogs () {
  yield takeEvery(ImportTypes.DOWNLOAD_FILE_LOGS, _downloadFileLogs);
}

function * _downloadFileLogs ({ payload }) {
  const { id, fileName } = payload;
  yield createRequest({
    api: {
      ...API.DOWNLOAD_FILE_LOGS,
      params: {
        id: id
      }
    },
    onSuccess: function (response) {
      const blob = new Blob(['\ufeff', response], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, fileName);
    }
  });
}

function * importContentCSV () {
  yield takeEvery(ImportTypes.IMPORT_CONTENT_CSV, _importContentCSV);
}

function * _importContentCSV ({ payload }) {
  yield createRequest({
    api: {
      ...API.IMPORT_CONTENT_CSV,
      params: {
        file_id: payload
      }
    },
    onSuccess: function * (response) {
      yield put({
        type: ImportTypes.GET_IMPORT_DATA_REQUEST,
        payload: {
          ...PAGINATION
        }
      });
      yield put({
        type: ImportTypes.CHECK_IMPORT_PROGRESS_REQUEST
      });
    }
  });
}

function * checkImportProgress () {
  yield takeEvery(ImportTypes.CHECK_IMPORT_PROGRESS_REQUEST, _checkImportProgress);
}

function * _checkImportProgress () {
  yield createRequest({
    api: {
      ...API.CHECK_IMPORT_PROGRESS
    },
    onSuccess: function * (response) {
      const progressFiles = yield select(getProgressingFiles);
      const pagination = yield select(getPagination);
      if (response.data.length !== progressFiles.length) {
        yield put({
          type: ImportTypes.GET_IMPORT_DATA_REQUEST,
          payload: pagination
        });
      }
      yield put({
        type: ImportTypes.CHECK_IMPORT_PROGRESS_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

export default function * importSaga () {
  yield all([
    loadImportData(),
    uploadContentCSV(),
    importContentCSV(),
    downloadTemplate(),
    downloadFileLogs(),
    checkImportProgress()
  ]);
}
