export default {
  GET_DISCLOSURE_UPLOADED_FILES: {
    url: '/upload-file/list',
    method: 'GET'
  },
  UPLOAD_CSV_FILE: {
    url: '/upload-file/upload-csv',
    method: 'POST'
  },
  UPLOAD_PDF_FILE: {
    url: '/upload-file/upload-pdf',
    method: 'POST'
  },
  IMPORT_CSV_FILE: {
    url: '/upload-file/import-csv-file',
    method: 'POST'
  },
  CHECK_IMPORT_PROGRESS: {
    url: '/upload-file/check-import-progress',
    method: 'GET'
  },
  DOWNLOAD_TEMPLATE: {
    url: '/upload-file/download-template',
    method: 'GET'
  }
};
