import { all } from 'redux-saga/effects';
import notificationSaga from './notification/notificationSaga';
import sessionSaga from './session/sessionSaga';
import handleApiError from './handler/errorSaga';
import routeSaga from './route/routeSaga';
import accountSaga from './account/accountSaga';
import contentSaga from './content/contentSaga';
import importSaga from './import/importSaga';
import importUserSaga from './import-user/importUserSaga';
import companySaga from './company/companySaga';
import uploadSaga from './upload/uploadSaga';
import searchSaga from './search/searchSaga';
import messageSaga from './message/messageSaga';
import userGroupSaga from './user-group/userGroupSaga';
import userSaga from './user/userSaga';
import contentGroupSaga from './content-group/contentGroupSaga';
import buttonSaga from './button/buttonSaga';
import bannerSaga from './banner/bannerSaga';
import sortSaga from './sort/sortSaga';
import templateSaga from './template/templateSaga';
import inputSaga from './input/inputSaga';
import layoutSaga from './layout/layoutSaga';
import tabSaga from './tab/tabSaga';
import homeSaga from './home/homeSaga';
import watermarkSaga from './watermark/watermarkSaga';
import searchResultSaga from './search-result/searchResultSaga';
import contentDetailSaga from './content-detail/contentDetailSaga';
import customLoginSaga from './login/loginSaga';
import emailTemplateSaga from './email-template/emailTemplateSaga';
import subscriptionSaga from './subscriptions/SubscriptionSaga';
import couponSaga from './coupon/couponSaga';
import paymentSaga from './payment/paymentSaga';
import hashtagSaga from './hashtag/hashtagSaga';
import exceedSessionSaga from './exceed-session/exceedSessionSaga';

// single entry point to start all Sagas at once
export default function * rootSaga () {
  yield all([
    sessionSaga(),
    notificationSaga(),
    handleApiError(),
    routeSaga(),
    accountSaga(),
    contentSaga(),
    importSaga(),
    importUserSaga(),
    companySaga(),
    uploadSaga(),
    searchSaga(),
    messageSaga(),
    userGroupSaga(),
    userSaga(),
    contentGroupSaga(),
    buttonSaga(),
    bannerSaga(),
    sortSaga(),
    templateSaga(),
    inputSaga(),
    layoutSaga(),
    tabSaga(),
    homeSaga(),
    searchResultSaga(),
    contentDetailSaga(),
    emailTemplateSaga(),
    watermarkSaga(),
    customLoginSaga(),
    subscriptionSaga(),
    couponSaga(),
    paymentSaga(),
    hashtagSaga(),
    exceedSessionSaga()
  ]);
}
