import SearchTypes from './SearchTypes';
import { PAGINATION } from '../../configs/AppConfig';

const initialState = {
  tabs: [],
  data: [],
  ftsContents: [],
  searchTermPage: [],
  totalPages: 0,
  totalRecords: 0,
  IsDataLoad: false,
  isSearchContent: false,
  pagination: {
    ...PAGINATION,
    startDate: null,
    endDate: null,
    sort: false,
    sortBy: '',
    totalPages: 0,
    totalRecords: 0
  },
  ftsPagination: {
    ...PAGINATION,
    totalPages: 0,
    totalRecords: 0
  },
  contentDetail: {},
  IsContentDetailLoad: false,
  searchSetting: {},
  beViewerHost: '',
  contentDetailParams: [],
  settingLogin: undefined,
  pageTitle: '',
  options: {},
  pageLogo: '',
  enablePrint: false,
  bodySetting: {},
  footerSetting: {},
  headerSetting: {},
  inputSetting: {
  },
  contentDetailSetting: {},
  tabSetting: {},
  companyName: '',
  layoutSetting: '',
  sidebarSetting: {},
  strictPassword: true,
  bannerSetting: {},
  moreLessBtnSetting: {},
  moreLessBackground: '',
  moreLessTextColor: '',
  accountType: '',
  selectedTabId: '',
  titleOfEmailField: '',
  titleOfPasswordField: '',
  allowForgotPassword: true,
  isLoading: false,
  multipleSelectHashtag: false,
  hashtagSetting: {},
  isCancelAccount: false
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  switch (type) {
    case SearchTypes.RESET_SETTING:
      newState.inputSetting = {};
      return newState;
    case SearchTypes.GET_DATA_REQUEST:
    case SearchTypes.GET_PUBLIC_DATA_REQUEST:
    case SearchTypes.SEARCH_FULL_TEXT_REQUEST:
      newState.IsDataLoad = true;
      return newState;
    case SearchTypes.GET_DATA_SUCCESS:
    case SearchTypes.GET_PUBLIC_DATA_SUCCESS:
    case SearchTypes.SEARCH_FULL_TEXT_SUCCESS:
      newState.data = [...payload.content];
      newState.pagination.totalRecords = payload.totalElements;
      newState.pagination.totalPages = payload.totalPages;
      newState.pagination.size = payload.pageSize || PAGINATION.size;
      newState.pagination.page = payload.pageNumber || PAGINATION.page;
      newState.pagination = { ...newState.pagination };
      newState.IsDataLoad = false;
      return newState;
    case SearchTypes.GET_CONTENT_INFO_SUCCESS:
      newState.contentDetail = { ...payload };
      newState.IsContentDetailLoad = false;
      return newState;
    case SearchTypes.GET_CONTENT_INFO_REQUEST:
      newState.IsContentDetailLoad = true;
      return newState;
    case SearchTypes.GET_CONTENT_INFO_ERROR:
      newState.contentDetail = null;
      newState.IsContentDetailLoad = false;
      return newState;
    case SearchTypes.GET_SETTING_SUCCESS:
      newState.accountType = payload.accountType;
      newState.strictPassword = payload.strictPassword;
      newState.allowLoginByIpAddress = payload.loginByIpAddress;

      newState.titleOfEmailField = payload.titleOfEmailField;
      newState.titleOfPasswordField = payload.titleOfPasswordField;

      newState.searchSetting = { ...payload.searchResultSettingResponse };
      newState.tabs = payload.searchTabResponses ? [...payload.searchTabResponses] : [];
      newState.contentDetailParams = payload.contentDetailParams ? [...payload.contentDetailParams] : [];
      newState.settingLogin = payload.settingLogin;
      newState.pageTitle = payload.titlePage;
      newState.pageLogo = payload.logo;
      newState.pageFavicon = payload.favicon;
      newState.beViewerHost = payload.beViewerHost;

      newState.bodySetting = payload.body;
      newState.footerSetting = payload.footerLinkResponse;
      newState.headerSetting = payload.header;
      newState.inputSetting = payload.input || {};
      newState.layoutSetting = payload.viewPage;
      newState.companyName = payload.companyName;
      if (!newState.inputSetting.borderColor) {
        newState.inputSetting.borderColor = '#3d993a';
        newState.inputSetting.hoverBorderColor = '#3d993a';
      }
      newState.contentDetailSetting = payload.contentSearchResultSettingResponse;
      newState.tabSetting = payload.tabSettingResponse;
      newState.bannerSetting = payload.banner;
      newState.sidebarSetting = {
        background: payload.sidebarBackground,
        active: payload.sidebarHighlight,
        text: payload.sidebarText,
        textActive: payload.sidebarTextHighlight,
        barBackground: payload.barBackground,
        barText: payload.barText
      };
      newState.moreLessBtnSetting = {
        moreLessBackground: payload.moreLessBackground,
        moreLessTextColor: payload.moreLessTextColor
      };
      newState.allowForgotPassword = payload.isActiveForgotPassword;
      newState.multipleSelectHashtag = payload.multipleSelectHashTag;
      newState.hashtagSetting = payload.hashTagSetting;
      newState.isCancelAccount = payload.cancelAccount;
      return newState;
    case SearchTypes.GET_OPTIONS_SUCCESS:
      newState.options = { ...payload };
      return newState;
    case SearchTypes.RESET_PAGE:
      newState.data = [];
      newState.ftsContents = [];
      newState.IsDataLoad = false;
      newState.pagination = {
        ...PAGINATION,
        totalPages: 0,
        totalRecords: 0
      };
      newState.ftsPagination = {
        ...PAGINATION,
        totalPages: 0,
        totalRecords: 0
      };
      newState.contentDetail = {};
      newState.IsContentDetailLoad = false;
      newState.isSearchContent = false;
      return newState;
    case SearchTypes.SYNC_CONTENT_DETAIL:
      if (newState.contentDetail.id === payload.contentId) {
        newState.contentDetail.isFavorite = payload.isFavorite;
      }
      newState.contentDetail = { ...newState.contentDetail };
      return newState;
    case SearchTypes.SELECTED_TAB_ID:
      newState.selectedTabId = payload;
      return newState;
    case SearchTypes.GET_SEARCH_TERM_PAGE_REQUEST:
      newState.isLoading = true;
      return newState;
    case SearchTypes.GET_SEARCH_TERM_PAGE_ERROR:
      newState.isLoading = false;
      return newState;
    case SearchTypes.GET_SEARCH_TERM_PAGE_SUCCESS:
      newState.searchTermPage = [...payload.content];
      newState.pagination.totalRecords = payload.totalElements;
      newState.pagination.totalPages = payload.totalPages;
      newState.pagination.startDate = payload.startDate;
      newState.pagination.endDate = payload.endDate;
      newState.pagination.size = payload.pageSize || PAGINATION.size;
      newState.pagination.page = payload.pageNumber || PAGINATION.page;
      newState.pagination.sort = payload.sort;
      newState.pagination.sortBy = payload.sortBy;

      newState.pagination = { ...newState.pagination };
      newState.isLoading = false;
      newState.totalRecords = payload.totalElements;
      newState.totalPages = payload.totalPages;
      return newState;
    default:
      newState = state;
      break;
  }
  return newState;
}
