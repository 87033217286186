
import { connect } from 'react-redux';
import { ActionContext } from '../../layout/MainContainer';
import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import paginationFactory from 'react-bootstrap-table2-paginator';
import TranslateMessage from '../../common/TranslateMessage/TranslateMessage';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import InputSearch from '../../common/Input/InputSearch';
import BootstrapTable from 'react-bootstrap-table-next';
// import CommonModal from '../../modal/CommonModal';
import PropTypes from 'prop-types';
// import {
//   getSubscriptionList,
//   getSubscriptionTotalPages,
//   getSubscriptionTotalRecords,
//   getPagination, getIsListLoad
// } from '../../../state/subscription/SubscriptionSelector';
import ModalConfirm from '../../common/Modal/ModalConfirm';
import { useHistory } from 'react-router-dom';
// import SubscriptionFormDetail from './SubscriptionFormDetail';
import { Spinner } from 'reactstrap';
import moment from 'moment';
import Util from '../../../utils/Util';
import { DURATION_MAP } from 'configs/AppConfig';
// import { getAuthUser } from 'state/session/sessionSelector';
import { getIsListLoad, getPagination, getSubscriptionList } from 'state/subscriptions/SubscriptionSelector';

// const FORM_MODAL = {
//   DETAIL: 'DETAIL',
//   REGISTER: 'REGISTER'
// };
const Style = {
  spin: {
    display: 'flex',
    justifyContent: 'center'
  },
  itemMax: {
    maxWidth: 300,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block'
  },
  coverCard: {
    display: 'flex',
    overflowX: 'auto',
    paddingBottom: '10px'
  },
  itemCard: {
    width: '300px',
    minWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.125)',
    borderRadius: '0.25rem'
  }

};

const UserTableSubscription = props => {
  const {
    list,
    queries,
    isListLoad,
    subscriptionActions: {
      getUserRegisteredPage,
      // getDetailUserRegistered,
      cancelUserRegistered,
      getPageUserSubscriptionPlans
      // getSubscriptionDetail
    }
    // couponActions: {
    //   loadCouponUser,
    //   setCouponUserNull
    // },
    // paymentActions: {
    //   authorizeCharge,
    //   resetHtml,
    //   resetPaymentStatus
    // },
    // notificationActions: {
    //   showNotification
    // },
    // sessionActions: {
    //   getAuthInfo
    // }
  } = props;
  const { formatMessage: f } = useIntl();
  const history = useHistory();

  const [isList, setIsList] = useState(false);
  // const [showFormModal, setShowFormModal] = useState('');
  // const [isDetailMode, setIsDetailMode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalMessage, setDeleteModalMessage] = useState('');
  const [selectedSubscription, setSelectedSubscription] = useState();
  // const [isOpenClose, setIsOpenClose] = useState(true);
  const [searchKey, setSearchKey] = useState('');

  // const isPaymentSuccess = useSelector((state) => state.payment.isPaymentSuccess);
  // const isPaymentError = useSelector((state) => state.payment.isPaymentError);
  // const authUser = useSelector(getAuthUser);

  // useEffect(() => {
  //   resetPaymentStatus();
  //   setShowFormModal('');
  // }, [isPaymentSuccess, isPaymentError]);
  /**
     * Total label for tables
     * @param from
     * @param to
     * @param size
     * @returns {*}
     */
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {f({ id: 'label.tableTotal' }, { from, to, size })}
    </span>
  );

  /**
     * Call data on page changed
     * @param page
     * @param sizePerPage
     */
  const handlePageChange = (page, sizePerPage) => {
    const newQueries = { ...queries };
    newQueries.page = page;
    newQueries.size = sizePerPage;
    newQueries.search_key = searchKey;
    getUserRegisteredPage(newQueries);
  };
  const pageListRenderer = ({
    pages,
    onPageChange
  }) => {
    // just exclude <, <<, >>, >
    const pageWithoutIndication = pages.filter(p => typeof p.page !== 'string');
    return (
      <ul className="pagination react-bootstrap-table-page-btns-ul">
        <li className={`page-item ${queries.page <= 1 ? 'disabled' : ''}`} onClick={() => onPageChange(1)} >
          <a className="page-link">{'<<'}</a>
        </li>
        <li className={`page-item ${queries.page <= 1 ? 'disabled' : ''}`} onClick={() => onPageChange(queries.page - 1)} >
          <a className="page-link">{'<'}</a>
        </li>
        {
          pageWithoutIndication.map(p => {
            if (p.page >= (queries.page - 3) && p.page <= (queries.page + 3)) {
              return (
                <li
                  className={`page-item ${queries.page === p.page ? 'active' : ''}`}
                  key={`page-${p.page}`}
                  onClick={() => onPageChange(p.page)}
                >
                  <a className="page-link">
                    {p.page}
                  </a>
                </li>
              );
            }
            if (p.page - 4 === queries.page || p.page === queries.page - 4) {
              return (
                <li
                  className={`page-item ${queries.page === p.page ? 'active' : ''}`}
                  key={`page-${p.page}`}
                  onClick={() => onPageChange(p.page)}
                >
                  <a className="page-link">
                    {'...'}
                  </a>
                </li>
              );
            }
          })
        }
        <li className={`page-item ${queries.page >= queries.totalPages ? 'disabled' : ''}`} onClick={() => onPageChange(queries.page + 1)} >
          <a className="page-link">{'>'}</a>
        </li>
        <li className={`page-item ${queries.page >= queries.totalPages ? 'disabled' : ''}`} onClick={() => onPageChange(queries.totalPages)} >
          <a className="page-link">{'>>'}</a>
        </li>
      </ul>
    );
  };

  /**
     * Table Pagination factory
     */
  const pagination = paginationFactory({
    page: queries.page,
    sizePerPage: queries.size,
    totalSize: queries.totalRecords,
    paginationSize: queries.totalPages,
    alwaysShowAllBtns: true,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    pageListRenderer: pageListRenderer,
    onPageChange: handlePageChange
  });

  // trigger event when the table has any data change here
  const onTableChange = (type, newState) => {
    // handle any data change here
    if (type === 'search') {
      const newQueries = { ...queries };
      newQueries.search_key = newState.searchText;
      newQueries.page = 1;
      setSearchKey(newState.searchText);
      getUserRegisteredPage(newQueries);
    }
  };

  const resetTable = () => {
    const newQueries = { ...queries };
    newQueries.page = 1;
    newQueries.size = 10;
    newQueries.search_key = '';
    getUserRegisteredPage(newQueries);
  };
  useEffect(() => {
    isList ? getPageUserSubscriptionPlans({ size: 100 }) : resetTable();
  }, [isList]);

  /**
     * Handle delete pressed
     * @param data
     */
  const handleDelete = (data) => {
    setDeleteModalMessage(f({ id: 'text.confirmCancel' }, { name: data.nameSubscriptionPlan }));
    setShowDeleteModal(true);
    setSelectedSubscription(data);
  };

  const doDeleteSubscription = () => {
    cancelUserRegistered({
      data: { id: selectedSubscription.id },
      queries: queries
    });
  };

  /**
     * Handle detail pressed
     * @param data
     */
  const handleDetail = (data) => {
    // getDetailUserRegistered(data.id);
    setSelectedSubscription(data);
    // setIsDetailMode(true);
    // setShowFormModal(FORM_MODAL.DETAIL);
    history.push('/subscription/' + data.subscriptionPlanId, { idRegister: data.id });
  };

  /**
     * Format header cell
     * @param col
     * @returns {*}
     */
  const headerFormatter = (col, row) => (
    <TranslateMessage id={col.text} />
  );

  /**
     * Actions component
     * @param col
     * @param row
     * @returns {*}
     */
  const renderActionsColumn = (col, row) => (
    <React.Fragment>
      <span className="pointer nowrap text-info mr-2" onClick={handleDetail.bind(this, row)}>
        <i className="fas fa-edit" /> <TranslateMessage id="label.detail" />
      </span>
      {
        !row.cancel &&
        <span className="pointer nowrap text-danger" onClick={handleDelete.bind(this, row)}>
          <i className="fa fa-trash" /> <TranslateMessage id="button.cancelSubscription" />
        </span>
      }

    </React.Fragment>
  );
  // const renderContentGroupColumn = (col, row) => {
  //   const value = row && row.subscriptionContentGroups
  //     ? row.subscriptionContentGroups.map(x => x.contentGroupName).join(', ')
  //     : [];
  //   return (
  //     <div title={value} style={Style.itemMax}>
  //       {
  //         value
  //       }
  //     </div>
  //   );
  // };
  const renderPriceColumn = (col, row) => {
    const value = '¥' + `${row.price !== 0 ? Util.formatThousandsNumber(row.price) : 0}`;
    return (<span>{value}</span>);
  };
  /**
   * Table columns configurations
   * @type {({dataField: string, headerFormatter: (function(*): *), text: string}|{dataField: string, headerFormatter: (function(*): *), text: string}|{dataField: string, headerFormatter: (function(*): *), text: string}|{formatter: (function(*, *): *), dataField: string, headerFormatter: (function(*): *), text: string}|{formatter: (function(*, *=): *), dataField: string, headerFormatter: (function(*): *), text: string})[]}
   */
  // const columns = [
  //   {
  //     dataField: 'name',
  //     text: 'label.name',
  //     headerFormatter,
  //     formatter: (col, row) => (<span style={Style.itemMax} title={row.nameSubscriptionPlan}>{row.nameSubscriptionPlan}</span>)
  //   },
  //   {
  //     dataField: 'price',
  //     text: 'label.totalPrice',
  //     headerFormatter,
  //     formatter: renderPriceColumn
  //   },
  //   {
  //     dataField: 'contentGroup',
  //     text: 'label.contentGroup',
  //     headerFormatter,
  //     formatter: renderContentGroupColumn
  //   },
  //   {
  //     dataField: 'createdDate',
  //     text: 'label.createdDate',
  //     headerFormatter,
  //     formatter: (col, row) => (moment.utc(row.createdDate).local().format('YYYY/MM/DD').toString())
  //   },
  //   {
  //     dataField: 'expiredDate',
  //     text: 'label.expiredDate',
  //     headerFormatter,
  //     formatter: (col, row) => (moment.utc(row.expiredDate).local().format('YYYY/MM/DD').toString())
  //   },
  //   {
  //     dataField: 'actions',
  //     text: 'label.actions',
  //     headerFormatter,
  //     formatter: renderActionsColumn
  //   }
  // ];
  const columns = [
    {
      dataField: 'name',
      text: 'label.plan',
      headerFormatter,
      formatter: (col, row) => (<span style={Style.itemMax} title={row.nameSubscriptionPlan}>{row.nameSubscriptionPlan}</span>)
    },
    {
      dataField: 'price',
      text: 'label.amount',
      headerFormatter,
      formatter: renderPriceColumn
    },
    {
      dataField: 'status',
      text: 'label.status',
      headerFormatter,
      formatter: (col, row) => f({ id: `subscriptionPlan.status.${row.status}` })
    },
    {
      dataField: 'registeredDate',
      text: 'label.registeredDate',
      headerFormatter,
      formatter: (col, row) => (moment.utc(row.createdDate).local().format('YYYY/MM/DD').toString())
    },
    {
      dataField: 'numberFreeTerm',
      text: 'label.freeDays',
      headerFormatter
    },
    {
      dataField: 'firstPaymentDate',
      text: 'label.firstPayment',
      headerFormatter,
      formatter: (col, row) => !row.cancel ? row.firstPaymentDate ? (moment.utc(row.firstPaymentDate).local().format('YYYY/MM/DD').toString()) : <div style={{ marginLeft: !row.firstPaymentDate ? '30px' : '' }}>{'-'}</div> : <div style={{ marginLeft: row.cancel ? '30px' : '' }}>{'-'}</div>
    },
    {
      dataField: 'expiredDate',
      text: 'label.expiredDate',
      headerFormatter,
      formatter: (col, row) => row.cancel ? (moment.utc(row.expiredDate).local().format('YYYY/MM/DD').toString()) : <div style={{ marginLeft: !row.cancel ? '45px' : '' }}>{'-'}</div>
    },
    {
      dataField: 'nextBillingDate',
      text: 'label.nextBillingDate',
      headerFormatter,
      formatter: (col, row) => !row.cancel ? (moment.utc(row.expiredDate).local().format('YYYY/MM/DD').toString()) : <div style={{ marginLeft: row.cancel ? '45px' : '' }}>{'-'}</div>
    },
    {
      dataField: 'cancelDate',
      text: 'label.cancelDate',
      headerFormatter,
      formatter: (col, row) => row.cancelDate ? (moment.utc(row.cancelDate).local().format('YYYY/MM/DD').toString()) : <div style={{ marginLeft: !row.cancelDate ? '45px' : '' }}>{'-'}</div>
    },
    {
      dataField: 'operations',
      text: 'label.operations',
      headerFormatter,
      formatter: renderActionsColumn
    }
  ];
  // const handleCompletePayment = () => {
  //   resetHtml();
  //   setShowFormModal('');
  //   getAuthInfo();
  //   getSubscriptionList({ size: 10, page: 1 });
  // };
  // const handleErrorPayment = _errorMessage => {
  //   if (_errorMessage) {
  //     showNotification({
  //       message: _errorMessage,
  //       level: 'error',
  //       autoDismiss: 3,
  //       position: 'tc'
  //     });
  //     setShowFormModal('');
  //   }
  // };
  // const handleFormSubmit = (data) => {
  //   // Check if add mode
  //   if (showFormModal === FORM_MODAL.REGISTER) {
  //     const params = {
  //       ...data,
  //       authUser,
  //       subscriptionPlanId: data.id
  //     };
  //     // Do add
  //     if (data.isHaveCard) {
  //       authorizeCharge(params);
  //     } else {
  //       const errorMessage = Util.handleVeritransPayment(params, authorizeCharge);
  //       if (errorMessage) {
  //         showNotification({
  //           message: errorMessage,
  //           level: 'error',
  //           autoDismiss: 3,
  //           position: 'tc'
  //         });
  //         setShowFormModal('');
  //       }
  //     }
  //   } else {
  //     setShowFormModal('');
  //   }
  // };

  return (
    <>
      <ToolkitProvider
        keyField='id'
        bootstrap4
        search
        data={list}
        columns={columns}
      >
        {
          props =>
            (
              <>
                <div className="row mb-3">
                  <div className="col-sm-12 col-md-4 mb-sm-3">
                    <button className="btn btn-info mr-1" onClick={() => {
                      setIsList(prevState => !prevState);
                    }} disabled={isListLoad}>
                      {
                        !isList && <TranslateMessage id="button.registerPlan" />
                      }
                      {
                        isList && <TranslateMessage id="button.myRegisterPlan" />
                      }
                    </button>
                  </div>
                  {
                    !isList && <div className="col-sm-12 col-md-8 mt-sm-0 mt-2">
                      <InputSearch
                        id="user-group-search"
                        searchText={queries.search_key}
                        onSearch={onTableChange}
                      />
                    </div>
                  }
                </div>
                {
                  (isListLoad) && <div style={Style.spin}>
                    <Spinner color='primary' style={{ marginRight: 20 }} /> <TranslateMessage id='progress.loading' />
                  </div>
                }
                {
                  (!isList && !isListLoad) && <BootstrapTable
                    wrapperClasses="table-responsive"
                    remote={{
                      filter: false,
                      pagination: list && list.length > 0,
                      sort: true,
                      cellEdit: false
                    }}
                    {...props.baseProps}
                    pagination={list && list.length > 0 ? pagination : null}
                    onTableChange={onTableChange}
                    noDataIndication={<TranslateMessage id="text.noData" />}
                    bordered={false}
                    tabIndexCell
                  />
                }
                {
                  (isList && !isListLoad) && <div style={Style.coverCard}>
                    {
                      isList && list.map(item => {
                        return (
                          <div key={item.id} className={item.id === list[0].id ? '' : 'ml-3'} style={Style.itemCard}>
                            <div className="card-header text-center">
                              <h4 className="text-bold">{item.name}</h4>
                              <h5 className="text-bold">
                                {
                                  item.price !== item.discountPrice && (item.discountPrice || item.discountPrice === 0)
                                    ? <del style={{ fontSize: '1rem', color: '#929292' }} className="mr-2">¥{Util.formatThousandsNumber(Util.addTax10(item.price))}</del>
                                    : null
                                }
                              ¥{Util.formatThousandsNumber(Util.addTax10(item.discountPrice || item.discountPrice === 0 ? item.discountPrice : item.price))}
                                {` (${f({ id: 'label.inclusiveTax' }, { value: '10%' })})`}
                              </h5>
                              <span>{item.duration + ' ' + f({ id: `subscription.${DURATION_MAP.get(0)}` })}</span>
                              <button className=" btn btn-xsm btn-round btn-block btn-success text-capitalize mt-2" onClick={() => {
                                // setSelectedSubscription(item);
                                // getSubscriptionDetail(item.id);
                                // setCouponUserNull();
                                // setShowFormModal(FORM_MODAL.REGISTER);
                                // setIsDetailMode(false);
                                history.push('/subscription/' + item.id);
                              }}>
                                {f({ id: 'label.register' })}
                              </button>
                            </div>
                            {
                              item.subscriptionDiscountType &&
                            <div className="p-0 w-100" style={{ display: 'flex', flexDirection: 'column', borderBottom: '1px solid rgba(0,0,0,.125)' }}>
                              <h5 className="justify-content-center my-2" style={{ display: 'flex' }}>{f({ id: 'label.discountDetail' })}</h5>
                              <p className="justify-content-center mb-2 px-2 text-center text-break" style={{ display: 'flex' }}>{f({ id: item.subscriptionDiscountType === 'ORDINAL' ? 'text.discountMessageOrdinal' : 'text.discountMessage' }, { name: item.nameSubscription, discount: item.discount })}</p>
                            </div>
                            }
                            <div className="card-body p-0">
                              <ul className="list-group list-group-flush pb-1">
                                <h5 className="t-level-5 text-center my-2">{f({ id: 'label.availableContent' })}</h5>
                                <div className="list-group-item">
                                  {
                                    item.subscriptionContentGroups && item.subscriptionContentGroups.map(contentGroup => {
                                      return (
                                        <div key={contentGroup.contentGroupId}>
                                          <i className="far fa-check-circle text-success mr-1"></i>
                                          {contentGroup.contentGroupName}
                                        </div>
                                      );
                                    })
                                  }
                                </div>
                              </ul>
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                }
              </>
            )
        }
      </ToolkitProvider>
      {/* <CommonModal
        isOpen={showFormModal === FORM_MODAL.DETAIL || showFormModal === FORM_MODAL.REGISTER}
        toggle={isOpenClose ? () => setShowFormModal('') : null}
        title={selectedSubscription ? selectedSubscription.nameSubscriptionPlan ? selectedSubscription.nameSubscriptionPlan : selectedSubscription.name : ''}
        size="lg"
        backdrop={'static'}
      >
        <SubscriptionFormDetail
          onCancel={() => setShowFormModal('')}
          onSubmit={handleFormSubmit}
          detailMode={isDetailMode}
          completePayment={handleCompletePayment}
          showNotification={showNotification}
          resetPaymentStatus={resetPaymentStatus}
          setIsOpenClose={setIsOpenClose}
          getCouponUser={loadCouponUser}
        />
      </CommonModal> */}
      <ModalConfirm
        isOpen={showDeleteModal}
        toggle={() => setShowDeleteModal(false)}
        title={f({ id: 'title.cancelSubscription' })}
        message={deleteModalMessage}
        doConfirm={doDeleteSubscription}
        doCancel={() => setShowDeleteModal(false)}
        backdrop={'static'}
        level={'danger'}
      />
    </>
  );
};
UserTableSubscription.propTypes = {
  subscriptionActions: PropTypes.object.isRequired,
  // couponActions: PropTypes.object.isRequired,
  // paymentActions: PropTypes.object.isRequired,
  // notificationActions: PropTypes.object.isRequired,
  // sessionActions: PropTypes.object.isRequired,
  list: PropTypes.array,
  queries: PropTypes.object,
  page: PropTypes.number,
  totalRecords: PropTypes.number,
  totalPages: PropTypes.number,
  searchProps: PropTypes.object,
  baseProps: PropTypes.object,
  isListLoad: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    list: getSubscriptionList(state),
    queries: getPagination(state),
    isListLoad: getIsListLoad(state)
  };
};

export default connect(mapStateToProps)(
  props => (
    <ActionContext.Consumer>
      {({ subscriptionActions }) => (
        <UserTableSubscription
          {...props}
          subscriptionActions={subscriptionActions}
        />
      )}
    </ActionContext.Consumer>
  )
);
